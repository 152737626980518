<div class="title-container mt-3">
    <div class="page-t">
        <div class="page-title" style="margin: auto;">
          <b>PRIVACY POLICY</b>
        </div>
    </div>
  </div>
  
  <div class="hostprofile">
    <div class="clarification">
      <div class="col-xs-12">
        <div class="row">
  
          <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 " *ngFor="let policy of policies">
            <!-- <div class="header-title">{{policy.policy_header}}</div> -->
            <span class="answer_tag" [innerHTML]="policy.policy_header"></span>
            <div style="position: relative; padding-left: 30px; ">
  <!--            <span class="content-holder" style="vertical-align: top;">A. </span> -->
              <span class="answer_tag" [innerHTML]="policy.policy"></span>
            </div>
            <br/>
          </div>
  
        </div>
        <style>
          span.answer_tag ul {
            padding: 0px;
          }
        </style>
  
        <hr/>
      </div>
    </div>
  </div>
  