import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import {WorldLeaders} from '../models/world-leaders';
import {WorldLeader} from '../models/world-leader';
import {PlayerSharedService} from '../services/player-shared.service'
import {map} from 'rxjs/operators'
import { UtilsClass } from '../components/utils';

@Injectable({
  providedIn: 'root'
})
export class WorldLeaderboardService {

  private token: string;

  constructor(private _http: HttpClient, private _sharedService: PlayerSharedService, private _utils: UtilsClass) { 
    this.token = _sharedService.getOption('player_token')
  }
  getHeaders(){
    let headers = {'Content-Type': 'application/json'}
    if (this.token){
      headers['Authorization'] = 'Bearer ' + this.token
    }
    return headers
  }

  getTournamentLeaderBoard(torunamenr_id: number): Observable<WorldLeader>{
    return this._http.get<WorldLeader>(environment.BASE_API_URL + 'tournaments/' + torunamenr_id + '/world_leaders/?limit=50&query=')
      .pipe(
        map(result => 
          result)
        );
  }

  getTournamentDetails(torunamenr_id: number): Observable<WorldLeader>{
    return this._http.get<WorldLeader>(environment.BASE_API_URL + 'tournaments/' + torunamenr_id)
      .pipe(
        map(result => 
          result)
        );
  }

  getLeaderBoard(timeFilter, stateFilter, isHomeBar): Observable<WorldLeaders>{
    return this._http.get<WorldLeaders>(environment.BASE_API_URL + 'games/world-leaders/?limit=50&query=&time=' + timeFilter + '&state=' + stateFilter + '&isHomeBar=' + isHomeBar)
    .pipe(
      map(result => 
        result)
      ); 
    }

    getPastLeaderBoard(timeFilter, stateFilter, isHomeBar): Observable<WorldLeaders>{
      return this._http.get<WorldLeaders>(environment.BASE_API_URL + 'games/past-world-leaders/?limit=50&query=&time=' + timeFilter + '&state=' + stateFilter + '&isHomeBar=' + isHomeBar)
      .pipe(
        map(result => 
          result)
        ); 
      }

    getHostLeaderBoard(timeFilter, stateFilter, isCurrent): Observable<WorldLeaders>{
      return this._http.get<any>(environment.BASE_API_URL + 'accounts/leaderboard/?limit=50&state=' + stateFilter + '&time=' + timeFilter + '&isCurrent=' + isCurrent)
      .pipe(
        map(result => 
          result)
        ); 
      }

    getHostState(hostID, isCurrent): Observable<any>{
      return this._http.get<any>(environment.BASE_API_URL + 'accounts/leaderboard/' + hostID + '/get_stats/?isCurrent=' + isCurrent)
      .pipe(
        map(result => result)
      );
    }
    
    getSearchedHostLeaderBoard(hostName, timeFilter, stateFilter, isCurrent): Observable<any>{
      return this._http.get<any>(environment.BASE_API_URL + 'accounts/leaderboard/?limit=50&state=' + stateFilter + '&query='+ hostName + '&time=' + timeFilter + '&isCurrent=' + isCurrent ,{ headers:  this._utils.getHeaders(null)})
        .pipe(
          map(result => 
            result),
            catchError(this.handlError)
          );
    }

    getStates(): Observable<any>{
      return this._http.get<any>(environment.BASE_API_URL + 'games/venue-states/')
        .pipe(
          map(result => 
            result),
            catchError(this.handlError)
          );
    }

    getSearchedTeamLeaderBoard(teamName, timeFilter, stateFilter, isHomeBar): Observable<any>{
      return this._http.get<any>(environment.BASE_API_URL + 'games/world-leaders/?query='+teamName + '&time=' + timeFilter + '&state=' + stateFilter + '&isHomeBar=' + isHomeBar ,{ headers:  this._utils.getHeaders(null)})
        .pipe(
          map(result => 
            result),
            catchError(this.handlError)
          );
    }

    getSearchedTeamPastLeaderBoard(teamName, timeFilter, stateFilter, isHomeBar): Observable<any>{
      return this._http.get<any>(environment.BASE_API_URL + 'games/past-world-leaders/?query='+teamName + '&time=' + timeFilter + '&state=' + stateFilter + '&isHomeBar=' + isHomeBar ,{ headers:  this._utils.getHeaders(null)})
        .pipe(
          map(result => 
            result),
            catchError(this.handlError)
          );
    }

    getSearchedTeamTournamentLeaderBoard(teamName, tournamentID): Observable<any>{
      return this._http.get<any>(environment.BASE_API_URL + 'tournaments/' + tournamentID + '/world_leaders/?query='+teamName ,{ headers:  this._utils.getHeaders(null)})
        .pipe(
          map(result => 
            result),
            catchError(this.handlError)
          );
    }

    handlError(error){
      let errorMessage = error.error;
      return throwError(errorMessage);
    }
  }
