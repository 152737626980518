export enum Answer {
    A = 1,
    B,
    C,
    D,
    E,
    F,
    G,
    H
}

export enum RoundType{
    "Multiple Choice" = 0,
    "Multiple Choice Wager",
    "Text Answers",
    "Text Answers Wager"
}