import { Component, OnInit } from '@angular/core';
import {ActiveGameVenue} from '../../../models/active_game_venue'
import {TeamGameScore} from '../../../models/team-game-score'
import {PlayerUser} from '../../../models/player_user'
import {PlayerSharedService} from '../../../services/player-shared.service'
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from "rxjs";
import {TokenPayload} from '../../../models/token-payload'
import {ActivatedRoute} from '@angular/router';
declare var jQuery:any;

@Component({
  selector: 'app-tournament-team-profile',
  templateUrl: './tournament-team-profile.component.html',
  styleUrls: ['./tournament-team-profile.component.css']
})
export class TournamentTeamProfileComponent implements OnInit {
  public loader: boolean;
  isOwner: boolean = false;
  player: PlayerUser;
  token: string;
  team_members: Array<any> = [];
  team_owner: any
  rank: number = 1;
  venueRank: number = 1;
  teamEditForm: FormGroup;
  successMsg: any;
  errorMsg: any;
  venue: any;
  gameID: any = null;
  inviteError: any = {email:[]};
  public invitePlayerForm: FormGroup;
  public result: any = {};
  inviteStatus: boolean;

  constructor(private route: ActivatedRoute, private _sharedService: PlayerSharedService) {
    this.token = localStorage.getItem('player_token')
    this.player =  JSON.parse(localStorage.getItem('player_user'));
   }

  ngOnInit() {
    this.loader = true;
    this.route.params.subscribe(params => {
      // this.rank = params['rank']
      this.venue = params['venueID'];
      this._sharedService.getProfile(params['teamID'], params['venueID']).subscribe(data => {
        this.result = data;
        this.team_members = data.registered_players_profile;
        this.venueRank = data.team_rank_venue;
        this.team_owner = data.owner;
        if (this.player){
          if (this.player.id == this.team_owner && this.player.profile.is_approved){
            this.isOwner = true;
            this.createTeamEditForm();
            this.inviteForm();
            this._sharedService.getVenueDetailService(this.venue).subscribe(result => {
              this.gameID = result.active_game;
          })
          }
        }
        this._sharedService.getRank(params['teamID']).subscribe(result => {
          this.rank = result.rank;
        })
        this.loader = false;
      })

    })
  }

  inviteForm(){
    this.invitePlayerForm = new FormGroup({
      email: new FormControl('',[
        Validators.required
      ])
    })
  }

  createTeamEditForm() {
    this.teamEditForm = new FormGroup({
      image_uri: new FormControl('',[
        Validators.required
      ]),
      file_name: new FormControl('',[
        Validators.required
      ]),
      image: new FormControl('',[
        Validators.required
      ]),
      name: new FormControl(this.player.my_team.name, [
        Validators.required
      ])
    });
  }

  updateTeam(image_file, image_file_name){
    let form_data = this.teamEditForm.value
    let data = {'name': form_data['name']}
    if (image_file != '' && image_file_name != ''){
      data['image_uri'] = image_file;
      data['file_name'] = image_file_name;
    }
    this._sharedService.updateTeamService(this.player.my_team.id, data, this.token)
    .subscribe(data => {
        localStorage.setItem('player_user', JSON.stringify(data.user));
        this.successMsg = 'Your Profile updated successfully.';
        this.player = data.user;
        jQuery('#editTeam').hide();
        jQuery('body').removeClass('modal-open');
        jQuery('body').css('padding-right',0);
        jQuery('.modal-backdrop').remove();
        delete this.errorMsg;
        location.reload();
        return data;
    }, error => {
        this.errorMsg = 'Something went wrong.';
        delete this.successMsg;
        return this.errorMsg;
    })
  }

  invitePlayer(){
    let data = this.invitePlayerForm.value;
    let gameInviteID = null;
    if (this.gameID){
      gameInviteID = this.gameID
    } else {
      gameInviteID = 'noActiveGame';
    }
    this._sharedService.invitePlayerService({'email': this.invitePlayerForm.value.email, 'team_id': this.player.my_team.id, 'game_id': gameInviteID, 'team_name': this.player.my_team.name}, this.token)
      .subscribe(data => {
          this.inviteError = {email:[]};
          this.inviteStatus = data.success;
          this.invitePlayerForm.controls['email'].setValue('');
      }, error => {
        this.inviteStatus = null;
          this.inviteError = error
      })
  }

  onInviteCLose(){
    this.inviteForm();
    this.inviteError = {email:[]};
    this.inviteStatus = null;
  }

  addOpacity() {
    jQuery(document).ready(function() {
      jQuery('.modal.fade').css('opacity', 1);
      jQuery('.modal.fade').addClass('show');  
      let cls = document.getElementsByClassName("modal-backdrop").length;
      if (cls>0) {
        jQuery('div.modal-backdrop').addClass('show');  
        jQuery('div.modal-backdrop').css('opacity', 0.8);  
      }
    });
  }

}
