import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HostService} from '../../../services/host.service';
import {Observable} from "rxjs";
import {WorldLeaderboardService} from '../../../services/world-leaderboard.service'

@Component({
  selector: 'app-host-tournaments-leaderboard',
  templateUrl: './host-tournaments-leaderboard.component.html',
  styleUrls: ['./host-tournaments-leaderboard.component.css']
})
export class HostTournamentsLeaderboardComponent implements OnInit {

  public tournamentId: any;
  public tournamentName: any;
  public leaderBoard: any;
  public filter: any;
  public loader: boolean;
  public defaultImage: string = 'assets/img/location.png';
  public defaultLogo: string = 'assets/img/logo1.png';
  public main_sponsor_image_path: string = 'assets/img/logo1.png';
  public second_sponsor_image_path: string = 'assets/img/logo1.png';
  public second_sponsor_url: string = null;
  public main_sponsor_url: string = null;
  constructor(private route: ActivatedRoute,
              private hostService: HostService,
              private router: Router,
              private _leaderboardServie: WorldLeaderboardService) { }

  ngOnInit() {
    this.loader = true;
    this.route.params.subscribe((result) => {
      if(!result.id) {
        this.getDefaultLeaderboard();

      } else {
        this.tournamentId = result.id;
        this.tournamentName = result.name;
        this.getLeaderboard(this.tournamentId);
      }
    });
    this.getTournamentDetails(this.tournamentId).subscribe(tournDetail => {
      this.main_sponsor_image_path = tournDetail.main_sponsor_image_path;
      this.second_sponsor_image_path = tournDetail.second_sponsor_image_path;
      this.second_sponsor_url = tournDetail.second_sponsor_url;
      this.main_sponsor_url = tournDetail.main_sponsor_url;
  })

  }

  getLeaderboard(tournamentId) {
    this.hostService.getTournamentLeaderboard(tournamentId)
      .subscribe((result) => {
        this.loader = false;
        return this.leaderBoard = result.sort(this.hostService.sortResponse('equalizer'));
      });
  }

  getTournamentDetails(tournament_id: any): Observable<any>{
    return this._leaderboardServie.getTournamentDetails(tournament_id);
  }

  getDefaultLeaderboard() {
    this.hostService.getDefaultLeaderboard()
      .subscribe((result) => {
        // console.log(result);
        this.loader = false;
        return this.leaderBoard = result.results.sort(this.hostService.sortResponse('equalizer'));
      });
  }

  popItUpSecondary(){
    window.open(this.second_sponsor_url, 'popUpWindow','height='+window.innerHeight+',width='+window.innerWidth+',left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes');
  }

  popItUpMain(){
    window.open(this.main_sponsor_url, 'popUpWindow','height='+window.innerHeight+',width='+window.innerWidth+',left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes');
  }
}
