<div class="title-container mt-3">
  <div class="page-t">
      <div class="page-title" style="margin: auto;">
          <b>THERE ARE TOURNAMENTS HAPPENING HERE! CLICK BELOW TO COMPETE</b>
      </div>
  </div>
</div>

<div *ngIf="tournaments.length > 0">
  <div class="tournamentcard m-3" *ngFor="let tournament of tournaments">
    <div class="top">
        <div class="image">
            <img src="{{tournament.image}}">
        </div>
        <div class="game-info mr-2">
            <span>{{tournament.name}}</span>
            <small>INPUT FROM ADMIN</small>
        </div>
        <div class="button d-flex flex-column justify-content-center">
            <a style="cursor: pointer;" class="btn btn-md mb-1" data-toggle="modal" data-target="#team-info-modal" (click)="tournamentInfo(tournament)">INFO</a>
            <a style="cursor: pointer;" class="btn btn-md mb-1" data-toggle="modal" (click)="joinTheTournament()">CLICK HERE TO COMPETE</a>
        </div>
    </div>
  </div>
</div>

<div *ngIf="tournaments.length == 0">
  <div class="tournamentcard m-3">
    <div class="top">
        <div class="game-info mr-2">
            <span>NO SCOREBOARD <span style="text-align: center;font-size: medium">BUT You can still play in tonight's game</span></span>
        </div>
        <div class="button d-flex flex-column justify-content-center">
            <a style="cursor: pointer;" class="btn btn-md mb-1" data-toggle="modal" (click)="justPlayTonight()">PLAY</a>
        </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="team-info-modal">
  <div class="modal fade" id="team-info-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
          <div class="modal-header">
              <span class="modal-title" id="example ModalLabel">INFO</span>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" class="fa fa-times"></span>
              </button>
          </div>
          <div class="modal-body d-flex justify-content-center">
              <div class="mr-3">
                  <div class="image">
                      <img src="{{selectedTournament.image}}">
                  </div>
              </div>
              <div class="d-flex flex-column" style="margin:auto 0">
                  <span>{{selectedTournament.name}}</span>
                  <small [innerHtml]="selectedTournament.info"></small>
              </div>
          </div>
          </div>
      </div>
  </div>
</div>
<script>
//
/*function openNav() {
  document.getElementById("myNav").style.height = "100%";
}

function closeNav() {
  document.getElementById("myNav").style.height = "0%";
}*/
//
$('#myModal').on('shown.bs.modal', function () {
  $('#myInput').trigger('focus')
})
</script>
