<div class="title-container mt-3">
  <div class="page-t">
    <div class="row">
      <div class="col-md-4 col-3">
        <div class="top-logo__ logo_align" *ngIf="main_sponsor_url">
          <a href="/#{{this.router.url}}" (click)="popItUpMain()"><img [src]="main_sponsor_image_path != null ? main_sponsor_image_path : defaultLogo" alt="TRIVTRAK LOGO" class="triv_logo lg-right" ></a>
        </div>
        <div class="top-logo__ logo_align" *ngIf="!main_sponsor_url">
          <img [src]="main_sponsor_image_path != null ? main_sponsor_image_path : defaultLogo" alt="TRIVTRAK LOGO" class="triv_logo lg-right" >
        </div>
      </div>
      <div class="col-md-4 col-6">
        <div class="page-title cus-width-title">
            <b>TOP 50 <br/> {{tournamentName}} LEADERBOARD</b>
        </div>
      </div>

      <div class="col-md-4 col-3">
          <div class="top-logo__ logo_align" *ngIf="second_sponsor_url">
            <a href="/#{{this.router.url}}" (click)="popItUpSecondary()"><img [src]="second_sponsor_image_path != null ? second_sponsor_image_path : defaultLogo" alt="TRIVTRAK LOGO" class="triv_logo lg-left"></a>
          </div>
          <div class="top-logo__ logo_align" *ngIf="!second_sponsor_url">
            <img [src]="second_sponsor_image_path != null ? second_sponsor_image_path : defaultLogo" alt="TRIVTRAK LOGO" class="triv_logo lg-left">
          </div>
        </div>
    </div>
  </div>
</div>

<div class="searchbox mt-3 ml-3 mr-3">
  <form action="#">
    <input type="text" placeholder="SEARCH TEAM" [(ngModel)]="filter" name="search">
    <button type="submit"><i class="fa fa-search"></i></button>
  </form>
</div>
<div class="scoreboard-tbl m-3">
  <div class="table-responsive table-fontsize">
  <table class="table-hover" mdbTable >
    <thead>
    <tr>
      <th [mdbTableSort]="leaderBoard" sortBy="rank" style="background-color: rgb(33, 32, 30); color:  #ffbf00;"><div class="text-center">RK</div></th>
      <th [mdbTableSort]="leaderBoard" sortBy="name" style="background-color: rgb(33, 32, 30); color:  #ffbf00;" class="teamtbl">TEAM</th>
      <th class="hostColumn" style="background-color: rgb(33, 32, 30); color:  #ffbf00;">Host</th>
      <th class="venuetbl" style="background-color: rgb(33, 32, 30); color:  #ffbf00;"><div class="text-center" >VENUE</div></th>
      <th class="hostColumn" style="background-color: rgb(33, 32, 30); color:  #ffbf00;"><div class="text-center">P</div></th>
      <th [mdbTableSort]="leaderBoard" sortBy="equalizer" style="background-color: rgb(33, 32, 30); color:  #ffbf00;"><div class="text-center">TWS</div></th>
      <th style="background-color: rgb(33, 32, 30);"><div class="text-center"></div></th>
    </tr>
    </thead>
    <tbody *ngIf="leaderBoard != null">
    <tr *ngFor="let leaderBoard of leaderBoard |filter:filter" style="background-color: rgb(33, 32, 30); color: white;" [ngClass]="{'unregistered': !leaderBoard.registered_players}" >
      <td scope="row"><div class="text-center">{{leaderBoard.rank}}</div></td>
      <td [routerLink]="['/host/team-profile', leaderBoard.id, leaderBoard.venue.id,leaderBoard.rank]">{{leaderBoard.name}}</td>
      <td class="hostColumn"><div class="text-center">{{leaderBoard.venue.hostname}}</div></td>
      <td>
        <div class="text-center"  >
          <img class="hostColumn" [src]="leaderBoard.venue.image != null ? leaderBoard.venue.image : defaultImage" style="margin: 0 10px;" />
          <span>{{leaderBoard.venue.name}}</span>
        </div>
      </td>
      <td class="hostColumn"><div class="text-center">{{leaderBoard.num_of_players}}</div></td>
      <td><div class="text-center">{{leaderBoard.equalizer}}</div></td>
      <td><div class="arrow fa fa-angle-right" [routerLink]="['/host/team-profile', leaderBoard.id, leaderBoard.venue.id,leaderBoard.rank]"></div></td>
    </tr>
    </tbody>
  </table>
</div>
</div>

<div class="loader" *ngIf="loader">
  <div class="loaderbx">
    <i class="fas fa-3x fa-spinner fa-spin" aria-hidden="true"></i>
  </div>
</div>
