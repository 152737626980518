import { Component, OnInit } from '@angular/core';
import {ActiveGameVenue} from '../../../models/active_game_venue'
import {Router} from '@angular/router';
import {PlayerUser} from '../../../models/player_user'
declare var jQuery:any;

@Component({
  selector: 'app-join-team-congrats',
  templateUrl: './join-team-congrats.component.html',
  styleUrls: ['./join-team-congrats.component.css']
})
export class JoinTeamCongratsComponent implements OnInit {
  selectedVenue: ActiveGameVenue;
  message: string;
  button_text: string = 'OK';
  player: PlayerUser;
  
  constructor(private router: Router) {
    this.message = localStorage.getItem('congrats-mesg')
    this.player = JSON.parse(localStorage.getItem('player_user'));
    this.selectedVenue = JSON.parse(localStorage.getItem('venue'));

    if ((this.player.email == null || this.player.email.trim().length <= 0) && !this.selectedVenue.open_tournament && this.selectedVenue.active_game){
        this.button_text = "PLAY";
    }
   }

  ngOnInit() {
    jQuery('#joinTeamCongratsModal').modal('toggle');
    this.addOpacity();
  }

  proceed(){
    if (this.selectedVenue.active_game){
    this.router.navigate(['/score-card', this.selectedVenue.active_game], {replaceUrl:true});
    } else {
      jQuery('#joinTeamCongratsModal').modal('toggle');
      this.router.navigateByUrl('/');
    }
  }

  addOpacity() {
    jQuery(document).ready(function() {
      jQuery('.modal.fade').css('opacity', 1);
      jQuery('.modal.fade').addClass('show');  
      let cls = document.getElementsByClassName("modal-backdrop").length;
      if (cls>0) {
        jQuery('div.modal-backdrop').addClass('show');  
        jQuery('div.modal-backdrop').css('opacity', 0.8);  
      }
    });
  }
}
