<div class="title-container mt-3">
  <div class="page-t">
    <div>
        <div class="col-md-5 col-4 col-sm-5 col-lg-5 col-xl-4" style="float: right;">
          
            <div class="form-group">
                <label class="lbl-title">Choose:</label>
          <select class="form-control player-faq" (change)="filterFAQ($event.target.value)" >
            <option value="player">PLAYER</option>
            <option value="host"> HOST</option>
            <option value="virtual"> VIRTUAL</option>
          </select>
          </div>
       </div>
      <div class="page-title" style="margin: auto;">
        <b>FAQ</b>
      </div>
      


    </div>
  </div>
</div>

<div class="hostprofile">
  <div class="clarification">
    <div class="col-xs-12">
      <div class="row">

        <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 " *ngFor="let faq of faqs">
          <div [ngClass]="{'header-title': true, 'question-is-latest': faq.is_updated}"><span >Q.{{faq.faq_question}} ?</span></div>
          <div style="position: relative; padding-left: 30px; " [ngClass]="{'answer-is-latest': faq.is_updated}">
<!--            <span class="content-holder" style="vertical-align: top;">A. </span> -->
            <span class="answer_tag" [innerHTML]="faq.faq_answer"></span>
          </div>
          <br/>
        </div>

      </div>
      <style>
        span.answer_tag ul {
          padding: 0px;
        }
      </style>

      <hr/>
    </div>
  </div>
</div>
