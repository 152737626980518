import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {PlayerSharedService} from '../../services/player-shared.service'
declare var jQuery:any;
var socket;
var thisWebsocket;

@Component({
  selector: 'app-health-check',
  templateUrl: './health-check.component.html',
  styleUrls: ['./health-check.component.css']
})
export class HealthCheckComponent implements OnInit {
  socketStatusMessage: string;
  socketErrorMessage: any;
  dbStatusMessage: string;
  dbErrorMessage: any;


  constructor(private route: Router, private _sharedService: PlayerSharedService) {
    thisWebsocket = this;
   }

  ngOnInit() {
    jQuery('#healthCheckModel').modal('toggle');
      thisWebsocket._sharedService.checkDB().subscribe(data => {
          thisWebsocket.dbStatusMessage = data.status
          thisWebsocket.dbErrorMessage = data.errMessage
      }, error => {
        thisWebsocket.dbStatusMessage = "Fail"
        thisWebsocket.dbErrorMessage = error;
        thisWebsocket.dbStatusMessage = "Fail";
            thisWebsocket.dbErrorMessage = error;
      })

      try{
        thisWebsocket.socketStatusMessage = "Success";
        socket = new WebSocket(environment.WS_URL + 'health-check/');
        // ws://localhost:8001/ws/
        // socket = new WebSocket('ws://localhost:8001/healthCheck/');
        socket.onopen = function open() {
          console.log('WebSockets connection created.');
        };
        socket.onmessage = function(event) {
          // debugger;
          let data = JSON.parse(event.data);
          thisWebsocket.socketStatusMessage = data['status'];
          thisWebsocket.socketErrorMessage = data['errMessage']
          console.log(thisWebsocket.socketStatusMessage)
        };
        if (socket.readyState == WebSocket.OPEN) {
          socket.onopen();
        }
        
      } catch (error){
        console.log(error)
        thisWebsocket.socketStatusMessage = "Fail";
        thisWebsocket.socketErrorMessage = error;
        thisWebsocket.dbStatusMessage = "Fail";
        thisWebsocket.dbErrorMessage = error;
      }
    
  }

  proceed(){
    jQuery('#healthCheckModel').modal('toggle');
    this.route.navigate(['/'], {replaceUrl:true});
  }

}
