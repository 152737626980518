<div class="title-container mt-3">
  <div class="page-t">
      <div class="page-title" style="margin: auto;">
          <b>SCOREBOARDS</b>
      </div>
  </div>
</div>

<div class="tournamentcard m-3" *ngFor="let tournament of myActiveTournaments">
  <div class="card-header navbar" style="background-color: rgb(39, 150, 39); color: white;" *ngIf="tournament.is_default">
      <span class="date">{{(tournament.end_date != null)?((tournament.end_date | date)): "CURRENT LEADERBOARD 2020"}}</span>
  </div>
  <div class="card-header navbar" *ngIf="!tournament.is_default">
      <span class="live">LIVE SCOREBOARDS</span>
      <span class="date">{{(tournament.end_date != null)?((tournament.end_date | date)): "DEFAULT"}}</span>
  </div>
  <div class="top">
      <div class="image">
            <div class="imageround">
          <img [src]="tournament.image_path != null ? tournament.image_path : 'assets/img/tournament.png'">
          </div>
      </div>
      <div class="game-info mr-2">
          <span>{{tournament.name}}</span>
          <small>{{tournament.main_sponsor_name}}</small>
      </div>
      <div class="button d-flex flex-column justify-content-center">
          <a style="cursor: pointer;" class="btn btn-md mb-1" data-toggle="modal" data-target="#team-info-modal" (click)="tournamentInfo(tournament)">INFO</a>
          <a class="btn btn-md" *ngIf="tournament.is_default" [routerLink]="['/tournament-leaderboard-home', tournament.id]" role="button">LEADERBOARD</a>
          <a class="btn btn-md" *ngIf="!tournament.is_default" [routerLink]="['/tournament-leaderboard', tournament.id, tournament.name]" role="button">LEADERBOARD</a>
      </div>
  </div>
</div>
<div class="tournamentcard m-3" *ngFor="let tournament1 of allTournaments">
  <div class="card-header navbar">
      <span>UPCOMING SCOREBOARDS</span>
      <span class="date">{{(tournament1.end_date != null)?((tournament1.end_date | date)): "DEFAULT"}}</span>
  </div>
  <div class="top">
      <div class="image">
            <div class="imageround">
          <img [src]="tournament1.image_path != null ? tournament1.image_path : 'assets/img/tournament.png'">
          </div>
      </div>
      <div class="game-info mr-2">
          <span>{{tournament1.name}}</span>
          <small>{{tournament1.main_sponsor_name}}</small>
      </div>
      <div class="button d-flex flex-column justify-content-center">
          <a style="cursor: pointer;" class="btn btn-md mb-1" data-toggle="modal" data-target="#team-info-modal" (click)="tournamentInfo(tournament1)">INFO</a>
          <a class="btn btn-md" *ngIf="tournament1.is_default" [routerLink]="['/tournament-leaderboard-home', tournament1.id]" role="button">LEADERBOARD</a>
          <a class="btn btn-md" *ngIf="!tournament1.is_default" [routerLink]="['/tournament-leaderboard', tournament1.id, tournament1.name]" role="button">LEADERBOARD</a>
      </div>
  </div>
</div>

<div class="tournamentcard m-3" *ngFor="let tournament2 of pastTournaments">
  <div class="card-header navbar">
      <span>PAST SCOREBOARDS</span>
      <span class="date">{{(tournament2.end_date != null)?((tournament2.end_date | date)): "DEFAULT"}}</span>
  </div>
  <div class="top">
      <div class="image">
            <div class="imageround">
          <img [src]="tournament2.image_path != null ? tournament2.image_path : 'assets/img/tournament.png'">
          </div>
      </div>
      <div class="game-info mr-2">
          <span>{{tournament2.name}}</span>
          <small>{{tournament2.main_sponsor_name}}</small>
      </div>
      <div class="button d-flex flex-column justify-content-center">
          <a style="cursor: pointer;" class="btn btn-md mb-1" data-toggle="modal" data-target="#team-info-modal" (click)="tournamentInfo(tournament2)">INFO</a>
          <a class="btn btn-md" *ngIf="tournament2.is_default" [routerLink]="['/tournament-leaderboard-home', tournament2.id]" role="button">LEADERBOARD</a>
          <a class="btn btn-md" *ngIf="!tournament2.is_default" [routerLink]="['/tournament-leaderboard', tournament2.id, tournament2.name]" role="button">LEADERBOARD</a>
      </div>
  </div>
</div>

<div class="tournamentcard m-3">
  <div class="card-header navbar">
      <span>REQUEST A SCOREBOARD</span>
      <!-- <span class="date">{{(tournament.end_date != null)?((tournament.end_date | date)|dateSuffix): "DEFAULT"}}</span> -->
  </div>
  <div class="alert alert-success alert-dismissible" *ngIf="successMsg">
      <button type="button" class="close" data-dismiss="alert">&times;</button>
      Your Request Has Been sent to admin successully
    </div>
    <div class="alert alert-danger alert-dismissible" *ngIf="errMsg">
      <button type="button" class="close" data-dismiss="alert">&times;</button>
      Please try again later
    </div>
  <div class="top">
      <div class="image">
          <img class='woCircle' src="assets/img/tournament.png">
      </div>
      <div class="game-info mr-2">
          <span>WOULD YOU LIKE TO START A NEW SCOREBOARD ?</span>
          <!-- <small>INPUT FROM ADMIN</small> -->
      </div>
      <div class="button d-flex flex-column justify-content-center">
          <a style="cursor: pointer;" class="btn btn-md mb-1" data-toggle="modal" data-target="#tourn-request-modal" (click)="addOpacity()">CLICK HERE</a>
          <!-- <a class="btn btn-md" *ngIf="tournament.is_default" routerLink="/tournament-leaderboard" role="button">LEADERBOARD</a>
          <a class="btn btn-md" *ngIf="!tournament.is_default" [routerLink]="['/tournament-leaderboard', tournament.id, tournament.name]" role="button">LEADERBOARD</a> -->
      </div>
  </div>
</div>

<div class="tournamentcard m-3" *ngFor="let tournament3 of defaultPastLeaderBoard">
  <div class="card-header navbar" style="background-color: #9370DB !important; color: white;">
      <span>RETIRED LEADERBOARD 2019</span>
  </div>
  <div class="top">
      <div class="image">
            <div class="imageround">
          <img [src]="tournament3.image_path != null ? tournament3.image_path : 'assets/img/tournament.png'">
          </div>
      </div>
      <div class="game-info mr-2">
          <span>{{tournament3.name}}</span>
          <small>{{tournament3.main_sponsor_name}}</small>
      </div>
      <div class="button d-flex flex-column justify-content-center">
          <a style="cursor: pointer;" class="btn btn-md mb-1" data-toggle="modal" data-target="#team-info-modal" (click)="tournamentInfo(tournament3)">INFO</a>
          <a class="btn btn-md" [routerLink]="['/past-leaderboard-home', tournament3.id]" role="button">LEADERBOARD 2019</a>
      </div>
  </div>
</div>

<div class="team-form-modal">
  <div class="modal fade" id="tourn-request-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header" style="height: 42px !important">
              <span class="modal-title">Fill the Form</span>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" class="fa fa-times"></span>
              </button>
            </div>
              <div class="modal-container">
                  <div class="modal-body">
                      <span>FILL IN THE DETAILS TO REQUEST A SCOREBOARD</span>
                  </div>
                  <div class="team-form mb-4">
                      <form (ngSubmit)="onStartTournamentRequest()" [formGroup]="tournReqForm">
                          <div class="form-group">
                            <input type="text" class="form-control" placeholder="Venue" formControlName="venue">
                              <span *ngFor="let error of tournReqErrors.venue" class="error-message">{{ error }}</span>
                          </div>
                          <div class="form-group">
                              <input type="email" class="form-control" placeholder="Email Address" formControlName="email">
                              <span *ngFor="let error of tournReqErrors.email" class="error-message">{{ error }}</span>
                          </div>
                          <div class="form-group">
                              <input type="text" class="form-control" placeholder="Comment" formControlName="comment">
                              <span *ngFor="let error of tournReqErrors.comment" class="error-message">{{ error }}</span>
                          </div>
                          <div class="form-btn">
                              <!-- <a style="cursor: pointer;" class="btn success mr-1" data-toggle="modal" data-target="#success-modal">CLICK TO OWN</a> -->
                              <button type="submit" style="cursor: pointer;" id="req-submit" class="btn success mr-1" data-toggle="modal">CLICK TO REQUEST</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<div class="team-info-modal">
    <div class="modal fade" id="team-info-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <span class="modal-title" id="example ModalLabel" >{{selectedTournament.name}}</span>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" class="fa fa-times"></span>
            </button>
          </div>
          <div class="modal-body d-flex ">

            <div class="row" style="margin-left: 0px; margin-right: 0px;">

              <div class="col-12">
                <div class="row info-modal">
                  <div class="col-md-3">
                    <div class="image">
                      <div class="imageround">
                        <img src="{{selectedTournament.image_path}}" *ngIf="selectedTournament.image_path">
                        <img src="assets/img/tournament.png" *ngIf="!selectedTournament.image_path">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <dl class="info-modal">
                      <div class="listpart" *ngIf="selectedTournament.start_date">
                        <dt>Start Date</dt>
                        <dd> : {{selectedTournament.start_date}}</dd>
                      </div>
                      <div class="listpart" *ngIf="selectedTournament.end_date">
                        <dt>End Date</dt>
                        <dd> : {{selectedTournament.end_date}}</dd>
                      </div>
                      <div class="listpart" *ngIf="selectedTournament.main_sponsor_name">
                        <dt>Sponsor</dt>
                        <dd> : {{selectedTournament.main_sponsor_name}}</dd>
                      </div>
                    </dl>
                  </div>
                  <hr/>
                  <div class="row ">
                    <div class="col-md-12">
                      <span style="color: #ffbf00;">Venues</span>
                      <ol class="text-left" style="padding-left: 18px;" *ngIf="selectedTournament.venues">
                        <li *ngFor="let venue of selectedTournament.venues">
                          <!--<a routerLink="/host/launch-game/{{venue.id}}">{{ venue.name }}</a>-->
                          {{ venue.name }}
                        </li>
                      </ol>
                      <p *ngIf="selectedTournament.venues && selectedTournament.venues.length == 0">ALL VENUES</p>
                      <hr/>
                      <div class="text-left">
                        <small [innerHtml]="selectedTournament.info"></small>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<div class="loader" *ngIf="loader">
  <div class="loaderbx">
    <i class="fas fa-3x fa-spinner fa-spin" aria-hidden="true"></i>
  </div>
</div>

<script>
//
/*function openNav() {
  document.getElementById("myNav").style.height = "100%";
}

function closeNav() {
  document.getElementById("myNav").style.height = "0%";
}*/
//
$('#myModal').on('shown.bs.modal', function () {
  $('#myInput').trigger('focus')
})
</script>
