import {Injectable} from '@angular/core';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {tap, retryWhen, delay } from 'rxjs/operators';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

export const WS_ENDPOINT = environment.WS_URL;
export const RECONNECT_INTERVAL = environment.RECONNECT_INTERVAL;


@Injectable({
  providedIn: 'root'
})
export class WebsocketProviderService {
  private socket$: WebSocketSubject<any>;
  public ws: Observable<any>;
  private gameID: number;
  public url: string;
  public calls: number = 0;

  constructor() {
  }
  
  public connect(gameID: number): Observable<any> {
    this.gameID = gameID;
    this.url = WS_ENDPOINT;
    this.calls ++;
    console.log("WS SERVICE !!!!! CALLED " + this.calls + " times");
    //if (!this.socket$ || this.socket$.closed) {
      this.socket$ = this.getNewWebSocket();
      this.ws = this.socket$.pipe(
        retryWhen(errors => errors.pipe(
            tap(err => console.log('[WebsocketProviderService] Trying to reconnect to game: ', this.gameID,' Error: ', err)
          ), 
          delay(RECONNECT_INTERVAL)
          )
        )
      );
    //}
    return this.ws;
  }
  
  private getNewWebSocket(): WebSocketSubject<any> {
    try {
      return webSocket({
            url: this.url,
            openObserver: {
              next: () => {
                console.log("[WebsocketProvider Service]: connection ok");
                this.sendMessage({
                  command: "join",
                  game: this.gameID
                });
              }
            },
            closeObserver: {
              next(closeEvent) {
                console.log('[WebsocketProvider Service]: connection closed');
                console.log(closeEvent);
              }
            },
          });
    } catch {
      setTimeout(function(){
        return this.getNewWebSocket();
      }, RECONNECT_INTERVAL*100);
    }
  }

  sendMessage(msg: any): void {
    console.log("sending connect message");
    this.socket$.next(msg);
  }

  close(): void {
    console.log("Closing socket to" +  this.url);
    this.socket$.complete(); 
  }

}