import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HostService} from '../../../services/host.service';
import {environment} from '../../../../environments/environment';
import {CookieService} from 'ngx-cookie-service';
declare var jQuery:any;
@Component({
  selector: 'app-host-tournaments',
  templateUrl: './host-tournaments.component.html',
  styleUrls: ['./host-tournaments.component.css']
})
export class HostTournamentsComponent implements OnInit {

  public tournaments: any;
  public selectedTournament: any = [];
  public loader: boolean;
  public defaultImage : string = 'assets/img/tournament.png';
  
  public activeTournaments: any = [];
  public upcomingTournaments: any = [];
  public pastTournaments: any = [];
  public venueArray: any  = [];
  constructor(private route: ActivatedRoute,
              private hostService: HostService,
              private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.getTournaments();

    let headers = {
      'Authorization':  'Bearer ' + this.cookieService.get('TrivTrak_TOKEN')
    };
    this.hostService.getHostVenues(headers)
      .subscribe(
        (result) => {
          let venues = result.results;
          let venueArray = [];
          venues.forEach(function(venue) {
            venueArray.push(venue.id);
          });
          this.venueArray = venueArray;
        }
      );
  }

  tournamentInfo(tournament): void{
    this.addOpacity();
    this.selectedTournament = tournament;
  }

  getTournaments() {
    this.loader = true;
    this.hostService.getTournaments()
      .subscribe((result) => {
        let tournaments = result.results;
        this.loader = false;

        for (let i = 0; i < tournaments.length; i++) {
          let tournamentVenues = [];
          tournaments[i].venues.forEach(function(venue) {
            tournamentVenues.push(venue.id);
          });

          const intersection = tournamentVenues.filter(element => this.venueArray.includes(element));

          if(intersection.length > 0) {
            if (tournaments[i].image != null && !tournaments[i].image.startsWith(environment.BASE_API_URL)){
              tournaments[i].image = environment.BASE_API_URL + tournaments[i].image;
            }
            if (tournaments[i].status == -1) {
              this.pastTournaments.push(tournaments[i]);
            } else if (tournaments[i].status == 0) {
              this.upcomingTournaments.push(tournaments[i]);
            } else if (tournaments[i].status == 1 ||
              tournaments[i].is_default) {
              this.activeTournaments.push(tournaments[i]);
            }
          }
        }
        return this.tournaments = this.activeTournaments;
      });
  }

  addOpacity() {
    jQuery(document).ready(function() {
      jQuery('.modal.fade').css('opacity', 1);
      jQuery('.modal.fade').addClass('show');  
      let cls = document.getElementsByClassName("modal-backdrop").length;
      if (cls>0) {
        jQuery('div.modal-backdrop').addClass('show');  
        jQuery('div.modal-backdrop').css('opacity', 0.8);  
      }
    });
  }
}
