import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import {Observable} from "rxjs";
import {ResultsService} from '../../../services/results.service';
import {ActivatedRoute} from '@angular/router';
import {ActiveGameVenue} from '../../../models/active_game_venue';
import {TeamGameScore} from '../../../models/team-game-score';
import {PlayerSharedService} from '../../../services/player-shared.service';
import {WebsocketProviderService} from '../../../services/websocket-provider.service';
import {PlayerUser} from '../../../models/player_user';
import {OwnTeamError} from '../../../models/own-team-error-model';
import {
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import {Router} from '@angular/router';
declare var jQuery: any;

var socket: any;
@Component({
  selector: 'app-score-card',
  templateUrl: './score-card.component.html',
  styleUrls: ['./score-card.component.css']
})
export class ScoreCardComponent implements OnInit, OnDestroy {
  gameData: ActiveGameVenue;
  gameTeamArray: Array<TeamGameScore> = [];
  player: PlayerUser;
  game: any = {};
  round_array: Array<number> = []
  ownTeamErrors: OwnTeamError = {first_name:[], email:[], city:[], password:[]};
  ownForm: FormGroup
  errors: any = []
  token: string
  message: string;
  gameID: any
  answerDisabled: boolean = false;


  constructor(private router: Router, 
    private _sharedService: PlayerSharedService,
    private wspService: WebsocketProviderService, 
    private _resultService: ResultsService, 
    private route: ActivatedRoute,
    private injector: Injector) {

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.player = JSON.parse(localStorage.getItem('player_user'));
    this.token = localStorage.getItem('player_token');
    this.ownTeamForm();

    
   }

  ngOnInit() {
    jQuery('body').removeClass('modal-open');
    jQuery('.modal-backdrop').remove();
    jQuery('body').css({'padding-right': '0px'});
    console.log("In NGONINIT Starting Webscoket");
    this.answerDisabled = false;
    this.route.params.subscribe(params =>{
      this.gameID = params['id'];
      this.wspService.connect(this.gameID)
      .subscribe((result) => {
        console.log(result);
        switch (result.msg_type) {
          case "status":
            this.updateGameData();
            break;
          case "error":
            break;
          case "update":
            if (result.message.available){
              this.openAnswerSheet();
              break;
            }
            else if (result.message.submit){
              this.answerDisabled = true;
            } else {
              this.updateGameData();
            }
            break;
          default:
            console.log("default case");
            this.updateGameData();
        }      
      });
    });
  }

  ngOnDestroy(): void {
    console.log("In NGONDESTROY Closing websocket");
    this.wspService.close();
  }

  updateGameData(): void {
    this.getGameData(this.gameID).subscribe(gameData =>{
      this.gameID = gameData.id
      this.game = gameData;
      if (this.round_array < gameData.max_round){
        this.answerDisabled = false;
      }
      this.round_array = Array(gameData.max_round).fill(0);
      this.gameData = gameData.venue;
      this._resultService.getTeamsPerGames(this.gameID).subscribe(result => {
        let teams = result;
        let team_ids = teams.map(t => t.id);
        this._sharedService.getFlair(team_ids).subscribe( data => {
          data.forEach(flair => {
            teams.find(t => t.id == flair.team_id).flair = flair;
          });
          this.gameTeamArray = teams.sort(this.compare);
          console.log(this.gameTeamArray);
          this.loadView();
        });
      });
      localStorage.setItem('gameTeamArray', JSON.stringify(this.gameTeamArray))
      if(this.game.end_date){
        this.loadView();
      }
    });
  }

  getGameData(game_id): Observable<any>{
    return this._resultService.getGameData(game_id);
  }

  compare(a, b) { 
    const rankA = a.rank; 
    const rankB = b.rank; 
    let comparison = 0; 
    if (rankA > rankB) {
      comparison = 1;
    } else if (rankA < rankB) {
      comparison = -1;
    }
    return comparison
  }

  ownTeamForm(){
    this.ownForm = new FormGroup({
      first_name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      city: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)])
    })
  }

  ownTeam(){
    // debugger;
    // let data = JSON.stringify(this.ownForm.value);
    this._sharedService.ownTeamService(this.player.my_team.id, this.ownForm.value, this.token)
      .subscribe(data => {
        this.player = data.user;
        localStorage.setItem('player_user', JSON.stringify(data.user));
        var message = 'Congrats! You are now a Bonafide member of “' + this.player.my_team.name + '". Look for an email update about the benefits! Good luck tonight!';
        localStorage.setItem('congrats-mesg', message);
        this.router.navigateByUrl('/join-team-congratulations');
       },
       error => {
        this.ownTeamErrors = error;
       })
  }

  loadView() {
    jQuery(document).ready(function() {
      var cols = [];
      var trs = jQuery('#PlayerScoreTable tbody tr');
      var data =jQuery.each(trs , function(index, tr){
        jQuery.each(jQuery(tr).find('td').not(':nth-of-type(3)'), function(index, td){
          cols[index] = cols[index] || [];
          if(!Number.isNaN(parseFloat(jQuery(td).text()))) {
            cols[index].push(jQuery(td).text());
          } else {
            cols[index].push(0);
          }
        });
      });
      cols.forEach(function(col, index){
        var max = Math.max.apply(null,col);
        var min = Math.min.apply(null,col);
        jQuery('#PlayerScoreTable tbody tr').find('td:eq('+(index+1)+')').each(function(i, td){
          if(max!==0 && parseFloat(jQuery(td).text())==max) {
            jQuery(td).children().addClass('max');
          }
        });
      });
    });
  }

  addOpacity() {
    jQuery(document).ready(function() {
      jQuery('.modal.fade').css('opacity', 1);
      jQuery('.modal.fade').addClass('show');  
      let cls = document.getElementsByClassName("modal-backdrop").length;
      if (cls>0) {
        jQuery('div.modal-backdrop').addClass('show');  
        jQuery('div.modal-backdrop').css('opacity', 0.8);  
      }
    });
  }

  openAnswerSheet(){
    console.log(this.player);
    this.router.navigate(['answer-sheet', this.gameID, this.round_array.length+1, this.player.my_team.id]);
  }


}
