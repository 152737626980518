import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {WorldLeaders} from '../models/world-leaders';

@Injectable({
  providedIn: 'root'
})
export class HostService {

  public headers: any = [];
  public user: any;

  constructor(private http: HttpClient,
              private cookieService: CookieService) {
    this.headers = {
      'Content-Type': 'application/json'
    };

    /*let token = this.cookieService.get('TrivTrak_TOKEN');
    let user = this.cookieService.get('TrivTrak_USER');
    this.headers['Authorization'] = 'Bearer ' + token;
    this.user = JSON.parse(user);*/
    console.log(localStorage.getItem('TrivTrak_TOKEN'));
    if (localStorage.getItem('TrivTrak_TOKEN')) {
      this.headers['Authorization'] = 'Bearer ' + localStorage.getItem('TrivTrak_TOKEN');
      let user = localStorage.getItem('TrivTrak_USER');
      this.user = JSON.parse(user);
    }
  }

  getActiveHosts() {
    return this.http.get<any>(environment.BASE_API_URL + 'accounts/get_active_host/')
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  handleError(errorResponse) {
    console.log(errorResponse.error);
    return errorResponse.error;
  }

  addVenue(data, headers): any {
    return this.http.post<any>(environment.BASE_API_URL + 'venues/', data, { headers: headers })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  addVenueImage(data, headers) {
    return this.http.post<any>(environment.BASE_API_URL + 'games/upload-venue-image/', data, { headers: headers })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  updateProfile(data, headers, user_id) {
    return this.http.put<any>(environment.BASE_API_URL + 'accounts/users/'+user_id+'/', data, { headers: headers })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  getProfile(user_id, headers) {
    return this.http.get<any>(environment.BASE_API_URL + 'accounts/users/'+user_id+'/',  { headers: headers })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  updateUserImage(data) {
    return this.http.post<any>(environment.BASE_API_URL + 'accounts/upload-profile-image/', data, { headers: this.headers })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  getHostVenues(headers) {
    return this.http.get<any>(environment.BASE_API_URL + 'hostActiveVenues/',  { headers: headers })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  getCompanyVenues(headers, companyID) {
    return this.http.get<any>(environment.BASE_API_URL + 'accounts/companies/' + companyID + '/get_venues/', { headers: headers })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  getHostRank(headers) {
    return this.http.get<any>(environment.BASE_API_URL + 'accounts/host-rank/',  { headers: headers })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  changePassword(data) {
    return this.http.post<any>(environment.BASE_API_URL + 'accounts/save-new-password/', data, { headers: this.headers })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  getOrCreateGame(venueId, token) {
    let data = {};
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    };
    return this.http.post<any>(environment.BASE_API_URL + 'venues/'+ venueId +'/get_or_create_game/',  data, { headers: headers })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  // getOrCreateGameTeamLimit(gameId, token, teamLimit) {
  //   let data = {'team_limit': teamLimit};
  //   let headers = {
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer ' + token
  //   };
  //   return this.http.post<any>(environment.BASE_API_URL + 'games/'+ gameId +'/createTeamLimit/',  data, { headers: headers })
  //     .pipe(catchError(e => throwError(this.handleError(e))));
  // }

  getGameInfo(gameId, token) {
    // let data = {'team_limit': teamLimit};
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    };
    return this.http.get<any>(environment.BASE_API_URL + 'games/'+ gameId +'/getGameInfo/', { headers: headers })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  createGameInfo(gameId, token, data) {
    // let data = {'team_limit': teamLimit};
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    };
    return this.http.post<any>(environment.BASE_API_URL + 'games/'+ gameId +'/createGameInfo/',  data, { headers: headers })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  getVenueTeams(venueId) {
    return this.http.get<any>(environment.BASE_API_URL + 'venues/'+ venueId +'/teams/', { headers: this.headers })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  getGameTeams(gameId) {
    return this.http.get<any>(environment.BASE_API_URL + 'games/'+ gameId +'/teams/', { headers: this.headers })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  joinTeam(gameId, data, headers) {
    return this.http.post<any>(environment.BASE_API_URL + 'games/'+ gameId +'/join/',  data, { headers: headers })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  removeTeam(gameId, data, headers) {
    return this.http.post<any>(environment.BASE_API_URL + 'games/'+ gameId +'/remove_team/',  data, { headers: headers })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  getGameData(gameId) {
    return this.http.get<any>(environment.BASE_API_URL + 'games/'+ gameId +'/', { headers: this.headers })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  saveGameScore(gameId, data, headers) {
    return this.http.post<any>(environment.BASE_API_URL + 'games/' + gameId + '/save_score/',  data, { headers: headers })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  saveGameScores(gameId, data, headers) {
    return this.http.post<any>(environment.BASE_API_URL + 'games/' + gameId + '/save_scores/',  data, { headers: headers })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  saveQuestions(gameId, data, headers) {
    return this.http.post<any>(environment.BASE_API_URL + 'games/' + gameId + '/generate_questions/',  data, { headers: headers })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }
  //returns teams that answered. has flag for automated collection
  getAnswers(gameId, data, headers){
    return this.http.post<any>(environment.BASE_API_URL + 'games/' + gameId + '/get_answers/',  data, { headers: headers })
    .pipe(catchError(e => throwError(this.handleError(e))));
  }

  saveGrades(gameId, data, headers){
    return this.http.post<any>(environment.BASE_API_URL + 'games/' + gameId + '/set_answers/',  data, { headers: headers })
    .pipe(catchError(e => throwError(this.handleError(e))));
  }

  getAllAnswerSheets(gameId, headers){
    return this.http.get<any>(environment.BASE_API_URL + 'games/' + gameId + '/get_all_text_answers/', { headers: headers })
    .pipe(catchError(e => throwError(this.handleError(e))));
  }

  checkQuestions(gameId, round, headers){
    return this.http.post<any>(environment.BASE_API_URL + 'games/' + gameId + '/check_questions/', round, { headers: headers })
    .pipe(catchError(e => throwError(this.handleError(e))));
  }

  getTournaments() {
    return this.http.get<any>(environment.BASE_API_URL + 'tournaments/', { headers: this.headers })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  createTournament(data, token) {
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    };
    return this.http.post<any>(environment.BASE_API_URL + 'tournaments/create-tournament/', data, { headers: headers })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  getTournamentLeaderboard(tournamentId) {
    return this.http.get<any>(environment.BASE_API_URL + 'tournaments/'+tournamentId+'/world_leaders/', { headers: this.headers })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  sortResponse(prop) {
    return function(a, b) {
      if (a[prop] < b[prop]) {
        return 1;
      } else if (a[prop] > b[prop]) {
        return -1;
      }
      return 0;
    }
  }

  getTeamProfile(teamId, venueId) {
    return this.http.get<any>(environment.BASE_API_URL + 'teams/'+teamId+'/profile?venue='+venueId, { headers: this.headers })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  clearData() {
    localStorage.clear();
    sessionStorage.clear();
    this.cookieService.deleteAll();
  }

  getDefaultLeaderboard() {
    return this.http.get<WorldLeaders>(environment.BASE_API_URL + '/games/world-leaders/?limit=50&query')
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  getAllVenues() {
    return this.http.get<any>(environment.BASE_API_URL + 'venues/', { headers: this.headers })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  getAllHosts(header: any) {
    return this.http.get<any>(environment.BASE_API_URL + 'accounts/get_all_host/', {headers: header})
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  getVenueStats(header: any) {
    return this.http.get<any>(environment.BASE_API_URL + 'games/players-per-venue/', {headers: header})
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  getPlayerTeamStats(header: any) {
    return this.http.get<any>(environment.BASE_API_URL + 'games/teams-players-per-venue/', {headers: header})
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  getTeamsVenueStats(header: any) {
    return this.http.get<any>(environment.BASE_API_URL + 'games/teams-per-venue/', {headers: header})
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  getGameScoreboardStats(header: any) {
    return this.http.get<any>(environment.BASE_API_URL + 'games/games-scores/', {headers: header})
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  getGameDetailStats(header: any) {
    return this.http.get<any>(environment.BASE_API_URL + 'games/game-detail-report/', {headers: header})
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  getHostVenueStats(header: any) {
    return this.http.get<any>(environment.BASE_API_URL + 'games/get-active-inactive-venues/', {headers: header})
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  getTournamentCheckinStats(header: any) {
    return this.http.get<any>(environment.BASE_API_URL + 'tournaments/get-tournament-check-in/', {headers: header})
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  getTournamentPerformanceStats(header: any) {
    return this.http.get<any>(environment.BASE_API_URL + 'tournaments/get-tournament-performance/', {headers: header})
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  getTournamentScoreboardStats(header: any) {
    return this.http.get<any>(environment.BASE_API_URL + 'tournaments/get-tournament-scoreboard/', {headers: header})
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  getTournamentLeaderStats(header: any) {
    return this.http.get<any>(environment.BASE_API_URL + 'tournaments/get-tournament-leaders/', {headers: header})
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  //!! Might not be the best place for this
  checkPermissions(permission: string, header: any) {
    let data = {permission: permission};
    return this.http.post<any>(environment.BASE_API_URL + 'accounts/user-has-permission/', data, {headers: header})
      .pipe(catchError(e => throwError(this.handleError(e))));
  }
}
