import { Component, OnInit } from '@angular/core';
import {ActiveGameVenue} from '../../../models/active_game_venue'
import {TeamGameScore} from '../../../models/team-game-score'
import {PlayerUser} from '../../../models/player_user'
import {PlayerSharedService} from '../../../services/player-shared.service'
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from "rxjs";
import {SimpleJWTToken} from '../../../models/token-payload'
declare var jQuery:any;

@Component({
  selector: 'app-team-profile',
  templateUrl: './team-profile.component.html',
  styleUrls: ['./team-profile.component.css']
})
export class TeamProfileComponent implements OnInit {
  selectedVenue: ActiveGameVenue;
  gameTeamArray: Array<TeamGameScore>;
  player: PlayerUser;
  rank: number = 1;
  team_members: Array<any> = [];
  team_owner: any
  teamEditForm: FormGroup;
  successMsg: any;
  errorMsg: any;
  token: string;
  refresh: string;
  playerEmail: string;
  inviteStatus: boolean;
  inviteError: any = {email:[]};
  twsRank: number = 1;
  teamRankVenue: any = 1;
  public invitePlayerForm: FormGroup;
  public loader: boolean = false;

  constructor(private _sharedService: PlayerSharedService) {
    this.token = localStorage.getItem('player_token');
    this.refresh = localStorage.getItem('refresh_token');
    this.player =  JSON.parse(localStorage.getItem('player_user'));
    this.selectedVenue = JSON.parse(localStorage.getItem('venue'));
    // this.gameTeamArray = JSON.parse(localStorage.getItem('gameTeamArray'));
    // for(let i=0; i<this.gameTeamArray.length; i++){
    //     if(this.gameTeamArray[i].name == this.player.my_team.name){
    //         this.rank = this.gameTeamArray[i].rank
    //     }
    // }
   }

  ngOnInit() {
    jQuery('body').removeClass('modal-open');
    jQuery('.modal-backdrop').remove();
    this.inviteForm();
    if(this.token){
      //this.getRefreshToken({'token': this.token}).subscribe(tokenData => {
      this.getRefreshToken({refresh: this.refresh}).subscribe(tokenData => {
          // sessionStorage.setItem('player_user', JSON.stringify(tokenData.user));
          // sessionStorage.setItem('player_token', tokenData.token)
          //localStorage.setItem('player_user', JSON.stringify(tokenData.user));
          localStorage.setItem('player_token', tokenData.access)
          //this.player = tokenData.user;
          this.token = tokenData.access;
          this._sharedService.getProfile(this.player.my_team.id, this.selectedVenue.id).subscribe(data => {
            this.team_members = data.registered_players_profile;
            this.team_owner = data.owner;
            this.teamRankVenue = data.team_rank_venue;
          })
          this._sharedService.getRank(this.player.my_team.id).subscribe(result => {
            this.twsRank = result.rank;
          })
      })
  }
    this.createTeamEditForm();
  }

  inviteForm(){
    this.invitePlayerForm = new FormGroup({
      email: new FormControl('',[
        Validators.required
      ])
    })
  }

  getRefreshToken(data): Observable<SimpleJWTToken>{
    return this._sharedService.refreshUser(data);
}

  createTeamEditForm() {
    this.teamEditForm = new FormGroup({
      image_uri: new FormControl('',[
        Validators.required
      ]),
      file_name: new FormControl('',[
        Validators.required
      ]),
      image: new FormControl('',[
        Validators.required
      ]),
      name: new FormControl(this.player.my_team.name, [
        Validators.required
      ])
    });
  }

  updateTeam(image_file, image_file_name){
    let form_data = this.teamEditForm.value
    let data = {'name': form_data['name']}
    if (image_file != '' && image_file_name != ''){
      data['image_uri'] = image_file;
      data['file_name'] = image_file_name;
    }
    this.loader = true;
    this._sharedService.updateTeamService(this.player.my_team.id, data, this.token)
    .subscribe(data => {
        localStorage.setItem('player_user', JSON.stringify(data.user));
        window.location.reload();
        this.successMsg = 'Your Profile updated successfully.';
        this.player = data.user;
        jQuery('#editTeam').hide();
        jQuery('body').removeClass('modal-open');
        jQuery('body').css('padding-right',0);
        jQuery('.modal-backdrop').remove();
        delete this.errorMsg;
        return data;
    }, error => {
        this.errorMsg = 'Something went wrong.';
        delete this.successMsg;
        return this.errorMsg;
    })
  }

  invitePlayer(){
    let data = this.invitePlayerForm.value;
    let gameID = null;
    if (this.selectedVenue.active_game){
      gameID = this.selectedVenue.active_game;
    } else {
      gameID = 'noActiveGame'
    }
    this._sharedService.invitePlayerService({'email': this.invitePlayerForm.value.email, 'team_id': this.player.my_team.id, 'game_id': gameID, 'team_name': this.player.my_team.name}, this.token)
      .subscribe(data => {
          this.inviteError = {email:[]};
          this.inviteStatus = data.success;
          this.invitePlayerForm.controls['email'].setValue('');
      }, error => {
        this.inviteStatus = null;
          this.inviteError = error
      })
  }

  onInviteCLose(){
    this.inviteForm();
    this.inviteError = {email:[]};
    this.inviteStatus = null;
  }

  addOpacity() {
    jQuery(document).ready(function() {
      jQuery('.modal.fade').css('opacity', 1);
      jQuery('.modal.fade').addClass('show');  
      let cls = document.getElementsByClassName("modal-backdrop").length;
      if (cls>0) {
        jQuery('div.modal-backdrop').addClass('show');  
        jQuery('div.modal-backdrop').css('opacity', 0.8);  
      }
    });
  }

}
