<!-- <div class="end-round-modal">
  <div class="modal fade" id="end-round-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Is Round Complete?</span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="modal-btn mr-3 ml-3 mb-3">
            <button type="button" data-dismiss="modal" (click)="saveGameData()" class="btn btn-large mt-2">Yes</button>
            <button type="button" data-dismiss="modal" class="btn btn-large mt-2">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="custompopup">
  <div class="headerpopup">Is Round Complete? <div class="closeicon"><i class="fa fa-times"></i></div></div>
  <div class="maincontendpart">
      Round - {{roundNumber}}
  </div>
  <div class="Buttonpart">
  <a href="javascript:void(0)" (click)="saveGameData()">YES</a>
  <a [routerLink]="['/host', 'start-round', gameID]">NO</a>
  </div>
  
 </div>


<div class="loader" *ngIf="loader">
  <div class="loaderbx">
    <i class="fas fa-3x fa-spinner fa-spin" aria-hidden="true"></i>
  </div>
</div>

<!-- <span class="modal-title">Is Round Complete?</span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times"></span>
          </button>

          <div class="modal-btn mr-3 ml-3 mb-3">
            <button type="button" data-dismiss="modal" (click)="saveGameData()" class="btn btn-large mt-2">Yes</button>
            <button type="button" data-dismiss="modal" class="btn btn-large mt-2">No</button>
          </div> -->


