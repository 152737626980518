<h1 mat-dialog-title>Wager Report</h1>
<div mat-dialog-content>
    <!-- <mat-table [dataSource]="dataSource" matSort>
        
        <ng-container matColumnDef="Rank">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Rank </mat-header-cell>
            <mat-cell *matCellDef="let team"> {{team.rank}} </mat-cell>
        </ng-container>

       
        <ng-container matColumnDef="Team">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Team </mat-header-cell>
            <mat-cell *matCellDef="let team"> {{team.team_name}} </mat-cell>
        </ng-container>

        
        <ng-container matColumnDef="Score">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Score </mat-header-cell>
            <mat-cell *matCellDef="let team"> {{team.total_score}} </mat-cell>
        </ng-container>

        
        <ng-container matColumnDef="Wager">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Wager </mat-header-cell>
            <mat-cell *matCellDef="let team"> {{team.score}} </mat-cell>
        </ng-container>

        
        <ng-container matColumnDef="Answer" *ngIf="input.scores[0].roundType == 1">
            <mat-header-cell *matHeaderCellDef> Answer </mat-header-cell>
            <mat-cell *matCellDef="let team"> {{getAns(team.answers[0])}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Answer" *ngIf="input.scores[0].roundType == 3">
            <mat-header-cell *matHeaderCellDef> Answer </mat-header-cell>
            <mat-cell *matCellDef="let team"> {{team.textAnswers[0].response}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table> -->

    <mat-table fxFlex="100%" [dataSource]="reportData" matSort>
        <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{column}} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element[column]}} </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
</div>
<div mat-dialog-actions>
  <button  class ="btn btn-large mt-2" (click)="close()" cdkFocusInitial>Return to Scoreboard</button>
</div>