<div class="title-container-2 mt-3">
  <div class="page-t">
    <div>
      <div class="page-title">
        <b>GO TO <span>TRIVTRAK.COM</span> TO <span>JOIN</span> OR <span>CREATE YOUR TEAM</span></b>
      </div>
    </div>
  </div>
</div>

<div class="teamcard m-3  hovercard" *ngIf="errorMsg2">
  <div class="alert alert-danger alert-dismissible">
    <button type="button" class="close" data-dismiss="alert">&times;</button>
    {{errorMsg2}}
  </div>
</div>

<div class="teamcard m-3 hovercard" *ngIf="!errorMsg2 && venueDetails">

  <div class="alert alert-success alert-dismissible" *ngIf="successMsg">
    <button type="button" class="close" data-dismiss="alert">&times;</button>
    {{successMsg}}
  </div>

  <div class="card-header d-flex justify-content-between">
    <div style="margin: auto 0;" class="d-flex">
      <div>
        <span></span>
      </div>
      <div class="text-left mr-2 ml-2">
        <span></span>
      </div>
    </div>
    <!--<div style="margin: auto 0;">
      <a style="background-color:white; white-space: normal;font-size: 12px;" class="btn btn-sm mr-1 mb-1" href="present-24-light.html" role="button">PRESENT</a>
    </div>-->
  </div>
  <div class="top">
    <div class="image">
      <div class="imageround">
        <img src="{{venueDetails.image_path}}" *ngIf="venueDetails.image_path">
        <img src="{{defaultImage}}" *ngIf="!venueDetails.image_path">
      </div>
      <span>VENUE</span>
    </div>
    <div class="teamgame-info">
      <span>{{venueDetails.name}}</span>
      <small>{{venueDetails.address}}</small>
    </div>
    <div class="teamgame-info-2">
      <span>HOST: {{venueDetails.stage_name}}</span>
      <small>COMPANY: {{user.profile.company_name}}</small>
    </div>
  </div>
</div>

<div class="rank-table m-3" *ngIf="!errorMsg2">
  <div class="table-responsive">
    <table class="table">
      <thead>
      <tr>
        <th scope="col">RK</th>
        <th scope="col">TEAM</th>
        <th scope="col">P</th>
        <th scope="col">TTL</th>
      </tr>
      </thead>
      <tbody *ngIf="gameTeams">
      <tr *ngFor="let team of gameTeams | sortBy:'total_score':'desc'" [ngClass]="{'unregistered': !team.has_registered_player}">
        <th scope="row">{{team.rank}}</th>
        <!--<td>{{team | json}}</td>-->
        <td><a style="cursor: pointer;" data-target="#remove-team-modal" data-toggle="modal" (click)="openRemoveTeamModal(team.id, team.name)">{{team.name}}</a></td>
        <td>{{team.checked_in_players}}</td>
        <td class="ttl">{{team.total_score}}</td>
      </tr>
      </tbody>
    </table>
  </div>

</div>
<br>
<br>

<!--<div class="footer" id="footer1">

  <button class="button" type="button" id="inputBtn" (click)="hideBtn()">Input</button>

  <button class="button" disabled="disabled">REWARDS</button>

  <button class="button" disabled="disabled">STATS</button>

  <button class="button" disabled="disabled">SHARE</button>

  <button class="button" disabled="disabled">CHAT</button>

</div>-->

<div class="footer" id="footer2" *ngIf="!errorMsg2" >

  <button class="button" data-toggle="modal" data-target="#add-team-modal" (click)="addOpacity();"  >Add Team</button>

  <button class="button" data-toggle="modal" data-target="#update-venue-modal" >Update Game Info</button>

  <button class="button" *ngIf="game" [routerLink]="['/host/start-round', game.id]">Launch</button>

  <button class="button disabledBtn" disabled="disabled">Next</button>

  <button class="button disabledBtn" disabled="disabled">End Game</button>

</div>

<div class="add-team-modal">
  <div class="modal fade" id="add-team-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
          <div class="modal-header">
            <span class="modal-title">Add Team</span>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" class="fa fa-times"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="modal-btn mr-3 ml-3 mb-3">
              <button type="button" data-target="#create-team-modal" data-dismiss="modal" data-toggle="modal" class="btn btn-large mt-2" (click)="addOpacity();">New Team</button>
              <button type="button" data-target="#search-team-modal" data-dismiss="modal" data-toggle="modal" class="btn btn-large mt-2" (click)="addOpacity();">Existing Team</button>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>

<div class="add-team-modal">
  <div class="modal fade" id="limit-team-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
          <div class="modal-header">
            <span class="modal-title">Team Limit Reached</span>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" class="fa fa-times"></span>
            </button>
          </div>
          <div class="modal-container">
            <div class="modal-body" style="text-align: center; font-family: 'BourtonBase';">
                <span style="font-size: 25px;">You can not add a team</span>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>

<div class="create-team-modal">
  <div class="modal fade" id="create-team-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Add Team</span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger alert-dismissible" *ngIf="errorMsg">
            {{errorMsg}}
          </div>
          <form [formGroup]="teamForm" (ngSubmit)="joinTeam(null)" id="createTeam">
            <input type="text" class="form-control" placeholder="Team Name *" formControlName="team_name"/>
            <div class="modal-btn ">
              <button type="submit" class="button btn btn-large mt-2" [disabled]="!teamForm.valid">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="search-team-modal" *ngIf="venueTeams">
  <div class="modal fade" id="search-team-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Add Team</span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="venue-tbl m-3"  >
            <select class="form-control" (change)="getVenueTeams(team.value);" #team>
              <option value="">Select Venue</option>
              <option value="{{venue.id}}" *ngFor="let venue of venues" >{{venue.name}}</option>
            </select>
            <div class="searchbox">
              <input type="text" [(ngModel)]="term" placeholder="Search Team *">
              <button type="submit"><i class="fa fa-search"></i></button>
            </div>
            <div style="height: 400px; overflow: auto;">
              <table class="table-hover">
                <thead>
                <tr>
                  <th>Team Name</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of venueTeams | filter:term ">
                  <td><a style="cursor: pointer;" (click)="joinTeam(item.id)">{{item.name}}</a></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="remove-team-modal" *ngIf="removeTeamId">
  <div class="modal fade" id="remove-team-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" >
        <div class="modal-header">
          <span class="modal-title">Remove Team  "{{removeTeamId.name}}"?</span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="modal-btn mr-3 ml-3 mb-3">
            <button type="button" data-dismiss="modal" class="btn btn-large mt-2" (click)="removeTeam(removeTeamId.id)" >Yes</button>
            <button type="button" data-dismiss="modal" class="btn btn-large mt-2">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="update-venue-modal">
  <div class="modal fade" id="update-venue-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <form id="update-form-venue" [formGroup]="teamLimitForm" (ngSubmit)="setTeamLimit()">
          <div class="modal-header">
            <span class="modal-title">Set Game Info</span>
            <button class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" class="fa fa-times" (click)="clearMsg()"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger alert-dismissible" *ngIf="errorMsgLaunchVenue">
              <button type="button" class="close" data-dismiss="alert">&times;</button>
              <p *ngIf="errorMsgLaunchVenue.website"><strong>Website : </strong>{{errorMsgLaunchVenue.website}}</p>
              <p *ngIf="errorMsgLaunchVenue.description"><strong>Description : </strong>{{errorMsgLaunchVenue.description}}</p>
              <p *ngIf="errorMsgLaunchVenue.game_open_date"><strong>Game Date : </strong>{{errorMsgLaunchVenue.game_open_date}}</p>
            </div>
            <input type="text" class="form-control mb-2" placeholder="Live Stream Info Link Here"
                   formControlName="website"/>
            <textarea type="text" class="form-control mb-2" formControlName="description"
                      placeholder="Game Description *" rows="4"></textarea>
            <input type="datetime-local" class="form-control mb-2" id="date1"  [(ngModel)]="localCompleteDate"
                   step="60" [ngModelOptions]="{standalone: true}"/>
            <input type="number" class="form-control mb-2" formControlName="teamLimit" placeholder="Set Team Limit">
          </div>
          <div class="modal-btn mr-3 ml-3 mb-3">
            <button type="submit" class="btn button" [disabled]="!teamLimitForm.valid">CLICK TO PROCEED
            </button>
            <button class="btn button" data-dismiss="modal">CANCEL</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<div class="loader" *ngIf="loader">
  <div class="loaderbx">
    <i class="fas fa-3x fa-spinner fa-spin" aria-hidden="true"></i>
  </div>
</div>
