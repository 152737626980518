<div class="title-container mt-3">
  <div class="page-t">
    <div>
      <div class="page-title" style="margin: auto;">
        <b>HOST PROFILE</b>
      </div>
    </div>
  </div>
</div>

<div class="hostprofile">
  <div class="alert alert-success alert-dismissible" *ngIf="successMsg">
    <button type="button" class="close" data-dismiss="alert">&times;</button>
    {{successMsg}}
  </div>
  <div class="alert alert-danger alert-dismissible" *ngIf="errorMsg3">
    <button type="button" class="close" data-dismiss="alert">&times;</button>
    <p *ngIf="errorMsg3">{{errorMsg3}}</p>
  </div>
  <div class="card hovercard">
    <div class="cardheader"></div>
    <div class="avatar">
      <img alt="" src="{{this.user['profile']['image']}}" style="border: 1px solid"
           *ngIf="this.user['profile']['image']">
      <img alt="" src="{{defaultProfile}}" style="border: 1px solid" *ngIf="!this.user['profile']['image']">
      <div class="edit-img">
        <a type="button" class="fa fa-camera" data-toggle="modal" data-target="#edit-photo-modal"
           (click)="addOpacity();"></a>
      </div>
    </div>
    <div class="content">
      <span>{{user.first_name + ' ' + user.last_name}}
        <a style="cursor: pointer;" class="fas fa-pen-fancy" data-toggle="modal" data-target="#edit-profile-modal"
           (click)="addOpacity();"></a>
      </span>

      <hr/>
      <div class="team-info">
        <div class="info-content col-6">
          <span>VENUE</span>
          <small *ngFor="let venue of venuesList">{{venue.name}}</small>
        </div>
        <div class="info-content col-6">
          <span>RANK IN NETWORK</span>
          <span class="ranknumber">{{hostRank}}</span>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="button-grp">
      <a style="cursor: pointer;" class="button mr-1 mb-1" data-target="#select-venue-modal" data-toggle="modal"
         role="button" (click)="addOpacity();">LAUNCH GAME</a>
      <a style="cursor: pointer;" routerLink="/host/host-leaderboard" class="button ml-1 mb-1" role="button">STATS</a>
      <a style="cursor: pointer;" class="button ml-1 mb-1" (click)="openReports()" *ngIf="canViewReports" role="button">REPORTS</a>
    </div>
    <div class="button-grp">
      <a class="button mr-1 mt-1 disabledBtn" onClick="return false;" role="button">MY REWARDS</a>
      <a style="cursor: pointer;" class="button btn team-form mt-1 ml-1" data-toggle="modal"
         data-target="#add-venue-modal" (click)="addOpacity();">ADD GAME</a>
    </div>
    <div class="button-grp" style="margin-top: 4px;">
      <div class="button-grp mr-1" role="group" aria-label="Scoreboard Buttons">
        <a style="cursor: pointer;" class="button mr-1 mt-1" routerLink="/host/tournaments">SCOREBOARDS</a>
        <a style="cursor: pointer;" class="button btn tournament-form mt-1 ml-1" data-toggle="modal"
         data-target="#add-tournament-modal" (click)="addOpacity();">ADD SCOREBAORD</a>
      </div>
      <a style="cursor: pointer;" class="button btn team-form mt-1 ml-1" data-toggle="modal"
         data-target="#edit-profile-modal" (click)="addOpacity();">EDIT PROFILE</a>
    </div>
  </div>
</div>

<div class="add-tournament-modal">
  <div class="modal fade" id="add-tournament-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <form id="tournament_form" name="form-tournament" [formGroup]="tournamentForm">
          <div class="modal-header">
            <span class="modal-title">ADD SCOREBOARD</span>
            <button class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" class="fa fa-times" (click)="clearMsg()"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger alert-dismissible" *ngIf="errorMsg2">
              <button type="button" class="close" data-dismiss="alert">&times;</button>
              <!-- Tournament Stuff -->
              <p *ngIf="errorMsg2.name"><strong>Name : </strong>{{errorMsg2.name}}</p>
              <p *ngIf="errorMsg2.start_date"><strong>Start Date : </strong>{{errorMsg2.start_date}}</p>
              <p *ngIf="errorMsg2.end_date"><strong>End Date : </strong>{{errorMsg2.end_date}}</p>
              <!-- Main Sponsor -->
              <p *ngIf="errorMsg2.main_sponsor_name"><strong>Main Sponsor Name : </strong>{{errorMsg2.main_sponsor_name}}</p>
              <p *ngIf="errorMsg2.main_sponsor_url"><strong>Main Sponsor URL : </strong>{{errorMsg2.main_sponsor_url}}</p>
              <p *ngIf="errorMsg2.main_sponsor_logo"><strong>Main Sponsor Logo : </strong>{{errorMsg2.main_sponsor_logo}}</p>
              <!-- Second Sponsor -->
              <p *ngIf="errorMsg2.second_sponsor_name"><strong>Second Sponsor Name : </strong>{{errorMsg2.second_sponsor_name}}</p>
              <p *ngIf="errorMsg2.second_sponsor_url"><strong>Second Sponsor URL : </strong>{{errorMsg2.second_sponsor_url}}</p>
              <p *ngIf="errorMsg2.second_sponsor_logo"><strong>Second Sponsor Logo : </strong>{{errorMsg2.second_sponsor_logo}}</p>
              <!-- Rest of Normal Tournament Stuff -->
              <p *ngIf="errorMsg2.venues"><strong>Venues : </strong>{{errorMsg2.venues}}</p>
              <p *ngIf="errorMsg2.image"><strong>Image : </strong>{{errorMsg2.image}}</p>
              <p *ngIf="errorMsg2.info"><strong>Info : </strong>{{errorMsg2.info}}</p>
            </div>
            <div class="alert alert-info alert-dismissible">
              <button type="button" class="close" data-dismiss="alert">&times;</button>
              <p class="mb-0">Estimated <em>Total</em> image size limit is 7.5mb</p>
            </div>
            <!-- Name -->
            <input type="text" class="form-control mb-2" placeholder="Scoreboard Name *" formControlName="name" required>
            <!-- Image -->
            <img class="preview" id="preview" src="assets/img/blank-img.png" alt="your image"/>
            <input #tour_image type='file' onchange="readURL(this);" class="form-control mb-2"/>
            <input #image type="hidden" class="form-control mb-2 image_uri" id="image" formControlName="image"/>
            <input #image_name type="hidden" class="form-control mb-2 file_name" id="image_name" formControlName="image_name"/>
            <!-- Start/End Dates -->
            <input type="date" required class="form-control mb-2" id="id_start_date" formControlName="start_date" placeholder="Select Start Date *" step="1"/>
            <label for="id_start_date">Start Date *</label>
            <input type="date" required class="form-control mb-2" id="id_end_date" formControlName="end_date" placeholder="Select End Date *" step="1"/>
            <label for="id_end_date">End Date *</label>
            <!-- Main Sponsor -->
            <input type="text" class="form-control mb-2" placeholder="Main Sponsor Name" formControlName="main_sponsor_name">
            <input type="text" class="form-control mb-2" placeholder="Main Sponsor URL" formControlName="main_sponsor_url"/>
            <!-- MS Image -->
            <img class="ms_preview" id="ms_preview" src="assets/img/blank-img.png" alt="main sponsor image"/>
            <input type='file' onchange="readMSURL(this);" data-prefix="ms" class="form-control mb-2"/>
            <input #ms_image type="hidden" class="form-control mb-2 ms_image_uri" id="ms_image" formControlName="ms_image"/>
            <input #ms_image_name type="hidden" class="form-control mb-2 ms_file_name" id="ms_image_name" formControlName="ms_image_name"/>
            <!-- Second Sponsor -->
            <input type="text" class="form-control mb-2" placeholder="Second Sponsor Name" formControlName="second_sponsor_name">
            <input type="text" class="form-control mb-2" placeholder="Second Sponsor URL" formControlName="second_sponsor_url"/>
            <!-- MS Image -->
            <img class="ss_preview" id="ss_preview" src="assets/img/blank-img.png" alt="main sponsor image"/>
            <input type='file' onchange="readSSURL(this);" data-prefix="ms" class="form-control mb-2"/>
            <input #ss_image type="hidden" class="form-control mb-2 ss_image_uri" id="ss_image" formControlName="ss_image"/>
            <input #ss_image_name type="hidden" class="form-control mb-2 ss_file_name" id="ss_image_name" formControlName="ss_image_name"/>
            <!-- Venue -->
            <label for="tournament-venues" class="mt-2">Select Venue(s) *</label>
            <select id="tournament-venues" class="form-control mb-2 w-100" multiple="multiple" required data-is-stacked="0" formControlName="venues">
              <option *ngFor="let item of companyVenuesList | filter:term" [value]="item.id">Venue: {{item.name}} || Stage Name: {{item.stage_name}}</option>
            </select>
            <!-- Info -->
            <textarea type="text" class="form-control mb-2" formControlName="info"
              placeholder="Info Field that can contain info about the tournament, prizes, graphics, sponsors, rules. *" rows="4">
            </textarea>
          </div>
          <div class="modal-btn mr-3 ml-3 mb-3 d-flex">
            <button type="submit" class="btn button mr-1"
              (click)="addTournament(image.value, image_name.value, ms_image.value, ms_image_name.value, ss_image.value, ss_image_name.value)"
              [disabled]="!tournamentForm.valid">ADD SCOREBOARD
            </button>
            <button class="btn button ml-1" data-dismiss="modal">CANCEL</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="add-venue-modal">
  <div class="modal fade" id="add-venue-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">

        <form id="form-venue" name="form-venue" [formGroup]="venueForm">
          <div class="modal-header">
            <span class="modal-title">ADD GAME</span>
            <button class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" class="fa fa-times" (click)="clearMsg()"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger alert-dismissible" *ngIf="errorMsg2">
              <button type="button" class="close" data-dismiss="alert">&times;</button>
              <p *ngIf="errorMsg2.name"><strong>Name : </strong>{{errorMsg2.name}}</p>
              <p *ngIf="errorMsg2.stage_name"><strong>Stage Name : </strong>{{errorMsg2.stage_name}}</p>
              <p *ngIf="errorMsg2.company"><strong>Company : </strong>{{errorMsg2.company}}</p>
              <p *ngIf="errorMsg2.address"><strong>Address : </strong>{{errorMsg2.address}}</p>
              <!-- <p *ngIf="errorMsg2.phone"><strong>Phone : </strong>{{errorMsg2.phone}}</p> -->
              <p *ngIf="errorMsg2.website"><strong>Website : </strong>{{errorMsg2.website}}</p>
              <p *ngIf="errorMsg2.description"><strong>Description : </strong>{{errorMsg2.description}}</p>
              <p *ngIf="errorMsg2.game_open_date"><strong>Game Date : </strong>{{errorMsg2.game_open_date}}</p>
            </div>
            <input type="text" class="form-control mb-2" placeholder="Game Name *" formControlName="name">
            <div class="input-group mb-3">
              <select class="custom-select" name="state" formControlName="state">
                <option [ngValue]="null" disabled>State *</option>
                <option *ngFor="let state of stateList" [ngValue]="state.id">
                  {{state.name }} - ({{state.code}})
                </option>
              </select>
            </div>
            <input type="text" class="form-control mb-2" placeholder="Stage Name *" formControlName="stage_name">
            <div class="input-group mb-3">
              <select class="custom-select" name="host" formControlName="host">
                <option [ngValue]="null" disabled>Host *</option>
                <option *ngFor="let host of activeHosts" [ngValue]="host.id">
                  {{host.full_name}}
                </option>
              </select>
            </div>
            <input type="text" class="form-control mb-2" placeholder="Game Address" formControlName="address"/>
            <!-- <input type="phone" class="form-control mb-2" placeholder="Venue Phone" formControlName="phone" maxlength="10" /> -->
            <input type="text" class="form-control mb-2" placeholder="Live Stream Info Link Here"
                   formControlName="website"/>
            <textarea type="text" class="form-control mb-2" formControlName="description"
                      placeholder="Game Description *" rows="4"></textarea>
            <!--<input type="text" class="form-control mb-2" placeholder="Select Date *" value="{{selectedDate}}">
            <dl-date-time-picker startView="day" maxView="year" minView="minute" minuteStep="5"
                                 [(ngModel)]="selectedDate" [ngModelOptions]="{standalone: true}"></dl-date-time-picker>-->

            <input type="datetime-local" class="form-control mb-2" id="date2"  [(ngModel)]="todayISOString" placeholder="Select Date *" step="1" [ngModelOptions]="{standalone: true}" required/>

            <img class="preview" id="preview" src="assets/img/blank-img.png" alt="your image"/>
            <input type='file' onchange="readURL(this);" class="form-control"/>
            <input #image_uri type="hidden" class="form-control mb-2 image_uri" id="image_uri" name="image_uri"
                   formControlName="image_uri"/>
            <input #file_name type="hidden" class="form-control mb-2 file_name" id="file_name" name="file_name"
                   formControlName="file_name"/>
          </div>
          <div class="modal-btn mr-3 ml-3 mb-3">
            <button type="submit" class="col-6 btn button mr-1" (click)="addVenue(image_uri.value, file_name.value)"
                    [disabled]="!venueForm.valid">ADD GAME
            </button>
            <button class="col-6 btn button mr-1" data-dismiss="modal">CANCEL</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="edit-photo-modal">
  <div class="modal fade" id="edit-photo-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <form [formGroup]="imageForm">
          <div class="modal-header">
            <span class="modal-title" id="exampleModalLabel">UPDATE PICTURE</span>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" class="fa fa-times"></span>
            </button>
          </div>
          <div class="modal-body">

            <!--<img src="{{this.user['profile']['image']}}" class="preview"/>-->
            <img alt="" src="{{this.user['profile']['image']}}" style="border: 1px solid"
                 *ngIf="this.user['profile']['image']" class="preview"/>
            <img alt="" src="{{defaultProfile}}" style="border: 1px solid" *ngIf="!this.user['profile']['image']"
                 class="preview">
            <input type="hidden" formControlName="image_uri" #image_uri2 class="image_uri">
            <input type="hidden" formControlName="file_name" #file_name2 class="file_name">
          </div>
          <div style="display:flex; flex-direction:column; align-items: center">
            <span class="modal-title" id="exampleModalLabel">For best image result, use image with 550*320 pixels and 100 KB Size</span>
          </div>
          <div class="modal-btn mr-3 ml-3 mb-3">
            <div style="position: relative;width: 70%;">
              <input type="file" style="position: absolute; width: 100%; top: 0px; height: 100%; opacity: 0;"
                     formControlName="image" onchange="readURL(this);">
              <a class="btn button mt-2" style="width: 100%" data-dismiss="modal">UPLOAD PHOTO</a>
            </div>
            <button style="width: 70%" type="submit" class="btn button mt-2"
                    (click)="updateUserPhoto(image_uri2.value, file_name2.value)">SAVE
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="edit-profile-modal">
  <div class="modal fade" id="edit-profile-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">UPDATE PROFILE</span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="modal-container">
            <div class="team-form mb-4">
              <form id="form-profile" name="form-profile" [formGroup]="profileForm" (ngSubmit)="updateProfile()">
                <div class="form-group">
                  <input type="text" class="form-control" formControlName="first_name" placeholder="First Name *">
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" formControlName="last_name" placeholder="Last Name *">
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" formControlName="email" placeholder="Email *">
                </div>
                <div class="form-group" formGroupName="profile">
                  <input type="text" class="form-control" placeholder="City *" name="city" formControlName="city">
                </div>
                <div class="form-group" formGroupName="profile">
                  <select class="form-control" name="state" formControlName="state">
                    <option [ngValue]="null" disabled>State *</option>
                    <option *ngFor="let state of stateList" [value]="state.id">
                      {{state.name }} - ({{state.code}})
                    </option>
                  </select>
                </div>
                <div class="modal-btn button-grp">
                  <button type="submit" class="btn button mr-1 mb-1" [disabled]="!profileForm.valid">UPDATE</button>
                  <button type="button" class="btn button mr-1 mb-1" data-dismiss="modal">CANCEL</button>
                </div>
                <div class="modal-btn button-grp">
                  <button type="button" class="btn button mr-1 mb-1" (click)="changePasswordModelOpen();">Change
                    Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="update-venue-modal">
  <div class="modal fade" id="update-venue-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">

        <form id="update-form-venue" [formGroup]="teamLimitForm" (ngSubmit)="setTeamLimit()">
          <div class="modal-header">
            <span class="modal-title">Set Game Info</span>
            <button class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" class="fa fa-times" (click)="clearMsg()"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger alert-dismissible" *ngIf="errorMsgLaunchVenue">
              <button type="button" class="close" data-dismiss="alert">&times;</button>
              <p *ngIf="errorMsgLaunchVenue.website"><strong>Website : </strong>{{errorMsgLaunchVenue.website}}</p>
              <p *ngIf="errorMsgLaunchVenue.description"><strong>Description : </strong>{{errorMsgLaunchVenue.description}}</p>
              <p *ngIf="errorMsgLaunchVenue.game_open_date"><strong>Game Date : </strong>{{errorMsgLaunchVenue.game_open_date}}</p>
            </div>

            <input type="text" class="form-control mb-2" placeholder="Live Stream Info Link Here"
                   formControlName="website"/>

            <textarea type="text" class="form-control mb-2" formControlName="description"
                      placeholder="Game Description *" rows="4"></textarea>

            <input type="datetime-local" class="form-control mb-2" id="date1"  [(ngModel)]="localCompleteDate"
                   step="60" [ngModelOptions]="{standalone: true}"/>

            <input type="number" class="form-control mb-2" formControlName="teamLimit" placeholder="Set Team Limit">

          </div>
          <div class="modal-btn mr-3 ml-3 mb-3">
            <button type="submit" class="col-6 btn button mr-1" [disabled]="!teamLimitForm.valid">CLICK TO PROCEED
            </button>
            <button class="col-6 btn button mr-1" data-dismiss="modal">CANCEL</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div> -->

<div class="edit-password-modal">
  <div class="modal fade" id="edit-password-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <form [formGroup]="passwordForm" (ngSubmit)="changePassword()" id="form-password">
          <div class="modal-header">
            <span class="modal-title">UPDATE PASSWORD</span>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" class="fa fa-times" (click)="clearMsg()"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger alert-dismissible" *ngIf="errorMsg">
              <button type="button" class="close" data-dismiss="alert">&times;</button>
              {{errorMsg}}
            </div>

            <div class="form-group show_hide_password">
              <show-hide-password size="lg" btnStyle="primary">
                <input name="password" class="form-control" type="text" placeholder="ENTER PASSWORD"
                       formControlName="password">
              </show-hide-password>
            </div>
            <div class="form-group show_hide_password">
              <show-hide-password size="lg" btnStyle="primary">
                <input name="password" class="form-control" type="text" placeholder="RE-ENTER PASSWORD"
                       formControlName="re_password">
              </show-hide-password>
            </div>
            <div class="modal-btn button-grp">
              <button type="submit" class="btn button mr-1 mb-1" [disabled]="!passwordForm.valid">Change Password
              </button>
              <button type="button" class="btn reg-success mr-1 mb-1" data-dismiss="modal">CANCEL</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="select-venue-modal">

  <div class="modal fade" id="select-venue-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Select Venue</span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times" (click)="clearMsg()"></span>
          </button>
        </div>
        <div class="modal-body">


          <div class="venue-tbl m-3">
            <div class="searchbox">
              <input type="text" [(ngModel)]="term" placeholder="Select Venue *">
              <button type="submit"><i class="fa fa-search"></i></button>
            </div>
            <div style="height: 400px; overflow: auto;">
              <table class="table-hover">
                <thead>
                <tr>
                  <th>Venue Name</th>
                  <th class="hostColumn">Stage Name</th>
                  <!--<th class="hostColumn">Host</th>-->
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of companyVenuesList | filter:term ">
                  <td><a href="javascript:void(0)" data-toggle="modal" data-target="#team-limit-modal"
                         data-dismiss="modal" (click)="launchGame(item.id)">{{item.name}}</a></td>
                  <td class="hostColumn"><a>{{item.stage_name}}</a></td>
                  <!--<td class="hostColumn"><a>{{item.host_name}}</a></td>-->
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loader" *ngIf="loader">
  <div class="loaderbx">
    <i class="fas fa-3x fa-spinner fa-spin" aria-hidden="true"></i>
  </div>
</div>
