import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HostService} from '../../../services/host.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ErrorMsg} from '../../../models/error';
import {Router} from '@angular/router';
declare var jQuery: any;
@Component({
  selector: 'app-launch-game',
  templateUrl: './launch-game.component.html',
  styleUrls: ['./launch-game.component.css']
})
export class LaunchGameComponent implements OnInit {
  public venueId: any;
  public venueDetails: any;
  public game: any;
  public venueTeams: any;
  public gameTeams: any;
  public term: any;
  public teamForm: FormGroup;
  public successMsg: any;
  public errorMsg: any;
  public errorMsg2: any;
  public removeTeamId: any;
  public loader: boolean = true;
  public defaultImage: string = 'assets/img/location.png';
  public venues: any;
  public user: any;
  public headers: any = [];
  public teamLimitValue: any;
  public canJoin: boolean = false;

  public token: string;
  public completeDate: Date;
  public localCompleteDate: string;
  public teamLimitForm: FormGroup;
  public errorMsg4: any;
  public errorMsgLaunchVenue: any;
  public disabledLaunch: any;

  constructor(
    private route: ActivatedRoute,
    private hostService: HostService,
    private router: Router
  ) {
    this.user = JSON.parse(localStorage.getItem('TrivTrak_USER'));
    let token = localStorage.getItem('TrivTrak_TOKEN');
    this.token = localStorage.getItem('TrivTrak_TOKEN');
    // this.teamLimitValue = localStorage.getItem('team_limit');
    this.teamLimitValue = this.teamLimitValue ? this.teamLimitValue : null;
    this.headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    };

    jQuery('body').removeClass('modal-open');
    jQuery('body').css('padding-right',0);
    jQuery('.modal-backdrop').remove();

    this.route.params.subscribe(result => this.venueId = result.id);

    this.hostService.getOrCreateGame(this.venueId, token)
      .subscribe((result) => {
          // this.hostService.getOrCreateGameTeamLimit(result.id, token, this.teamLimitValue).subscribe(data1 => {
          //   this.teamLimitValue = data1.team_limit;
          //   localStorage.setItem('team_limit', this.teamLimitValue);
          // },
          // (error: ErrorMsg) => {
          //   this.loader = false;
          //   this.errorMsg2 = "Please try again.";
          // }
          // );

          this.venueDetails = result.venue;
          this.loader = false;
          if((this.venueDetails.host == this.user.id) || (this.venueDetails.company == this.user.profile.company)) {
            this.game = result;
            if(this.game!= null) {
              this.hostService.getVenueTeams(this.venueId)
                .subscribe(res => this.venueTeams = res);
              this.hostService.getGameTeams(this.game.id)
                .subscribe(res => {
                  this.gameTeams = res;
                  this.checkTeamLimit();
                });
              if(this.game.id) {
                this.hostService.getGameData(this.game.id)
                  .subscribe((result) => {
                    console.log(result);
                    let data = result.teams;
                    this.gameTeams = data.sort(this.hostService.sortResponse('total_score'));
                    this.checkTeamLimit();
                    this.teamLimitFormFunc();
                  });
              }
              let localGameInfo = JSON.parse(localStorage.getItem(this.game.id));
              if( localGameInfo ) {
                console.log("localGameInfo.team_limit", localGameInfo.team_limit);
                this.teamLimitValue = localGameInfo.team_limit ?? null;
              } else {
                this.teamLimitValue = null;
              }

              return this.game;
            }
          } else {
            this.errorMsg2 = 'This game or venue does not belong to you.';
          }
        },
        (error: ErrorMsg) => {
          this.loader = false;
          this.errorMsg2 = error.detail;
        }
      );

    this.teamForm = new FormGroup({
      team_name: new FormControl('',[
        Validators.required
      ])
    });

    this.getVenues();
    this.teamLimitFormFunc();
  }

  ngOnInit() {

  }

  /**
   *
   *
   *
   *
   * START New Stuff
   *
   *
   *
   */

  teamLimitFormFunc() {
    console.log(this.teamLimitValue);
    if (this.venueDetails) {
      this.completeDate = new Date(this.venueDetails.game_open_date);
      this.localCompleteDate = this.toISOLocal(this.completeDate);
    }
    this.teamLimitForm = new FormGroup({
      teamLimit: new FormControl(this.teamLimitValue, [Validators.required]),
      description: new FormControl(this.venueDetails ? this.venueDetails.description : "", [Validators.required]),
      website: new FormControl(this.venueDetails ? this.venueDetails.website : "")
    })
  }

  setTeamLimit() {
    this.loader = true;
    jQuery('#add-venue-modal').hide();
    let data = this.teamLimitForm.value;
    // data['game_open_date'] = this.launchSelectedDate;
    if( !data.game_open_date ) {
      data['game_open_date'] = new Date(this.localCompleteDate);
      data['offset'] = new Date().getTimezoneOffset() *-1;
    }
    jQuery('#update-venue-modal').hide();
    this.hostService.createGameInfo(this.venueDetails.active_game, this.token, data).subscribe(data1 => {
        this.loader = false;
        if (data1) {
          console.log("data1", data1)
          this.teamLimitValue = data1.team_limit;
          this.completeDate = data.game_open_date;
          localStorage.setItem(this.venueDetails.active_game, JSON.stringify({
            'team_limit': data1.team_limit
          }));
          delete this.errorMsg4;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/host/launch-game', this.venueId]);
          });
        }
      },
      (error) => {
        this.loader = false;
        this.errorMsg4 = error;
      }
    );
  }

  toISOLocal(d) {
    var z  = n =>  ('0' + n).slice(-2);
    //var zz = n => ('00' + n).slice(-3);
    var off = d.getTimezoneOffset();
    //var sign = off < 0? '+' : '-';
    off = Math.abs(off);

    return d.getFullYear() + '-'
           + z(d.getMonth()+1) + '-' +
           z(d.getDate()) + 'T' +
           z(d.getHours()) + ':'  +
           z(d.getMinutes()) //+ ':' +
           //z(d.getSeconds()) + '.' +
           //zz(d.getMilliseconds()) +
           //sign + z(off/60|0) + ':' + z(off%60);
  }

  clearMsg() {
    this.loader = false;
    delete this.errorMsg;
    delete this.successMsg;
  }

  /**
   *
   *
   *
   *
   * END New Stuff
   *
   *
   *
   */

  checkTeamLimit(){
    if (this.gameTeams.length < this.teamLimitValue){
      this.canJoin = true;
    }
    else{
      this.canJoin = false;
    }
  }

  hideBtn() {
    jQuery('#footer1').hide();
    jQuery('#footer2').show();
  }

  joinTeam(teamId: null) {
    this.loader = true;
    let data: any;
    if(teamId == null) {
      data = JSON.stringify(this.teamForm.value);
    } else {
      data = {
        team_id: teamId
      }
    }
    this.hostService.joinTeam(this.game.id,data,this.headers)
      .subscribe(
        (result) => {
          this.game = result.game;
          jQuery('body').removeClass('modal-open');
          jQuery('.modal-backdrop').remove();
          jQuery('body').css('padding-right',0);
          jQuery('#createTeam').trigger('reset');
          jQuery('#create-team-modal').hide();
          jQuery('#search-team-modal').hide();
          this.successMsg = 'Team Added Successfully';
          delete this.errorMsg;
          this.loader = false;
          setTimeout(function(){ location.reload(); }, 500);
        },
        (error: ErrorMsg) => {
          this.loader = false;
          delete this.successMsg;
          this.errorMsg = error.team_name;
        }
      );
  }

  removeTeam(teamId) {
    this.loader = true;
    let data = {
      team_id: teamId
    };
    this.hostService.removeTeam(this.game.id,data,this.headers)
      .subscribe(result => {
        this.game = result.game;
        this.successMsg = 'Team Removed Successfully.';
        jQuery('body').removeClass('modal-open');
        jQuery('.modal-backdrop').remove();
        jQuery('#create-team-modal').hide();
        jQuery('#search-team-modal').hide();
        this.loader = false;
        setTimeout(function(){ location.reload(); }, 500);
      });
  }

  openRemoveTeamModal(teamId, teamName) {
    this.addOpacity();
    this.removeTeamId =  {
      id: teamId,
      name: teamName
    };
  }

  getVenues() {
    this.hostService.getAllVenues()
      .subscribe(
        (result) => {
            this.venues = result;
          }
      );
  }

  getVenueTeams(venueId) {
    this.hostService.getVenueTeams(venueId)
      .subscribe(res => this.venueTeams = res);
  }

  addOpacity() {
    jQuery(document).ready(function() {
      jQuery('.modal.fade').css('opacity', 1);
      jQuery('.modal.fade').addClass('show');
      let cls = document.getElementsByClassName("modal-backdrop").length;
      if (cls>0) {
        jQuery('div.modal-backdrop').addClass('show');
        jQuery('div.modal-backdrop').css('opacity', 0.8);
      }
    });
  }
}
