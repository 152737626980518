import { Component, OnInit } from '@angular/core';
import {PlayerSharedService} from '../../../services/player-shared.service'
import {PlayerUser} from '../../../models/player_user'
import { Observable, fromEventPattern } from 'rxjs';
import {ResultsService} from '../../../services/results.service'
import {ActiveGameVenue} from '../../../models/active_game_venue'
import {OwnTeamError} from '../../../models/own-team-error-model'
import {GameResult} from '../../../models/game_result'
import {ResultsComponent} from '../results/results.component'
import {Router} from '@angular/router';
import {
    FormGroup,
    FormControl,
    Validators
  } from '@angular/forms';
declare var jQuery:any;

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css'],
  providers: [ResultsComponent]
})
export class LocationsComponent implements OnInit {
  token: string;
  player: PlayerUser;
  player_user_logout_status: boolean;
  venues: Array<ActiveGameVenue>;
  nonActiveVenues: Array<any> = [];
  gameData: Array<GameResult> = [];
  selectedVenue: any = {};
  teamName: string;
  ownTeamErrors: OwnTeamError = {first_name:[], email:[], city:[], password:[]};
  ownForm: FormGroup;
  errors: any = [];
  public loader: boolean;

  constructor(private router: Router, private _resultComp: ResultsComponent, private _sharedService: PlayerSharedService, private _resultService: ResultsService) { 
    // this.token = _sharedService.player_token;
    // this.player = _sharedService.player_user;
    // this.player_user_logout_status = _sharedService.player_user_logout_status;
    this.token = localStorage.getItem('player_token');
    this.player = JSON.parse(localStorage.getItem('player_user'));
    this.player_user_logout_status =  JSON.parse(localStorage.getItem('player_user_logout_status'));
    this.ownTeamForm();
  }

  ngOnInit() {
    jQuery('body').removeClass('modal-open');
    jQuery('.modal-backdrop').remove();
    this.loader = true;
    if (this.token != null){
              this.getCheckinPlayer(this.token).subscribe(data => {
                this.player = data;
                // this._sharedService.player_user = data
                localStorage.setItem('player_user', JSON.stringify(data));
              })
          }
    // this._sharedService.getNotActiveGameVenues(this.token).subscribe(venues => {
    //     this.nonActiveVenues = venues.results;
    // })
    this._resultComp.getAllVenues(this.token).subscribe(venues => {
      this.venues = venues;
      var gameCurrent = 0;
      for (let i=0; i<this.venues.length; i++){
        if (this.venues[i].active_game){
          this._resultComp.getGameResult(this.venues[i].active_game).subscribe(gameData => {
            
              this.gameData[gameCurrent] = gameData;
              gameCurrent++;
            })
      } else {
        let data = {'id':1, 'venue': this.venues[i], 'creation_date':'', 'end_date':'', 'max_round':0, 'modified_date':'', 'players':[], 'tournaments':[], 'teams':[]}
        this.gameData[gameCurrent] = data
        gameCurrent++;
      }       
      }
      this.loader = false;
    })

  }

    getCheckinPlayer(token): Observable<PlayerUser> {
      return this._sharedService.getCheckinPlayer(token);
    }

    // get sortData() {
    //     return this.gameData.sort((a, b) => {
    //       return <any>new Date(b.modified_date) - <any>new Date(a.modified_date);
    //     });
    // }

    get sortData() {
      return this.gameData.sort(this.compare);
  }

//   get sortData1() {
//     return this.gameData.sort(this.compare);
// }

    onVenueSelect(venue){
      if (venue.active_game){
        this.selectedVenue = venue;
        localStorage.setItem('venue', JSON.stringify(venue));
        // this._sharedService.setOption("venue", venue);
        //We need to allow a player who is already a "Bonafide Member" to create or join a different team at a different venue.
        if (this.player && this.player.my_team && this.player.email != null && this.player.email.trim().length > 0 && 
            this.token){
              // alert("Already a bonafide member");
              this._sharedService.checkPlyerPlayGameService(this.player.id, this.token)
                .subscribe(data => {
                  this._sharedService.checkTeanGameLimitService(this.selectedVenue.active_game, this.player.my_team.id).subscribe(data1 => {
                    if (data1.canJoin){
                      if (data.play){
                        this.selectVenue();
                      } else{
                        console.log("Offer change team prompt");
                        jQuery('#changeTeamModal').modal('toggle');
                      }
                    } else {
                      jQuery('#teamLimitModalLocation').modal('toggle');
                      this.addOpacity();
                    }
                  })
                })
            }
        else{
          this.selectVenue();
        }
        // this._sharedService.changeMessage("Venue Set.........")
    } else {
      // for not active locations
      this.selectedVenue = venue;
      localStorage.setItem('venue', JSON.stringify(venue));
      if(this.player && this.player.my_team && this.token){
          var message = "Ok, you joined a team at not live location";
          localStorage.setItem('congrats-mesg', message);
          this.router.navigateByUrl('/join-team-congratulations')

      } else {
        jQuery('#teamModal').modal('toggle');
        this.addOpacity()
      }
    }
    }

    joinTheTournament(){
      localStorage.setItem('player_user_logout_status', JSON.stringify(false));
      let game_id = this.selectedVenue.active_game;
      if (game_id >0 ){
          this._sharedService.CheckInUser(game_id, {'team_id': this.player.my_team.id}, this.token)
            .subscribe(data => {
              this.player = data.user;
              this.token = data.token.access;
              localStorage.setItem('player_token', data.token.access);
              localStorage.setItem('refresh_token', data.token.refresh);
              localStorage.setItem('player_user', JSON.stringify(data.user));
              localStorage.setItem('game', JSON.stringify(data.game));
              if (this.player.email != null && this.player.email.trim().length > 0){
                if (this.player.id == this.player.my_team.owner && !this.player.profile.is_approved){
                  var message = "You're almost there! Check your email to confirm your role as TEAM CAPTAIN of " + this.player.my_team.name;
                }
                else if (this.player.id == this.player.my_team.owner && this.player.profile.is_approved){
                  var message = 'Congrats, you joined the game with team "' + this.player.my_team.name + '". You are the OFFICIAL OWNER of it. Good Luck Tonight!';
                } 
                else {
                  var message = 'Congrats! You are now a Bonafide member of "' + this.player.my_team.name + '". Look for an email update about the benefits! Good Luck Tonight!';
                }
              } else{
                var message = 'Congrats! You are now a Bonafide member of "' + this.player.my_team.name + '". Look for an email update about the benefits! Good Luck Tonight!';
              }
              localStorage.setItem('congrats-mesg', message);
              this.router.navigateByUrl('/join-team-congratulations');
            })
      }
    }

    selectVenue(){
      if(this.player && this.player.my_team && this.token){
        if (this.player.email != null && this.player.email.trim().length > 0) {
            //Register Player
            if (this.selectedVenue.is_register_player_check_in && this.selectedVenue.open_tournament){
              //Register player & already check ins the game.
              this.router.navigateByUrl('/join-game-confirm')
            } else {
              //Register player & new Check-ins to game.
              this.joinTheTournament();
            }
        } else {
              //not Bonafide Member.
              this._sharedService.checkTeanGameLimitService(this.selectedVenue.active_game, this.player.my_team.id).subscribe(data1 => {
                if (data1.canJoin){
                  console.log("Has user team. SO no need to join the team directly and go to score-card....");
                  this._sharedService.JoinTeamService(this.selectedVenue.active_game, {'team_id': this.player.my_team.id}, this.token)
                    .subscribe(data => {
                      this.player = data.user;
                      this.token = data.token.access;
                      localStorage.setItem('player_token', data.token.access);
                      localStorage.setItem('refresh_token', data.token.refresh);
                      localStorage.setItem('player_user', JSON.stringify(data.user));
                      localStorage.setItem('game', JSON.stringify(data.game));
                      var message = "Ok, you don't have Bonafide Member Access but you're ready to play!.";
                      localStorage.setItem('congrats-mesg', message);
                      this.router.navigateByUrl('/join-team-congratulations')
                })
                } else {
                  jQuery('#teamLimitModalIndex1').modal('toggle');
                  this.addOpacity();
                }
              })
          }
      } else {
        console.log("Need to show creat-team/join exisiting dialog box.........")
        // jQuery('body').append('<div class="modal-backdrop fade show"></div>');
        jQuery('#teamModal').modal('toggle');
        this.addOpacity();
      }
    }

    checkTeamLimit(){
      this.loader = true;
      this._sharedService.checkTeanGameLimitService(this.selectedVenue.active_game, null).subscribe(data1 => {
        if (data1.canJoin){
          this.loader = false;
          jQuery('#createTeamModal').modal('toggle');
          this.addOpacity();
        } else {
          this.loader = false;
          jQuery("#teamModal").modal('toggle');
          jQuery('#teamLimitModal1').modal('toggle');
          this.addOpacity();
        }
      })
    }

    onCreateTeamClick(){
      if (this.selectedVenue.active_game){
          this._sharedService.JoinTeamService(this.selectedVenue.active_game, {'team_name': this.teamName}, this.token)
          .subscribe(data => {
            this.player = data.user;
            this.token = data.token.access;
            localStorage.setItem('player_token', data.token.access);
            localStorage.setItem('refresh_token', data.token.refresh);
            localStorage.setItem('player_user', JSON.stringify(data.user));
            localStorage.setItem('game', JSON.stringify(data.game));
            this.teamName = '';
            this.errors = [];
            jQuery('#teamModal').modal('toggle');
            jQuery('#createTeamModal').modal('toggle');
            jQuery('#confirmCreateModal').modal('toggle');
            this.addOpacity();
           },
           error => {
             this.errors = error;
           })
      } else {
        this._sharedService.CreateTeamService({'name': this.teamName}, this.token)
            .subscribe(data => {
              this.player = data.user;
              this.token = data.token.access;
              localStorage.setItem('player_token', data.token.access);
              localStorage.setItem('refresh_token', data.token.refresh);
              localStorage.setItem('player_user', JSON.stringify(data.user));
              localStorage.setItem('team_name', this.teamName);
              this.teamName = '';
              jQuery('#teamModal').modal('toggle');
              jQuery('#confirmCreateModal').modal('toggle');
              this.addOpacity();
            },
            error => {
              this.errors = error;
            })
        }
        }
    
    navigate(){
          localStorage.setItem("player_user_logout_status", JSON.stringify(false));
          if (this.selectedVenue.active_game){
            var message = "Ok, you don't have Bonafide Member Access but you're ready to play!.";
          }
          else {
            var message = "Ok, you don't have Bonafide Member Access but you joined the team....";
          }
          localStorage.setItem('congrats-mesg', message);
          this.router.navigateByUrl('/join-team-congratulations')
      }
    
    ownTeamForm(){
        this.ownForm = new FormGroup({
          first_name: new FormControl('', [Validators.required]),
          email: new FormControl('', [Validators.required, Validators.email]),
          city: new FormControl('', [Validators.required]),
          password: new FormControl('', [Validators.required, Validators.minLength(6)])
        })
      }
    
    ownTeam(){
        // debugger;
        // let data = JSON.stringify(this.ownForm.value);
        this._sharedService.ownTeamService(this.player.my_team.id, this.ownForm.value, this.token)
          .subscribe(data => {
            this.player = data.user;
            localStorage.setItem('player_user', JSON.stringify(data.user));
            this.ownTeamForm();
            if (this.selectedVenue.active_game){
              if (this.selectedVenue.open_tournament) {
                console.log('need to create join game confirm component')
                this.router.navigateByUrl('/join-game-confirm')
              } else {
                this.joinTheTournament();
              }
            } else {
              var message = "Ok, you joined a team at not live location";
              localStorage.setItem('congrats-mesg', message);
              this.router.navigateByUrl('/join-team-congratulations')
            }
           },
           error => {
             this.ownTeamErrors = error;
           })
      }

  changeTeam(){
    var message = "Ok, For '" + this.selectedVenue.name + "' location, You have the following options to change your team!.";
    localStorage.setItem('congrats-mesg', message);
    this.router.navigateByUrl('/change-team')
  }

  compare(a, b) { 
    const venueA = a.venue.name.toLowerCase(); 
    const venueB = b.venue.name.toLowerCase(); 
    let comparison = 0; 
    if (venueA > venueB) {
      comparison = 1;
    } 
    if (venueA < venueB) {
      comparison = -1;
    }
    return comparison
  }

  refreshNewTeam(){
    this.teamName = '';
  }

  addOpacity() {
    jQuery(document).ready(function() {
      jQuery('.modal.fade').css('opacity', 1);
      jQuery('.modal.fade').addClass('show');  
      let cls = document.getElementsByClassName("modal-backdrop").length;
      if (cls>0) {
        jQuery('div.modal-backdrop').addClass('show');  
        jQuery('div.modal-backdrop').css('opacity', 0.8);  
      }
    });
  }
}