
<div class="createTeamModal">
  <div class="modal fade" id="confirmUserModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
          <div class="modal-header">
              <span class="modal-title" id="exampleModalLabel">{{messageHeader}}</span>
              <button class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" class="fa fa-times"></span>
              </button>
          </div>
          <div class="modal-body">
              <span>{{message}}</span>
          </div>
          <div class="modal-btn mb-3">
              <a style="cursor: pointer;" class="btn confirmCreate mt-1 mb-2" data-toggle="modal" (click)="proceed()">{{buttonText}}</a>
          </div>
          </div>
      </div>
  </div>
</div>


<div class="loader" *ngIf="loader">
    <div class="loaderbx">
      <i class="fas fa-3x fa-spinner fa-spin" aria-hidden="true"></i>
    </div>
  </div>