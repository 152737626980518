<!-- login -->
<div class="login-container">
  <div id="login">
    <span>HOST LOGIN</span>
    <form id="form-login" name="form-login" (ngSubmit)="loginHost()" [formGroup]="loginForm">
      <fieldset>
        <div class="alert alert-danger" *ngIf="errorMsg">
          {{errorMsg}}
        </div>
        <div class="alert alert-danger" *ngIf="registrationSuccess">
          {{registrationSuccess}}
        </div>

        <div class="form-group m-3">
          <div class="col-12">
            <input name="username" class="form-control" placeholder="ENTER EMAIL" type="email" formControlName="username" style="font-family: 'Times New Roman', Times, serif; font-weight: bold;"/>
          </div>
        </div>
        <div class="form-group m-3">

          <div class="input-group col-12 show_hide_password">

            <show-hide-password size="lg" btnStyle="primary">
              <input name="password" class="form-control" type="text" placeholder="ENTER PASSWORD" formControlName="password"  style="font-family: 'Times New Roman', Times, serif; font-weight: bold;"/>
            </show-hide-password>

          </div>
        </div>
        <div class="form-group m-2 ml-3 mr-3">
          <div class="col-md-12">
<!--            <a class="btn btn-lg btn-block d-flex-inline" routerLink="/profile" role="button">SIGN IN</a>-->
            <button type="submit" class="btn btn-lg btn-block d-flex-inline" role="button">SIGN IN</button>
          </div>
        </div>
        <div class="form-group m-2 ml-3 mr-3">
          <div class="col-md-12">
            <a class="btn btn-lg btn-block d-flex-inline" data-toggle="modal" data-target="#registerModal" role="button" (click)="addOpacity();">REGISTER</a>
          </div>
        </div>
        <div class="form-group m-2 ml-3 mr-3">
          <div class="col-md-12">
            <a class="btn btn-lg btn-block d-flex-inline" (click)="signInWithFB()" role="button"><i class="fab fa-facebook-square"></i> &nbsp;SIGN IN VIA FACEBOOK</a>
          </div>
        </div>
        <div class="extra-action mr-3 ml-3">
          <a style="cursor: pointer;" data-toggle="modal" class="col text-center" data-target="#forgotPassModal" (click)="addOpacity();">FORGOT PASSWORD?</a>
        </div>
      </fieldset>
    </form>
  </div>
</div>
<!-- end of login -->

<!-- modal -->
<div class="registerModal">
  <div class="modal fade" id="registerModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title" id="exampleModalRegister">REGISTER</span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times" (click)="clearHistory()"></span>
          </button>
        </div>
        <br/>
        <div class="alert alert-danger" *ngIf="errorMsg2">
          <button type="button" class="close" data-dismiss="alert">&times;</button>
          <p *ngIf="errorMsg2.email"><strong>Email : </strong>{{errorMsg2.email}}</p>
          <p *ngIf="errorMsg2.password"><strong>Password : </strong>{{errorMsg2.password}}</p>
          <p *ngIf="errorMsg2.re_password"><strong>Re Password : </strong>{{errorMsg2.re_password}}</p>
        </div>
        <div class="alert alert-success" *ngIf="successMsg">
          {{successMsg}}
        </div>
        <div class="modal-container">
          <div class="team-form mb-4">
            <form id="form-register" name="form-register" (ngSubmit)="registerHost()" [formGroup]="registerForm">
              <div class="form-group">
                <input type="text" class="form-control" placeholder="First Name *" name="first_name" formControlName="first_name" >
              </div>
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Last Name *" name="last_name" formControlName="last_name" >
              </div>
              <div class="form-group">
                <input type="email" class="form-control" placeholder="Email Address *" name="email" formControlName="email" >
              </div>
              <div class="form-group">
                <show-hide-password size="lg" btnStyle="primary">
                  <input type="text" class="form-control" placeholder="Enter Password *" name="password" formControlName="password" >
                </show-hide-password>
              </div>
              <div class="form-group">
                <show-hide-password size="lg" btnStyle="primary">
                <input type="text" class="form-control" placeholder="Confirm Password *" name="re_password" formControlName="re_password" >
                </show-hide-password>
              </div>
              <div class="form-group" formGroupName="profile">
                <input type="text" class="form-control" placeholder="City *" name="city" formControlName="city">
              </div>
              <div class="form-group" formGroupName="profile" >
                <select class="form-control" name="state" formControlName="state">
                  <option [ngValue]="null" disabled>State *</option>
                  <option *ngFor="let state of stateList" value="{{state.id}}">
                    {{state.name }} - ({{state.code}})
                  </option>
                </select>
              </div>
              <div class="form-group" formGroupName="profile" >
                <select class="form-control" name="company" formControlName="company">
                  <option [ngValue]="null" disabled>Company *</option>
                  <option *ngFor="let company of companyList" value="{{company.id}}">
                    {{company.name }}
                  </option>
                </select>
              </div>
              <div class="form-btn" >
                <!-- <a class="btn reg-success mr-1" data-toggle="modal" data-target="#reg-success-modal">SIGN UP</a>-->
                <button type="submit" class="btn reg-success mr-1" [disabled]="!registerForm.valid" >SIGN UP</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="reg-success-modal">
  <div class="modal fade" id="reg-success-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times" (click)="clearHistory()"></span>
          </button>
        </div>
        <div class="modal-body">
          <span>CONGRATULATION, ANNE HATHAWAY</span>
          <small>We'll get in touch you shortly after the account is verified by the admin</small>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="forgotPassModal">
  <div class="modal fade" id="forgotPassModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title" id="exampleModalReset">RESET PASSWORD</span>
          <button class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times" (click)="clearHistory()"></span>
          </button>
        </div>
        <br/>
        <div class="alert alert-danger" *ngIf="errorMsg">
          {{errorMsg}}
        </div>
        <!--<div class="alert alert-success" *ngIf="successMsg">
          {{successMsg}}
        </div>-->
        <form id="form-reset" name="form-reset" (ngSubmit)="sendResetEmail()" [formGroup]="resetPasswordForm">
          <div class="inputarea m-3">
            <input type="email" class="form-control" placeholder="Enter Registered Email Address" name="email" formControlName="email">
          </div>
          <div class="modal-btn mb-3">
            <button class="btn sendEmail mt-1 mb-2" type="submit" [disabled]="!resetPasswordForm.valid" >SEND EMAIL</button>
            <!--<a class="btn sendEmail mt-1 mb-2" data-toggle="modal" data-target="#sendSuccessModal">SEND EMAIL</a>-->
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="sendSuccessModal">
  <div class="modal fade" id="sendSuccessModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times"></span>
          </button>
        </div>
        <div class="modal-body">
          <span>CONGRATULATIONS!</span>
          <br/>
          <small style="font-size: 18px;">An Email was sent to your Email Address.</small>
          <small style="font-size: 18px;">Follow the link in the Email to reset password.</small>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loader" *ngIf="loader">
  <div class="loaderbx">
    <i class="fas fa-3x fa-spinner fa-spin" aria-hidden="true"></i>
  </div>
</div>
