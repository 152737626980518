import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-tos',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.css']
})
export class TosComponent implements OnInit {
  public toss: any;

  constructor(private http: HttpClient) {
    this.http.get<any>(environment.BASE_API_URL + 'termsOfService/')
      .subscribe((result) => {
        this.toss = result.results;
      });
   }

  ngOnInit() {
  }

}
