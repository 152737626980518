<h1 mat-dialog-title>Response Review</h1>
<div mat-dialog-content *ngIf="ready">
  <div fxLayout="row">
    <mat-label fxFlex="20%"><strong>Round:</strong></mat-label>
    <mat-select fxFlex="80%" [(ngModel)]="selectedRound" (selectionChange)="list.deselectAll(); updateTeamList();">
      <mat-option *ngFor="let round of rounds" [value]="round" >
        Round: {{round}}
      </mat-option>
    </mat-select>
  </div>
  <div fxLayout="row">
    <mat-label fxFlex="20%"><strong>Team:</strong></mat-label>
    <mat-select fxFlex="80%" [(ngModel)]="selectedTeam" (selectionChange)="list.deselectAll(); updateAnswerDisplay();">
      <mat-option *ngFor="let team of teams" [value]="team.id" >
        {{team.name}}
      </mat-option>
    </mat-select>
  </div>
  <hr>  
  <div fxLayout="row">
    <mat-selection-list #list [(ngModel)]="gradedAnswers" fxFlex="100%">
      <mat-list-option *ngFor="let q of selectedCard.textAnswers; let i = index;" [value]="i">
        Q&nbsp;{{i+1}}&nbsp;For&nbsp;{{q.points}}&nbsp;Points:<br><strong>{{q.response}}</strong>
      </mat-list-option>
    </mat-selection-list>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button *ngIf="selectedTeam" (click)="close()">Exit</button>
  <button mat-raised-button *ngIf="selectedTeam" (click)="save()">Next</button>
</div>
