import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {MatSortModule} from '@angular/material/sort';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatTableModule} from '@angular/material/table';
import { MatSliderModule } from '@angular/material/slider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';

import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LocationsComponent } from './pages/player/locations/locations.component';
import { HostLoginComponent } from './pages/host/host-login/host-login.component';
import { IndexComponent } from './pages/player/index/index.component';
import { ProfileComponent } from './pages/host/profile/profile.component';
import { ReportsComponent } from './pages/host/reports/reports.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {HostLoginService} from './services/host-login.service';
import { ContactComponent } from './components/contact/contact.component';
import { BlankscreenComponent } from './components/blankscreen/blankscreen.component';
import {PlayerSharedService} from './services/player-shared.service';
import { TournamentsComponent } from './pages/player/tournaments/tournaments.component';
import { CardsComponent } from './pages/player/cards/cards.component';
import {CookieService} from 'ngx-cookie-service';
import { TournamentLeaderboardComponent } from './pages/player/tournament-leaderboard/tournament-leaderboard.component';
import { ResultsComponent } from './pages/player/results/results.component';
import {DateSuffix, RankSuffix} from './pipe-extenders/date-suffix-pipe'
import {SocialLoginModule, AuthServiceConfig, FacebookLoginProvider} from 'angularx-social-login';
import {environment} from '../environments/environment';
import {ShowHidePasswordModule} from 'ngx-show-hide-password';
import { ScoreCardComponent } from './pages/player/score-card/score-card.component';
import {FlairComponent} from './pages/player/flair/flair.component';
import {AnswerSheetComponent} from './pages/player/answer-sheet/answer-sheet.component';
import { ChangePassowrdComponent } from './pages/host/change-passowrd/change-passowrd.component'
import { SearchTeamComponent } from './pages/player/search-team/search-team.component'
import { JoinTeamCongratsComponent } from './pages/player/join-team-congrats/join-team-congrats.component';
import { JoinGameConfirmComponent } from './pages/player/join-game-confirm/join-game-confirm.component';
import { LaunchGameComponent } from './pages/host/launch-game/launch-game.component';
import { ComingSoonComponent } from './pages/host/coming-soon/coming-soon.component';
import { StartRoundComponent } from './pages/host/start-round/start-round.component';
import {GenerateQuestionsDialog} from './pages/host/generate-questions-dialog/generate-questions-dialog.component';
import { WagerReportDialog } from './pages/host/wager-report-dialog/wager-report-dialog.component';
import { ScoringSheetDialog } from './pages/host/scoring-sheet/scoring-sheet.component';
import { GameResultComponent } from './pages/host/game-result/game-result.component';
import { SortByPipe } from './pipe-extenders/sort-by.pipe';
import { ConfirmUserComponent } from './components/confirm-user/confirm-user.component';
import { ChangeTeamComponent } from './pages/player/change-team/change-team.component';
import { HostTournamentsComponent } from './pages/host/host-tournaments/host-tournaments.component';
import { HostTournamentsLeaderboardComponent } from './pages/host/host-tournaments-leaderboard/host-tournaments-leaderboard.component';
import {TableModule} from 'angular-bootstrap-md';
import { PresentationLeaderboardComponent } from './pages/host/presentation-leaderboard/presentation-leaderboard.component';
import { HealthCheckComponent } from './components/health-check/health-check.component';
import { TeamProfileComponent } from './pages/player/team-profile/team-profile.component';
import { ViewTeamProfileComponent } from './pages/host/view-team-profile/view-team-profile.component';
import { ResetPasswordComponent } from './pages/host/reset-password/reset-password.component';
import { CreateChangeTeamComponent } from './pages/player/create-change-team/create-change-team.component';
import { InvitePlayerComponent } from './pages/player/invite-player/invite-player.component';
import { FaqComponent } from './components/faq/faq.component';
// import {LZStringModule, LZStringService} from 'ng-lz-string';
import { ReversePipe } from './pipe-extenders/reverse.pipe';
// import {AngularFontAwesomeModule} from 'angular-font-awesome';
import { TournamentTeamProfileComponent } from './pages/player/tournament-team-profile/tournament-team-profile.component';
import { StartGameComponent } from './pages/host/start-game/start-game.component';
import { EndRoundHostComponent } from './pages/host/end-round-host/end-round-host.component';
import { EndGameHostComponent } from './pages/host/end-game-host/end-game-host.component';
import {DataTablesModule} from 'angular-datatables';
import { DefaultTournamentLeaderboardComponent } from './pages/player/default-tournament-leaderboard/default-tournament-leaderboard.component';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { HostLeaderboardComponent } from './pages/host/host-leaderboard/host-leaderboard.component';
import { DefaultTournamentLeaderboardHomeComponent } from './pages/player/default-tournament-leaderboard-home/default-tournament-leaderboard-home.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TosComponent } from './components/tos/tos.component';
import { PastTournamentLeaderboardHomeComponent } from './pages/player/past-tournament-leaderboard-home/past-tournament-leaderboard-home.component';
import { PastTournamentLeaderboardComponent } from './pages/player/past-tournament-leaderboard/past-tournament-leaderboard.component';
import { HostLeaderboardAllComponent } from './pages/host/host-leaderboard-all/host-leaderboard-all.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatTooltipModule, } from "@angular/material/tooltip";
import {MatButtonModule} from "@angular/material/button";
import {MatListModule} from '@angular/material/list';
// import { DlDateTimeDateModule, DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';


let config = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(environment.FB_APP_ID)
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LocationsComponent,
    HostLoginComponent,
    IndexComponent,
    ProfileComponent,
    ReportsComponent,
    ContactComponent,
    BlankscreenComponent,
    TournamentsComponent,
    CardsComponent,
    TournamentLeaderboardComponent,
    ResultsComponent,
    DateSuffix,
    RankSuffix,
    ScoreCardComponent,
    FlairComponent,
    ChangePassowrdComponent,
    SearchTeamComponent,
    JoinTeamCongratsComponent,
    JoinGameConfirmComponent,
    LaunchGameComponent,
    ComingSoonComponent,
    StartRoundComponent,
    GameResultComponent,
    SortByPipe,
    ConfirmUserComponent,
    ChangeTeamComponent,
    HostTournamentsComponent,
    HostTournamentsLeaderboardComponent,
    HealthCheckComponent,
    PresentationLeaderboardComponent,
    TeamProfileComponent,
    ViewTeamProfileComponent,
    ResetPasswordComponent,
    CreateChangeTeamComponent,
    InvitePlayerComponent,
    FaqComponent,
    ReversePipe,
    TournamentTeamProfileComponent,
    StartGameComponent,
    EndRoundHostComponent,
    EndGameHostComponent,
    DefaultTournamentLeaderboardComponent,
    HostLeaderboardComponent,
    DefaultTournamentLeaderboardHomeComponent,
    PrivacyPolicyComponent,
    TosComponent,
    PastTournamentLeaderboardHomeComponent,
    PastTournamentLeaderboardComponent,
    HostLeaderboardAllComponent,
    GenerateQuestionsDialog,
    WagerReportDialog,
    ScoringSheetDialog,
    AnswerSheetComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    SocialLoginModule,
    ShowHidePasswordModule,
    MatTableModule,
    TableModule,
    DataTablesModule,
    UiSwitchModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    FlexLayoutModule,
    MatSortModule,
    MatSliderModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatDialogModule,
    MatListModule,
  ],
  providers: [CookieService, PlayerSharedService,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    // LZStringService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
