<div class="title-container mt-3">
  <div class="page-t">
    <div>
      <div class="page-title" style="margin: auto;">
        <b>SCOREBOARD</b>
      </div>
    </div>
  </div>
</div>


<div class="tournamentcard m-3" *ngFor="let tournament of tournaments">
  <div class="card-header navbar">
    <span class="live">LIVE SCOREBOARDS</span>
    <span class="date">{{(tournament.end_date != null)?((tournament.end_date | date)): "DEFAULT"}}</span>
  </div>
  <div class="top">
    <div class="image">
      <div class="imageround">
      <img src="{{tournament.image}}" *ngIf="tournament.image"/>
      <img src="{{defaultImage}}" *ngIf="!tournament.image"/>
      </div>
    </div>
    <div class="game-info mr-2">
      <span>{{tournament.name}}</span>
      <small>{{tournament.main_sponsor_name}}</small>
    </div>
    <div class="button d-flex flex-column justify-content-center">
      <a style="cursor: pointer;" class="btn btn-md mb-1" data-toggle="modal" data-target="#team-info-modal" (click)="tournamentInfo(tournament)">INFO</a>
      <a class="btn btn-md" *ngIf="tournament.is_default" routerLink="/host/tournament-leaderboard" role="button">LEADERBOARD</a>
      <a class="btn btn-md" *ngIf="!tournament.is_default" routerLink="/host/tournament-leaderboard/{{tournament.id}}/{{tournament.name}}" role="button">LEADERBOARD</a>
    </div>
  </div>
</div>


<div class="tournamentcard m-3" *ngFor="let tournament of pastTournaments">
  <div class="card-header navbar">
    <span>PAST SCOREBOARDS</span>
    <span class="date">{{(tournament.end_date != null)?((tournament.end_date | date)): "DEFAULT"}}</span>
  </div>
  <div class="top">
    <div class="image">
      <div class="imageround">
        <img src="{{tournament.image}}" *ngIf="tournament.image"/>
        <img src="{{defaultImage}}" *ngIf="!tournament.image"/>
      </div>
    </div>
    <div class="game-info mr-2">
      <span>{{tournament.name}}</span>
      <small>{{tournament.main_sponsor_name}}</small>
    </div>
    <div class="button d-flex flex-column justify-content-center">
      <a style="cursor: pointer;" class="btn btn-md mb-1" data-toggle="modal" data-target="#team-info-modal" (click)="tournamentInfo(tournament)">INFO</a>
      <a class="btn btn-md" *ngIf="tournament.is_default" routerLink="/host/tournament-leaderboard" role="button">LEADERBOARD</a>
      <a class="btn btn-md" *ngIf="!tournament.is_default" routerLink="/host/tournament-leaderboard/{{tournament.id}}/{{tournament.name}}" role="button">LEADERBOARD</a>
    </div>
  </div>
</div>

<!--<div class="team-info-modal">
  <div class="modal fade" id="team-info-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title" id="example ModalLabel">{{selectedTournament.name}}</span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times"></span>
          </button>
        </div>
        <div class="modal-body d-flex justify-content-center">
          <div>
            <div class="mr-3">
              <div class="image">
                <img src="{{selectedTournament.image}}">
              </div>
            </div>
            <div style="text-align: left">
              <div class="row">
                <div class="col-md-4">
                  Start Date
                </div>
                <div class="col-md-8">
                  {{selectedTournament.start_date}}
                </div>
                <div class="col-md-4">
                  End Date
                </div>
                <div class="col-md-8">
                  {{selectedTournament.end_date}}
                </div>
                <div class="col-md-4">
                  Sponsor
                </div>
                <div class="col-md-8">
                  {{selectedTournament.main_sponsor_name}}
                </div>
                <div class="col-md-4">
                  Venues
                </div>
                <div class="col-md-8">
                  <ol style="margin-left: -10%;">
                    <li *ngFor="let venue of selectedTournament.venues">
                      <a routerLink="/host/launch-game/{{venue.id}}">{{ venue.name }}</a>
                    </li>
                  </ol>
                </div>
              </div>



              <hr/>
              <small [innerHtml]="selectedTournament.info"></small>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>-->

<div class="team-info-modal">
  <div class="modal fade" id="team-info-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title" id="example ModalLabel" >{{selectedTournament.name}}</span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times"></span>
          </button>
        </div>
        <div class="modal-body d-flex ">

          <div class="row">

            <div class="col-12">
              <div class="row info-modal">
                <div class="col-md-3">
                  <div class="image">
                    <img src="{{selectedTournament.image}}" *ngIf="selectedTournament.image">
                    <img src="{{defaultImage}}" *ngIf="!selectedTournament.image">
                  </div>
                </div>
                <div class="col-md-9">
                  <dl class="info-modal">
                    <!--<table class="table" border="0" >
                      <tr>
                        <td style="text-align: right; width: 55%;">Start Date :</td><td style="text-align: left; width: 45%;word-break: break-all;">{{selectedTournament.start_date}}</td>
                      </tr>
                      <tr>
                        <td style="text-align: right; width: 55%;">End Date :</td><td style="text-align: left; width: 45%;word-break: break-all;">{{selectedTournament.end_date}}</td>
                      </tr>
                      <tr>
                        <td style="text-align: right; width: 55%;">Sponsor :</td><td style="text-align: left; width: 45%;word-break: break-all;">{{selectedTournament.main_sponsor_name}}</td>
                      </tr>
                    </table>-->
                    <div class="listpart" *ngIf="selectedTournament.start_date">
                      <dt>Start Date</dt>
                      <dd> : {{selectedTournament.start_date}}</dd>
                    </div>
                    <div class="listpart" *ngIf="selectedTournament.end_date">
                      <dt>End Date</dt>
                      <dd> : {{selectedTournament.end_date}}</dd>
                    </div>
                    <div class="listpart" *ngIf="selectedTournament.main_sponsor_name">
                      <dt>Sponsor</dt>
                      <dd> : {{selectedTournament.main_sponsor_name}}</dd>
                    </div>
                  </dl>
                </div>
                <hr/>
                <div class="row ">
                  <div class="col-md-12">
                    <span style="color: #ffbf00;">Venues</span>
                    <ol class="text-left" style="padding-left: 18px;" *ngIf="selectedTournament.venues">
                      <li *ngFor="let venue of selectedTournament.venues">
                        <!--<a routerLink="/host/launch-game/{{venue.id}}">{{ venue.name }}</a>-->
                        {{ venue.name }}
                      </li>
                    </ol>
                    <p *ngIf="selectedTournament.venues && selectedTournament.venues.length == 0">No Venues Found.</p>
                    <hr/>
                    <div class="text-left">
                      <small [innerHtml]="selectedTournament.info"></small>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loader" *ngIf="loader">
  <div class="loaderbx">
    <i class="fas fa-3x fa-spinner fa-spin" aria-hidden="true"></i>
  </div>
</div>
