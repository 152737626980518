import {Router} from '@angular/router';
import {Component, OnInit, Injector, OnDestroy} from '@angular/core';
import {FormControl, FormGroup, FormArray, Validators, FormBuilder} from '@angular/forms';
import {Observable} from "rxjs";
import {ResultsService} from '../../../services/results.service';
import {ActivatedRoute} from '@angular/router';
import {PlayerSharedService} from '../../../services/player-shared.service';
import {PlayerUser} from '../../../models/player_user';
import {AnswerSheet, Answers, question} from '../../../models/QuestionInterface';
import { Answer } from 'src/app/models/AnswersEnum';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { WebsocketProviderAnswersService } from 'src/app/services/websocket-provider-answers.service';

@Component({
  selector: 'app-answer-sheet',
  templateUrl: './answer-sheet.component.html',
  styleUrls: ['./answer-sheet.component.css'],
  providers: [WebsocketProviderAnswersService]
})
export class AnswerSheetComponent implements OnInit, OnDestroy {
  public player: PlayerUser;
  public round: number;
  public score: number;
  public answerForm: FormGroup;
  public gameID: any;
  public answerSheet: AnswerSheet;
  public answerOptions: Answer[] = [1, 2];
  public wagerSubmitted: boolean = false;
  public ready: boolean = false;
  public team_id: number;
  public force: boolean = false;
  
 

  constructor(private router: Router, 
    private _sharedService: PlayerSharedService, 
    private _resultService: ResultsService, 
    private wsService: WebsocketProviderAnswersService,
    private route: ActivatedRoute, 
    public fb: FormBuilder,
    public dialog: MatDialog,
    private injector: Injector
    ) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
   }
  ngOnDestroy(): void {
    console.log("IN NGONDESTROY CLOSING SOCKET");
    this.wsService.close();
  }

  ngOnInit() {
    console.log("IN NGONINIT");
    this.player = JSON.parse(localStorage.getItem('player_user'));
    this.route.params.subscribe(params =>{
      this.round = Number(params['roundNumber']);
      this.gameID = params['gameId'];
      this.team_id = params['teamId'];
      this.getQuestions(this.gameID, this.round, this.team_id).subscribe(gameData =>{
        if (!gameData ){
          this.redirect();
        }
        this.answerSheet = gameData;
        this.getTeamScore(this.gameID, this.team_id);
      });
      this.wsService.connect(this.gameID)
      .subscribe((result) => {
        console.log(result);
        switch (result.msg_type) {
          case "status":
            break;
          case "error":
            break;
          case "leave":
            break;
          case "join":
            console.log(result);
          case "update":
            console.log(result.message.submit);
            //let msg = JSON.parse(result.message);
            if (result.message.submit){
              this.force = true;
              this.autoSubmit();
            }
            break;
          default:
            console.log("default case");
            break;
          }
        }
      );
    });
  }

  get sizing(): string{
    if (this.answerOptions.length%3 == 0 || this.answerOptions.length%5 == 0){
      return "33%";
    } else if((this.answerOptions.length%2 == 0 && this.answerOptions.length > 2) || this.answerOptions.length%3 == 1){
      return "25%";
    }
    return "50%";
  }

  initForm(as: AnswerSheet){
    this.answerForm = this.fb.group({
      'questions': this.fb.array([this.initQs()])
    });
    this.updateQuestions();
    this.initAns();
  }

  initTextForm(as: AnswerSheet){
    this.answerForm = this.fb.group({
      'textAnswers': this.fb.array([this.initTextQs()])
    });
    this.updateTextQuestions();
  }

  initWager(as: AnswerSheet, score: number){
    this.answerForm = this.fb.group({
      'questions': this.fb.array([this.fb.group({
        'points': [0, [Validators.required, Validators.min(0), Validators.max(score)]],
        'answer': ['', Validators.required]
      })])
    });
    this.initAns();
  }

  initTextWager(as: AnswerSheet, score: number){
    this.answerForm = this.fb.group({
      'textAnswers': this.fb.array([this.fb.group({
        'points': [0, [Validators.required, Validators.min(0), Validators.max(score)]],
        'response': ['', Validators.required]
      })])
    });
  }

  initAns(){
    for (var i=this.answerOptions.length; i < this.answerSheet.numAns; i++){
      this.answerOptions.push(this.answerOptions.length+1);
    }
  }

  initQs(): FormGroup {
    return this.fb.group({
      'points': [0],
      'answer': ['', Validators.required]
    })
  }

  initTextQs(): FormGroup {
    return this.fb.group({
      'response': []
    })
  }
  
  preloadWager(): void {
    switch (this.answerSheet.roundType) {
      case 0:
        break;
      case 2:
        break;
      case 1:
        this.qs.controls[0].get('points').setValue(this.answerSheet.wager == null ? this.score : this.answerSheet.wager);
        if(this.answerSheet.wager != null){
          this.wagerSubmitted = true;
          this.qs.controls[0].get('points').disable();
        }
        break;
      case 3:
        this.textQs.controls[0].get('points').setValue(this.answerSheet.wager == null ? this.score : this.answerSheet.wager);
        if(this.answerSheet.wager != null){
          this.wagerSubmitted = true;
          this.textQs.controls[0].get('points').disable();
        }
        break;
    }
  }

  get qs(): FormArray {
    return this.answerForm.get('questions') as FormArray;
  }

  get textQs(): FormArray {
    return this.answerForm.get('textAnswers') as FormArray;
  }

  //#region Questions Modifiers

  updateQuestions() {
    console.log(this.qs.length);
    console.log(this.answerSheet.questions);
    if (this.answerSheet.questions < this.qs.length){
      this.removeQuestion();
    }else if(this.answerSheet.questions > this.qs.length){
      this.addQuestion();
    }
    if (this.answerSheet.questions != this.qs.length){
      this.updateQuestions();
    }
  }

  updateTextQuestions() {
    console.log(this.textQs.length);
    console.log(this.answerSheet.questions);
    if (this.answerSheet.questions < this.textQs.length){
      this.removeTextQuestion();
    }else if(this.answerSheet.questions > this.textQs.length){
      this.addTextQuestion();
    }
    if (this.answerSheet.questions != this.textQs.length){
      this.updateTextQuestions();
    }
  }

  addQuestion() {
    this.qs.push(this.fb.group({
      'answer': ['', Validators.required]
      }));
  }

  addTextQuestion() {
    this.textQs.push(this.fb.group({
      'response': ['', Validators.required]
      }));
  }

  removeQuestion() {
    this.textQs.removeAt(this.textQs.length - 1);
  }

  removeTextQuestion() {
    this.textQs.removeAt(this.qs.length - 1);
  }
  //#endregion

  getAns(a: number){
    return Answer[a];
  }

  getQuestions(gameId: number, roundNumber: number, team: number): Observable<AnswerSheet>{
    let data = {gameId: gameId, round: roundNumber, team: team}
    return this._sharedService.getAnswerSheet(data);
  }

  getTeamScore(gameId: number, teamId: number){
    this._resultService.getTeamsPerGames(gameId).subscribe((result) => {
      this.score = result.find(s => s.id == teamId).total_score;
      console.log(result);
      console.log(teamId);
      switch (this.answerSheet.roundType){
        case 0:
          this.initForm(this.answerSheet);
          break;
        case 1:
          this.initWager(this.answerSheet, this.score);
          break;
        case 2:
          this.initTextForm(this.answerSheet);
          break;
        case 3:
          this.initTextWager(this.answerSheet, this.score);
          break;
        default:
          this.noSheetAlert();
          break;
      }
      if (!(typeof this.answerSheet.wager === 'undefined')){
        this.preloadWager();
      }
      this.ready = true;
      console.log(this.answerForm);
    });
  }
//refactor submit to accept wager flag
  submit(model: Answers){
    model.round = this.round;
    model.roundType = this.answerSheet.roundType;
    //removed IAW spec
    //model.player = this.player.id ? this.player.id : null;
    model.team_id = this.player.my_team.id;
    this._sharedService.submitAnswerSheet(model, this.gameID).subscribe((result) => {
      console.log(result);
      if(model.roundType === 1 || model.roundType === 3){
        this.wagerSubmitted = !this.wagerSubmitted;
      }
      if(!this.wagerSubmitted){
        this.redirect();
      } 
      if(this.force){
        this.redirect();
      }
      if(!result.status){
        if(this.force){
          this.redirect();
        }else{
          this.submissionAlert();
        }
      }    
    });
  }

  confirmSubmit(){
    const dialogRef = this.dialog.open(ConfirmSubmissionAlertDialog, {
      width: '250px'
    });

    dialogRef.afterClosed().subscribe(res => {
        if (res){
          this.submit(this.answerForm.value);
        }
    });
  }

  autoSubmit(){
    console.log("Forcing Submission");
    this.submit(this.answerForm.value);
  }

  submissionAlert(): void{
    const dialogRef = this.dialog.open(AnswersSubmissionAlertDialog, {
      width: '250px'
    });

    dialogRef.afterClosed().subscribe(res => {
        this.redirect();
    });
  }

  noSheetAlert(): void{
    const dialogRef = this.dialog.open(NoSheetAlertDialog, {
      width: '250px'
    });

    dialogRef.afterClosed().subscribe(res => {
        this.redirect();
    });
  }

  redirect(){
    this.router.navigate(['score-card', this.gameID]);
  }
}

//Alert dialog
export interface AlertData{
  answers: number,
  teams: number,
  round: number
}

@Component({
  selector: 'answers-submission-alert-dialog',
  templateUrl: 'answers-submission-alert-dialog.html',
})
export class AnswersSubmissionAlertDialog {

  constructor(
    public dialogRef: MatDialogRef<AnswersSubmissionAlertDialog>
    ) 
    {
      dialogRef.backdropClick().subscribe(result => {
        dialogRef.disableClose = true; //disable default close operation
        dialogRef.close();
      });
    }
  onNoClick(): void {
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'confirm-submission-alert-dialog',
  templateUrl: 'confirm-submission-alert-dialog.html',
})
export class ConfirmSubmissionAlertDialog {

  constructor(
    public dialogRef: MatDialogRef<AnswersSubmissionAlertDialog>
    ) 
    {
      dialogRef.backdropClick().subscribe(result => {
        dialogRef.disableClose = true; //disable default close operation
        dialogRef.close(false);
      });
    }
  onNoClick(): void {
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close(false);
  }

  confirm(): void {
    this.dialogRef.close(true);
  }
}

@Component({
  selector: 'no-sheet-alert-dialog',
  templateUrl: 'no-sheet-alert-dialog.html',
})
export class NoSheetAlertDialog {

  constructor(
    public dialogRef: MatDialogRef<NoSheetAlertDialog>
    ) 
    {
      dialogRef.backdropClick().subscribe(result => {
        dialogRef.disableClose = true; //disable default close operation
        dialogRef.close();
      });
    }
  onNoClick(): void {
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    this.dialogRef.close();
  }
}
