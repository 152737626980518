import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dateSuffix' })
export class DateSuffix implements PipeTransform {
    transform(value: string): string {
    let suffix = 'th',
        day = value.substring(0,2),
        month = value.substring(3)

        if (day == '1' || day == '21' || day == '31') {
            suffix = 'st'
        } else if (day == '2' || day == '22') {
            suffix = 'nd';
        } else if (day == '3' || day == '23') {
           suffix = 'rd';
        }

        return day+suffix+' '+month;

    }
}


@Pipe({ name: 'rankSuffix' })
export class RankSuffix implements PipeTransform {
    transform(value: number): string {

    let suffix = 'th',
        day = value;
        console.log(day);
        if (day == 1 || day == 21 || day == 31) {
            suffix = 'st'
        } else if (day == 2 || day == 22) {
            suffix = 'nd';
        } else if (day == 3 || day == 23) {
           suffix = 'rd';
        }

        return day+suffix;

    }
}
