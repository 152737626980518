import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import {UserInfo} from '../models/user-info';
import {Router} from '@angular/router';
import {UserInfoService} from './user-info.service';

@Injectable({
  providedIn: 'root'
})

export class HostLoginService {

  public isAuthenticated: boolean;

  constructor(
    private http: HttpClient,
    private router: Router,
    private userInfoService: UserInfoService
  ) {
  }

  login(data): Observable<UserInfo[]> {
    this.userInfoService.clearUser();
    return this.http.post<UserInfo[]>
    (environment.BASE_API_URL + 'accounts/signin/', data,{ headers: {'Content-Type': 'application/json'} })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  logout() {
    this.userInfoService.clearUser();
  }

  register(data): any {
    return this.http.post<any>
    (environment.BASE_API_URL + 'accounts/signup/host/', data, { headers: {'Content-Type': 'application/json'} })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  resetPassword(data): any {
    return this.http.post<any>
    (environment.BASE_API_URL + 'accounts/send-reset-password/', data,{ headers: {'Content-Type': 'application/json'} })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  handleError(errorResponse) {
    return errorResponse.error;
  }

  FBLogin(data) {
    return this.http.post<any>
      (environment.BASE_API_URL + 'accounts/facebook-login/host/', data, { headers: {'Content-Type': 'application/json'} })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  updatePassword(data, token) {
    return this.http.post<any>
    (environment.BASE_API_URL + 'accounts/save-new-password/', data, { headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+token } })
      .pipe(catchError(e => throwError(this.handleError(e))));
  }
}
