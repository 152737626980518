    <div class="title-container mt-3">
        <div class="page-t">
            <div class="page-title" style="margin: auto;">
                <b>CHOOSE LOCATION</b>
            </div>
        </div>
    </div>

    <div *ngIf="!sortData">
        No Locations to Choose from.....
    </div>
    <div *ngIf="sortData.length > 0" class="searchbox mt-3 ml-3 mr-3">
        <form action="#">
            <input type="text" placeholder="Search Location..." [(ngModel)]="liveFilter" name="search">
            <button type="submit"><i class="fa fa-search"></i></button>
        </form>
    </div>
    <div *ngIf="sortData.length > 0" class="livegamecard m-3">
        <div class="card-header" style="background-color:rgb(39, 150, 39); color: white; text-align: center !important;padding-right: 70px;">
            <b>OPEN GAMES</b>
        </div>
        <div *ngIf="sortData.length > 0">
            <div class="livegamecard m-3" *ngFor="let game of sortData | filter:liveFilter">
                <div *ngIf="game.venue.active_game" class="card-header logoimgcs text-right" style="background-color:rgb(39, 150, 39); color: white">
                  <b id="stards">START  {{game.venue.game_open_date| date: ' MMM d, EEE, h:mm '}}</b>
                <!-- <img class="text-left" style="float: left;" src="assets/img/logo_cropped_final_white.png" alt="TRIVTRAK BOLT" width="4%"> -->
                <img class="text-left" style="float: left;" src="assets/img/logo_white.png" alt="TRIVTRAK LOGO" width="4%">
                </div>
                <a *ngIf="game.venue.active_game" style="cursor: pointer;" data-toggle="modal" (click) = "onVenueSelect(game.venue)">
                    <div class="top">
                        <div class="image">
                            <div class="imageround">
                                <img [src]="game.venue.image_path != null ? game.venue.image_path : 'assets/img/location.png'">
                            </div>
                        </div>
                        <div class="game-info">
                            <span>{{game.venue.name}}</span>
                            <small>{{game.venue.address}}</small>
                        </div>
                        <div class="game-info" id="stard" style="font-size:20px;">
                          <span class="text-right" style="font-size:28px;"><u>START TIME</u>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;</span>
                          <span class="text-right">{{game.venue.game_open_date| date: ' MMM dd, EEE, h:mm a'}}</span>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <!-- Modal -->
<div class="teamModal">
        <div class="modal fade" id="teamModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <span class="modal-title" id="exampleModalLabel">WELCOME TO</span>
                    <button class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" class="fa fa-times"></span>
                    </button>
                </div>
                <div class="modal-body">
                    <span>{{selectedVenue.name}}</span>
                </div>
                <div class="modal-btn mb-3">
                    <a style="cursor: pointer;" class="btn create mt-1 mb-2" (click)="checkTeamLimit();">NEW TEAM</a>
                    <a class="btn join-existing" routerLink="/search-team" role="button">JOIN EXISTING TEAM</a>
                </div>
                </div>
            </div>
        </div>
    </div>

    <div class="add-team-modal">
        <div class="modal fade" id="teamLimitModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                  <span class="modal-title">Team Limit Reached</span>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" class="fa fa-times"></span>
                  </button>
                </div>
                <div class="modal-container">
                  <div class="modal-body" style="text-align: center; font-family: 'BourtonBase';">
                      <span style="font-size: 25px;">We're sorry, this game is at Capacity.<br/>Please check schedule for the next game, coming soon.</span>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div class="add-team-modal">
        <div class="modal fade" id="teamLimitModalLocation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                  <span class="modal-title">Team Limit Reached</span>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" class="fa fa-times"></span>
                  </button>
                </div>
                <div class="modal-container">
                  <div class="modal-body" style="text-align: center; font-family: 'BourtonBase';">
                      <span style="font-size: 25px;">We're sorry, this game is at Capacity.<br/>Please check schedule for the next game, coming soon.</span>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div class="add-team-modal">
        <div class="modal fade" id="teamLimitModalIndex1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                  <span class="modal-title">Team Limit Reached</span>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" class="fa fa-times"></span>
                  </button>
                </div>
                <div class="modal-container">
                  <div class="modal-body" style="text-align: center; font-family: 'BourtonBase';">
                      <span style="font-size: 25px;">We're sorry, this game is at Capacity.<br/>Please check schedule for the next game, coming soon.</span>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>

    <!-- Create team modal -->
    <div class="createTeamModal">
        <div class="modal fade" id="createTeamModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <span class="modal-title" id="exampleModalLabel">NEW TEAM</span>
                    <button class="close" data-dismiss="modal" aria-label="Close" (click)="refreshNewTeam()">
                    <span aria-hidden="true" class="fa fa-times"></span>
                    </button>
                </div>
                <div class="modal-body">
                    <span>TYPE THE NAME OF YOUR TEAM</span>
                </div>
                <div class="inputarea mr-5 ml-5 mb-3">
                    <input type="text" class="form-control text-center" placeholder="Team Name" [(ngModel)]="teamName" required>
                    <span *ngFor="let error of errors.team_name" class="error-message">{{ error }}</span>
                </div>
                <div class="modal-btn mb-3">
                    <a style="cursor: pointer;" class="btn confirmCreate mt-1 mb-2" data-toggle="modal" (click)="onCreateTeamClick()">CREATE</a>
                </div>
                </div>
            </div>
        </div>
    </div>

    <div class="confirmCreateModal">
        <div class="modal fade" id="confirmCreateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" class="fa fa-times"></span>
                        </button>
                    </div>
                    <div class="modal-container">
                        <div class="modal-body">
                            <span>OWN YOUR TEAM!!!</span>
                        </div>
                        <div class="modal-btn">
                            <a class="btn team-form mr-1" data-toggle="modal" data-target="#team-form-modal" (click)="addOpacity();">CLICK TO OWN</a>
                        </div>
                        <div class="link mt-2">
                            <a (click)="navigate()">NO THANK YOU</a>
                        </div>
                        <div class="modal-info">
                            <div class="info-header mb-2">
                                <span>BENEFITS OF OWNERSHIP</span>
                            </div>
                            <ul>
                                <li>1.  Ownership of Team Name</li>
                                <li>2.  Change Team Name</li>
                                <li>3.  Access to scoreboards</li>
                                <li>4.  Access to special events</li>
                                <li>5.  Rewards and Stats</li>
                                <li>6.  Join our Trivia Community</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



<div class="confirmCreateModal">
    <div class="modal fade" id="changeTeamModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" class="fa fa-times"></span>
                    </button>
                </div>
                <div class="modal-container">
                    <div class="modal-body">
                        <span>DO YOU WANT TO CHANGE YOUR TEAM?</span>
                        <!-- <span>Your current Team is '{{player.my_team.name}}'</span> -->
                    </div>
                    <div class="modal-btn" style="margin-bottom: 15px;">
                        <!-- <a class="btn team-form mr-1" data-toggle="modal" (click)="selectVenue()">CONTINUE WITH TEAM '{{player.my_team.name}}'</a> -->
                        <a class="btn team-form mr-1" data-toggle="modal" style="width: 66%; cursor: pointer" (click)="selectVenue()">CONTINUE WITH CURRENT TEAM</a>
                    </div>
                    <div class="modal-btn" style="margin-bottom: 10px;">
                        <a class="btn team-form mr-1" data-toggle="modal" style="width: 66%; cursor: pointer" (click)="changeTeam()">PLAY WITH DIFFERENT TEAM</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="team-form-modal">
        <div class="modal fade" id="team-form-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header"></div>
                    <div class="modal-container">
                        <div class="modal-body">
                            <span>FILL IN THE FOLLOWING DETAILS TO OWN YOUR TEAM</span>
                        </div>
                        <div class="team-form mb-4">
                            <form (ngSubmit)="ownTeam()" [formGroup]="ownForm">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Fullname" formControlName="first_name">
                                    <span *ngFor="let error of ownTeamErrors.first_name" class="error-message">{{ error }}</span>
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Email Address" formControlName="email">
                                    <span *ngFor="let error of ownTeamErrors.email" class="error-message">{{ error }}</span>
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="City" formControlName="city">
                                    <span *ngFor="let error of ownTeamErrors.city" class="error-message">{{ error }}</span>
                                </div>
                                <div class="form-group">
                                    <input type="password" class="form-control" placeholder="Password can be anything" formControlName="password">
                                    <span *ngFor="let error of ownTeamErrors.password" class="error-message">{{ error }}</span>
                                </div>
                                <div class="form-btn">
                                    <!-- <a style="cursor: pointer;" class="btn success mr-1" data-toggle="modal" data-target="#success-modal">CLICK TO OWN</a> -->
                                    <button type="submit" style="cursor: pointer;" id="own-submit" class="btn success mr-1" data-toggle="modal">CLICK TO OWN</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="success-modal">
        <div class="modal fade" id="success-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" class="fa fa-times"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <span>SUCCESSFULLY CREATED TEAM</span>
                        <a class="btn m-3" href="my-team.html" role="button">OK</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="loader" *ngIf="loader">
      <div class="loaderbx">
        <i class="fas fa-3x fa-spinner fa-spin" aria-hidden="true"></i>
      </div>
    </div>

<script>

    //
    $('#myModal').on('shown.bs.modal', function () {
        $('#myInput').trigger('focus')
    })

    $('.create').click(function(e){
        e.preventDefault();

        $('#teamModal')
            .modal('hide')
            .on('hidden.bs.modal', function (e) {
                $('#createTeamModal').modal('show');

                $(this).off('hidden.bs.modal'); // Remove the 'on' event binding
            });

    });

    $('.confirmCreate').click(function(e){
        e.preventDefault();

        $('#createTeamModal')
            .modal('hide')
            .on('hidden.bs.modal', function (e) {
                $('#confirmCreateModal').modal('show');

                $(this).off('hidden.bs.modal'); // Remove the 'on' event binding
            });

    });

    $('.team-form').click(function(e){
        e.preventDefault();

        $('#confirmCreateModal')
            .modal('hide')
            .on('hidden.bs.modal', function (e) {
                $('#team-form-modal').modal('show');

                $(this).off('hidden.bs.modal'); // Remove the 'on' event binding
            });

    });

    $('.success').click(function(e){
        e.preventDefault();

        $('#team-form-modal')
            .modal('hide')
            .on('hidden.bs.modal', function (e) {
                $('#success-modal').modal('show');

                $(this).off('hidden.bs.modal'); // Remove the 'on' event binding
            });

    });
</script>
