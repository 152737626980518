<link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
<div class="title-container mt-3">
  <div class="page-t">
    <div>
      <div class="page-title">
        <b>GAME RESULT</b>
      </div>
    </div>
  </div>
</div>


<div class="teamcard m-3 hovercard" *ngIf="venueDetails">

  <div class="alert alert-success alert-dismissible" *ngIf="successMsg">
    <button type="button" class="close" data-dismiss="alert">&times;</button>
    {{successMsg}}
  </div>

  <div class="card-header d-flex justify-content-between">
    <div style="margin: auto 0;" class="d-flex">
      <div>
        <span>Company: </span>
      </div>
      <div class="text-left mr-2 ml-2" *ngIf="venueDetails">
        <span>{{user.profile.company_name}}</span>
      </div>

    </div>
    <div style="margin: auto 0;" class="d-flex bar-title">
      <div>
        <span>Venue: </span>
      </div>
      <div class="text-left mr-2 ml-2" *ngIf="venueDetails">
        <span>{{venueDetails.name}}</span>
      </div>
    </div>

    <div style="margin: auto 0;" class="d-flex bar-title">
      <div>
        <span>STAGE: </span>
      </div>
      <div class="text-left mr-2 ml-2" *ngIf="venueDetails">
        <span>{{venueDetails.stage_name}}</span>
      </div>
    </div>

    <div style="margin: auto 0;" class="presentbtnhide">
      <a style="background-color:white; white-space: normal;font-size: 12px;" class="btn btn-sm mr-1 mb-1" data-id="{{gameId}}" onclick="window.open('/#/host/presentation/'+ this.id ,'popUpWindow','height=500,width=1130,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=yes');" role="button">PRESENT</a>
    </div>
  </div>

  <div class="top" style="display: none;">
    <div class="image">
      <div class="imageround">
        <img src="{{venueDetails.image_path}}" *ngIf="venueDetails.image_path">
        <img src="{{defaultImage}}" *ngIf="!venueDetails.image_path">
      </div>
      <span>VENUE</span>
    </div>
    <div class="teamgame-info">
      <span>{{venueDetails.name}}</span>
      <small>{{venueDetails.address}}</small>
    </div>
    <div class="teamgame-info-2">
      <span>HOST: {{venueDetails.host_name}}</span>
      <small>COMPANY: {{venueDetails.company_name}}</small>
    </div>
  </div>
</div>



<div class="rank-table"  *ngIf="gameTeams && game">
  <div class="tbl1">
    <table class="table" mdbTable [attr.value]="game.max_round">
      <thead>
      <tr>
        <th [mdbTableSort]="gameTeams"  class="rkname" sortBy="rank" scope="col"><div >RK</div></th>
        <th [mdbTableSort]="gameTeams"  class="teamname" sortBy="name" scope="col" ><div >TEAM</div></th>
        <th [mdbTableSort]="gameTeams"  sortBy="total_score" scope="col" class="ttlname"><div >TTL</div></th>
        <th [mdbTableSort]="gameTeams"  class="pname" sortBy="number_of_player"  scope="col" ><div >P</div></th>
      </tr>
      </thead>
      <tbody *ngIf="gameTeams">
      <tr *ngFor="let team of gameTeams" [ngClass]="{'unregistered': !team.has_registered_player}">
        <td scope="row"><div >{{team.rank}}</div></td>
        <td class="teamname" style="text-align: left">
          <a style="cursor: pointer;text-align: left">
            {{team.name}}
            <img src="../../../../assets/img/gold.png" *ngIf="game.end_date && team.rank==1 && team.total_score && team.total_score > 0" class="medal"  />
            <img src="../../../../assets/img/silver.png" *ngIf="game.end_date && team.rank==2 && team.total_score && team.total_score > 0" class="medal" />
            <img src="../../../../assets/img/bronze.png" *ngIf="game.end_date && team.rank==3 && team.total_score && team.total_score > 0" class="medal" />
          </a>
        </td>
        <td><div >{{team.total_score}}</div></td>
        <td *ngIf="team.total_registered_player"><div >{{team.total_registered_player}}</div></td>
        <td *ngIf="!team.total_registered_player">0</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="tbl2">
    <div class=" table-responsive">
      <table class="table" id="hostScoreTable" mdbTable  [attr.value]="game.max_round">
        <thead>
        <tr>
          <th *ngFor="let item of [].constructor(game.max_round); let i = index" class="inputbox">
            <span>{{i+1}}</span>
          </th>
        </tr>
        </thead>
        <tbody *ngIf="gameTeams">
        <tr *ngFor="let team of gameTeams" [ngClass]="{'unregistered': !team.has_registered_player}">
          <td *ngFor="let score of team.scores" class="inputbox">
            <span *ngIf="score.score">{{score.score}}</span><!--Variable is not null display score-->
            <span *ngIf="!score.score">0</span><!--Variable is null display 0-->
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<br>

<div class="footer" id="footer1">

  <button class="button disabledBtn" >REWARDS</button>

  <button class="button disabledBtn" >STATS</button>

  <button class="button" data-toggle="modal" data-target="#share-result-modal" (click)="addOpacity();" >SHARE</button>

  <button class="button disabledBtn" >CHAT</button>

  <a [routerLink]="['/host']" class="button" style="color: #000;">Exit</a>

</div>

<div class="share-result-modal">
  <div class="modal fade" id="share-result-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Share Result</span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="modal-btn mr-3 ml-3 mb-3 text-center">
            <button type="button" style="background-color: #4267B2; color: #fff; margin: 0px 5px;"  class="btn  mt-2" id="fbShare" data-value="{{gameId}}" onClick="share(this.value)">Share&nbsp;&nbsp;<i class="fa fa-facebook-square" style="font-size: 24px;
    vertical-align: sub;"></i></button>
            <button type="button" style=" background-color: #55acee; color: #fff; margin: 0px 5px;" class="btn  mt-2" id="twitterShare" onClick="window.open('https://twitter.com/intent/tweet?url=https://qa.trivtrak.com:50443/%23/host/game-result/2431&text=TrivTrak.com&hashtags=trivtrak');">Share&nbsp;&nbsp;<i class="fa fa-twitter-square" style="font-size: 24px;
    vertical-align: sub;" ></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loader" *ngIf="loader">
  <div class="loaderbx">
    <i class="fas fa-3x fa-spinner fa-spin" aria-hidden="true"></i>
  </div>
</div>

<style>
  table.table td, table.table th {
    padding: 1rem;
    font-size: 16px;
  }
  .modal .disabledBtn {
    height: 42px;
    border-radius: 5px;
    font-size: 20px;
  }



</style>
