import {Component, OnInit, AfterViewInit, Input, Inject} from '@angular/core';
import {FormControl, FormGroup, FormArray, Validators, FormBuilder} from '@angular/forms';
import {Questions, question} from '../../../models/QuestionInterface';
import {Answer, RoundType} from '../../../models/AnswersEnum';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface QuestionsDialogInput{
  round: number
}

@Component({
  selector: 'generate-questions-dialog',
  templateUrl: './generate-questions-dialog.component.html',
  styleUrls: ['./generate-questions-dialog.component.css']
})
export class GenerateQuestionsDialog implements OnInit, AfterViewInit {
  @Input() currentRound: number;
  public gameId: any;
  public venueDetails: any;
  public game: any;
  public loader: boolean;
  public user: any;
  public numQuestionSlider: any = {max: 20,
                  min: 1,
                  step: 1,
                  thumbLabel: true
                };
  public numAnswerSlider: any = {max: 8,
                  min: 2,
                  step: 1,
                  thumbLabel: true
                };
  public answerOptions: Answer[] = [1, 2];
  public roundTypes: RoundType[] = [0, 1, 2, 3];
  public roundType: number;
  public questionsForm: FormGroup;
  public wagerForm: FormGroup;
  public textQuestionsForm: FormGroup;
  public textWagerForm: FormGroup;
  
  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<GenerateQuestionsDialog>,
    @Inject(MAT_DIALOG_DATA) public input: QuestionsDialogInput
     
  ) {

    console.log(input);
    this.currentRound = input.round;
      dialogRef.backdropClick().subscribe(result => {
        dialogRef.disableClose = true; //disable default close operation
        dialogRef.close(false);
      });
    }
    onNoClick(): void {
      this.dialogRef.close(false);
    }

    close(model): void {
      this.dialogRef.close(model);
    }


  ngOnInit() {
    this.createForm();
  }

  ngAfterViewInit() {
  }
//#region Round
  createForm() {
    this.questionsForm = this.fb.group({
      'numAnswers': '2',
      'numQuestions': '1',
      'questions': this.fb.array([this.initMCQs()])
    });

    this.wagerForm = this.fb.group({
      'numAnswers': '2',
      'numQuestions': '1',
      'questions': this.fb.array([this.initFinalMCQs()])
    });

    this.textQuestionsForm = this.fb.group({
      'numQuestions': '1',
      'questions': this.fb.array([this.initTextQs()])
    });

    this.textWagerForm = this.fb.group({
      'numQuestions': '1',
      'questions': this.fb.array([this.initFinalTextQs()])
    });
  }

  initMCQs(){
    return this.fb.group({
      'answer': ['', Validators.required],
      'points': ['',[Validators.required, Validators.min(1)]]
    });
  }

  initFinalMCQs(){
    return this.fb.group({
      'answer': ['', Validators.required],
      'points': 0
    });
  }

  initTextQs(){
    return this.fb.group({
      'points': ['',[Validators.required, Validators.min(1)]]
    });
  }

  initFinalTextQs(){
    return this.fb.group({
      'points': 0
    });
  }

  get qs(): FormArray {
    return this.questionsForm.get('questions') as FormArray;
  }

  get textqs(): FormArray {
    return this.textQuestionsForm.get('questions') as FormArray;
  }

  //#region Questions Modifiers
  updateMCQuestions() {
    if (this.questionsForm.value.numQuestions < this.qs.length){
      this.removeQuestion();
    }else if(this.questionsForm.value.numQuestions > this.qs.length){
      this.addMCQuestion()
    }
    if (this.questionsForm.value.numQuestions != this.qs.length){
      this.updateMCQuestions();
    }
  }

  updateTextQuestions() {
    if (this.textQuestionsForm.value.numQuestions < this.textqs.length){
      this.removeTextQuestion();
    }else if(this.textQuestionsForm.value.numQuestions > this.textqs.length){
      this.addTextQuestion()
    }
    if (this.textQuestionsForm.value.numQuestions != this.textqs.length){
      this.updateTextQuestions();
    }
  }

  addMCQuestion() {
    this.qs.push(this.fb.group({
      'answer': ['', Validators.required],
      'points': ['',[Validators.required, Validators.min(1)]]
      }));
  }

  addTextQuestion() {
    this.textqs.push(this.fb.group({
      'points': ['',[Validators.required, Validators.min(1)]]
    }));
  }

  removeQuestion() {
    this.qs.removeAt(this.qs.length - 1);
  }

  removeTextQuestion() {
    this.textqs.removeAt(this.qs.length - 1);
  }
  //#endregion
  //#region Answers Modifiers
  updateMCAnswers(form: any){
    if (form.value.numAnswers < this.answerOptions.length){
      this.removeAnswer();
    }else if (form.value.numAnswers > this.answerOptions.length){
      this.addMCAnswer();
    }
    if (form.value.numAnswers != this.answerOptions.length){
      this.updateMCAnswers(form);
    }
  }

  addMCAnswer(){
    this.answerOptions.push(this.answerOptions.length+1);
  }

  removeAnswer(){
    this.answerOptions.pop();
  }

  getAns(a: number){
    return Answer[a];
  }

  getRoundType(rt: number){
    return RoundType[rt];
  }

//#endregion

//#endregion
  save(model: Questions){
    model.round = this.currentRound;
    model.roundType = this.roundType;
    this.close(model);
  }
}
