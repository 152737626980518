import { Component, OnInit } from '@angular/core';
import {PlayerUser} from '../../../models/player_user'
import {ActiveGameVenue} from '../../../models/active_game_venue'
import {Router} from '@angular/router';
import {PlayerSharedService} from '../../../services/player-shared.service'
import {OwnTeamError} from '../../../models/own-team-error-model'
import {
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
declare var jQuery:any;

@Component({
  selector: 'app-change-team',
  templateUrl: './change-team.component.html',
  styleUrls: ['./change-team.component.css']
})
export class ChangeTeamComponent implements OnInit {
  player: PlayerUser;
  selectedVenue: ActiveGameVenue;
  message: string;
  errors: any = [];
  token: string;
  player_user_logout_status: boolean;
  teamName: string;
  ownTeamErrors: OwnTeamError = {first_name:[], email:[], city:[], password:[]};
  ownForm: FormGroup;

  constructor(private router: Router, private _sharedService: PlayerSharedService,) {
    this.message = localStorage.getItem('congrats-mesg');
    this.player = JSON.parse(localStorage.getItem('player_user'));
    this.selectedVenue = JSON.parse(localStorage.getItem('venue'));
    this.token = localStorage.getItem('player_token');
    this.player_user_logout_status =  JSON.parse(localStorage.getItem('player_user_logout_status'));
    this.ownTeamForm();
   }

  ngOnInit() {
    jQuery('#changeTeamCongratsModal').modal('toggle');
    this.addOpacity();
  }

  addOpacity() {
    jQuery(document).ready(function() {
      jQuery('.modal.fade').css('opacity', 1);
      jQuery('.modal.fade').addClass('show');  
      let cls = document.getElementsByClassName("modal-backdrop").length;
      if (cls>0) {
        jQuery('div.modal-backdrop').addClass('show');  
        jQuery('div.modal-backdrop').css('opacity', 0.8);  
      }
    });
  }

  onJoinExistingTeam(){
    localStorage.setItem('fromChangeTeam', JSON.stringify(true));
    this.router.navigateByUrl('/search-team');
  }

  checkTeamLimit(){
    this._sharedService.checkTeanGameLimitService(this.selectedVenue.active_game, null).subscribe(data1 => {
      if (data1.canJoin){
        jQuery('#createTeamModal').modal('toggle');
        this.addOpacity();
      } else {
        jQuery("#teamModal").modal('toggle');
        jQuery('#teamLimitModal1').modal('toggle');
        this.addOpacity();
      }
    })
  }

  onCreateTeamClick(){
    this._sharedService.JoinTeamService(this.selectedVenue.active_game, {'team_name': this.teamName, 'team_change_create':true}, this.token)
    .subscribe(data => {
      this.player = data.user;
      this.token = data.token.access;
      localStorage.setItem('player_token', data.token.access);
      localStorage.setItem('refresh_token', data.token.refresh);
      localStorage.setItem('player_user', JSON.stringify(data.user));
      localStorage.setItem('game', JSON.stringify(data.game));
      // jQuery('#teamModal').modal('toggle');
      // jQuery('#confirmCreateModal').modal('toggle');
      if (this.selectedVenue.open_tournament) {
        this.router.navigateByUrl('/join-game-confirm')
      } else {
        this.joinTheTournament();
      }
      // this.addOpacity();
     },
     error => {
       this.errors = error;
     })
  }

  navigate(){
    localStorage.setItem("player_user_logout_status", JSON.stringify(false));
    var message = "Ok, you don't have Bonafide Member Access but you're ready to play!.";
    localStorage.setItem('congrats-mesg', message);
    this.router.navigateByUrl('/join-team-congratulations')
}

ownTeamForm(){
  this.ownForm = new FormGroup({
    first_name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    city: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)])
  })
}

ownTeam(){
  // debugger;
  // let data = JSON.stringify(this.ownForm.value);
  this._sharedService.ownTeamService(this.player.my_team.id, this.ownForm.value, this.token)
    .subscribe(data => {
      this.player = data.user;
      localStorage.setItem('player_user', JSON.stringify(data.user));
      if (this.selectedVenue.open_tournament) {
        this.router.navigateByUrl('/join-game-confirm')
      } else {
        this.joinTheTournament();
      }
     },
     error => {
       this.ownTeamErrors = error;
     })
}

joinTheTournament(){
  localStorage.setItem('player_user_logout_status', JSON.stringify(false));
  let game_id = this.selectedVenue.active_game;
  if (game_id >0 ){
      this._sharedService.CheckInUser(game_id, {'team_id': this.player.my_team.id}, this.token)
        .subscribe(data => {
          this.player = data.user;
          this.token = data.token.access;
          localStorage.setItem('player_token', data.token.access);
          localStorage.setItem('refresh_token', data.token.refresh);
          localStorage.setItem('player_user', JSON.stringify(data.user));
          localStorage.setItem('game', JSON.stringify(data.game));
          if (this.player.id == this.player.my_team.owner && !this.player.profile.is_approved){
            var message = "You're almost there! Check your email to confirm your role as TEAM CAPTAIN of " + this.player.my_team.name;
          }
          else if (this.player.id == this.player.my_team.owner && this.player.profile.is_approved){
            var message = 'Congrats, you joined the game with team "' + this.player.my_team.name + '". You are the OFFICIAL OWNER of it. Good Luck Tonight!';
          } 
          else {
            var message = 'Congrats! You are now a Bonafide member of "' + this.player.my_team.name + '". Look for an email update about the benefits! Good Luck Tonight!';
          }
          localStorage.setItem('congrats-mesg', message);
          this.router.navigateByUrl('/join-team-congratulations');
        })
  }
}

}
