import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {ResultsService} from '../../../services/results.service'
import {ActiveGameVenues} from '../../../models/active_game_venues'
import {ActiveGameVenue} from '../../../models/active_game_venue'
import {GameResult} from '../../../models/game_result'
import {PlayerSharedService} from '../../../services/player-shared.service'
declare var jQuery: any;

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.css']
})
export class ResultsComponent implements OnInit {
  venues: Array<ActiveGameVenue>;
  gameData: Array<GameResult>;
  token: string;
  loader: boolean;

  constructor(private _resultService: ResultsService, private _sharedService: PlayerSharedService) { 
    this.token = localStorage.getItem('player_token');
  }

  ngOnInit() {
    jQuery('body').removeClass('modal-open');
    jQuery('.modal-backdrop').remove();
    this.loader = true;
    this._resultService.getEndGameTwoDaysResult().subscribe(result => {
      this.gameData = result;
      this.loader = false;
    });
  }

  getVenues(token): Observable<Array<ActiveGameVenue>>{
    return this._resultService.getActiveGameVenues(token);
}

getAllVenues(token): Observable<Array<ActiveGameVenue>>{
  return this._resultService.getAllVenuesService(token);
}

  getGameResult(game_id): Observable<GameResult>{
    return this._resultService.getGameResult(game_id);
  }

  get sortData() {
    return this.gameData.sort(this.compare);
  }

  compare(a, b) { 
    let comparison = 0; 
    if (a.modified_date > b.modified_date) {
      comparison = -1;
    } 
    if (a.modified_date < b.modified_date) {
      comparison = 1;
    }
    return comparison
  }

}
