<div class="title-container mt-3">
  <div class="page-t">
    <div>
      <div class="page-title">
        <b><span>Round</span> : <span>{{ currentRound  }}</span></b>
      </div>
    </div>
  </div>
</div>

<div class="teamcard m-3  hovercard" *ngIf="errorMsg2">
  <div class="alert alert-danger alert-dismissible">
    <button type="button" class="close" data-dismiss="alert">&times;</button>
    {{errorMsg2}}
  </div>
</div>

<div class="teamcard m-3 hovercard" *ngIf="!errorMsg2" >

  <div class="alert alert-success alert-dismissible" *ngIf="successMsg">
    <button type="button" class="close" data-dismiss="alert">&times;</button>
    {{successMsg}}
  </div>

  <div class="alert alert-danger alert-dismissible" *ngIf="errorMsg">
    <button type="button" class="close" data-dismiss="alert">&times;</button>
    {{errorMsg}}
  </div>

  <div class="card-header d-flex justify-content-between">
    <div style="margin: auto 0;" class="d-flex">
      <div>
        <span>Company: </span>
      </div>
      <div class="text-left mr-2 ml-2" *ngIf="venueDetails">
        <span>{{venueDetails.company}}</span>
      </div>

    </div>
    <div style="margin: auto 0;" class="d-flex bar-title">
      <div>
        <span>Venue: </span>
      </div>
      <div class="text-left mr-2 ml-2" *ngIf="venueDetails">
        <span>{{venueDetails.name}}</span>
      </div>
    </div>

    <div style="margin: auto 0;" class="d-flex bar-title">
      <div>
        <span>STAGE: </span>
      </div>
      <div class="text-left mr-2 ml-2" *ngIf="venueDetails">
        <span>{{venueDetails.stage_name}}</span>
      </div>
    </div>

    <div style="margin: auto 0;" class="presentbtnhide">
      <a style="background-color:white; white-space: normal;font-size: 12px;" class="btn btn-sm mr-1 mb-1" data-id="{{gameId}}" onclick="window.open('/#/host/presentation/'+ this.id ,'popUpWindow','height=500,width=1130,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=yes');" role="button">PRESENT</a>
    </div>
  </div>
  <!--<div class="top" *ngIf="venueDetails" style="display: none;">
    <div class="image">
      <div class="imageround">
        <img src="{{venueDetails.image}}" *ngIf="venueDetails.image">
        <img src="{{defaultImage}}" *ngIf="!venueDetails.image">
      </div>
      <span>VENUE</span>
    </div>
    <div class="teamgame-info">
      <span>{{venueDetails.name}}</span>
      <small>{{venueDetails.address}}</small>
    </div>
    <div class="teamgame-info-2">
      <span>HOST: {{venueDetails.host_name}}</span>
      <small>COMPANY: {{venueDetails.company}}</small>
    </div>
    <p style="background-color: #0f6674;color: #FFF">
    </p>
  </div>-->
</div>

<div class="rank-table scroll_table m-3" *ngIf="gameTeams && game">
  <div class="table-responsive" id="mehul">
    <table class="table" mdbTable id="score-table" [attr.value]="game.max_round">
      <thead>
      <tr>
        <th [mdbTableSort]="gameTeams" class=""  sortBy="rank" scope="col">RK</th>
        <th [mdbTableSort]="gameTeams" class="" sortBy="name" width="550" scope="col" >TEAM</th>
        <th [mdbTableSort]="gameTeams"  sortBy="total_score" scope="col" class="ttl ">TTL</th>
        <th [mdbTableSort]="gameTeams" class="" sortBy="number_of_player" scope="col" >P</th>
          <th *ngFor="let item of [].constructor(game.max_round + 1); let i = index" class="inputbox">
          <!--<th *ngFor="let item of rounds; let i = index">-->
          {{i+1}}
        </th>
        <!--<th *ngFor="let item of rounds" >
          {{item}}
        </th>
        <th class=" lastColumn">
          {{this.rounds.length + 1}}
        </th>-->
      </tr>
      </thead>
      <tbody *ngIf="gameTeams">
      <tr *ngFor="let team of gameTeams" [ngClass]="{'unregistered': !team.has_registered_player}">
        <td scope="row" class="">{{team.rank}}</td>
        <td class=""><a style="cursor: pointer;" data-target="#remove-team-modal"data-toggle="modal" (click)="openRemoveTeamModal(team.id, team.name)">{{team.name}}</a></td>
        <td class="ttl ">{{team.total_score}}</td>
        <td class=" " *ngIf="team.checked_in_players">{{team.checked_in_players}}</td>
        <td class=" " *ngIf="!team.checked_in_players">0</td>

        <td *ngFor="let score of team.scores" class="inputbox">
          <input type="number" min="0" step="any" class="score-input" (change)="saveScore(team.id, score.round_number, $event.target.value)" [value]="score.score"/>
        </td>
        <td style="width: 70px;" max="10" maxlength="2" *ngIf="!game.end_date" class="lastColumn">
          <input type="number" min="0" max="10" maxlength="2" step="any" class="score-input" (change)="pushScore(team.id, $event.target.value)" tabindex="1" />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

</div>
<br>
<br>

<div class="footer" id="footer2" *ngIf="!errorMsg2 && game">

  <button class="button" data-toggle="modal" data-target="#add-team-modal" [disabled]="game.end_date" (click)="addOpacity();">Add Team</button>

  <button class="button disabledBtn" [disabled]>Chat</button>

  <button class="button disabledBtn" [disabled]>Stats</button>

  <button class="button" data-toggle="modal" data-target="#end-round-modal" [disabled]="game.end_date" (click)="addOpacity();">Next</button>

  <!-- <button class="button" [routerLink]="['/host', game.id, 'end-round', game.max_round+1]" [disabled]="game.end_date">Next</button> -->

  <button class="button" data-toggle="modal" data-target="#end-game-modal" [disabled]="game.end_date" (click)="addOpacity();">End Game</button>
  <!-- <button class="button" [routerLink]="['/host', 'end-game', game.id]" [disabled]="game.end_date">End Game</button> -->

</div>

<div class="add-team-modal">
  <div class="modal fade" id="add-team-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Add Team</span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="modal-btn mr-3 ml-3 mb-3">
            <button type="button" data-target="#create-team-modal" data-dismiss="modal" data-toggle="modal" class="btn btn-large mt-2" (click)="addOpacity();">New Team</button>
            <button type="button" data-target="#search-team-modal" data-dismiss="modal" data-toggle="modal" class="btn btn-large mt-2" (click)="addOpacity();">Existing Team</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="create-team-modal">
  <div class="modal fade" id="create-team-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Add Team</span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger alert-dismissible" *ngIf="errorMsg">
            {{errorMsg}}
          </div>
          <form [formGroup]="teamForm" (ngSubmit)="joinTeam(null)" id="createTeam">
            <input type="text" class="form-control" placeholder="Team Name *" formControlName="team_name"/>
            <div class="modal-btn ">
              <button type="submit" class="button btn btn-large mt-2" [disabled]="!teamForm.valid">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="search-team-modal" *ngIf="venueTeams">
  <div class="modal fade" id="search-team-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Add Team</span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="venue-tbl m-3"  >
            <select class="form-control" (change)="getVenueTeams(team.value);" #team>
              <option value="">Select Venue</option>
              <option value="{{venue.id}}" *ngFor="let venue of venues" >{{venue.name}}</option>
            </select>
            <div class="searchbox">
              <input type="text" [(ngModel)]="term" placeholder="Search Team *">
              <button type="submit"><i class="fa fa-search"></i></button>
            </div>
            <div style="height: 400px; overflow: auto;">
              <table class="table-hover">
                <thead>
                <tr>
                  <th>Team Name</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of venueTeams | filter:term ">
                  <td><a style="cursor: pointer;" (click)="joinTeam(item.id)">{{item.name}}</a></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="remove-team-modal">
  <div class="modal fade" id="remove-team-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title" *ngIf="removeTeamId">Remove Team  "{{removeTeamId.name}}"?</span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="modal-btn mr-3 ml-3 mb-3">
            <button type="button" data-dismiss="modal" class="btn btn-large mt-2" (click)="removeTeam(removeTeamId.id)" >Yes</button>
            <button type="button" data-dismiss="modal" class="btn btn-large mt-2">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="end-round-modal">
  <div class="modal fade" id="end-round-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Is Round Complete?</span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="modal-btn mr-3 ml-3 mb-3">
            <button type="button" data-dismiss="modal" (click)="saveGameData()" class="btn btn-large mt-2">Yes</button>
            <button type="button" data-dismiss="modal" class="btn btn-large mt-2">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="end-game-modal">
  <div class="modal fade" id="end-game-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Do you want to end game?</span>
          <button type="button"   class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="modal-btn mr-3 ml-3 mb-3">
            <button type="button" data-dismiss="modal" (click)="endGame()" class="btn btn-large mt-2">Yes</button>
            <button type="button" data-dismiss="modal" class="btn btn-large mt-2">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loader" *ngIf="loader">
  <div class="loaderbx">
    <i class="fas fa-3x fa-spinner fa-spin" aria-hidden="true"></i>
  </div>
</div>
