<div class="title-container mt-3">
  <div class="page-t">

    <div class="row">
      <div class="col-md-4 col-3">
        <div class="top-logo__ logo_align" *ngIf="main_sponsor_url">
          <a href="/#{{this.router.url}}" (click)="popItUpMain()"><img [src]="main_sponsor_image_path != null ? main_sponsor_image_path : defaultLogo" alt="TRIVTRAK LOGO" class="triv_logo lg-right" ></a>
        </div>
        <div class="top-logo__ logo_align" *ngIf="!main_sponsor_url">
          <img [src]="main_sponsor_image_path != null ? main_sponsor_image_path : defaultLogo" alt="TRIVTRAK LOGO" class="triv_logo lg-right" >
        </div>
      </div>
      <div class="col-md-4 col-6">
        <div class="page-title cus-width-title">
            <b>TOP 50 <br/> {{tournamentName}} LEADERBOARD</b>
        </div>
      </div>

      <div class="col-md-4 col-3">
          <div class="top-logo__ logo_align" *ngIf="second_sponsor_url">
            <a href="/#{{this.router.url}}" (click)="popItUpSecondary()"><img [src]="second_sponsor_image_path != null ? second_sponsor_image_path : defaultLogo" alt="TRIVTRAK LOGO" class="triv_logo lg-left"></a>
          </div>
          <div class="top-logo__ logo_align" *ngIf="!second_sponsor_url">
            <img [src]="second_sponsor_image_path != null ? second_sponsor_image_path : defaultLogo" alt="TRIVTRAK LOGO" class="triv_logo lg-left">
          </div>
        </div>
    </div>
 </div>
</div>
<!-- searchbox (_sections.scss) -->
<div class="searchbox mt-3 ml-3 mr-3">
 <div class="row">
        <div class="col-xl-10  col-lg-10 col-md-9 col-sm-9 col-7 top-align">
                <form action="#">
                <input type="text" #searchTeamInput placeholder="SEARCH TEAM" name="search">
          <button type="submit"><i class="fa fa-search"></i></button>
          
      </form>  
        </div>
        
          <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-5 sidedropdown">
              <form  class="form-inline">
                <div class="row">
                        <!-- <div class="col-md-5 col-4 col-sm-5 col-lg-5 col-xl-4">
                             <div class="form-group">
                            <label>Time: </label>
                          <select class="form-control" (change)="filterTime($event.target.value)">
                            <option value="all">--All--</option>
                            <option *ngFor="let timeFilter of timeFilterValues" value={{timeFilter[1]}}>
                                {{timeFilter[0]}}
                            </option>
                          </select>
                          </div>
                        </div>
                    
                       
                        <div class="col-md-5 col-5 col-sm-5 col-lg-5 col-xl-5">
                                <div class="form-group">
                            <label  >State: </label>
                            <select class="form-control" (change)="filterState($event.target.value)">
                              <option value="">--All--</option>
                              <option *ngFor="let state of states" value={{state[0]}}>
                                  {{state[1]}}
                              </option>
                            </select>
                          </div>
                        </div> -->
                        
                        <div class="col-md-12 col-12 col-sm-12 col-lg-10 col-xl-10 cus_pad" style="top: 0 !important;
                        right: 0 !important;
                        position: absolute;">
                          <div class="form-group" style="float: right;">
                            <label _ngcontent-ron-c4="" class="txt_venue">Venue: </label>
                            <!-- <ui-switch class="pad-top" name="toggle" (change)="onToggleChange($event)" labelOn="H" labelOff="T" [(ngModel)]="toggleSwitch" style="margin-top: 4px;"></ui-switch> -->
                            <label class="switch"><input type="checkbox" id="togBtn" (click)="onToggleChange(null)"><div class="slider round"><span class="off">T</span></div></label>
                          </div>
                        </div>
                    </div>
                </form>
          </div>
 </div>
     
 
</div>
<!-- end of searchbox -->
<!-- <div class="scoreboard-tbl m-3" *ngIf="isSearching">
  <div class="table-responsive table-fontsize">
      <h4>Searching ... </h4>
  </div>
</div> -->

<!-- scoreboard table (_tables.scss) -->
<div class="scoreboard-tbl m-3">
  <div class="table-responsive table-fontsize">
      <table class="table-hover" mdbTable>
          <thead>
          <tr>
              <th [mdbTableSort]="leaderboard" sortBy="rank" style="background-color: rgb(33, 32, 30); color:  #ffbf00;"><div class="text-center">RK</div></th>
              <th [mdbTableSort]="leaderboard" sortBy="name" style="background-color: rgb(33, 32, 30); color:  #ffbf00;">TEAM</th>
              <th class="lapColumn" style="background-color: rgb(33, 32, 30); color:  #ffbf00;">Host</th>
              <th style="background-color: rgb(33, 32, 30); color:  #ffbf00;"><div class="text-center">VENUE</div></th>
              <th class="lapColumn" style="background-color: rgb(33, 32, 30); color:  #ffbf00;"><div class="text-center">P</div></th>
              <th [mdbTableSort]="leaderboard" sortBy="equalizer" style="background-color: rgb(33, 32, 30); color:  #ffbf00;"><div class="text-center">TWS</div></th>
              <th style="background-color: rgb(33, 32, 30);"><div class="text-center"></div></th>
          </tr>
          </thead>
          <tbody *ngIf="leaderboard != null">
          <tr *ngFor="let leadeBoard of leaderboard" style="background-color: rgb(33, 32, 30); color: white;" [ngClass]="{'unregistered': !leadeBoard.registered_players}">
              <td scope="row"><div class="text-center">{{leadeBoard.rank}}</div></td>
              <td>{{leadeBoard.name}}</td>
              <td class="lapColumn"><div class="text-center">{{leadeBoard.home_venue.stagename}}</div></td>
              <td>
                  <div class="text-center">
              <!-- <td class="lapColumn"><div class="text-center">{{leadeBoard.venue.hostname}}</div></td> -->
                      <img class="lapColumn" [src]="leadeBoard.home_venue.image_path != null ? leadeBoard.home_venue.image_path : defaultImage" alt="">
                      <span>{{leadeBoard.home_venue.name}}</span>
                      </div>
                  </td>
              <td class="lapColumn"><div class="text-center">{{leadeBoard.num_of_players}}</div></td>
              <td><div class="text-center">{{leadeBoard.equalizer_2019}}</div></td>
              <td><div class="arrow fa fa-angle-right" [routerLink]="['/tournament-team-prof', leadeBoard.id, leadeBoard.venue.id,leadeBoard.rank]"></div></td>
          </tr>
          </tbody>
      </table>
      </div>
  </div>

<div class="loader" *ngIf="loader">
<div class="loaderbx">
  <i class="fas fa-3x fa-spinner fa-spin" aria-hidden="true"></i>
</div>
</div>
