import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {IndexComponent} from './pages/player/index/index.component';
import {HostLoginComponent} from './pages/host/host-login/host-login.component';
import {ProfileComponent} from './pages/host/profile/profile.component';
import {ContactComponent} from './components/contact/contact.component';
import {BlankscreenComponent} from './components/blankscreen/blankscreen.component';
import {TournamentsComponent} from './pages/player/tournaments/tournaments.component';
import {AuthGuardService} from './guards/auth-guard.service';
import {TournamentLeaderboardComponent} from './pages/player/tournament-leaderboard/tournament-leaderboard.component';
import {ResultsComponent} from './pages/player/results/results.component';
import {ScoreCardComponent} from './pages/player/score-card/score-card.component';
import {AnswerSheetComponent} from './pages/player/answer-sheet/answer-sheet.component'
import {ChangePassowrdComponent} from './pages/host/change-passowrd/change-passowrd.component';
import {LocationsComponent} from './pages/player/locations/locations.component';
import {SearchTeamComponent} from './pages/player/search-team/search-team.component';
import {JoinTeamCongratsComponent} from './pages/player/join-team-congrats/join-team-congrats.component';
import {JoinGameConfirmComponent} from './pages/player/join-game-confirm/join-game-confirm.component';
import {LaunchGameComponent} from './pages/host/launch-game/launch-game.component';
import {ComingSoonComponent} from './pages/host/coming-soon/coming-soon.component';
import {StartRoundComponent} from './pages/host/start-round/start-round.component';
import {GameResultComponent} from './pages/host/game-result/game-result.component';
import {ConfirmUserComponent} from './components/confirm-user/confirm-user.component';
import {ChangeTeamComponent} from './pages/player/change-team/change-team.component';
import {HostTournamentsComponent} from './pages/host/host-tournaments/host-tournaments.component';
import {HostTournamentsLeaderboardComponent} from './pages/host/host-tournaments-leaderboard/host-tournaments-leaderboard.component';
import {PresentationLeaderboardComponent} from './pages/host/presentation-leaderboard/presentation-leaderboard.component';
import {HealthCheckComponent} from './components/health-check/health-check.component';
import {TeamProfileComponent} from './pages/player/team-profile/team-profile.component';
import {ViewTeamProfileComponent} from './pages/host/view-team-profile/view-team-profile.component';
import {ResetPasswordComponent} from './pages/host/reset-password/reset-password.component';
import {InvitePlayerComponent} from './pages/player/invite-player/invite-player.component';
import {FaqComponent} from './components/faq/faq.component';
import {TournamentTeamProfileComponent} from './pages/player/tournament-team-profile/tournament-team-profile.component';
import {StartGameComponent} from './pages/host/start-game/start-game.component';
import {EndRoundHostComponent} from './pages/host/end-round-host/end-round-host.component';
import {EndGameHostComponent} from './pages/host/end-game-host/end-game-host.component';
import {DefaultTournamentLeaderboardComponent} from './pages/player/default-tournament-leaderboard/default-tournament-leaderboard.component';
import {HostLeaderboardComponent} from './pages/host/host-leaderboard/host-leaderboard.component';
import {HostLeaderboardAllComponent} from './pages/host/host-leaderboard-all/host-leaderboard-all.component';
import {DefaultTournamentLeaderboardHomeComponent} from './pages/player/default-tournament-leaderboard-home/default-tournament-leaderboard-home.component';
import {PrivacyPolicyComponent} from './components/privacy-policy/privacy-policy.component';
import {TosComponent} from './components/tos/tos.component';
import {PastTournamentLeaderboardComponent} from './pages/player/past-tournament-leaderboard/past-tournament-leaderboard.component';
import {PastTournamentLeaderboardHomeComponent} from './pages/player/past-tournament-leaderboard-home/past-tournament-leaderboard-home.component';
import {ReportsComponent} from './pages/host/reports/reports.component';

const routes: Routes = [
  {path: '', component: IndexComponent},
  {path: 'host', component: HostLoginComponent},
  {path: 'host/profile', component: ProfileComponent, canActivate: [AuthGuardService]},
  {path: 'host/reports', component: ReportsComponent, canActivate: [AuthGuardService]},
  //{path: 'host/reports/:reportName', component: ReportsComponent, canActivate: [AuthGuardService]},
  {path: 'host/launch-game/:id', component: LaunchGameComponent, canActivate: [AuthGuardService]},
  {path: 'host/start-round/:id', component: StartRoundComponent, canActivate: [AuthGuardService]},
  {path: 'host/:id/end-round/:roundID', component: EndRoundHostComponent, canActivate: [AuthGuardService]},
  {path: 'host/end-game/:gameID', component: EndGameHostComponent, canActivate: [AuthGuardService]},
  {path: 'host/game-result/:id', component: GameResultComponent},
  {path: 'host/presentation/:id', component: PresentationLeaderboardComponent},
  {path: 'host/tournaments', component: HostTournamentsComponent, canActivate: [AuthGuardService]},
  {path: 'host/tournament-leaderboard', component: HostTournamentsLeaderboardComponent},
  {path: 'host/tournament-leaderboard/:id/:name', component: HostTournamentsLeaderboardComponent, canActivate:[AuthGuardService]},
  {path: 'host/change-password', component: ChangePassowrdComponent, canActivate: [AuthGuardService]},
  {path: 'host/reset-password', component: ResetPasswordComponent},
  {path: 'host/team-profile/:id/:venue/:rank', component: ViewTeamProfileComponent, canActivate: [AuthGuardService]},
  {path: 'host/host-leaderboard', component: HostLeaderboardComponent},
  {path: 'host/host-leaderboard-all', component: HostLeaderboardAllComponent},
  {path: 'coming-soon', component: ComingSoonComponent},
  {path: 'contacts', component: ContactComponent},
  {path: 'blankscreen', component: BlankscreenComponent},
  {path: 'tournaments', component: TournamentsComponent},
  {path: 'tournament-leaderboard/:id/:name', component: TournamentLeaderboardComponent},
  {path: 'tournament-leaderboard/:id', component: DefaultTournamentLeaderboardComponent},
  {path: 'tournament-leaderboard-home/:id', component: DefaultTournamentLeaderboardHomeComponent},
  {path: 'results', component: ResultsComponent},
  {path: 'score-card/:id', component: ScoreCardComponent},
  {path: 'answer-sheet/:gameId/:roundNumber/:teamId', component: AnswerSheetComponent},
  {path: 'locations', component: LocationsComponent},
  {path: 'search-team', component: SearchTeamComponent},
  {path: 'join-team-congratulations', component: JoinTeamCongratsComponent},
  {path: 'join-game-confirm', component: JoinGameConfirmComponent},
  {path: 'user-confirm/:token/:profile_type', component: ConfirmUserComponent},
  {path: 'change-team', component: ChangeTeamComponent},
  {path: 'health-check', component: HealthCheckComponent},
  {path: 'team-profile', component: TeamProfileComponent},
  {path: 'invite-player/:teamID/:gameID', component: InvitePlayerComponent},
  {path: 'faq', component: FaqComponent},
  {path: 'tournament-team-prof/:teamID/:venueID/:rank', component: TournamentTeamProfileComponent},
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'tos', component: TosComponent},
  {path: 'past-leaderboard/:id', component: PastTournamentLeaderboardComponent},
  {path: 'past-leaderboard-home/:id', component: PastTournamentLeaderboardHomeComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload'})],
  //imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
