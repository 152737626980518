import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PlayerUser} from '../../../models/player_user'
import {PlayerSharedService} from '../../../services/player-shared.service'
import {Router} from '@angular/router';
import {
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import {OwnTeamError} from '../../../models/own-team-error-model'
declare var jQuery: any;

@Component({
  selector: 'app-invite-player',
  templateUrl: './invite-player.component.html',
  styleUrls: ['./invite-player.component.css']
})
export class InvitePlayerComponent implements OnInit {
  game_id: any
  team_id: any
  ownForm: FormGroup
  ownTeamErrors: OwnTeamError = {first_name:[], email:[], city:[], password:[]};
  token: string;
  player: PlayerUser;
  selectedVenue: any = {};
  public loader: boolean = false;

  constructor(private router: Router, private _sharedService: PlayerSharedService, private route: ActivatedRoute) {
    this.ownTeamForm();
    this.token = localStorage.getItem('player_token')
    this.player = JSON.parse(localStorage.getItem('player_user'));
    this.selectedVenue = JSON.parse(localStorage.getItem('venue'));
   }

  ngOnInit() {
    jQuery('body').removeClass('model-open');
    jQuery('.modal-backdrop').remove();
    this.route.params.subscribe(params => {
        this.game_id = params['gameID']
        this.team_id = params['teamID']
        if (this.game_id != 'noActiveGame'){
            this._sharedService.JoinTeamService(this.game_id, {'team_id': this.team_id}, this.token)
                .subscribe(data => {
                  this.player = data.user;
                  this.token = data.token.access;
                  localStorage.setItem('player_user', JSON.stringify(data.user));
                  localStorage.setItem('player_token', data.token.access)
                  localStorage.setItem('game', JSON.stringify(data.game));
                  localStorage.setItem('venue', JSON.stringify(data.game.venue));
                  this.selectedVenue = data.game.venue;
                  jQuery('#team-form-modal').modal('toggle');
                  this.addOpacity();
                })
        } else {
          this._sharedService.JoinTeamWoGameService(this.team_id, this.token)
            .subscribe(data => {
              this.loader = false;
              this.player = data.user;
              this.token = data.token.access;
              localStorage.setItem('player_user', JSON.stringify(data.user));
              localStorage.setItem('player_token', data.token.access);
              localStorage.setItem('refresh_token', data.token.refresh);
              // jQuery('#teamModal').modal('toggle');
              // this.addOpacity();
              jQuery('#team-form-modal').modal('toggle');
              this.addOpacity();
            })
        }
    })
  }

  ownTeamForm(){
    this.ownForm = new FormGroup({
      first_name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      city: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)])
    })
  }

  navigate(){
    this.router.navigate(['/score-card', this.game_id], {replaceUrl:true});
  }

  ownTeam(){
    // debugger;
    // let data = JSON.stringify(this.ownForm.value);
    this.ownForm.value['invite'] = 1;
    this.loader = true;
    if (this.game_id != 'noActiveGame'){
      this._sharedService.ownTeamService(this.player.my_team.id, this.ownForm.value, this.token)
      .subscribe(data => {
          this._sharedService.CheckInUser(this.game_id, {'team_id': this.player.my_team.id}, this.token)
              .subscribe(data => {
                this.player = data.user;
                this.token = data.token.access;
                localStorage.setItem('player_token', data.token.access);
                localStorage.setItem('refresh_token', data.token.refresh);
                localStorage.setItem('player_user', JSON.stringify(data.user));
                localStorage.setItem('game', JSON.stringify(data.game));
                var message = 'Congrats! You are now a Bonafide member of  “' + this.player.my_team.name + '". Good luck tonight!';
                localStorage.setItem('congrats-mesg', message);
                this.loader = false;
                this.router.navigateByUrl('/join-team-congratulations');
              })
       },
       error => {
        this.ownTeamErrors = error;
       })
    } else {
        this._sharedService.ownTeamService(this.player.my_team.id, this.ownForm.value, this.token)
                  .subscribe(data => {
                    this.loader = false;
                    this.player = data.user;
                    localStorage.setItem('player_user', JSON.stringify(data.user));
                    jQuery('#team-form-modal').modal('toggle');
                    this.router.navigate(['/'], {replaceUrl:true});
                  },
                  error => {
                    this.loader = false;
                    this.ownTeamErrors = error;
                  })
      }
    
  }

  addOpacity() {
    jQuery(document).ready(function() {
      jQuery('.modal.fade').css('opacity', 1);
      jQuery('.modal.fade').addClass('show');  
      let cls = document.getElementsByClassName("modal-backdrop").length;
      if (cls>0) {
        jQuery('div.modal-backdrop').addClass('show');  
        jQuery('div.modal-backdrop').css('opacity', 0.8);  
      }
    });
  }

}
