<div class="title-container mt-3">
  <div class="page-t">
    <div>
      <div class="page-title" style="margin: auto;">
        <b>REPORTS</b>
      </div>
    </div>
  </div>
</div>

<div fxLayoutAlign="space-around center">

  <mat-card fxFlex="66%">
    <mat-card-header fxLayout="row">
      <mat-label fxFlex="20%">Select Report</mat-label>
      <mat-select fxFlex="40%" [(ngModel)]="selectedReport" (selectionChange)="updateReport()">
        <mat-option *ngFor="let report of reportList" [value]="report" >
          {{report}}
        </mat-option>
      </mat-select>
    </mat-card-header>
    <hr>
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)">
    </mat-form-field>    
    <mat-card-content fxLayout="row">
        <mat-table fxFlex="100%" [dataSource]="reportData" matSort>
          <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{column}} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element[column]}} </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button (click)="exportReport()">Export</button>
    </mat-card-actions>
  </mat-card>
</div>
