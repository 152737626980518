<!-- header -->
<div class="main-header">
  <nav class="navbar navbar-custom navbar-fixed-top" role="navigation">
    <div class="left-icon col-4 d-flex justify-content-start">
      <a routerLink="/"><span class="fa fa-home"></span></a>
      <a onclick="history.back()"><span class="fa fa-reply"></span></a>
      <!-- <a routerLink="/"><span class="fa fa-question-circle" style="color: red"></span></a> -->
      <!-- <span class="fa-stack fa-1x">
        <i class="fa fa-circle fa-stack-1x"></i>
        <i class="fa fa-question fa-stack-1x" style="color: red"></i>
       </span> -->
    </div>
    <div class="col-4 d-flex justify-content-center">
      <img *ngIf="player" [src]="player.profile.company_image != null ? player.profile.company_image : defaultLogo" alt="TRIVTRAK LOGO">
      <img *ngIf="localstorage_obj.getItem('TrivTrak_USER')" [src]="host.profile.company_image != null ? host.profile.company_image : defaultLogo" alt="TRIVTRAK LOGO">
      <img *ngIf="!localstorage_obj.getItem('TrivTrak_USER') && !localstorage_obj.getItem('player_user')" src="assets/img/logo.png" alt="TRIVTRAK LOGO">
    </div>
    <div class="col-4 d-flex justify-content-end">
        <a href="javascript:void(0)" *ngIf="!localstorage_obj.getItem('player_user') && !(router.url.search('host')==1)" data-toggle="modal" data-target="#team-login-modal" (click)="addOpacity();" style="margin-right: 15px"><span>PLAYER LOGIN</span></a>
        <a routerLink="/faq" *ngIf="isUpdated"><span class="fa fa-question-circle" style="color: red; margin-right: 12px"></span></a>
        <a routerLink="/faq" *ngIf="!isUpdated"><span class="fa fa-question-circle" style="margin-right: 12px"></span></a>
      <a onclick="openNav()"><span class="fa fa-align-justify"></span></a>
    </div>
  </nav>
</div>
<div id="myNav" class="overlay">
  <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
  <div class="overlay-content">
    <a href="javascript:void(0)" id="showModel">Click here for 1 min intro</a>
    <a routerLink="/contacts" onclick="closeNav()">Contact</a>
    <a routerLink="/faq" onclick="closeNav()">FAQ</a>
    <a routerLink="/host/profile" *ngIf="cookieService.check('TrivTrak_TOKEN')" onclick="closeNav()">Host Profile</a>
    <a routerLink="/team-profile" *ngIf="localstorage_obj.getItem('player_user') && localstorage_obj.getItem('venue')" onclick="closeNav()">Player Profile</a>
    <a routerLink="/privacy-policy" onclick="closeNav()">Privacy Policy</a>
    <a routerLink="/tos" onclick="closeNav()">Terms of Service</a>
    <a href="javascript:void(0)" *ngIf="cookieService.check('TrivTrak_TOKEN') || localstorage_obj.getItem('player_token')" (click)="logout(router.url)" onclick="closeNav()">Log out</a>
  </div>
</div>

<div class="team-form-modal">
  <div class="modal fade" id="team-login-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetLogin()">
                      <span aria-hidden="true" class="fa fa-times"></span>
                      </button>
              </div>
              <div class="modal-container">
                  <div class="modal-body">
                      <span>FILL IN THE FOLLOWING DETAILS TO LOG IN</span>
                  </div>
                  <div class="team-form mb-4">
                      <form (ngSubmit)="login()" [formGroup]="loginForm">
                          <div class="form-group">
                              <input type="email" class="form-control" placeholder="Email Address" formControlName="email">
                              <span *ngFor="let error of loginErrors.email" class="error-message">{{ error }}</span>
                          </div>
                          <div class="form-group">
                              <input type="password" class="form-control" placeholder="Password can be anything" formControlName="password">
                              <span *ngFor="let error of loginErrors.password" class="error-message">{{ error }}</span>
                          </div>

                            <a href="javascript:void(0)" style="color: #212529; text-decoration: underline;" data-toggle="modal" data-target="#forgot-password-modal" (click)="addOpacity();">Forgot Password?</a>

                          <div class="form-btn">

                              <button type="submit" style="cursor: pointer;" id="login-submit" class="btn success mr-1" data-toggle="modal">CLICK TO LOG IN</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<!--popvideo for  code-->
  <div class="modal fade" id="myModal1" role="dialog">
    <div id="" class="modal-dialog modal-dialog-centered modal-sm modal-lg">
      <div  class="modal-content" >
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button></div>
        <div class="modal-body"  id="myvideo2">
                <div  class="embed-responsive embed-responsive-16by9">
<!--                    <iframe id="cartoonVideo" controls="1" class="embed-responsive-item" src="../../../assets/video/ds.mp4" autohide=1 allowfullscreen="true" width="640"  height="360" frameborder="0"></iframe>-->
                   <video  id="cartoonVideo1"   controls="controls" class="embed-responsive-item">
                      <source   src="../../../assets/video/ds.mp4" type="video/mp4">
                </video>
                 </div>
              <div class="modal-btn mb-3" style="text-align: center;margin-top: 20px;">
              <button type="button" id="myBtn" style="background-color:#ffbf00;font-family: BigNoodleTitling; margin-top: 25px;" class="btn confirmCreate mt-1 mb-2" data-keyboard="false" data-dismiss="modal-body">I don't need this, thank you </button>
          </div>
          </div>
      </div>
    </div>
  </div>
<!--popup video code ends here-->


<div class="team-form-modal">
  <div class="modal fade" id="forgot-password-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetForgotPassword()">
                      <span aria-hidden="true" class="fa fa-times"></span>
                      </button>
              </div>
              <div class="modal-container">
                  <div class="modal-body">
                      <span>Enter an email address where we can send forgot password link</span>
                  </div>
                  <div class="team-form mb-4">
                      <form (ngSubmit)="forgotPassword()" [formGroup]="forgotPasswordForm">
                          <div class="form-group">
                              <input type="email" class="form-control" placeholder="Email Address" formControlName="email">
                              <span *ngFor="let error of forgotPassErrors.email" class="error-message">{{ error }}</span>
                              <span *ngIf="forgotStatus">Email Sent Successfully</span>
                          </div>
                          <div class="form-btn">
                              <button type="submit" style="cursor: pointer;" id="forgot-password-submit" class="btn success mr-1" data-toggle="modal">SEND EMAIL</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
