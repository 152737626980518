<link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
    <div class="title-container-2 mt-3">
        <div class="page-t">
            <div class="page-title" style="margin: auto;">
                <b>GO TO <span>TRIVTRAK.COM</span> TO <span>JOIN</span> OR <span>CREATE YOUR TEAM</span></b>
            </div>
        </div>
    </div>
    <div class="teamcard m-3" *ngIf="gameData">
        <div *ngIf="player" class="card-header"><b>MY TEAM: <span>{{player.my_team.name}}</span></b></div>
        <div *ngIf="! player" class="card-header"><b><span>Score Card</span></b></div>
        <div class="top" style="color: #ffbf00; background-color: rgb(33, 32, 30);">
            <div class="image">
                <div class="imageround">
                    <img [src]="gameData.image_path ? gameData.image_path : 'assets/img/location.png'">
                </div>
                <span>VENUE</span>
            </div>
            <div class="teamgame-info">
                <span>{{gameData.name}}</span>
                <small *ngIf="gameData.address">{{gameData.address}}</small>
            </div>
            <div class="teamgame-info-2">
                <span>HOST: {{gameData.stage_name}}</span>
                <small *ngIf="gameData.company_name">COMPANY: {{gameData.company_name}}</small>
            </div>
        </div>
    </div>

    <div class="rank-table m-3">
        <div class="table-responsive">
            <table class="table" id="PlayerScoreTable" style="background-color: rgb(33, 32, 30); color:white">
                <thead>
                    <tr>
                    <th scope="col" style="vertical-align: middle;"><span matTooltipClass="custom-tooltip-blue" matToolTipHideDelay="50000" matTooltipPosition="above" matTooltip="Rank - Your team's position on the board based on your TSW TWS - Total World Score - This is your combined score, over time and how you see how you compare to other trivia teams." matTooltipHideDelay="10s"  href="https://trivtrak.com.com/#/faq" >RK</span> </th>
                    <th scope="col" style="vertical-align: middle;"><span matTooltipClass="custom-tooltip-blue" matTooltipPosition="above"  matTooltip="Your team name. If it's white you own it." href="https://trivtrak.com/#/faq">TEAM</span></th>
                    <th scope="col" style="vertical-align: middle;"><span matTooltipClass="custom-tooltip-blue" matTooltipPosition="above" matTooltip="Players - The number of Bonafide teammates on your team." style="cursor: pointer" href="https://trivtrak.com/#/faq" >P</span></th>
                    <th scope="col" style="vertical-align: middle;"><span matTooltipClass="custom-tooltip-blue" matTooltipPosition="above" matTooltip="Total Score - For your game"  href="https://trivtrak.com/#/faq">TTL</span></th>
                    <th scope="col" *ngFor="let item of round_array let i = index">{{i+1}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let gameTeam of gameTeamArray">
                        <th scope="row" [ngClass]="{'unregistered':!gameTeam.has_registered_player}">{{gameTeam.rank}}</th>
                        <td [ngClass]="{'unregistered':!gameTeam.has_registered_player}">
                            <!--<span style="display: flex">
                                <span style="display: block; float: left; background: transparent; width:100px;text-align: left; width:100%;">{{gameTeam.name}}</span>
                                <span style="float: right;">
                                    <img *ngIf="gameTeam.rank == 1 && game.end_date" src="assets/img/gold.png">
                                    <img *ngIf="gameTeam.rank == 2 && game.end_date" src="assets/img/silver.png">
                                    <img *ngIf="gameTeam.rank == 3 && game.end_date" src="assets/img/bronze.png">
                                </span>
                            </span>-->

                          <a style="cursor: pointer;">{{gameTeam.name}}</a>
                          <flair [flair]="gameTeam.flair" *ngIf="gameTeam.flair" class="medal"></flair>
                          <img src="../../../../assets/img/gold.png" *ngIf="gameTeam.rank==1 && game.end_date && gameTeam.total_score && gameTeam.total_score > 0" class="medal" />
                          <img src="../../../../assets/img/silver.png" *ngIf="gameTeam.rank==2 && game.end_date && gameTeam.total_score && gameTeam.total_score > 0" class="medal" />
                          <img src="../../../../assets/img/bronze.png" *ngIf="gameTeam.rank==3 && game.end_date && gameTeam.total_score && gameTeam.total_score > 0" class="medal" />
                        </td>
                        <td [ngClass]="{'unregistered':!gameTeam.has_registered_player}">{{gameTeam.total_registered_player}}</td>
                        <!-- <td style="font-size: 30px !important">{{gameTeam.total_score}}</td> -->
                        <td class="ttl" [ngClass]="{'unregistered':!gameTeam.has_registered_player}">{{gameTeam.total_score}}</td>
                        <td *ngFor="let item of gameTeam.scores" [ngClass]="{'unregistered':!gameTeam.has_registered_player}">
                            <span *ngIf="game.end_date">{{item.score || 0}}</span>
                            <span *ngIf="!game.end_date">{{item.score || 0}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
    <br>
    <br>

    <div class="footer">
        <div class="button">
            <a class="disabledBtnScore">STATS</a>
        </div>
        <div class="button">
            <a class="disabledBtnScore">REWARDS</a>
        </div>
        <div class="button">
            <a [ngClass]="{'disabledBtnScore':!game.end_date}" [ngStyle]="{'cursor': game.end_date?'pointer':'not-allowed'}" data-toggle="modal" data-target="#share-result-modal" (click)="addOpacity();">SHARE</a>
        </div>
        <div class="button">
            <a class="disabledBtnScore">CHAT</a>
        </div>
        <div class="button">
            <a class="" [ngClass]="{'disabledBtnScore':game.end_date || answerDisabled}" (click)="openAnswerSheet()">Answer Sheet</a>
        </div>
    </div>

    <div class="share-result-modal">
        <div class="modal fade" id="share-result-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <span class="modal-title">Share Result</span>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" class="fa fa-times"></span>
                </button>
              </div>
              <div class="modal-body">
                <div class="modal-btn mr-3 ml-3 mb-3 text-center">
                    <button type="button" style="background-color: #4267B2; color: #fff; margin: 0px 5px;"  class="btn  mt-2" id="fbShare" data-value="{{gameID}}" onClick="sharePlayer(this.value)">Share&nbsp;&nbsp;<i class="fa fa-facebook-square" style="font-size: 24px;
                            vertical-align: sub;"></i></button>
                    <button type="button" style=" background-color: #55acee; color: #fff; margin: 0px 5px;" class="btn  mt-2" id="twitterShare" onClick="window.open('https://twitter.com/intent/tweet?url=https://qa.trivtrak.com:50443/%23/host/game-result/2431&text=TrivTrak.com&hashtags=trivtrak');">Share&nbsp;&nbsp;<i class="fa fa-twitter-square" style="font-size: 24px;
                        vertical-align: sub;" ></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    <!-- Join team modal -->
    <div class="join-team-modal">
            <div class="modal fade" id="teamModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" class="fa fa-times"></span>
                            </button>
                        </div>
                        <div class="modal-container">
                            <div class="modal-body">
                                <span>BE A BONIFIDE MEMBER OF THIS TEAM</span>
                            </div>
                                <div class="modal-btn">
                                    <a role="button" class="btn team-form mr-1" data-toggle="modal" data-target="#team-form-modal" (click)="addOpacity();">YES</a>
                                </div>
                                <div class="link mt-2">
                                    <a data-dismiss="modal">NO THANK YOU</a>
                                </div>
                                <div class="modal-info">
                                    <div class="info-header mb-2">
                                        <span>BENEFITS OF OWNERSHIP</span>
                                    </div>
                                    <ul>
                                        <li>1.  Ownership of Team Name</li>
                                        <li>2.  Change Team Name</li>
                                        <li>3.  Access to scoreboards</li>
                                        <li>4.  Access to special events</li>
                                        <li>5.  Rewards and Stats</li>
                                        <li>6.  Join our Trivia Community</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div class="team-form-modal">
            <div class="modal fade" id="team-form-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header"></div>
                        <div class="modal-container">
                            <div class="modal-body">
                                <span>FILL IN THE FOLLOWING DETAILS TO BE PART OF THE TEAM</span>
                            </div>
                            <div class="team-form mb-4">
                                <form (ngSubmit)="ownTeam()" [formGroup]="ownForm">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Fullname" formControlName="first_name">
                                        <span *ngFor="let error of ownTeamErrors.first_name" class="error-message">{{ error }}</span>
                                    </div>
                                    <div class="form-group">
                                        <input type="email" class="form-control" placeholder="Email Address" formControlName="email">
                                        <span *ngFor="let error of ownTeamErrors.email" class="error-message">{{ error }}</span>
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="City" formControlName="city">
                                        <span *ngFor="let error of ownTeamErrors.city" class="error-message">{{ error }}</span>
                                    </div>
                                    <div class="form-group">
                                        <input type="password" class="form-control" placeholder="Password can be anything" formControlName="password">
                                        <span *ngFor="let error of ownTeamErrors.password" class="error-message">{{ error }}</span>
                                    </div>
                                    <div class="form-btn">
                                        <!-- <a style="cursor: pointer;" class="btn success mr-1" data-toggle="modal" data-target="#success-modal">CLICK TO OWN</a> -->
                                        <button type="submit" style="cursor: pointer;" id="own-submit" class="btn success mr-1" data-toggle="modal">CLICK TO JOIN</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
