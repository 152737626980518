    <div class="title-container mt-3">
        <div class="page-t">
            <div class="page-title" style="margin: auto;">
                <b>TEAM PROFILE</b>
            </div>
        </div>
    </div>

    <div class="hostprofile">
        <div class="alert alert-success alert-dismissible" *ngIf="successMsg">
            <button type="button" class="close" data-dismiss="alert">&times;</button>
            {{successMsg}}
        </div>
        <div class="alert alert-danger alert-dismissible" *ngIf="errorMsg">
            <button type="button" class="close" data-dismiss="alert">&times;</button>
            {{errorMsg}}
        </div>
        <div class="card hovercard">
            <div class="cardheader"></div>
            <div class="avatar">
                <img alt="" [src]="player.my_team.image_path != null ? player.my_team.image_path :'assets/img/pic1.jpg'">
            </div>
            <div class="content">
                <div class="d-flex flex-column">
                    <span>{{player.my_team.name}} <img src="../../../../assets/img/star.jpg" *ngIf="player.id == team_owner && player.profile.is_approved" style="width: 50px;height: auto;display: inline-block;"/></span>
                    <small>{{selectedVenue.name}}</small>
                </div>
                <div class="team-info">
                    <div class="info-content col-6">
                        <span>RANK IN VENUE</span>
                        <small>{{teamRankVenue}}</small>
                    </div>
                    <div class="info-content col-6">
                        <span>TWS Rank</span>
                        <small>{{twsRank}}</small>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="button-grp">
                <a class="button mt-2" routerLink="/tournaments" role="button">TOURNAMENTS</a>
            </div>
            <div class="button-grp">
                <a class="button mt-2" style="cursor: pointer;" data-toggle="modal" data-target="#teamMemberModal" (click)="addOpacity();">TEAM MEMBERS</a>
            </div>
            <div class="button-grp" *ngIf="player.id == team_owner && player.profile.is_approved">
                <a class="button mt-2" style="cursor: pointer;" data-toggle="modal" data-target="#editTeam" (click)="addOpacity();">UPDATE TEAM</a>
            </div>
            <div class="button-grp" *ngIf="player.id != team_owner && player.email">
                <a class="button mt-2" style="cursor: pointer;" data-toggle="modal" data-target="#editTeam" (click)="addOpacity();">UPDATE TEAM</a>
            </div>
            <!-- <div class="button-grp">
                <a class="button mt-2" data-toggle="modal" data-target="#editTeam">UPDATE TEAM</a>
            </div> -->
            <div class="button-grp mt-2">
                <a class="button mr-1 mb-1 disabledBtn" role="button">REWARDS</a>
                <a class="button ml-1 mb-1" style="cursor: pointer;" *ngIf="player.id == team_owner && player.profile.is_approved" data-toggle="modal" data-target="#inviteUser" role="button" (click)="addOpacity();">INVITE</a>
                <a class="button ml-1 mb-1" style="cursor: pointer;" *ngIf="player.id != team_owner && player.email" data-toggle="modal" data-target="#inviteUser" role="button" (click)="addOpacity();">INVITE</a>
            </div>
        </div>
    </div>

    <div class="createTeamModal">
        <div class="modal fade" id="inviteUser" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <span class="modal-title" id="exampleModalLabel">Invite Players</span>
                    <button class="close" data-dismiss="modal" aria-label="Close" (click)="onInviteCLose()">
                    <span aria-hidden="true" class="fa fa-times"></span>
                    </button>
                </div>
                <div class="modal-body">
                    <span>TYPE THE EMAIL OF PLAYER</span>
                </div>
                <div class="team-form mb-4" style="margin-left: 40px; margin-right: 40px;">
                        <form (ngSubmit)="invitePlayer()" [formGroup]="invitePlayerForm">
                            <div class="form-group">
                                <input type="email" class="form-control" placeholder="Email Address" formControlName="email">
                                <span *ngFor="let error of inviteError.email" class="error-message">{{ error }}</span>
                                <span *ngIf="inviteStatus">Email Sent Successfully</span>
                            </div>
                            <div class="form-btn">
                                <!-- <a style="cursor: pointer;" class="btn success mr-1" data-toggle="modal" data-target="#success-modal">CLICK TO OWN</a> -->
                                <button type="submit" style="cursor: pointer;" class="btn success mr-1" id="inv-submit" data-toggle="modal">INVITE</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="createTeamModal">
        <div class="modal fade" id="teamMemberModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <span class="modal-title" id="exampleModalLabel">TEAM MEMBERS</span>
                    <button class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" class="fa fa-times"></span>
                    </button>
                </div>
                <div class="modal-body">
                    <div *ngIf="team_members.length > 0">
                        <span *ngFor="let member of team_members">{{member}}<br></span>
                    </div>
                    <div *ngIf="team_members.length == 0">
                            <span>No Registered Members</span>
                        </div>
                </div>
                </div>
            </div>
        </div>
    </div>

    <div class="edit-photo-modal">
        <div class="modal fade" id="editTeam" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <form [formGroup]="teamEditForm" (ngSubmit)="updateTeam(image_uri2.value, file_name2.value)">
                <div class="modal-header">
                  <span class="modal-title" id="exampleModalLabel">UPDATE TEAM</span>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" class="fa fa-times"></span>
                  </button>
                </div>
                <div class="modal-body">
                  <img src="{{this.player['my_team']['image'] != null ? this.player['my_team']['image'] : 'assets/img/pic1.jpg'}}" class="preview"/>
                  <input type="hidden" formControlName="image_uri" #image_uri2 class="image_uri">
                  <input type="hidden" formControlName="file_name" #file_name2 class="file_name">
                  
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="name" formControlName="name">
                    </div>
                </div>
                <div class="modal-btn mr-3 ml-3 mb-3">
                  <div style="position: relative;width: 70%;">
                    <input type="file" style="position: absolute; width: 100%; top: 0px; height: 100%; opacity: 0;" formControlName="image" onchange="readURL(this);">
                    <a class="btn button mt-2" style="width: 100%; font-family: BourtonBase" data-dismiss="modal" >CHANGE PHOTO</a>
                  </div>
                  <button style="width: 70%; background-color: #ffbf00; font-family: BourtonBase" type="submit" class="btn button mt-2">CLICK TO UPDATE</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="loader" *ngIf="loader">
        <div class="loaderbx">
          <i class="fas fa-3x fa-spinner fa-spin" aria-hidden="true"></i>
        </div>
      </div>

<style>
.input-group>.custom-select {
    font-size: 20px;
    color: #495057;
    width:100%;
}
.add-venue-modal .modal-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
}
</style>
<script>
    
    $('#add-venue-modal').on('shown.bs.modal', function () {
        $('#myInput').trigger('focus')
    })

    function readURL(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                $('#blah')
                    .attr('src', e.target.result);
            };

            reader.readAsDataURL(input.files[0]);
        }
    }
</script>
