import { Component, OnInit } from '@angular/core';
import {PlayerSharedService} from '../../../services/player-shared.service'
import {PlayerUser} from '../../../models/player_user'
import { Observable } from 'rxjs';
import {ActiveGameVenue} from '../../../models/active_game_venue'
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';
declare var jQuery:any;

@Component({
  selector: 'app-join-game-confirm',
  templateUrl: './join-game-confirm.component.html',
  styleUrls: ['./join-game-confirm.component.css']
})
export class JoinGameConfirmComponent implements OnInit {
  token: string;
  player: PlayerUser;
  player_user_logout_status: boolean;
  message: string;
  selectedVenue: ActiveGameVenue;
  tournaments: any = [];
  selectedTournament: any = [];

  constructor(private router: Router, private _sharedService: PlayerSharedService) {
    this.token = localStorage.getItem('player_token')
    this.player = JSON.parse(localStorage.getItem('player_user'));
    this.player_user_logout_status = JSON.parse(localStorage.getItem('player_user_logout_status'));
    // this.token = sessionStorage.getItem('player_token')
    // this.player = JSON.parse(sessionStorage.getItem('player_user'));
    // this.player_user_logout_status = JSON.parse(sessionStorage.getItem('player_user_logout_status'));
    this.selectedVenue = JSON.parse(localStorage.getItem('venue'));
   }

  ngOnInit() {
    this._sharedService.activeGameTournamentService(this.selectedVenue.id, this.token)
      .subscribe(data => {
          this.tournaments = data;
          for (let i = 0; i < this.tournaments.length; i++) {
            if (this.tournaments[i].image != null && !this.tournaments[i].image.startsWith(environment.BASE_API_URL)){
                this.tournaments[i].image = environment.BASE_API_URL + this.tournaments[i].image;
            }
        }
      },
      error => {
        console.log(error);
      });
  }

  tournamentInfo(tournament): void{
    this.selectedTournament = tournament;
    this.addOpacity()
}

  justPlayTonight(){
    localStorage.setItem('player_user_logout_status', JSON.stringify(false));
    this._sharedService.JoinTeamService(this.selectedVenue.active_game, {'team_id':this.player.my_team.id}, this.token)
      .subscribe(data => {
        this.player = data.user;
        this.token = data.token.access;
        localStorage.setItem('player_user', JSON.stringify(data.user));
        localStorage.setItem('player_token', data.token.access);
        localStorage.setItem('refresh_token', data.token.refresh);
        localStorage.setItem('game', JSON.stringify(data.game));

        var message = 'Ok your team is not part of the tournament. You can join at a later date if you want. Click ok to go to scoreboard';
        localStorage.setItem('congrats-mesg', message);
        this.router.navigateByUrl('/join-team-congratulations');
      },
      error => {
          console.log(error);
      });
  }

  joinTheTournament(){
      localStorage.setItem('player_user_logout_status', JSON.stringify(false));
      let game_id = this.selectedVenue.active_game;
      if (game_id >0 ){
          this._sharedService.CheckInUser(game_id, {'team_id': this.player.my_team.id}, this.token)
            .subscribe(data => {
              this.player = data.user;
              this.token = data.token.access;
              localStorage.setItem('player_token', data.token.access);
              localStorage.setItem('player_user', JSON.stringify(data.user));
              localStorage.setItem('game', JSON.stringify(data.game));
              var message = 'Congrats, you joined the scoreboard with team "' + this.player.my_team.name + '". Click play to go to scoreboard';
              localStorage.setItem('congrats-mesg', message);
              this.router.navigateByUrl('/join-team-congratulations');
            })
      }
  }

  addOpacity() {
    jQuery(document).ready(function() {
      jQuery('.modal.fade').css('opacity', 1);
      jQuery('.modal.fade').addClass('show');
      let cls = document.getElementsByClassName("modal-backdrop").length;
      if (cls>0) {
        jQuery('div.modal-backdrop').addClass('show');
        jQuery('div.modal-backdrop').css('opacity', 0.8);
      }
    });
  }

}
