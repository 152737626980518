<!-- main card (_cards.scss) -->
<div class="best-card m-3" *ngIf="bannerFlag">
  <a href="{{bannerAdData.link}}" target="_blank">
    <div style="display: flex; justify-content: center;">
      <div class="teamNameLogo logoimgnormal banner-ad-logo">
        <img [src]="bannerAdData.image" class="img-responsive .banner-ad-img">
      </div>
    </div>
  </a>
</div>

 <!--<div class="title-container mt-3">-->
        <!--<div class="page-t" style="height: 35px;">-->
        <!--</div>-->
    <!--</div>-->

<div class="best-card m-3" *ngIf="localstorage_obj.getItem('player_user')">
  <div class="card-header" style="text-align: center;"></div>
  <div class="top">
 <div class="teamNameLogo">
        <img class="img-responsive" [src]="player.my_team.image_path != null ? player.my_team.image_path : '../../../../assets/img/pic1.jpg'">
      </div>

    <div class="name-tag">
      <h1 *ngIf="selectedVenue">{{selectedVenue.name}}</h1>
      <span>{{player.my_team.name}}</span>
    </div>
    <div class="rank d-flex flex-column text-center">
      <a (click)="goToLeaderBoard()" style="cursor: pointer;">
        <span>RANK</span>
        <h2>{{team_rank}}</h2>
      </a>
    </div>
  </div>
  <div class="below">
    <div class="scoreboard-btn">
      <div style="text-align: center">
        <a class="btn btn-sm mb-3" (click)="onPlayClick()" role="button" style="padding-top:7px;">CREATE/JOIN TEAM</a>
      </div>
    </div>
  </div>
</div>
<!-- end of main card -->

<div class="best-card m-3" *ngIf="!localstorage_obj.getItem('player_user')">
  <div class="card-header" style="text-align: center; height: 13px;"></div>
  <div class="top">
    <div class="teamNameLogo logoimgnormal">
      <img src="../../../../assets/img/logo.png" class="img-responsive">
    </div>


    <div class="name-tag">
      <h1>YOUR LOCATION</h1>
      <span>YOUR TEAM NAME</span>
    </div>
    <div class="rank d-flex flex-column text-center">
      <a [routerLink]="['/tournament-leaderboard-home', tournID]">
        <span>RANK</span>
        <h2>1st</h2>
      </a>
    </div>
  </div>
  <div class="below">
    <div class="scoreboard-btn">
      <div style="text-align: center">
        <a class="btn btn-sm mb-3" (click)="onPlayClickWithoutUser()" role="button" style="padding-top:7px;">CREATE/JOIN TEAM</a>
      </div>
    </div>
  </div>
</div>

<!-- <div class="pagelinkcard m-3">
  <div class="card-header"></div>
  <a routerLink="/locations">
    <div class="content">
      <span>PLAY NOW</span>
      <i class="fa fa-angle-right"></i>
    </div>
  </a>
</div> -->

<!-- page link card (_cards.scss) -->
<div class="pagelinkcard m-3">
  <div class="card-header"></div>
  <a routerLink="/results">
    <div class="content">
      <span>RESULTS</span>
      <i class="fa fa-angle-right"></i>
    </div>
  </a>
</div>
<!-- end of page link card -->

<div class="pagelinkcard m-3">
  <div class="card-header"></div>
  <a routerLink="/tournaments">
    <div class="content">
      <span>TOURNAMENTS/LEADERBOARDS</span>
      <i class="fa fa-angle-right"></i>
    </div>
  </a>
</div>

<div class="pagelinkcard m-3">
  <div class="card-header"></div>
  <a routerLink="/host">
    <div class="content">
      <span>HOSTS</span>
      <i class="fa fa-angle-right"></i>
    </div>
  </a>
</div>

<div class="pagelinkcard m-3">
  <div class="card-header"></div>
  <a class="disabledBtn">
    <div class="content">
      <span>MY REWARDS</span>
      <i class="fa fa-angle-right"></i>
    </div>
  </a>
</div>

<div class="pagelinkcard m-3">
  <div class="card-header"></div>
  <a class="disabledBtn">
    <div class="content">
      <span>FIND A GAME</span>
      <i class="fa fa-angle-right"></i>
    </div>
  </a>
</div>

<div class="add-team-modal">
  <div class="modal fade" id="teamLimitModalIndex" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
          <div class="modal-header">
            <span class="modal-title">Team Limit Reached</span>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" class="fa fa-times"></span>
            </button>
          </div>
          <div class="modal-container">
            <div class="modal-body" style="text-align: center; font-family: 'BourtonBase';">
                <span style="font-size: 25px;">We're sorry, this game is at Capacity.<br/>Please check schedule for the next game, coming soon.</span>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>

<div class="createTeamModal">
  <div class="modal fade" id="infoIndex" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
          <div class="modal-header">
              <span class="modal-title" id="exampleModalLabel">INFO</span>
              <button class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" class="fa fa-times"></span>
              </button>
          </div>
          <div class="modal-body">
              <span>You are about to choose your game.<br/>Games have different details.<br/>Find the game you want.</span>
          </div>
          <div class="modal-body" style="display: none;" id="myvideo1">
          <div  class="embed-responsive embed-responsive-16by9">
             <video  id="cartoonVideo"   controls="controls" class="embed-responsive-item">
                <source   src="../../../assets/video/ds.mp4" type="video/mp4">
          </video>
           </div>
        <div class="modal-btn mb-3">
        <!-- <button type="button" id="btnclosevid"  (click)="onClickvideoIntroclose()"  style="background-color:#ffbf00;font-family: BigNoodleTitling; margin-top: 25px;" class="btn confirmCreate mt-1 mb-2" data-keyboard="false" data-dismiss="modal-body">I don't need this, thank you </button> -->
    </div>
    </div>
          <div class="modal-btn mb-3" style="flex-direction: unset;">
             <a type="button"class="btn confirmCreate mt-1 mb-2" style="margin-left: 15px;flex-direction: unset;" id="myBtn2">Learn More </a>
              <a style="cursor: pointer;" class="btn confirmCreate mt-1 mb-2" style="margin-left: 50px;margin-right: 10px; flex-direction: unset;" data-toggle="modal" routerLink="/locations">CLICK TO CONTINUE</a>
          </div>
          </div>
      </div>
  </div>
</div>

<!-- <div class="team-form-modal">
    <div class="modal fade" id="team-login-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetLogin()">
                        <span aria-hidden="true" class="fa fa-times"></span>
                        </button>
                </div>
                <div class="modal-container">
                    <div class="modal-body">
                        <span>FILL IN THE FOLLOWING DETAILS TO LOG IN</span>
                    </div>
                    <div class="team-form mb-4">
                        <form (ngSubmit)="login()" [formGroup]="loginForm">
                            <div class="form-group">
                                <input type="email" class="form-control" placeholder="Email Address" formControlName="email">
                                <span *ngFor="let error of loginErrors.email" class="error-message">{{ error }}</span>
                            </div>
                            <div class="form-group">
                                <input type="password" class="form-control" placeholder="Password can be anything" formControlName="password">
                                <span *ngFor="let error of loginErrors.password" class="error-message">{{ error }}</span>
                            </div>
                            <div class="form-btn">
                                <button type="submit" style="cursor: pointer;" id="login-submit" class="btn success mr-1" data-toggle="modal">CLICK TO LOG IN</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
