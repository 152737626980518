<div id="TEST">
 <div class="present-header Container-fluid">
      <div class="row text-center topheaderpart" style="margin:0px;">
        <div class="col-md-4">
            <div  *ngIf="tournamentSponser">
                <img *ngIf="tournamentSponser[0][1]" style="height: 85px;" src="/media/{{tournamentSponser[0][1]}}">
                <span *ngIf="!tournamentSponser[0][1]" style="font-size: 32px; margin-left: 50px; font-weight: bold;">CREATE OR JOIN YOUR TEAM AT<br>TRIVTRAK.COM</span>
              </div>
              <div *ngIf="!tournamentSponser">
                <span>CREATE OR JOIN YOUR TEAM AT<br>TRIVTRAK.COM</span>
              </div>

        </div>
        <div class="col-md-4">
            <div class="left-sidetext">
                <span>TONIGHT'S</span>
              </div>
          <div class="top-logo__">
            <!-- <img src="assets/img/logo1.png" alt="TRIVTRAK LOGO"  > -->
            <img *ngIf="localstorage_obj.getItem('TrivTrak_USER')" [src]="host.profile.company_image != null ? host.profile.company_image : defaultLogo" alt="TRIVTRAK LOGO">
            <img *ngIf="!localstorage_obj.getItem('TrivTrak_USER')" src="assets/img/logo1.png" alt="TRIVTRAK LOGO">

          </div>
          <div class="right-sidetext" style="margin-left: -10px;">
              <span>SCORES</span>
            </div>
        </div>
        <div class="col-md-4">
            <div>
                <span>CREATE OR JOIN YOUR TEAM AT<br>TRIVTRAK.COM</span>
            </div>

        </div>
      </div>


        <!-- <nav class="navbar navbar-custom navbar-fixed-top" role="navigation">
          <div class="header col-12 d-flex justify-content-center">
            <div class="left-ads" *ngIf="tournamentSponser">
              <img *ngIf="tournamentSponser[0][1]" src="/media/{{tournamentSponser[0][1]}}">
              <span *ngIf="!tournamentSponser[0][1]" style="font-size: 32px; margin-left: 50px; font-weight: bold;">CREATE OR JOIN YOUR team AT TRIVTRAK.COM</span>
            </div>
            <div class="left-ads" *ngIf="!tournamentSponser">
              <span style="font-size: 32px; margin-left: 50px; font-weight: bold;">CREATE OR JOIN YOUR team AT TRIVTRAK.COM</span>
            </div>
            <div class="logo d-flex justify-content-center">
              <div class="mt-auto mb-auto mr-4" style="font-size:40px; padding-top: 2px;">
                <span>TONIGHT'S</span>
              </div>
              <div class="img-logo">
                <img src="assets/img/logo1.png" alt="TRIVTRAK LOGO">
              </div>
              <div class="mt-auto mb-auto ml-4" style="font-size:40px; padding-top: 2px;">
                <span>SCORES</span>
              </div>
            </div>
              <div class="right-ads" style="font-size: 32px; text-align:center; font-weight: bold;">
                  <span>CREATE OR JOIN YOUR team AT TRIVTRAK.COM</span>
              </div>
          </div>
        </nav>   -->
      </div>
      <!--<div class="bground"></div>-->
      <div class="row m-2 bgwhite" *ngIf="gameTeams && gameTeams.length <= 12" style="position:relative;z-index:99;">
        <table class="rank-table" style="width:100%;border-top: 0px;" mdbTable>
          <thead>
            <tr>
              <th [mdbTableSort]="gameTeams" sortBy="rank" scope="col">RANK</th>
              <th [mdbTableSort]="gameTeams" sortBy="name" scope="col">TEAM</th>
              <th [mdbTableSort]="gameTeams" sortBy="scores[max_round-1].score"scope="col">LAST</th>
              <th [mdbTableSort]="gameTeams" sortBy="total_score" scope="col" >TOTAL</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let team of gameTeams" [ngClass]="{'unregistered': !team.has_registered_player}">
              <td scope="row">{{team.rank}}</td>
              <td>
                {{team.name}}
                <flair [flair]="team.flair" *ngIf="team.flair"></flair>
                <img src="../../../../assets/img/gold.png" *ngIf="game.end_date && team.rank==1 && team.total_score && team.total_score > 0" class="medal" />
                <img src="../../../../assets/img/silver.png" *ngIf="game.end_date && team.rank==2 && team.total_score && team.total_score > 0" class="medal" />
                <img src="../../../../assets/img/bronze.png" *ngIf="game.end_date && team.rank==3 && team.total_score && team.total_score > 0" class="medal" />
              </td>
              <td>
                <span *ngIf="team.scores.length > 0 && team.scores[team.scores.length-1].score">{{team.scores[team.scores.length-1].score}}</span><!--Variable is not null display score-->
                <span *ngIf="team.scores.length == 0 || !team.scores[team.scores.length-1].score">0</span><!--Variable is null display 0-->
              </td>
              <td class="ttl">{{team.total_score}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row no-gutters m-2 bgwhite" *ngIf="gameTeams && gameTeams.length > 12">
        <div class="col-6" style="border-right: 2px #dee2e6 solid;">
          <table class="rank-table m-auto" style="width:100%;border-top: 0px;" mdbTable>
            <thead>
            <tr>
              <th [mdbTableSort]="gameTeams" sortBy="rank" scope="col">RANK</th>
              <th [mdbTableSort]="gameTeams" sortBy="name" scope="col">TEAM</th>
              <th [mdbTableSort]="gameTeams" sortBy="scores[max_round-1].score"scope="col">LAST</th>
              <th [mdbTableSort]="gameTeams" sortBy="total_score" scope="col" >TOTAL</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let team of gameTeams | slice: 0: 12" [ngClass]="{'unregistered': !team.has_registered_player}">
              <td scope="row">{{team.rank}}</td>
              <td>
                {{team.name}}
                <flair [flair]="team.flair" *ngIf="team.flair"></flair>
                <img src="../../../../assets/img/gold.png" *ngIf="game.end_date && team.rank==1 && team.total_score && team.total_score > 0" class="medal" />
                <img src="../../../../assets/img/silver.png" *ngIf="game.end_date && team.rank==2 && team.total_score && team.total_score > 0" class="medal" />
                <img src="../../../../assets/img/bronze.png" *ngIf="game.end_date && team.rank==3 && team.total_score && team.total_score > 0" class="medal" />
              </td>
              <td>
                <span *ngIf="team.scores.length > 0">{{team.scores[team.scores.length-1].score}}</span>
                <span *ngIf="team.scores.length == 0">0</span>
              </td>
              <td class="ttl">{{team.total_score}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-6">
          <table class="rank-table m-auto" style="width:100%;border-top: 0px;" mdbTable>
            <thead>
            <tr>
              <th [mdbTableSort]="gameTeams" sortBy="rank" scope="col">RANK</th>
              <th [mdbTableSort]="gameTeams" sortBy="name" scope="col">TEAM</th>
              <th [mdbTableSort]="gameTeams" sortBy="scores[max_round-1].score"scope="col">LAST</th>
              <th [mdbTableSort]="gameTeams" sortBy="total_score" scope="col" >TOTAL</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let team of gameTeams | slice: 12: 24" [ngClass]="{'unregistered': !team.has_registered_player}">
              <td scope="row">{{team.rank}}</td>
              <td>
                {{team.name}}
                <flair [flair]="team.flair" *ngIf="team.flair"></flair>
              </td>
              <td>
                <span *ngIf="team.scores.length > 0">{{team.scores[team.scores.length-1].score}}</span>
                <span *ngIf="team.scores.length == 0">0</span>
              </td>
              <td class="ttl">{{team.total_score}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="present-footer" style="z-index: 99999">
        <div style="position:absolute; bottom: .5px;right:25px">
          <a class="btn btn-sm btn-primary" style="cursor: pointer; color: white;display: none;" id="light" role="button" (click)="showLight()">Light Mode</a>
          <a class="btn btn-sm btn-primary" style="cursor: pointer; color: white; " id="dark"  role="button" (click)="showDark()">Dark Mode</a>
          <a class="btn btn-sm btn-primary" style="cursor: pointer; color: white; " role="button" id="full" (click)="showFull()">Full Screen</a>
        </div>
      </div>
</div>
<div class="loader" *ngIf="loader">
  <div class="loaderbx">
    <i class="fas fa-3x fa-spinner fa-spin" aria-hidden="true"></i>
  </div>
</div>

<style>
  .bground{
    background: #fff;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .rank-table{
    background: none !important;
  }
  .max {
    font-size: 1.3rem;
    border-radius: 30px;
    border: 2px solid #ffbf00;
    padding: 5px 15px 10px 15px;
    vertical-align: middle;
  }

  .bgwhite {
    background: #FFF;
    color: #000 !important;
  }
  .bgblack {
    background: #000;
    color: #FFF !important;
  }
</style>
