import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {StateListService} from '../../../services/state-list.service';
import {HostService} from '../../../services/host.service';
import {CookieService} from 'ngx-cookie-service';
import {ErrorMsg} from '../../../models/error';
import {environment} from '../../../../environments/environment';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
// import {LZStringService} from 'ng-lz-string';
import {delay} from 'rxjs/operators';
import {Router} from '@angular/router';
import { Observable } from 'rxjs';
import {DateButton} from "angular-bootstrap-datetimepicker";
import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe, formatDate} from '@angular/common';


// declare var $:any;
declare var jQuery: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  providers: [DatePipe]
})
export class ProfileComponent implements OnInit {
  public headers: any = [];
  public venueForm: FormGroup;
  public profileForm: FormGroup;
  public imageForm: FormGroup;
  public passwordForm: FormGroup;
  public stateList: any;
  public activeHosts: any;
  public successMsg: any;
  public errorMsg: any;
  public errorMsg2: any;
  public errorMsg3: any;
  public errorMsg4: any;
  public errorMsgLaunchVenue: any;
  public venuesList: any;
  public user: any;
  public term: any;
  public loader: boolean;
  public hostRank: number = 1;
  public defaultProfile: string = 'assets/img/avatar.png';
  public token: string;
  public companyVenuesList: any = [];
  public teamLimitForm: FormGroup;
  public teamLimitValue: any;
  public teamLimitError: string = null;
  public venueID: any = '';
  public selectedDate: any;
  public launchSelectedDate: any;
  public gameDescription: any = '';
  public gameLink: any = '';
  public gameID: any;
  public canViewReports: boolean = false;
  public launchVenue: any;
  currentDate: string;
  todayISOString: string;
  completeDate: Date;
  localCompleteDate: string;

  public tournamentForm: FormGroup;
  public tournamentImage: File;

  constructor(
    private stateService: StateListService,
    private hostService: HostService,
    private cookieService: CookieService,
    private router: Router
  ) {
    this.loader = true;
    this.user = JSON.parse(this.cookieService.get('TrivTrak_USER'));
    this.token = localStorage.getItem('TrivTrak_TOKEN');
    this.headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    };
    // this.teamLimitFormFunc();
    this.hostService.getProfile(this.user.id, this.headers)
      .subscribe((result) => {
        this.user = result;
        this.loader = false;
      });
    this.currentDate = new Date().toUTCString();
    this.todayISOString = new Date(this.currentDate).toISOString();
    this.todayISOString = this.todayISOString.substring(0, this.todayISOString.length - 1);
  }

  ngOnInit() {
    this.loader = false;
    jQuery('body').removeClass('model-open');
    jQuery('.modal-backdrop').remove();
    this.createVenueForm();
    this.createTournamentForm();
    this.stateService.getStates()
      .subscribe(
        (res: any) => this.stateList = res.results
      );
    this.hostService.getActiveHosts()
      .subscribe(
        (res: any) => this.activeHosts = res
      );
    this.getReportVis();
    this.createImageForm();
    this.createProfileForm();
    this.createPasswordForm();
    this.getHostVenues();
    this.getCompanyVenues();
    this.getHostRank();


  }

  openReports() {
    this.router.navigate(['/host/reports/']);
  }

  getReportVis(): void{
    this.hostService.checkPermissions("view_reports", this.headers)
      .subscribe( (result: boolean) => {
        this.canViewReports = result;
      });
  }

  createVenueForm() {
    this.venueForm = new FormGroup({
      name: new FormControl('', [
        Validators.required
      ]),
      state: new FormControl(null, [
        Validators.required
      ]),
      address: new FormControl('', [
        //Validators.required
      ]),
      phone: new FormControl('', [
        //Validators.required
      ]),
      website: new FormControl('', [
        //Validators.required
      ]),
      stage_name: new FormControl('', [
        Validators.required
      ]),
      host: new FormControl(null, [
        Validators.required
      ]),
      description: new FormControl('', [Validators.required]),
      image_uri: new FormControl(),
      file_name: new FormControl()
    });
  }

  createTournamentForm() {
    this.tournamentForm = new FormGroup({
      name: new FormControl('', [
        Validators.required
      ]),
      image: new FormControl(),
      image_name: new FormControl(),
      start_date: new FormControl('', [
        Validators.required
      ]),
      end_date: new FormControl('', [
        Validators.required
      ]),
      main_sponsor_name: new FormControl(),
      main_sponsor_url: new FormControl(),
      ms_image: new FormControl(),
      ms_image_name: new FormControl(),
      second_sponsor_name: new FormControl(),
      second_sponsor_url: new FormControl(),
      ss_image: new FormControl(),
      ss_image_name: new FormControl(),
      venues: new FormControl('', [
        Validators.required
      ]),
      info: new FormControl('', [Validators.required])
    });
  }

  selectFilter(dateButton: DateButton, viewName: string): boolean {
    return dateButton.value > (new Date()).getTime();
  }

  // teamLimitFormFunc() {
  //   //console.log(this.launchVenue);
  //   if (this.launchVenue) {

  //     // this.launchSelectedDate = new Date(this.launchVenue.game_open_date);
  //     // var timeZoneCode = new Date(this.launchVenue.game_open_date).toLocaleTimeString('en-us', {timeZoneName: 'short'}).split(' ')[2];

  //     this.completeDate = new Date(this.launchVenue.game_open_date);
  //     this.localCompleteDate = this.toISOLocal(this.completeDate);
  //     //this.localCompleteDate = this.localCompleteDate.substring(0, this.localCompleteDate.length - 1);

  //   }
  //   this.teamLimitForm = new FormGroup({
  //     teamLimit: new FormControl(this.teamLimitValue, [Validators.required]),
  //     description: new FormControl(this.launchVenue ? this.launchVenue.description : "", [Validators.required]),
  //     website: new FormControl(this.launchVenue ? this.launchVenue.website : "")
  //   })
  // }

  toISOLocal(d) {
    var z  = n =>  ('0' + n).slice(-2);
    //var zz = n => ('00' + n).slice(-3);
    var off = d.getTimezoneOffset();
    //var sign = off < 0? '+' : '-';
    off = Math.abs(off);

    return d.getFullYear() + '-'
           + z(d.getMonth()+1) + '-' +
           z(d.getDate()) + 'T' +
           z(d.getHours()) + ':'  +
           z(d.getMinutes()) //+ ':' +
           //z(d.getSeconds()) + '.' +
           //zz(d.getMilliseconds()) +
           //sign + z(off/60|0) + ':' + z(off%60);
  }

  // setTeamLimit() {
  //   this.loader = true;
  //   // this.teamLimitForm.value['dateTime'] = this.selectedDate;
  //   // this.teamLimitForm.value['link'] = null;
  //   jQuery('#add-venue-modal').hide();
  //   let data = this.teamLimitForm.value;
  //   // data['game_open_date'] = this.launchSelectedDate;
  //   data['game_open_date'] = new Date(this.localCompleteDate);
  //   data['offset'] = new Date().getTimezoneOffset() *-1;
  //   this.hostService.createGameInfo(this.gameID, this.token, data).subscribe(data1 => {
  //       this.loader = false;
  //       if (data1) {
  //         this.teamLimitValue = data1.team_limit;
  //         localStorage.setItem('team_limit', this.teamLimitValue);
  //         delete this.errorMsg4;
  //         this.router.navigate(['/host/launch-game', this.venueID]);
  //         // this.gameDescription = data1.description;
  //         // this.gameLink = data1.game_link;
  //       }
  //     },
  //     (error) => {
  //       this.loader = false;
  //       this.errorMsg4 = error;
  //       // this.errorMsg2 = "Please try again.";
  //     }
  //   );
  // }

  // resetLimit() {
  //   this.teamLimitFormFunc();
  //   this.teamLimitError = null;
  // }


  launchGame(venueID) {
    this.loader = true;
    this.venueID = venueID;
    // this.hostService.getOrCreateGame(this.venueID, this.token).subscribe(result => {
    //   this.gameID = result.id;
    //   this.launchVenue = result.venue;
    //   this.hostService.getGameInfo(result.id, this.token).subscribe(data1 => {
    //       this.loader = false;
    //       if (data1) {
    //         this.teamLimitValue = data1.team_limit;
    //         // this.gameDescription = data1.description;
    //         // this.gameLink = data1.game_link;
    //         // this.selectedDate = data1.game_open_date;
    //         this.teamLimitFormFunc();
    //         jQuery('#update-venue-modal').modal('show');
    //       }
    //     },
    //     (error) => {
    //       this.loader = false;
    //       // this.errorMsg2 = "Please try again.";
    //     }
    //   );
    // })
    this.router.navigate(['/host/launch-game', this.venueID]);
    this.addOpacity();
  }

  createProfileForm() {
    this.profileForm = new FormGroup({
      first_name: new FormControl(this.user.first_name, [
        Validators.required
      ]),
      last_name: new FormControl(this.user.last_name, [
        Validators.required
      ]),
      email: new FormControl(this.user.email, [
        Validators.required
      ]),
      profile: new FormGroup({
        state: new FormControl(this.user.profile['state'], [
          Validators.required
        ]),
        city: new FormControl(this.user.profile['city'], [
          Validators.required
        ]),
        profile_type: new FormControl(this.user.profile['profile_type'])
      })
    });
  }

  createImageForm() {
    this.imageForm = new FormGroup({
      image_uri: new FormControl('', [
        Validators.required
      ]),
      file_name: new FormControl('', [
        Validators.required
      ]),
      image: new FormControl('', [
        Validators.required
      ]),
    });
  }

  createPasswordForm() {
    this.passwordForm = new FormGroup({
      password: new FormControl('', [
        Validators.required
      ]),
      re_password: new FormControl('', [
        Validators.required
      ])
    });
  }

  addVenue(file, file_name) {
    this.loader = true;
    let data = this.venueForm.value;
    data['company'] = this.user.profile.company;
    data['phone'] = '';
    data['image_uri'] = file;
    data['file_name'] = file_name;
    // data['game_open_date'] = this.selectedDate;
    // data['game_open_date'] = new Date(new Date(this.todayISOString).toUTCString());
    data['game_open_date'] = new Date(this.todayISOString);
    this.hostService.addVenue(data, this.headers)
      .subscribe(
        (res) => {
          if (data['file_name'] && data['image_uri']) {
            let venue = {
              'venue_id': res.id,
              'image_uri': data['image_uri'],
              'file_name': data['file_name']
            };
            this.hostService.addVenueImage(venue, this.headers).subscribe(response => response);
          }
          jQuery('#add-venue-modal').hide();
          jQuery('body').removeClass('modal-open');
          jQuery('body').css('padding-right', 0);
          jQuery('.modal-backdrop').remove();
          this.successMsg = 'Venue Added Successfully.';
          this.loader = false;
          this.getHostVenues();
          this.getCompanyVenues();
          jQuery('#form-venue').trigger('reset');
          jQuery('html, body').animate({scrollTop: "0px"}, 800);
          delete this.errorMsg2;
          setTimeout(function () {
            location.reload();
          }, 3000);
        },
        (error: ErrorMsg) => {
          this.loader = false;
          jQuery('.add-venue-modal').animate({scrollTop: "0px"}, 800);
          delete this.successMsg;
          return this.errorMsg2 = error;
        }
      );
  }

  addTournament(image, image_name, ms_image, ms_image_name, ss_image, ss_image_name) {
    this.loader = true;
    let data = this.tournamentForm.value;
    if( image && image_name ) {
      data["image"] = image;
      data["image_name"] = image_name;
    }
    if(ms_image && ms_image_name) {
      data["main_sponsor_image"] = ms_image;
      data["main_sponsor_image_name"] = ms_image_name;
    }
    if(ss_image && ss_image_name) {
      data["second_sponsor_image"] = ss_image;
      data["second_sponsor_image_name"] = ss_image_name;
    }
    if( data.venus ) {
      for(const venue of data.venues) {
        data["venues"] = venue
      }
    }
    if(this.user) {
      data["owner"] = this.user;
    }
    this.hostService.createTournament(data, this.token).subscribe(
      (response) => {
        jQuery('#add-tournament-modal').hide();
        jQuery('body').removeClass('modal-open');
        jQuery('body').css('padding-right', 0);
        jQuery('.modal-backdrop').remove();
        this.successMsg = 'Scoreboard Added Successfully.';
        this.loader = false;
        jQuery('#tournament_form').trigger('reset');
        delete this.errorMsg2;
        console.log("response", response);
      },
      (error: ErrorMsg) => {
        this.loader = false;
        console.log("error", error);
        delete this.successMsg;
        this.errorMsg2 = error;
        return this.errorMsg2;
      }
    );
  }

  updateProfile() {
    this.loader = true;
    let data = JSON.stringify(this.profileForm.value);
    this.hostService.updateProfile(data, this.headers, this.user.id)
      .subscribe(
        (result) => {
          this.successMsg = 'Your Profile updated successfully.';
          localStorage.setItem('TrivTrak_USER', JSON.stringify(result));
          this.cookieService.set('TrivTrak_USER', JSON.stringify(result));
          sessionStorage.setItem('TrivTrak_USER', JSON.stringify(result));
          this.user = result;
          jQuery('#edit-profile-modal').hide();
          jQuery('body').removeClass('modal-open');
          jQuery('body').css('padding-right', 0);
          jQuery('.modal-backdrop').remove();
          this.loader = false;
          jQuery('html, body').animate({scrollTop: "0px"}, 800);
          delete this.errorMsg3;
          return result;
        },
        (error: any) => {
          this.errorMsg3 = 'Something went wrong.';
          this.loader = false;
          jQuery('.modal-body').animate({scrollTop: "0px"}, 800);
          delete this.successMsg;
          return this.errorMsg3;
        }
      );
  }

  updateUserPhoto(file, file_name) {
    this.loader = true;
    let data = this.imageForm.value;
    data['image_uri'] = file;
    data['file_name'] = file_name;
    data['user_id'] = this.user.id;
    this.hostService.updateUserImage(data)
      .subscribe(
        (result) => {
          this.user.profile['image'] = result.image;
          localStorage.setItem('TrivTrak_USER', JSON.stringify(this.user));
          this.cookieService.set('TrivTrak_USER', JSON.stringify(this.user));
          sessionStorage.setItem('TrivTrak_USER', JSON.stringify(this.user));
          jQuery('#edit-photo-modal').hide();
          jQuery('body').removeClass('modal-open');
          jQuery('body').css('padding-right', 0);
          jQuery('.modal-backdrop').remove();
          this.successMsg = 'Image uploaded successfully.';
          this.loader = false;
          jQuery('html, body').animate({scrollTop: "0px"}, 800);
          delete this.errorMsg;
          return result;
        },
        (error: ErrorMsg) => {
          this.errorMsg = 'Something went wrong.';
          this.loader = false;
          jQuery('.modal-body').animate({scrollTop: "0px"}, 800);
          delete this.successMsg;
          return this.errorMsg;
        }
      );
  }

  arrayUnique(array) {
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
      for (var j = i + 1; j < a.length; ++j) {
        if (a[i]['id'] === a[j]['id'])
          a.splice(j--, 1);
      }
    }

    return a;
  }

  getHostVenues() {
    this.hostService.getHostVenues(this.headers)
      .subscribe(
        (result) => {
          this.venuesList = result.results;
          let company = this.user.profile.company;
          if (company) {
            this.hostService.getCompanyVenues(this.headers, company)
              .subscribe(
                (result) => {
                  this.companyVenuesList = this.arrayUnique(this.venuesList.concat(result));
                  return this.companyVenuesList;
                }
              );
          } else {
            this.companyVenuesList = this.arrayUnique(this.venuesList.concat([]));
          }
        }
      );
  }

  getCompanyVenues() {
    let company = this.user.profile.company;
    if (company) {
      this.hostService.getCompanyVenues(this.headers, company)
        .subscribe(
          (result) => {
            return this.companyVenuesList = result;
          }
        );
    }
  }

  getHostRank() {
    this.hostService.getHostRank(this.headers)
      .subscribe(result => {
        this.hostRank = result.host_rank
      })
  }

  changePassword() {
    this.loader = true;
    let data = JSON.stringify(this.passwordForm.value);
    let value = JSON.parse(data);
    if (value.password == value.re_password) {
      this.hostService.changePassword(data)
        .subscribe(
          (result) => {
            this.successMsg = 'Your Password updated successfully.';
            jQuery('#form-password').trigger('reset');
            jQuery('body').removeClass('modal-open');
            jQuery('.modal-backdrop').remove();
            jQuery('body').css('padding-right', 0);
            jQuery('#edit-password-modal').hide();
            jQuery('html, body').animate({scrollTop: "0px"}, 800);
            this.loader = false;
            delete this.errorMsg;
            return result;
          },
          (error: ErrorMsg) => {
            this.errorMsg = error.re_password;
            jQuery('.modal-body').animate({
              scrollTop: "0px"
            }, 800);
            this.loader = false;
            delete this.successMsg;
            return this.errorMsg;
          }
        );
    } else {
      this.loader = false;
      this.errorMsg = 'Password does not match.';
      delete this.successMsg;
      return this.errorMsg;
    }
  }

  clearMsg() {
    this.loader = false;
    delete this.errorMsg;
    delete this.successMsg;
  }

  changePasswordModelOpen() {
    jQuery('#edit-profile-modal').modal('hide');
    jQuery('#edit-password-modal').modal('show');
    this.addOpacity();
  }

  addOpacity() {
    jQuery(document).ready(function () {
      jQuery('.modal.fade').css('opacity', 1);
      jQuery('.modal.fade').addClass('show');
      let cls = document.getElementsByClassName("modal-backdrop").length;
      if (cls > 0) {
        jQuery('div.modal-backdrop').addClass('show');
        jQuery('div.modal-backdrop').css('opacity', 0.8);
      }
    });
  }
}
