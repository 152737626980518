import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HostService} from '../../../services/host.service';
import {WebsocketProviderService} from '../../../services/websocket-provider.service';
import {CookieService} from 'ngx-cookie-service';
import {environment} from '../../../../environments/environment';
import {ErrorMsg} from '../../../models/error';
declare var jQuery: any;
@Component({
  selector: 'app-start-game',
  templateUrl: './start-game.component.html',
  styleUrls: ['./start-game.component.css']
})
export class StartGameComponent implements OnInit, OnDestroy {

  public gameId: any;
  public venueDetails: any;
  public game: any;
  public venueTeams: any;
  public gameTeams: any;
  public term: any;
  public teamForm: FormGroup;
  public successMsg: any;
  public errorMsg: any;
  public errorMsg2: any;
  public removeTeamId: any;
  public currentRound: any;
  public currentScores: any;
  public loader: boolean;
  public defaultImage: string = 'assets/img/location.png';
  public rounds: any = [];
  public venues: any;
  public user: any;
  public headers: any = [];

  constructor(
    private route: ActivatedRoute,
    private hostService: HostService,
    private wsService: WebsocketProviderService,
    private router: Router,
    private cookieService: CookieService
  ) {
    //This should be handled in a user service.
    this.user = JSON.parse(localStorage.getItem('TrivTrak_USER'));
    let token = localStorage.getItem('TrivTrak_TOKEN');
    this.headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    };
    this.loader = true;
    this.currentScores = {};
    localStorage.setItem('currentScores', JSON.stringify({}));
    jQuery('body').removeClass('modal-open');
    jQuery('body').css('padding-right',0);
    jQuery('.modal-backdrop').remove();
    this.route.params.subscribe(result => this.gameId = result.id);

    
    
    this.teamForm = new FormGroup({
      team_name: new FormControl('',[
        Validators.required
      ])
    });
    this.getVenues();

  }

  ngOnInit() {
    this.wsService.connect(this.gameId)
    .subscribe((result) => {
      if (result.msg_type == "update" || result.msg_type == "status"){
        this.getGameData();
        this.loader = false;
      }        
    });
  }

  ngOnDestroy(): void {
    this.wsService.close();
  }

  saveScore(teamId, round, score) {
    let data = {
      team_id: teamId,
      round_number: round,
      score: score
    };

    this.hostService.saveGameScore(this.gameId, data, this.headers)
      .subscribe((result) => {
        this.getGameData();
      });

  }

  openRemoveTeamModal(teamId, teamName) {
    this.addOpacity();
    this.removeTeamId =  {
      id: teamId,
      name: teamName
    };
  }

  removeTeam(teamId) {
    this.loader = true;
    let data = {
      team_id: teamId
    };
    this.hostService.removeTeam(this.game.id,data,this.headers)
      .subscribe(
        (result) => {
          this.game = result.game;
          this.successMsg = 'Team Removed Successfully.';
          this.loader = false;
          delete this.errorMsg;
          jQuery('body').removeClass('modal-open');
          jQuery('.modal-backdrop').remove();
          jQuery('#create-team-modal').hide();
          jQuery('#search-team-modal').hide();
          setTimeout(function(){ location.reload(); }, 500);
        }
      );
  }

  getGameData() {
    this.hostService.getGameData(this.gameId)
      .subscribe((result) => {
        this.game = result;

        if(this.game.venue.host == this.user.id){
          if(this.game.end_date) {
            this.router.navigate(['/host/game-result',this.game.id]);
          }
          for(let i=1; i<=this.game.max_round;i++) {
            this.rounds.push(i);
          }
          this.currentRound = this.game.max_round+1;
          this.venueDetails = result.venue;
          this.hostService.getGameTeams(this.gameId)
            .subscribe(result => this.gameTeams = result.sort(this.hostService.sortResponse('total_score')));
          this.hostService.getVenueTeams(this.game.venue.id)
            .subscribe(res => this.venueTeams = res);
          this.loadView();
        } else {
          this.errorMsg2 = 'This game or venue does not belong to you.';
        }
      },
        (error: ErrorMsg) => {
        this.loader = false;
        this.errorMsg2 = 'Something Went Wrong';
        });
  }

  saveGameData() {
    this.loader = true;
    if(this.isObjectEmpty(this.currentScores)) {
      for(let team of this.gameTeams) {
        this.currentScores[team.id] = 0;
      }
    }
    let data = {
      'scores': this.currentScores
    };
    this.hostService.saveGameScores(this.gameId, JSON.stringify(data), this.headers)
      .subscribe((result) => {
        delete this.errorMsg;
        this.getGameData();
        for(let team of this.gameTeams) {
          this.currentScores[team.id] = 0;
        }
        this.loader = false;
      });
  }

  endGame() {
    if(this.isObjectEmpty(this.currentScores)) {
      for(let team of this.gameTeams) {
        this.currentScores[team.id] = 0;
      }
    }
    this.loader = true;
    let data = {
      'end': true
    };
    this.saveGameData();
    this.hostService.saveGameScores(this.gameId, JSON.stringify(data), this.headers)
      .subscribe((result) => {
        this.getGameData();
        this.loader = false;
        this.currentScores = null;
        this.router.navigate(['/host/game-result', this.game.id]);
      });
  }

  pushScore(teamId, score) {
    this.currentScores[teamId] = score;
    localStorage.setItem('currentScores', JSON.stringify(this.currentScores))
  }

  joinTeam(teamId: null) {
    this.loader = true;
    let data: any;
    if(teamId == null) {
      data = JSON.stringify(this.teamForm.value);
    } else {
      data = {
        team_id: teamId
      };
    }
    this.hostService.joinTeam(this.game.id,data,this.headers)
      .subscribe(
        (result) => {
          this.game = result.game;
          jQuery('body').removeClass('modal-open');
          jQuery('#createTeam').trigger('reset');
          jQuery('.modal-backdrop').remove();
          jQuery('#create-team-modal').hide();
          jQuery('#search-team-modal').hide();
          this.successMsg = 'Team Added Successfully';
          this.loader = false;
          delete this.errorMsg;
          setTimeout(function(){ location.reload(); }, 500);
        },
        (error: ErrorMsg) => {
          this.loader = false;
          delete this.successMsg;
          this.errorMsg = error.team_name;
        }
      );
  }

  isObjectEmpty(Obj) {
    for(var key in Obj) {
      if(Obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  getVenues() {
    this.hostService.getAllVenues()
      .subscribe(
        (result) => {
          this.venues = result;
        }
      );
  }

  getVenueTeams(venueId) {
    this.hostService.getVenueTeams(venueId)
      .subscribe(res => this.venueTeams = res);
  }

  loadView() {
    jQuery(document).ready(function() {
      setTimeout(() => {
        var windowWidth = jQuery(window).width();
        if(windowWidth==1024) {
          let maxround = jQuery('#score-table').attr('value');
          if(maxround>5) {
            let margin = (maxround-4)*100;
            jQuery('#score-table').css('margin-left', margin+'px');
          }
        }
        if(windowWidth>1300) {

          let maxround = jQuery('#score-table').attr('value');
          if(maxround>7) {
            let margin = (maxround-7)*100-10;
            jQuery('#score-table').css('margin-left', margin+'px');
          }
        }
      },3000);

      /*setTimeout(() => {
          var windowWidth = jQuery(window).width();
          var maxround = jQuery('#score-table').attr('value');
          if(windowWidth == 1366) {
            if (maxround > 7) {
              let margin = (maxround - 7) * 100;
              jQuery('#score-table').css('margin-left', margin + 'px');
            }
          } else if(windowWidth == 812) {
            if (maxround > 7) {
              let margin = (maxround - 6) * 100;
              jQuery('#score-table').css('margin-left', margin + 'px');
            }
          }
        },3000);*/
    });
  }

  ngAfterViewInit() {
    this.loadView();
  }
  addOpacity() {
    jQuery(document).ready(function() {
      jQuery('.modal.fade').css('opacity', 1);
      jQuery('.modal.fade').addClass('show');
      let cls = document.getElementsByClassName("modal-backdrop").length;
      if (cls>0) {
        jQuery('div.modal-backdrop').addClass('show');
        jQuery('div.modal-backdrop').css('opacity', 0.8);
      }
    });
  }
}
