<!-- Modal -->
<div class="teamModal">
  <div class="modal fade" id="changeTeamCongratsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
          <div class="modal-header">
              <span class="modal-title" id="exampleModalLabel">Options For Team Change</span>
              <button class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" class="fa fa-times"></span>
              </button>
          </div>
          <div class="modal-body">
              <span>{{message}}</span>
          </div>
          <div class="modal-btn mb-3">
              <a style="cursor: pointer;" class="btn create mt-1 mb-2" (click)="checkTeamLimit();">NEW TEAM</a>
              <a class="btn join-existing" (click)="onJoinExistingTeam()" href="javascript:void(0)" role="button">JOIN EXISTING TEAM</a>
          </div>
          </div>
      </div>
  </div>
</div>

<div class="add-team-modal">
    <div class="modal fade" id="teamLimitModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
              <span class="modal-title">Team Limit Reached</span>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" class="fa fa-times"></span>
              </button>
            </div>
            <div class="modal-container">
              <div class="modal-body" style="text-align: center; font-family: 'BourtonBase';">
                  <span style="font-size: 25px;">We're sorry, this game is at Capacity.<br/>Please check schedule for the next game, coming soon.</span>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>


<!-- Create team modal -->
<div class="createTeamModal">
  <div class="modal fade" id="createTeamModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
                <span class="modal-title" id="exampleModalLabel">NEW TEAM</span>
                <button class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" class="fa fa-times"></span>
                </button>
            </div>
            <div class="modal-body">
                <span>TYPE THE NAME OF YOUR TEAM</span>
            </div>
            <div class="inputarea mr-5 ml-5 mb-3">
                <input type="text" class="form-control text-center" placeholder="Team Name" [(ngModel)]="teamName" required>
                <span *ngFor="let error of errors.team_name" class="error-message">{{ error }}</span>
            </div>
            <div class="modal-btn mb-3">
                <a style="cursor: pointer;" class="btn confirmCreate mt-1 mb-2" data-toggle="modal" (click)="onCreateTeamClick()">CREATE</a>
            </div>
          </div>
      </div>
  </div>
</div>


<div class="confirmCreateModal">
  <div class="modal fade" id="confirmCreateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <button class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" class="fa fa-times"></span>
                  </button>
              </div>
              <div class="modal-container">
                  <div class="modal-body">
                      <span>OWN YOUR TEAM!!!</span>
                  </div>
                  <div class="modal-btn">
                      <a class="btn team-form mr-1" data-toggle="modal" data-target="#team-form-modal" (click)="addOpacity();">CLICK TO OWN</a>
                  </div>
                  <div class="link mt-2">
                      <a (click)="navigate()">NO THANK YOU</a>
                  </div>
                  <div class="modal-info">
                      <div class="info-header mb-2">
                          <span>BENEFITS OF OWNERSHIP</span>
                      </div>
                      <ul>
                          <li>1.  Ownership of Team Name</li>
                          <li>2.  Change Team Name</li>
                          <li>3.  Access to tournaments</li>
                          <li>4.  Access to special events</li>
                          <li>5.  Rewards and Stats</li>
                          <li>6.  Join our Trivia Community</li>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<div class="team-form-modal">
  <div class="modal fade" id="team-form-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <div class="modal-header"></div>
              <div class="modal-container">
                  <div class="modal-body">
                      <span>FILL IN THE FOLLOWING DETAILS TO OWN YOUR TEAM</span>
                  </div>
                  <div class="team-form mb-4">
                      <form (ngSubmit)="ownTeam()" [formGroup]="ownForm">
                          <div class="form-group">
                              <input type="text" class="form-control" placeholder="Fullname" formControlName="first_name">
                              <span *ngFor="let error of ownTeamErrors.first_name" class="error-message">{{ error }}</span>
                          </div>
                          <div class="form-group">
                              <input type="email" class="form-control" placeholder="Email Address" formControlName="email">
                              <span *ngFor="let error of ownTeamErrors.email" class="error-message">{{ error }}</span>
                          </div>
                          <div class="form-group">
                              <input type="text" class="form-control" placeholder="City" formControlName="city">
                              <span *ngFor="let error of ownTeamErrors.city" class="error-message">{{ error }}</span>
                          </div>
                          <div class="form-group">
                              <input type="password" class="form-control" placeholder="Password can be anything" formControlName="password">
                              <span *ngFor="let error of ownTeamErrors.password" class="error-message">{{ error }}</span>
                          </div>
                          <div class="form-btn">
                              <!-- <a style="cursor: pointer;" class="btn success mr-1" data-toggle="modal" data-target="#success-modal">CLICK TO OWN</a> -->
                              <button type="submit" style="cursor: pointer;" id="own-submit" class="btn success mr-1" data-toggle="modal">CLICK TO OWN</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>