import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {WorldLeaderboardService} from '../../../services/world-leaderboard.service'
import {PlayerSharedService} from '../../../services/player-shared.service';
import {WorldLeaders} from '../../../models/world-leaders';
import {WorldLeader} from '../../../models/world-leader';
import {Observable, fromEvent, of} from "rxjs";
import {PlayerUser} from '../../../models/player_user';
import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter
} from "rxjs/operators";
import {Router} from '@angular/router';
declare var jQuery: any;

@Component({
  selector: 'app-host-leaderboard',
  templateUrl: './host-leaderboard.component.html',
  styleUrls: ['./host-leaderboard.component.css']
})
export class HostLeaderboardComponent implements OnInit {
  @ViewChild('searchTeamInput', {static: true}) searchTeamInput: ElementRef;
  isSearching:boolean;
  public user: PlayerUser;
  public leaderboard: Array<WorldLeader>;
  public leaderboard_tmp: Array<WorldLeader>;
  public tournamentName: string = 'DEFAULT';
  public filter: any;
  defaultImage: string = "assets/img/location.png";
  public loader: boolean;
  public tournamentID: number;
  public timeFilterValues: Array<any> = [['Week','1w'], ['Month', '1m'], ['3 Months', '3m'], ['Year', '1y']]
  public timeFilterValue: string = 'all';
  public stateFilterValue: string = '';
  public states: Array<any>;
  public hostName: string;
  public registeredPlayers: number;
  public unRegisteredPlayers: number;
  public registeredTeams: number;
  public unRegisteredTeams: number;
  public toggleSwitch: boolean = true;

  constructor(private router: Router, private route: ActivatedRoute, private _leaderboardServie: WorldLeaderboardService, private _sharedService: PlayerSharedService) {
    this.user = JSON.parse(localStorage.getItem('player_user'));
    this.isSearching = false;
   }

  ngOnInit() {
    jQuery('body').removeClass('modal-open');
    jQuery('.modal-backdrop').remove();
    this.loader = true;
    this.getHostLeaderBoard(this.timeFilterValue, this.stateFilterValue).subscribe(leaderboard => {
      this.loader = false;
      this.leaderboard = leaderboard.results.sort(this.sortResponse('points'));
      this.leaderboard_tmp = this.leaderboard;
      this._leaderboardServie.getStates().subscribe(states => {
          this.states = states;
      })
    });

    fromEvent(this.searchTeamInput.nativeElement, 'keyup').pipe(
      // get value
      map((event:any) => {
        if (event.target.value === ''){
            this.leaderboard = this.leaderboard_tmp;
        }
        return event.target.value;
      }),
      // if character length greater then 3
      filter(res => res.length > 3),
      // Time in milliseconds between key events
      debounceTime(100),
      // If previous query is diffent from current
      distinctUntilChanged()).subscribe((text: string) => {
        this.loader = true;
        this.getSearchedTeamWorld(text, this.timeFilterValue, this.stateFilterValue).subscribe(leaderboard => {
          this.loader = false;
          this.leaderboard = leaderboard.results.sort(this.sortResponse('points'));
        }, error => {
          console.log(error)
        })
      })
  }

  sortResponse(prop) {
    return function(a, b) {
      if (a[prop] < b[prop]) {
        return 1;
      } else if (a[prop] > b[prop]) {
        return -1;
      }
      return 0;
    }
  }

  getHostLeaderBoard(timeFilter, stateFilter): Observable<WorldLeaders>{
    return this._leaderboardServie.getHostLeaderBoard(timeFilter, stateFilter, this.toggleSwitch);
}

getSearchedTeamWorld(term: string, timeFilter, stateFilter){
  if (term === ''){
    return of([]);
  }
  return this._leaderboardServie.getSearchedHostLeaderBoard(term, timeFilter, stateFilter, this.toggleSwitch);
}

filterTime(filterVal: any) {
  this.loader = true;
  this.timeFilterValue = filterVal;
  this.getHostLeaderBoard(this.timeFilterValue, this.stateFilterValue).subscribe(leaderboard => {
    this.loader = false;
    this.leaderboard = leaderboard.results.sort(this.sortResponse('points'));
    for (let i=0; i<this.leaderboard.length; i++){
      this.leaderboard[i]['rank'] = i+1;
    }
    this.leaderboard_tmp = this.leaderboard;
  });
}

filterState(filterState: any){
  this.loader = true;
  this.stateFilterValue = filterState;
  this.getHostLeaderBoard(this.timeFilterValue, this.stateFilterValue).subscribe(leaderboard => {
    this.loader = false;
    this.leaderboard = leaderboard.results.sort(this.sortResponse('points'));
    for (let i=0; i<this.leaderboard.length; i++){
      this.leaderboard[i]['rank'] = i+1;
    }
    this.leaderboard_tmp = this.leaderboard;
  });
}

// switchLeaderboard(){
//   this.router.navigate(['/tournament-leaderboard'], {replaceUrl:true});
// }

addOpacity() {
  jQuery(document).ready(function() {
    jQuery('.modal.fade').css('opacity', 1);
    jQuery('.modal.fade').addClass('show');
    let cls = document.getElementsByClassName("modal-backdrop").length;
    if (cls>0) {
      jQuery('div.modal-backdrop').addClass('show');
      jQuery('div.modal-backdrop').css('opacity', 0.8);
    }
  });
}

getHostStates(hostID): Observable<any>{
  return this._leaderboardServie.getHostState(hostID, this.toggleSwitch);
}

showHostReport(hostID, hostName){
  this.hostName = hostName;
  this.loader = true;
  this.getHostStates(hostID).subscribe(state => {
    this.loader = false;
    this.registeredPlayers = state.registered_players;
    this.unRegisteredPlayers = state.unregistered_players;
    this.registeredTeams = state.registered_teams;
    this.unRegisteredTeams = state.unregistered_teams;
    this.addOpacity();
  });
}

onToggleChange(event: any){
  this.router.navigate(['/host/host-leaderboard-all'], {replaceUrl:true});
}

}


