import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import {Tournaments} from '../models/tournaments'
import {PlayerSharedService} from '../services/player-shared.service'
import {map} from 'rxjs/operators'
import { UtilsClass } from '../components/utils';


@Injectable({
  providedIn: 'root'
})
export class TournamentService {
  // private token: string;

  constructor(private _utils: UtilsClass, private _http: HttpClient, private _sharedService: PlayerSharedService) { 
    // this.token = _sharedService.getOption('player_token')
  }
  // getHeaders(){
  //   let headers = {'Content-Type': 'application/json'}
  //   if (this.token){
  //     headers['Authorization'] = 'Bearer ' + this.token
  //   }
  //   return headers
  // }
  getTournaments(token): Observable<Tournaments>{
    return this._http.get<Tournaments>(environment.BASE_API_URL + 'tournaments/', { headers: this._utils.getHeaders(token) })
    .pipe(
      map(result => 
        result)
      );
  }
}
