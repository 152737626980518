<link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
    <div class="title-container-2 mt-3">
        <div class="page-t">
            <div class="page-title" style="margin: auto;">
                <b>GO TO <span>TRIVTRAK.COM</span> TO <span>JOIN</span> OR <span>CREATE YOUR TEAM</span></b>
            </div>
        </div>
    </div>
<div class="card-container">
    <mat-card class="center-card answer-sheet-card" *ngIf="ready">
        <mat-card-title>
            Round {{round}} Answer Sheet
        </mat-card-title>
        <mat-card-subtitle>
            Playing For {{player.my_team.name}}: {{score}}&nbsp;Points
        </mat-card-subtitle>
        <mat-card-content>
            <div id="AnswerSheet" *ngIf="answerSheet.roundType === 0">
                <form [formGroup]="answerForm" novalidate>
                    <div>
                        <table formArrayName="questions" class="center-table">
                            <tr *ngFor="let q of qs['controls']; let i = index;" class="form-group" [formGroupName]="i">
                                <td class="qlabel">
                                    <mat-label>Q&nbsp;{{i+1}}:</mat-label>
                                </td>
                                <td class="">
                                    <mat-button-toggle-group fxLayout="row" formControlName="answer">
                                        <mat-button-toggle fxFlex="{{sizing}}" *ngFor="let ans of answerOptions" [value]="ans">
                                            {{getAns(ans)}}
                                        </mat-button-toggle>
                                    </mat-button-toggle-group>
                                </td>
                            </tr>
                        </table>
                    </div>
                </form>
            </div>
            <div id="WagerSheet" *ngIf="answerSheet.roundType === 1">
                <form [formGroup]="answerForm" novalidate>
                    <div formArrayName="questions">
                        <div *ngFor="let q of qs['controls']; let i = index;" class="form-group" [formGroupName]="i">
                            <mat-form-field fxLayoutAlign="center center">
                                <mat-label>Wager</mat-label>
                                <input matInput type='number' formControlName="points">
                            </mat-form-field>
                            <!--|| !qs.controls[i].get('points').touched-->
                            <button class ="btn btn-large mt-2"
                                (click)="submit(answerForm.value)"
                                *ngIf="answerSheet"
                                [disabled]="wagerSubmitted || qs.controls[i].get('points').invalid">
                                    Submit Your Team's Wager
                            </button>
                            <hr>
                            <mat-label fxLayoutAlign="center center">What is your Final Answer?</mat-label>
                            <mat-button-toggle-group fxLayout="row" formControlName="answer">
                                <mat-button-toggle fxFlex="{{sizing}}" *ngFor="let ans of answerOptions" disabled="{{!wagerSubmitted}}" [value]="ans">
                                    {{getAns(ans)}}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>
                </form>
            </div>
            <div id="TextAnswerSheet" *ngIf="answerSheet.roundType === 2">
                <form [formGroup]="answerForm" novalidate>
                    <div formArrayName="textAnswers" class="center-table">
                        <div *ngFor="let q of textQs['controls']; let i = index;" class="form-group" [formGroupName]="i">
                            <mat-form-field fxLayoutAlign="center center">
                                <mat-label>Q&nbsp;{{i+1}}:</mat-label>
                                <input matInput formControlName="response">
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </div>
            <div id="TextWagerSheet" *ngIf="answerSheet.roundType === 3">
                <form [formGroup]="answerForm" novalidate>
                    <div formArrayName="textAnswers">
                        <div *ngFor="let q of textQs['controls']; let i = index;" class="form-group" [formGroupName]="i">
                            <mat-form-field fxLayoutAlign="center center">
                                <mat-label>Wager</mat-label>
                                <input matInput type='number' formControlName="points">
                            </mat-form-field>
                            <button class ="btn btn-large mt-2"
                                (click)="submit(answerForm.value)"
                                *ngIf="answerSheet"
                                [disabled]="wagerSubmitted || textQs.controls[i].get('points').invalid">
                                    Submit Your Team's Wager
                            </button>
                            <hr>
                            <mat-label fxLayoutAlign="center center">What is your Final Answer?</mat-label>
                            <mat-form-field fxLayoutAlign="center center">
                                <input matInput formControlName="response">
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <!--(click)="submit(answerForm.value)" -->
            <button class ="btn btn-large mt-2"
                type="submit"
                (click)="confirmSubmit()"
                *ngIf="answerSheet"
                [disabled]="!wagerSubmitted && (answerSheet.round === 1 || answerSheet.roundType === 3)">
                    Submit for Your Team
            </button>
        </mat-card-actions>
    </mat-card>
</div>
