import { Component, OnInit } from '@angular/core';
import {TournamentService} from '../../../services/tournament.service';
import {Tournaments} from '../../../models/tournaments';
import {Observable} from "rxjs";
import {Tournament} from '../../../models/tournament'
import {environment} from '../../../../environments/environment';
import {PlayerSharedService} from '../../../services/player-shared.service';
import {ResultsService} from '../../../services/results.service';
import {PlayerUser} from '../../../models/player_user'
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActiveGameVenue} from '../../../models/active_game_venue'
declare var jQuery:any;

@Component({
  selector: 'app-tournaments',
  templateUrl: './tournaments.component.html',
  styleUrls: ['./tournaments.component.css']
})
export class TournamentsComponent implements OnInit {
  public tournaments: Array<Tournament>;
  public myActiveTournaments: Array<Tournament> = [];
  public defaultPastLeaderBoard: Array<Tournament> = [];
  public allTournaments: Array<Tournament> = [];
  public pastTournaments: Array<Tournament> = [];
  public user: PlayerUser;
  loader: boolean;
  selectedTournament: any = [];
  token: string;
  successMsg: boolean;
  errMsg: boolean;
  public tournReqForm: FormGroup;
  venues: Array<ActiveGameVenue>;
  tournReqErrors: any = {venue:[], email:[], comment:[]};

  constructor(private _resultService: ResultsService, private _tournamentService: TournamentService, private _sharedService: PlayerSharedService) { 
    this.user = JSON.parse(localStorage.getItem('player_user'));
    this.token = localStorage.getItem('player_token')
  }

  ngOnInit() {
    this.loader = true;
    this.getTournaments(this.token).subscribe(tournaments => {
      this.loader = false;
      this.tournaments = tournaments.results;
      this.partTournaments();
    });
    this.tournamentRequestForm();
    this._resultService.getActiveGameVenues(this.token).subscribe(result =>{
      this.venues = result;
    })
  }

  tournamentRequestForm(){
    this.tournReqForm = new FormGroup({
      venue: new FormControl('',[
        Validators.required
      ]),
      email: new FormControl('',[
        Validators.required
      ]),
      comment: new FormControl(''),
    })
  }

  partTournaments(): void{
    if (this.tournaments != null && this.tournaments.length > 0){
      for(let i=0; i<this.tournaments.length; i++){
        let tournament = this.tournaments[i];
        if(this.tournaments[i].image != null && !this.tournaments[i].image.startsWith(environment.BASE_API_URL)){
          this.tournaments[i].image = environment.BASE_API_URL + this.tournaments[i].image;
        }
        if (this.user != null && this.user.email != null && this.user.email.trim().length > 0) {
          //User teams checkin and live games(Tournament)
          //their teams has selected itself.
          if ( this.user.my_team && (tournament.venues.filter(x => x.id == this.user.my_team.home_venue).length)) {
            if ((tournament.status == 1) || this.tournaments[i].is_default) {
              //Uer active tournaments.
              this.myActiveTournaments.push(tournament);
              } 
            else if (tournament.status == 0) {
              //User teams not checkin and upcoming or live games(Tournament)
              // UPCOMING TOURNAMENTS
              this.allTournaments.push(tournament);
              } 
            else if (tournament.status == -1) {
              //User teams checkin and  past games(Tournament)
              // COMPLETED TOURNAMENTS
              this.pastTournaments.push(tournament);
              }
          } else {
            if (this.tournaments[i].is_default && !(/\d/.test(this.tournaments[i].name))) {
              this.myActiveTournaments.push(tournament);
            }
            else if (this.tournaments[i].is_default && (/\d/.test(this.tournaments[i].name))){
              this.defaultPastLeaderBoard.push(tournament)
            }
          }
        }
        else {
          if (this.tournaments[i].is_default && !(/\d/.test(this.tournaments[i].name))) {
            this.myActiveTournaments.push(tournament);
          }
          else if (this.tournaments[i].is_default && (/\d/.test(this.tournaments[i].name))){
            this.defaultPastLeaderBoard.push(tournament)
          }
        }
      }
    }
  }

  onStartTournamentRequest() {
    let data = this.tournReqForm.value;
    this._sharedService.requestTournamentService(this.token, data).subscribe(result => {
      console.log(result)
      delete this.errMsg
      jQuery('#tourn-request-modal').modal('toggle');
      this.successMsg = true;
    },
    error => {
      this.tournReqErrors = error;
      // this.errMsg = true;
      delete this.successMsg;
      // jQuery('#tourn-request-modal').modal('toggle');    
    })
  }

  tournamentInfo(tournament): void{
      this.selectedTournament = tournament;
      this.addOpacity();
  }

  getTournaments(token): Observable<Tournaments>{
    return this._tournamentService.getTournaments(token);
  }

  addOpacity() {
    jQuery(document).ready(function() {
      jQuery('.modal.fade').css('opacity', 1);
      jQuery('.modal.fade').addClass('show');  
      let cls = document.getElementsByClassName("modal-backdrop").length;
      if (cls>0) {
        jQuery('div.modal-backdrop').addClass('show');  
        jQuery('div.modal-backdrop').css('opacity', 0.8);  
      }
    });
  }

}
