import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PlayerSharedService} from '../../services/player-shared.service'
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {PlayerUser} from '../../models/player_user'
declare var jQuery:any;

@Component({
  selector: 'app-confirm-user',
  templateUrl: './confirm-user.component.html',
  styleUrls: ['./confirm-user.component.css']
})
export class ConfirmUserComponent implements OnInit {
  message: string;
  buttonText: string;
  status: boolean;
  profile_type: string;
  token: string;
  messageHeader: string;
  selectedVenue: any = {};
  player: PlayerUser;
  public loader: boolean = false;

  constructor(private cookieService: CookieService, private route: Router, private _sharedService: PlayerSharedService, private activeRoute: ActivatedRoute) {
    this.selectedVenue = JSON.parse(localStorage.getItem('venue'));
   }

  ngOnInit() {
    this.activeRoute.params.subscribe(params => {
      if (params['profile_type'] == 'host' || params['profile_type']=='player'){
          this.loader = true;
          this._sharedService.confirmUser(params['token']).subscribe(data => {
            if (params['profile_type'] == 'host'){
              console.log('host confirmation')
              this.profile_type = 'host'
              let date = new Date();
              let time = date.getTime();
              let hours = 1;
              date.setTime(time + (hours * 60 * 60 * 1000));
              let oneDay = 24 * 60 * 60 * 1000;
              let firstDate = new Date();
              let secondDate = new Date(date);
              let cookieDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
  
              localStorage.clear();
              sessionStorage.clear();
              this.cookieService.deleteAll();
              this.cookieService.set('TrivTrak_USERNAME', data.email, cookieDays, '/');
              this.cookieService.set('TrivTrak_TOKEN', params['token'], cookieDays, '/');
              this.cookieService.set('TrivTrak_USER', JSON.stringify(data), cookieDays, '/');
              localStorage.setItem('TrivTrak_USERNAME', data.email);
              localStorage.setItem('TrivTrak_TOKEN', params['token']);
              localStorage.setItem('TrivTrak_USER', JSON.stringify(data));
              sessionStorage.setItem('TrivTrak_USERNAME', data.email);
              sessionStorage.setItem('TrivTrak_TOKEN', params['token']);
              sessionStorage.setItem('TrivTrak_USER', JSON.stringify(data));
              this.status = true
              this.message = "Your details have been verified."
              this.buttonText = "Click to Proceed"
              this.messageHeader = "Congratulations"
              this.loader = false;
              jQuery('#confirmUserModal').modal('toggle');
              this.addOpacity();
            } else {
              console.log('player confirmation')
              localStorage.setItem('player_user', JSON.stringify(data));
              localStorage.setItem('player_token', params['token']);
              this.token = params['token'];
              this.player = data;
              this.profile_type = 'player';
              this.status = true;
              // if (this.player.id == this.player.my_team.owner){
              //   this.message = "Congrats you're now a Bonafide Team Owner! Go to your player profile to add a team pic and invite other players.";
              // }
              // else {
              //   this.message = "Congrats! You are now a Bonafide Player. Good luck tonight!";
              // }
              // this.buttonText = "View Profile";
              // this.messageHeader = "Congratulations";
              this.proceed();
            } 
          }, error => {
            this.loader = false;
            console.log(error)
            this.status = false
            this.profile_type = 'any'
              this.message = "Your details could not be verified."
              this.buttonText = "OK"
              this.messageHeader = "Sorry"
              jQuery('#confirmUserModal').modal('toggle');
              this.addOpacity();
          })

    } else {
            this.loader = false;
            console.log('Invalid confirmation')
            this.message = "Your are neither host nor player. Your details could not be verified"
            this.status = false
            this.buttonText = "OK"
            this.messageHeader = "Sorry"
            this.profile_type = 'any'
            jQuery('#confirmUserModal').modal('toggle');
            this.addOpacity();
    }
    // jQuery('#confirmUserModal').modal('toggle');
    // this.addOpacity();
    })
  }

  proceed(){
    if (this.status){
      if (this.profile_type == 'player'){
        if(this.selectedVenue){
          this._sharedService.getVenueDetailService(this.selectedVenue.id).subscribe(venueData => {
            this.selectedVenue = venueData;
              if (this.selectedVenue.active_game){
                localStorage.setItem('venue', JSON.stringify(venueData));
                localStorage.setItem('player_user_logout_status', JSON.stringify(false));
                let game_id = this.selectedVenue.active_game;
                if (game_id >0 ){
                    this._sharedService.CheckInUser(game_id, {'team_id': this.player.my_team.id}, this.token)
                      .subscribe(data => {
                        this.player = data.user;
                        this.token = data.token.access;
                        console.log(data.token);
                        localStorage.setItem('player_token', data.token.access);
                        localStorage.setItem('player_user', JSON.stringify(data.user));
                        localStorage.setItem('game', JSON.stringify(data.game));
                        this.loader = false;
                        this.route.navigate(['/team-profile'], {replaceUrl:true});
                        // this.route.navigate(['/score-card', this.selectedVenue.active_game], {replaceUrl:true});
                      });
                }
                // this._sharedService.changeMessage("Venue Set.........")
            } else {
              // for not active locations
              this.selectedVenue = venueData;
              localStorage.setItem('venue', JSON.stringify(venueData));
              // jQuery('#confirmUserModal').modal('toggle');
              this.loader = false;
              this.route.navigate(['/team-profile'], {replaceUrl:true});
              // this.route.navigate(['/'], {replaceUrl:true});
            }
          });
        } else {
          // jQuery('#confirmUserModal').modal('toggle');
          this.loader = false;
          this.route.navigate(['/'], {replaceUrl:true});
        }
      } else if (this.profile_type == 'host') {
        jQuery('#confirmUserModal').modal('toggle');
        this.route.navigate(['/host/profile'], {replaceUrl:true})
      } else {
        jQuery('#confirmUserModal').modal('toggle');
        this.route.navigate(['/'], {replaceUrl:true})
      }
      
    }
    else {
      this.loader = false;
      jQuery('#confirmUserModal').modal('toggle');
      this.route.navigate(['/'], {replaceUrl:true})
    }

  }

  joinTheTournament(){
    localStorage.setItem('player_user_logout_status', JSON.stringify(false));
    let game_id = this.selectedVenue.active_game;
    if (game_id >0 ){
        this._sharedService.CheckInUser(game_id, {'team_id': this.player.my_team.id}, this.token)
          .subscribe(data => {
            this.player = data.user;
            this.token = data.token.access;
            localStorage.setItem('player_token', data.token.access);
            localStorage.setItem('refresh_token', data.token.refresh);
            localStorage.setItem('player_user', JSON.stringify(data.user));
            localStorage.setItem('game', JSON.stringify(data.game));
            var message = 'Congrats, you joined the game with team "' + this.player.my_team.name + '". Click ok to go to scoreboard. Next time you check your email look for a Bonifcation Verification email, click confirm and you will be BONAFIDE!';
            localStorage.setItem('congrats-mesg', message);
            this.route.navigateByUrl('/join-team-congratulations');
          });
    }
  }

  addOpacity() {
    jQuery(document).ready(function() {
      jQuery('.modal.fade').css('opacity', 1);
      jQuery('.modal.fade').addClass('show');  
      let cls = document.getElementsByClassName("modal-backdrop").length;
      if (cls>0) {
        jQuery('div.modal-backdrop').addClass('show');  
        jQuery('div.modal-backdrop').css('opacity', 0.8);  
      }
    });
  }
}
