import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HostService} from '../../../services/host.service';
declare var jQuery:any;
@Component({
  selector: 'app-view-team-profile',
  templateUrl: './view-team-profile.component.html',
  styleUrls: ['./view-team-profile.component.css']
})
export class ViewTeamProfileComponent implements OnInit {

  public teamId: any;
  public venueId: any;
  public rank: any;
  public result: any;
  public loader: boolean;


  constructor(private route: ActivatedRoute,
              private hostService: HostService,
              private router: Router) {
    this.route.params.subscribe(result => {
      this.teamId = result.id;
      this.venueId = result.venue;
      this.rank = result.rank;
    });
  }

  ngOnInit() {
    this.loader = true;
    this.getTeamProfile();
  }

  getTeamProfile() {
    this.hostService.getTeamProfile(this.teamId,this.venueId)
      .subscribe((result) => {
        this.result = result;
        this.loader = false;
      });
  }

  addOpacity() {
    jQuery(document).ready(function() {
      jQuery('.modal.fade').css('opacity', 1);
      jQuery('.modal.fade').addClass('show');  
      let cls = document.getElementsByClassName("modal-backdrop").length;
      if (cls>0) {
        jQuery('div.modal-backdrop').addClass('show');  
        jQuery('div.modal-backdrop').css('opacity', 0.8);  
      }
    });
  }
}
