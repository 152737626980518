import { Component, OnInit, OnDestroy } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HostService} from '../../../services/host.service';
import {WebsocketProviderService} from '../../../services/websocket-provider.service';
import {environment} from '../../../../environments/environment';
import {PlayerSharedService} from '../../../services/player-shared.service';
import {PlayerUser} from '../../../models/player_user';

declare var jQuery: any;
@Component({
  selector: 'app-presentation-leaderboard',
  templateUrl: './presentation-leaderboard.component.html',
  styleUrls: ['./presentation-leaderboard.component.css']
})
export class PresentationLeaderboardComponent implements OnInit, OnDestroy {

  public gameId: any;
  public venueDetails: any;
  public game: any;
  public gameTeams: any;
  public term: any;
  public successMsg: any;
  public errorMsg: any;
  public gameTeams1: any;
  public gameTeams2: any;
  public loader: boolean;
  public tournamentSponser: Array<any>;
  public host: PlayerUser;
  public defaultLogo: string = 'assets/img/logo1.png';
  localstorage_obj: any;

  constructor(
    private route: ActivatedRoute,
    private hostService: HostService,
    private wsService: WebsocketProviderService,
    private _sharedService: PlayerSharedService
  ) {
    this.loader = true;
    this.route.params.subscribe(result => this.gameId = result.id);
    this.localstorage_obj = localStorage;

    

  }

  ngOnInit() {
    jQuery('.main-header').hide();
    jQuery('body').css('background-color', '#FFF');
    this.host = JSON.parse(localStorage.getItem('TrivTrak_USER'));
    // jQuery('#TEST')[0].addEventListener('fullscreenchange', this.exitHandler(), false);
    jQuery(document).bind('fullscreenchange webkitfullscreenchange mozfullscreenchange msfullscreenchange', function (e) {
      var fullscreenElement = document.fullscreenElement;
      
      if (!fullscreenElement) {
        jQuery('#dark').show();
        jQuery('#full').show();
        jQuery('#light').hide();
        jQuery('.row').removeClass('bgblack');
        jQuery('.row').addClass('bgwhite');
        jQuery('.table').css('color', '#000');
        jQuery('body').css('background-color', '#FFF');
        console.log('Exiting full-screen mode...');
      } else {
        console.log('Entering full-screen mode...');
      }
    });

    this.wsService.connect(this.gameId)
      .subscribe((result) => {
        if(result.msg_type == "update" || result.msg_type == "status"){
          this.gameData(); // scoreboard will literally flash on each update because of this. It's why we need a real scoreboard service.
        }
      });

    //this.gameData();
  }

  ngOnDestroy(): void {
    this.wsService.close();
  }

  showDark() {
    jQuery('#light').show();
    jQuery('#dark').hide();
    jQuery('#full').show();

    jQuery('.row').addClass('bgblack');
    jQuery('.row').removeClass('bgwhite');
    jQuery('.table').css('color', '#FFF');
    jQuery('body').css('background-color', '#000');
  }

  showLight() {
    jQuery('#dark').show();
    jQuery('#light').hide();
    jQuery('.row').removeClass('bgblack');
    jQuery('.row').addClass('bgwhite');
    jQuery('.table').css('color', '#000');
    jQuery('body').css('background-color', '#FFF');
  }

  gameData() {
    this.hostService.getGameData(this.gameId)
      .subscribe((result) => {
        this.tournamentSponser = result.tournament_sponsers;
        this.game = result;
        this.venueDetails = result.venue;
        this.hostService.getGameTeams(this.gameId)
          .subscribe(result => {
            this.gameTeams = result.sort(this.hostService.sortResponse('total_score'));
            let team_ids = this.gameTeams.map(t => t.id);
            this._sharedService.getFlair(team_ids).subscribe( data => {
              data.forEach(flair => {
                this.gameTeams.find(t => t.id == flair.team_id).flair = flair;
              });
            });
            this.loadView();
            this.loader = false;
          });
      });
  }


  loadView() {
    jQuery(document).ready(function () {
      var cols = [];
      var trs = jQuery('.rank-table tr');
      var data =jQuery.each(trs , function(index, tr){
        jQuery.each(jQuery(tr).find('td').not(':first'), function(index, td){
          cols[index] = cols[index] || [];
          if(!Number.isNaN(parseFloat(jQuery(td).text()))) {
            cols[index].push(jQuery(td).text());
          } else {
            cols[index].push(0);
          }
        });
      });
      cols.forEach(function(col, index){
        var max = Math.max.apply(null,col);
        var min = Math.min.apply(null,col);
        jQuery('.rank-table tbody tr').find('td:eq('+(index+1)+')').each(function(i, td){
          if(max!==0 && parseFloat(jQuery(td).text())==max) {
            jQuery(td).children().addClass('max');
          }
        });
      });

      jQuery('.ttl').first().addClass('top-score');
    });
  }
  showFull(){
    jQuery('#light').hide();
    jQuery('#dark').hide();
    jQuery('#full').hide();
    // jQuery('#TEST')[0].addEventListener('webkitExitFullscreen', this.exitHandler());
    // jQuery('#TEST')[0].addEventListener('webkitfullscreenchange', this.exitHandler());
    // jQuery('#TEST')[0].addEventListener('fullscreenchange', this.exitHandler());
    jQuery('.row').addClass('bgblack');
    jQuery('.row').removeClass('bgwhite');
    jQuery('.table').css('color', '#FFF');
    jQuery('body').css('background-color', '#000');
    if (jQuery('#TEST')[0].mozRequestFullScreen){
      jQuery('#TEST')[0].mozRequestFullScreen();
    } else if (jQuery('#TEST')[0].webkitRequestFullscreen){
      jQuery('#TEST')[0].webkitRequestFullscreen();
    } else if (jQuery('#TEST')[0].msRequestFullscreen){
      jQuery('#TEST')[0].msRequestFullscreen();
    }
    // jQuery('#full').hide();
    // jQuery(document).on('keydown', function(event){
    //   if(event.key == 'Escape'){
    //     alert('dffg');
    //   }
    // });
  }

  exitHandler(){
    if (jQuery('#TEST')[0].webkitIsFullScreen || jQuery('#TEST')[0].mozFullScreen || jQuery('#TEST')[0].msFullscreenElement != null)
    {
     console.log('fffgfggg')
    }
  }
}
