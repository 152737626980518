import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HostService} from '../../../services/host.service';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {HostLoginService} from '../../../services/host-login.service';
import {ErrorMsg} from '../../../models/error';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  public resetPasswordForm: FormGroup;
  public successMsg: any;
  public errorMsg: any;
  public token: any;
  public type: string;
  public loader: boolean;
  constructor(private hostService: HostService,
              private hostLoginService: HostLoginService,
              private router: Router,
              private route: ActivatedRoute) {
    this.route.queryParams.subscribe((result) => {
      this.token = result['token'];
      this.type = result['type'];
    });

  }

  ngOnInit() {
    this.createResetPasswordForm();
  }

  createResetPasswordForm() {
    this.resetPasswordForm = new FormGroup({
      password: new FormControl('',[
        Validators.required
      ]),
      re_password: new FormControl('',[
        Validators.required
      ])
    })
  }

  resetPassword() {
    let data = JSON.stringify(this.resetPasswordForm.value)
    this.hostLoginService.updatePassword(data, this.token)
      .subscribe(
        (result) => {
          if(result.success) {
            this.loader = false;
            delete this.errorMsg;
            this.successMsg = 'Password Reset Successfully. Please Login with new password.';
          }
        },
        (error: ErrorMsg ) => {
          if(error.re_password) {
            this.errorMsg = error.re_password;
          }
          delete this.successMsg;
          this.loader = false;
        }
      );
  }
}
