import { Component, OnInit } from '@angular/core';
import {PlayerSharedService} from '../../../services/player-shared.service'
import {PlayerUser} from '../../../models/player_user'
import { Observable} from 'rxjs';
import {Router} from '@angular/router';
import {
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import {OwnTeamError} from '../../../models/own-team-error-model'
declare var jQuery:any;

@Component({
  selector: 'app-create-change-team',
  templateUrl: './create-change-team.component.html',
  styleUrls: ['./create-change-team.component.css']
})
export class CreateChangeTeamComponent implements OnInit {
  teamName: string;
  errors: any = [];
  player: PlayerUser;
  token: string;
  ownTeamErrors: OwnTeamError = {first_name:[], email:[], city:[], password:[]};
  ownForm: FormGroup;
  player_user_logout_status: boolean;
  selectedVenue: any = {};

  constructor(private router: Router, private _sharedService: PlayerSharedService) {
    this.token = localStorage.getItem('player_token');
    this.player = JSON.parse(localStorage.getItem('player_user'));
    this.player_user_logout_status =  JSON.parse(localStorage.getItem('player_user_logout_status'));
    this.selectedVenue = JSON.parse(localStorage.getItem('venue'));
    this.ownTeamForm();
   }

  ngOnInit() {
    if (this.token != null){
      this.getCheckinPlayer(this.token).subscribe(data => {
        this.player = data;
        // this._sharedService.player_user = data
        localStorage.setItem('player_user', JSON.stringify(data));
      })
    }
    if (this.selectedVenue){
      if (this.player && this.player.my_team && this.player.email != null && this.player.email.trim().length > 0 &&
          this.token && this.player_user_logout_status && this.player_user_logout_status == true){
            // alert("Already a bonafide member");
            this._sharedService.checkPlyerPlayGameService(this.player.id, this.token)
              .subscribe(data => {
                if (data.play){
                  this.selectVenue();
                } else{
                  console.log("Offer change team prompt");
                  jQuery('#changeTeamModal').modal('toggle');
                }
              })
          }
      else{
        this.selectVenue();
      }
    } else {
      jQuery('#teamModal').modal('toggle');
    }

  }

  getCheckinPlayer(token): Observable<PlayerUser> {
    return this._sharedService.getCheckinPlayer(token);
  }

  onCreateTeamClick(){
    this._sharedService.CreateTeamService({'name': this.teamName}, this.token)
    .subscribe(data => {
      this.player = data.user;
      this.token = data.token.access;
      localStorage.setItem('player_token', data.token.access);
      localStorage.setItem('refresh_token', data.token.refresh);
      localStorage.setItem('player_user', JSON.stringify(data.user));
      localStorage.setItem('team_name', this.teamName);
      console.log(data.team_id)
      jQuery('#teamModal').modal('toggle');
      jQuery('#confirmCreateModal').modal('toggle');
     },
     error => {
       this.errors = error;
     })
  }

  navigate(){
    localStorage.setItem('bonafiedStatus', JSON.stringify(false));
    this.router.navigateByUrl('/locations');
  }

  ownTeamForm(){
    this.ownForm = new FormGroup({
      first_name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      city: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)])
    })
  }

  ownTeam(){
    // debugger;
    // let data = JSON.stringify(this.ownForm.value);
    this._sharedService.ownTeamService(this.player.my_team.id, this.ownForm.value, this.token)
      .subscribe(data => {
        this.player = data.user;
        localStorage.setItem('player_user', JSON.stringify(data.user));
        this.router.navigateByUrl('/locations')
       },
       error => {
         this.ownTeamErrors = error;
       })
  }

  joinTheTournament(){
    localStorage.setItem('player_user_logout_status', JSON.stringify(false));
    let game_id = this.selectedVenue.active_game;
    if (game_id >0 ){
        this._sharedService.CheckInUser(game_id, {'team_id': this.player.my_team.id}, this.token)
          .subscribe(data => {
            this.player = data.user;
            this.token = data.token.access;
            localStorage.setItem('player_token', data.token.access);
            localStorage.setItem('refresh_token', data.token.refresh);
            localStorage.setItem('player_user', JSON.stringify(data.user));
            localStorage.setItem('game', JSON.stringify(data.game));
            var message = 'Congrats, you joined the scoreboard with team "' + this.player.my_team.name + '". Click ok to go to scoreboard';
            localStorage.setItem('congrats-mesg', message);
            this.router.navigateByUrl('/join-team-congratulations');
          })
    }
  }

  selectVenue(){
    if(this.player && this.player.my_team && this.token){
      if (this.player.email != null && this.player.email.trim().length > 0) {
          //Register Player
          if (this.selectedVenue.is_register_player_check_in && this.selectedVenue.open_tournament){
            //Register player & already check ins the game.
            this.router.navigateByUrl('/join-game-confirm')
          } else {
            //Register player & new Check-ins to game.
            this.joinTheTournament();
          }
      } else {
            //not Bonafide Member.
            console.log("Has user team. SO no need to join the team directly and go to score-card....");
            this._sharedService.JoinTeamService(this.selectedVenue.active_game, {'team_id': this.player.my_team.id}, this.token)
              .subscribe(data => {
                this.player = data.user;
                this.token = data.token.access;
                localStorage.setItem('player_token', data.token.access);
                localStorage.setItem('refresh_token', data.token.refresh);
                localStorage.setItem('player_user', JSON.stringify(data.user));
                localStorage.setItem('game', JSON.stringify(data.game));
                var message = "Ok, you don't have Bonafide Member Access but you're ready to play!.";
                localStorage.setItem('congrats-mesg', message);
                this.router.navigateByUrl('/join-team-congratulations')
          })
        }
    }
  }

  changeTeam(){
    var message = "Ok, For '" + this.selectedVenue.name + "' location, You have the following options to change your team!.";
    localStorage.setItem('congrats-mesg', message);
    this.router.navigateByUrl('/change-team')
  }

}
