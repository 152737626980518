import { Component, OnInit } from '@angular/core';
import {PlayerSharedService} from '../../../services/player-shared.service'
import {PlayerUser} from '../../../models/player_user'
import { Observable } from 'rxjs';
import {ActiveGameVenue} from '../../../models/active_game_venue'
import {JoinTeamModel} from '../../../models/join-team-model'
import {OwnTeamError} from '../../../models/own-team-error-model'
import {AllTeams} from '../../../models/all-teams'
import {Router} from '@angular/router';
import { Location } from '@angular/common';
// import {LocationsComponent} from '../locations/locations.component'
import {
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
declare var jQuery:any;

@Component({
  selector: 'app-search-team',
  templateUrl: './search-team.component.html',
  styleUrls: ['./search-team.component.css'],
  // providers: [LocationsComponent]
})
export class SearchTeamComponent implements OnInit {
  token: string;
  player: PlayerUser;
  player_user_logout_status: boolean;
  selectedVenue: ActiveGameVenue;
  teams: any = [];
  filter: any;
  message: string;
  ownTeamErrors: OwnTeamError = {first_name:[], email:[], city:[], password:[]};
  ownForm: FormGroup
  errors: any = []
  public loader: boolean;
  public teamID: any;

  constructor(public location: Location, private _sharedService: PlayerSharedService, private router: Router) { 
    this.token = localStorage.getItem('player_token')
    // this.token = _sharedService.player_token;
    // this.player = _sharedService.player_user;
    // this.player_user_logout_status = _sharedService.player_user_logout_status;
    // this.selectedVenue = _sharedService.getOption('venue');
    this.player = JSON.parse(localStorage.getItem('player_user'));
    this.player_user_logout_status = JSON.parse(localStorage.getItem('player_user_logout_status'));
    this.selectedVenue = JSON.parse(localStorage.getItem('venue'));
    // console.log(JSON.parse(sessionStorage.getItem('venue')));
    // this._sharedService.currentMessage.subscribe(message => console.log(message))
    this.ownTeamForm();
  }

  ngOnInit() {
    jQuery('.modal-backdrop').remove();
    jQuery('body').removeClass('modal-open');
    jQuery('body').css({'padding-right': '0px'});
    this.loader = true;


    if (this.selectedVenue){
      this.getVenueTeams(this.selectedVenue.id).subscribe(data => {
        this.loader = false;
        this.teams = data;
      })
    }
    else{
      this.getAllTeams().subscribe(data => {
        this.loader = false;
        this.teams = data.results;
      })
    }
    }

    selectTeam(teamdID){
      this.teamID = teamdID;
      if (this.player && this.player.email != null && this.player.email.trim().length > 0 && 
            this.token){
              this.loader = true;
              if(this.selectedVenue.active_game){
                let fromChangeTeam = JSON.parse(localStorage.getItem('fromChangeTeam'));
                let dataJoin = null;
                if (fromChangeTeam){
                    dataJoin = {'team_id': this.teamID, 'team_change_create':true};
                } else {
                  dataJoin = {'team_id': this.teamID};
                }
                this._sharedService.checkTeanGameLimitService(this.selectedVenue.active_game, this.teamID).subscribe(data1 => {
                  if (data1.canJoin){
                      this._sharedService.JoinTeamService(this.selectedVenue.active_game, dataJoin, this.token)
                      .subscribe(data => {
                        this.player = data.user;
                        this.token = data.token.access;
                        localStorage.setItem('player_user', JSON.stringify(data.user));
                        localStorage.setItem('player_token', data.token.access);
                        localStorage.setItem('refresh_token', data.token.refresh);
                        // this._sharedService.player_user = this.player;
                        // this._sharedService.player_token = this.token;
                        localStorage.setItem('game', JSON.stringify(data.game));
                        localStorage.removeItem('fromChangeTeam');
                        if (this.selectedVenue.open_tournament) {
                          this.getVenue();
                        } else {
                          this.joinTheTournament();
                        }
                      })
                  } else {
                    this.loader = false;
                    jQuery('#teamLimitModal').modal('toggle');
                    this.addOpacity();
                  }
                })
                } else {
                  this._sharedService.JoinTeamWoGameService(this.teamID, this.token)
                    .subscribe(data => {
                      this.player = data.user;
                      this.token = data.token.access;
                      localStorage.setItem('player_user', JSON.stringify(data.user));
                      localStorage.setItem('player_token', data.token.access);
                      localStorage.setItem('refresh_token', data.token.refresh);
                      var message = "Ok, you joined a team at not live location";
                            localStorage.setItem('congrats-mesg', message);
                            this.router.navigateByUrl('/join-team-congratulations')
                    })
                }
            }
      else{
        if (this.selectedVenue.active_game){
          this.loader = true;
          this._sharedService.checkTeanGameLimitService(this.selectedVenue.active_game, this.teamID).subscribe(data1 => {
            if (data1.canJoin){
              this.loader = false;
              jQuery('#teamModal').modal('toggle');
              this.addOpacity();
            } else {
              this.loader = false;
              jQuery('#teamLimitModal').modal('toggle');
              this.addOpacity();
            }
          })
        } else {
          jQuery('#teamModal').modal('toggle');
          this.addOpacity();
        }
      }
    }

    joinTeam(){
      this.loader = true;
      if(this.selectedVenue.active_game){
        this._sharedService.JoinTeamService(this.selectedVenue.active_game, {'team_id': this.teamID}, this.token)
              .subscribe(data => {
                this.loader = false;
                this.player = data.user;
                this.token = data.token.access;
                localStorage.setItem('player_user', JSON.stringify(data.user));
                localStorage.setItem('player_token', data.token.access);
                localStorage.setItem('refresh_token', data.token.refresh);
                // this._sharedService.player_user = this.player;
                // this._sharedService.player_token = this.token;
                localStorage.setItem('game', JSON.stringify(data.game));
                // jQuery('#teamModal').modal('toggle');
                // this.addOpacity();
                this.navigate();
            })
        } else {
          this._sharedService.JoinTeamWoGameService(this.teamID, this.token)
            .subscribe(data => {
              this.loader = false
              this.player = data.user;
              this.token = data.token.access;
              localStorage.setItem('player_user', JSON.stringify(data.user));
              localStorage.setItem('player_token', data.token.access);
              localStorage.setItem('refresh_token', data.token.refresh);
              // jQuery('#teamModal').modal('toggle');
              // this.addOpacity();
              this.navigate();
            })
        }
    }

    addOpacity() {
      jQuery(document).ready(function() {
        jQuery('.modal.fade').css('opacity', 1);
        jQuery('.modal.fade').addClass('show');  
        let cls = document.getElementsByClassName("modal-backdrop").length;
        if (cls>0) {
          jQuery('div.modal-backdrop').addClass('show');  
          jQuery('div.modal-backdrop').css('opacity', 0.8);  
        }
      });
    }

    navigate(){
      if(this.selectedVenue.active_game){
        this.router.navigate(['/score-card', this.selectedVenue.active_game]);
      } else {
        var message = "Ok, you don't have Bonafide Member Access but you joined the team....";
        localStorage.setItem('congrats-mesg', message);
        this.router.navigate(['/join-team-congratulations'], {replaceUrl:true})
      }
    }

    getAllTeams(): Observable<AllTeams> {
      return this._sharedService.getTeams();
    }
  
    getVenueTeams(venue_id): Observable<any>{
      return this._sharedService.getVenueTeams(venue_id);
    }

    joinTheTournament(){
      localStorage.setItem('player_user_logout_status', JSON.stringify(false));
      let game_id = this.selectedVenue.active_game;
      if (game_id >0 ){
          this._sharedService.CheckInUser(game_id, {'team_id': this.player.my_team.id}, this.token)
            .subscribe(data => {
              this.player = data.user;
              this.token = data.token.access;
              localStorage.setItem('player_token', data.token.access);
              localStorage.setItem('refresh_token', data.token.refresh);
              localStorage.setItem('player_user', JSON.stringify(data.user));
              localStorage.setItem('game', JSON.stringify(data.game));
              if (this.player.id == this.player.my_team.owner && !this.player.profile.is_approved){
                var message = "You're almost there! Check your email to confirm your role as TEAM CAPTAIN of " + this.player.my_team.name;
              }
              else if (this.player.id == this.player.my_team.owner && this.player.profile.is_approved){
                var message = 'Congrats, you joined the game with team "' + this.player.my_team.name + '". You are the OFFICIAL OWNER of it. Good Luck Tonight!';
              } 
              else {
                var message = 'Congrats! You are now a Bonafide member of "' + this.player.my_team.name + '". Look for an email update about the benefits! Good Luck Tonight!';
              }
              localStorage.setItem('congrats-mesg', message);
              this.router.navigateByUrl('/join-team-congratulations');
            })
      }
    }

    ownTeamForm(){
      this.ownForm = new FormGroup({
        first_name: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        city: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required, Validators.minLength(6)])
      })
    }

    ownTeam(){
      // debugger;
      // let data = JSON.stringify(this.ownForm.value);
      this.loader = true;
      if(this.selectedVenue.active_game){
        this._sharedService.JoinTeamService(this.selectedVenue.active_game, {'team_id': this.teamID}, this.token)
              .subscribe(data => {
                this.player = data.user;
                this.token = data.token.access;
                localStorage.setItem('player_user', JSON.stringify(data.user));
                localStorage.setItem('player_token', data.token.access);
                localStorage.setItem('refresh_token', data.token.refresh);
                // this._sharedService.player_user = this.player;
                // this._sharedService.player_token = this.token;
                localStorage.setItem('game', JSON.stringify(data.game));
                // jQuery('#teamModal').modal('toggle');
                // this.addOpacity();
                this._sharedService.ownTeamService(this.player.my_team.id, this.ownForm.value, this.token)
                  .subscribe(data => {
                    this.loader = false;
                    this.player = data.user;
                    localStorage.setItem('player_user', JSON.stringify(data.user));
                    if (this.selectedVenue.open_tournament) {
                      this.getVenue();
                    } else {
                      this.joinTheTournament();
                    }
                  },
                  error => {
                    this.loader = false;
                    this.ownTeamErrors = error;
                  })
            })
        } else {
          this._sharedService.JoinTeamWoGameService(this.teamID, this.token)
            .subscribe(data => {
              this.player = data.user;
              this.token = data.token.access;
              localStorage.setItem('player_user', JSON.stringify(data.user));
              localStorage.setItem('player_token', data.token.access);
              localStorage.setItem('refresh_token', data.token.refresh);
              // jQuery('#teamModal').modal('toggle');
              // this.addOpacity();
              this._sharedService.ownTeamService(this.player.my_team.id, this.ownForm.value, this.token)
                  .subscribe(data => {
                    this.loader = false;
                    this.player = data.user;
                    localStorage.setItem('player_user', JSON.stringify(data.user));
                    var message = "Ok, you joined a team at not live location";
                    localStorage.setItem('congrats-mesg', message);
                    this.router.navigateByUrl('/join-team-congratulations')
                  },
                  error => {
                    this.loader = false;
                    this.ownTeamErrors = error;
                  })
            })
        }

      // this._sharedService.ownTeamService(this.player.my_team.id, this.ownForm.value, this.token)
      //   .subscribe(data => {
      //     this.loader = false;
      //     this.player = data.user;
      //     localStorage.setItem('player_user', JSON.stringify(data.user));
      //     if (this.selectedVenue.active_game){
      //         if (this.selectedVenue.open_tournament) {
      //           this.getVenue();
      //         } else {
      //           this.joinTheTournament();
      //         }
      //       } else {
      //         var message = "Ok, you joined a team at not live location";
      //         localStorage.setItem('congrats-mesg', message);
      //         this.router.navigateByUrl('/join-team-congratulations')
      //       }
      //    },
      //    error => {
      //      this.loader = false;
      //      this.ownTeamErrors = error;
      //    })
    }

    getVenue(){
      this._sharedService.getVenues(this.selectedVenue.id, this.token).subscribe(data => {
        let temp_venue = data;
        if (!temp_venue.is_register_player_check_in && temp_venue.open_tournament){
          this.router.navigateByUrl('/join-game-confirm')
        } else {
          this.joinTheTournament();
        }
      }, 
      error => {
          this.errors = error;
      });
    }
  }

  


