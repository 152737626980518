import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {retry, catchError} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import { Observable, throwError, Observer } from 'rxjs';
import {PlayerUser} from '../models/player_user';
import {SimpleJWTToken} from '../models/token-payload';
import {map} from 'rxjs/operators'
import { UtilsClass } from '../components/utils';
import {AllTeams} from '../models/all-teams';
import {Flair} from '../models/team-game-score';
import {JoinTeamModel, JoinTeamWoGameModel} from '../models/join-team-model'
import {CheckInUserModel} from '../models/check-in-user-model'
import {ActiveGameVenues} from '../models/active_game_venues'
import {ActiveGameVenue} from '../models/active_game_venue';
import {BannerAd} from '../models/banner_ad';
import {AnswerSheet} from '../models/QuestionInterface';
// import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlayerSharedService {
    private data = {};
    player_token: string;
    player_user: PlayerUser;
    player_user_logout_status: boolean;
    // private messageSource = new BehaviorSubject("default message");
    // currentMessage = this.messageSource.asObservable();

    constructor(private _http: HttpClient, private _utils: UtilsClass) {
     }
    //  changeMessage(message: string) {
    //   this.messageSource.next(message)
    // }

     setOption(option, value) {
        this.data[option] = value;
      }

      getOption(option) {
        return this.data[option];
      }

      refreshUser(data): Observable<SimpleJWTToken>{ //was TokenPayload
        //return this._http.post<TokenPayload>(environment.BASE_API_URL + 'accounts/api-token-refresh/', data,{ headers: {'Content-Type': 'application/json'} })
        return this._http.post<SimpleJWTToken>(environment.BASE_API_URL + 'api/token/refresh/', data,{ headers: {'Content-Type': 'application/json'} })
        .pipe(
          map(result =>
            result)
          );
      }

      getBannerAdData(pageRoute, deviceFlag): Observable<BannerAd>{
        return this._http.get<BannerAd>(environment.BASE_API_URL+ 'get_banner_ad/?page='+ pageRoute + '&device=' + deviceFlag)
        .pipe(
          map(result =>
            result),
            catchError(this.handleError)
        );
      }


      getCheckinPlayer(token): Observable<PlayerUser>{
        return this._http.get<PlayerUser>(environment.BASE_API_URL + 'accounts/checkin_player/', {headers: this._utils.getHeaders(token)})
        .pipe(
          map(result =>
            result)
          );
      }

      getTeams(): Observable<AllTeams>{
        return this._http.get<AllTeams>(environment.BASE_API_URL + 'teams/')
        .pipe(
          map(result =>
            result),
            catchError(this.handleError)
          );
      }

      //data must be array of team ids
      getFlair(data: any): Observable<Flair[]>{
        return this._http.post<Flair[]>(environment.BASE_API_URL + 'reward/get-flair-list/', data, { headers: {'Content-Type': 'application/json'} })
        .pipe(
          map(result =>
            result),
            catchError(this.handleError)
          );
      }

      getTeamDetail(team_id): Observable<any>{
        return this._http.get<any>(environment.BASE_API_URL + 'teams/' + team_id + '/')
        .pipe(
          map(result =>
            result)
          );
      }

      getVenueTeams(venue_id): Observable<any>{
        return this._http.get<any>(environment.BASE_API_URL + 'venues/'+ venue_id + '/teams/')
        .pipe(
          map(result =>
            result)
          );
      }

      JoinTeamService(game_id, data, token): Observable<JoinTeamModel>{
        return this._http.post<JoinTeamModel>(environment.BASE_API_URL + 'games/' + game_id+ '/join/', data,{ headers:  this._utils.getHeaders(token)})
        .pipe(
          map(result =>
            result),
            catchError(this.handleError)
          );
      }

      checkTeanGameLimitService(game_id, team_id): Observable<any>{
        return this._http.get<any>(environment.BASE_API_URL + 'games/' + game_id+ '/checkTeamLimitInGame/?team_id='+ team_id)
        .pipe(
          map(result =>
            result),
            catchError(this.handleError)
          );
      }

      JoinTeamWoGameService(team_id, token): Observable<JoinTeamWoGameModel>{
        return this._http.post<JoinTeamWoGameModel>(environment.BASE_API_URL + 'teams/' + team_id+ '/join_wo_game/', { headers:  this._utils.getHeaders(token)})
        .pipe(
          map(result =>
            result),
            catchError(this.handleError)
          );
      }

      checkPlyerPlayGameService(user_id, token): Observable<{'play': boolean}>{
        return this._http.post<{'play': boolean}>(environment.BASE_API_URL + 'accounts/users/' + user_id + '/check_player_pay_game/', { headers:  this._utils.getHeaders(token)})
        .pipe(
          map(result =>
            result)
        );
      }

      CheckInUser(game_id, data, token): Observable<CheckInUserModel>{
        return this._http.post<CheckInUserModel>(environment.BASE_API_URL + 'games/' + game_id + '/user_check_in/', data, {headers:  this._utils.getHeaders(token)})
          .pipe(
            map(result =>
              result)
          );
      }

      handleError(error){
        let errorMessage = error.error;
        console.log(error);
        return throwError(errorMessage);
      }

      redirectOnError(error){
        let errorMessage = error.error;
        console.log(error);
        return (false);
      }

      ownTeamService(team_id, data, token): Observable<{'user': PlayerUser}>{
        return this._http.post<{'user': PlayerUser}>(environment.BASE_API_URL + 'teams/' + team_id + '/own/', data, {headers:  this._utils.getHeaders(token)})
          .pipe(
            map(result =>
              result),
              catchError(this.handleError)
          );
      }

      activeGameTournamentService(venue_id, token): Observable<any>{
        return this._http.get<any>(environment.BASE_API_URL+ 'venues/'+ venue_id + '/active_game_tournaments/', {headers:this._utils.getHeaders(token)})
        .pipe(
          map(result =>
            result),
            catchError(this.handleError)
        );
      }

      getVenues(venue_id, token): Observable<any>{
        return this._http.get<any>(environment.BASE_API_URL+ 'venues/'+ venue_id + '/', {headers:this._utils.getHeaders(token)})
        .pipe(
          map(result =>
            result),
            catchError(this.handleError)
        );
      }

      confirmUser(token): Observable<any>{
        return this._http.get<any>(environment.BASE_API_URL+ 'accounts/confirm_user/', {headers:this._utils.getHeaders(token)})
        .pipe(
          map(result =>
            result),
            catchError(this.handleError)
        );
      }

      socketConnection(game_id): any{
        return new WebSocket(environment.WS_URL + 'game-' + game_id);
      }

      teamSocketConnection(team_id: number): WebSocket{
        return new WebSocket(environment.WS_URL + 'team-' + team_id);
      }

      checkDB(): Observable<any>{
        return this._http.get<any>(environment.BASE_API_URL+ 'accounts/check_db_connection/', {headers:this._utils.getHeaders(null)})
        .pipe(
          map(result =>
            result),
            catchError(this.handleError)
        );
      }

      getProfile(team_id, venue_id): Observable<any>{
        return this._http.get<any>(environment.BASE_API_URL + 'teams/' + team_id + '/profile?venue='+venue_id)
          .pipe(
            map(result =>
              result),
              catchError(this.handleError)
          );
      }

      getRank(team_id): Observable<any>{
        return this._http.get<any>(environment.BASE_API_URL + 'teams/' + team_id + '/rank/')
          .pipe(
            map(result =>
              result),
              catchError(this.handleError)
          );
      }

      updateTeamService(team_id, data, token): Observable<any>{
        return this._http.put<any>(environment.BASE_API_URL + 'teams/' + team_id + '/', data, {headers:this._utils.getHeaders(token)})
         .pipe(
          map(result =>
            result),
            catchError(this.handleError)
         );
      }

      CreateTeamService(data, token): Observable<any>{
        return this._http.post<any>(environment.BASE_API_URL + 'team/create/' ,data,{ headers:  this._utils.getHeaders(token)})
        .pipe(
          map(result =>
            result),
            catchError(this.handleError)
          );
      }

      playerLoginService(data): Observable<any>{
        return this._http.post<any>(environment.BASE_API_URL + 'accounts/player_login/' , data)
        .pipe(
          map(result =>
            result),
            catchError(this.handleError)
          );
      }

      forgotPasswordService(data): Observable<any>{
        return this._http.post<any>(environment.BASE_API_URL + 'accounts/send-reset-password/' , data)
        .pipe(
          map(result =>
            result),
            catchError(this.handleError)
          );
      }

      invitePlayerService(data, token): Observable<any>{
        return this._http.post<any>(environment.BASE_API_URL + 'accounts/invite-player/' ,data,{ headers:  this._utils.getHeaders(token)})
        .pipe(
          map(result =>
            result),
            catchError(this.handleError)
          );
      }

      isFQAUpdated(): Observable<any>{
        return this._http.get<any>(environment.BASE_API_URL + 'faq/is_updated/')
          .pipe(
            map(result =>
              result),
              catchError(this.handleError)
          );
      }

      requestTournamentService(token, data): Observable<any>{
        return this._http.post<any>(environment.BASE_API_URL + 'tournaments/request-for-live-tournament/' ,data, { headers:  this._utils.getHeaders(token)})
        .pipe(
          map(result =>
            result),
            catchError(this.handleError)
          );
      }

      getNotActiveGameVenues(token): Observable<ActiveGameVenues>{
        return this._http.get<ActiveGameVenues>(environment.BASE_API_URL + 'venues/?active_game=0', { headers: this._utils.getHeaders(token) })
        .pipe(
          map(result =>
            result)
          );
      }

      getVenueDetailService(venueID): Observable<ActiveGameVenue>{
        return this._http.get<ActiveGameVenue>(environment.BASE_API_URL + 'venues/'+venueID+'/')
        .pipe(
          map(result =>
            result)
          );
      }

      getAnswerSheet(data): Observable<AnswerSheet>{
        return this._http.post<any>(environment.BASE_API_URL + 'games/' + data.gameId + '/get_questions/', data)
          .pipe(map(result => result), catchError(e => throwError(this.redirectOnError(e))));
      }

      submitAnswerSheet(data, game){
        return this._http.post<any>(environment.BASE_API_URL + 'games/' + game+ '/generate_answers/', data)
          .pipe(catchError(e => throwError(this.handleError(e))));
      }

      getDefaultTournament(): Observable<any>{
        return this._http.get<any>(environment.BASE_API_URL + 'tournaments/default-tournament/')
        .pipe(
          map(result =>
            result),
            catchError(this.handleError)
          );
      }
}
