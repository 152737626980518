import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateListService {

  constructor(
    private http: HttpClient
  ) { }

  getStates() {
    return this.http.get<any>(environment.BASE_API_URL + 'accounts/states/')
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  getCompanies() {
    return this.http.get<any>(environment.BASE_API_URL + 'accounts/companies/')
      .pipe(catchError(e => throwError(this.handleError(e))));
  }

  handleError(errorResponse) {
    return errorResponse.error;
  }
}
