import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HostService} from '../../../services/host.service';
import {WebsocketProviderService} from '../../../services/websocket-provider.service';
import {environment} from '../../../../environments/environment';
declare var jQuery: any;
@Component({
  selector: 'app-game-result',
  templateUrl: './game-result.component.html',
  styleUrls: ['./game-result.component.css']
})
export class GameResultComponent implements OnInit, OnDestroy {

  public gameId: any;
  public venueDetails: any;
  public game: any;
  public gameTeams: any;
  public maxRound: any;
  public term: any;
  public successMsg: any;
  public errorMsg: any;
  public loader: boolean;
  public defaultImage: string = 'assets/img/location.png';
  public user: any;

  constructor(
    private route: ActivatedRoute,
    private hostService: HostService,
    private wsService: WebsocketProviderService,
    private router: Router
  ) {
    this.loader = true;
    jQuery('body').removeClass('modal-open');
    jQuery('body').css('padding-right',0);
    jQuery('.modal-backdrop').remove();
    this.user = JSON.parse(localStorage.getItem('TrivTrak_USER'));
    this.route.params.subscribe(result => this.gameId = result.id);

    this.hostService.getGameData(this.gameId)
      .subscribe((result) => {
        this.game = result;
        this.venueDetails = result.venue;
        this.maxRound = result.max_round;
        this.hostService.getGameTeams(this.gameId)
          .subscribe((result) => {
            this.gameTeams = result.sort(this.hostService.sortResponse('total_score'));
            this.loader = false;
            this.loadView();
          });
      });

    
  }

  loadView() {
    jQuery(document).ready(function() {
      var cols = [];
      var trs = jQuery('#hostScoreTable tbody tr');
      var data =jQuery.each(trs , function(index, tr){
        jQuery.each(jQuery(tr).find('td'), function(index, td){
          cols[index] = cols[index] || [];
          if(!Number.isNaN(parseFloat(jQuery(td).text()))) {
            cols[index].push(jQuery(td).text());
          } else {
            cols[index].push(0);
          }
        });
      });
      cols.forEach(function(col, index){
        var max = Math.max.apply(null,col);
        var min = Math.min.apply(null,col);
        jQuery('#hostScoreTable tbody tr').find('td:eq('+(index)+')').each(function(i, td){
          if(max!==0 && parseFloat(jQuery(td).text())==max) {
            jQuery(td).children().addClass('max');
          }
        });
      });
    });
  }
  ngOnInit(): void {
    this.wsService.connect(this.gameId)
      .subscribe((result) => {
        if (result.msg_type == "update"){
          let data = JSON.parse(result.message).teams;
          this.gameTeams = data.sort(this.hostService.sortResponse('total_score'));
          this.loadView();
        }  
      }
    );
  }
  ngOnDestroy(): void {
    this.wsService.close();
  }

  addOpacity() {
    jQuery(document).ready(function() {
      jQuery('.modal.fade').css('opacity', 1);
      jQuery('.modal.fade').addClass('show');
      let cls = document.getElementsByClassName("modal-backdrop").length;
      if (cls>0) {
        jQuery('div.modal-backdrop').addClass('show');
        jQuery('div.modal-backdrop').css('opacity', 0.8);
      }
    });
  }
}
