import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  public policies: any;

  constructor(private http: HttpClient) { 
    this.http.get<any>(environment.BASE_API_URL + 'privacyPolicy/')
      .subscribe((result) => {
        this.policies = result.results;
      });
  }

  ngOnInit() {
  }

}
