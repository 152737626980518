<div class="createTeamModal">
  <div class="modal fade" id="healthCheckModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
          <div class="modal-header">
              <span class="modal-title" id="exampleModalLabel">Health Check</span>
              <!-- <button class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" class="fa fa-times"></span>
              </button> -->
          </div>
          <div class="modal-body">
              <span>Socket Status: {{socketStatusMessage}}</span>
              <span *ngIf="socketErrorMessage">Socket Error: {{socketErrorMessage}}</span>
          </div>
          <div class="modal-body">
            <span>Database Status: {{dbStatusMessage}}</span>
            <span *ngIf="dbErrorMessage">Database Error: {{dbErrorMessage}}</span>
        </div>
          <div class="modal-btn mb-3">
              <a style="cursor: pointer;" class="btn confirmCreate mt-1 mb-2" data-toggle="modal" (click)="proceed()">OK</a>
          </div>
          </div>
      </div>
  </div>
</div>