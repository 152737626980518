import {Component, OnInit, OnDestroy, AfterViewInit, ViewChild, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { CDK_CONNECTED_OVERLAY_SCROLL_STRATEGY } from '@angular/cdk/overlay/overlay-directives';
import {ActivatedRoute, Router} from '@angular/router';
import {HostService} from '../../../services/host.service';
import {CsvExportService} from '../../../services/csv-export.service'
import {WebsocketProviderService} from '../../../services/websocket-provider.service';
import {ErrorMsg} from '../../../models/error';
import {CookieService} from 'ngx-cookie-service';
import { CSVScores } from '../../../models/CSVScoresModel'; 
import { CSVScoresHeader } from '../../../models/CSVScoreImportHeaderModel'; 
import { Questions } from 'src/app/models/QuestionInterface';
import { GenerateQuestionsDialog } from '../generate-questions-dialog/generate-questions-dialog.component';
import {WagerReportDialog} from '../wager-report-dialog/wager-report-dialog.component';
import { RoundType } from 'src/app/models/AnswersEnum';
import { ScoringSheetDialog } from '../scoring-sheet/scoring-sheet.component';
declare var jQuery: any;

@Component({
  selector: 'app-start-round',
  templateUrl: './start-round.component.html',
  styleUrls: ['./start-round.component.css']
})
export class StartRoundComponent implements OnInit, OnDestroy, AfterViewInit {
  public gameId: any;
  public venueDetails: any;
  public game: any;
  public venueTeams: any;
  public gameTeams: any = [];
  public term: any;
  public teamForm: FormGroup;
  public successMsg: any;
  public errorMsg: any;
  public errorMsg2: any;
  public removeTeamId: any;
  public currentRound: any;
  public currentScores: any;
  public currentScoresTmp: any;
  public loader: boolean;
  public defaultImage: string = 'assets/img/location.png';
  public rounds: any = [];
  public venues: any;
  public user: any;
  public headers: any = [];
  public teamLimitValue: any;
  public canJoin: boolean = false;
  public sortColumnData: any;
  public sortRank: string;
  public sortTTL: string;
  public sortTeamName: string;
  public generate: boolean = true;
  public wager: boolean = false;
  public roundType: RoundType;
  //public dtOptions: DataTables.Settings = {};

  //CSV read
  public records: any[] = [];
  public file: File | null;
  public numScored: number;
  public numIgnored: number;
  public numInvalid: number;
  public numDuplicate: number;
  public numNotFound: number;
  @ViewChild('csvReader') csvReader: any;  
  public displayImport: boolean = true;
  
  constructor(
    private route: ActivatedRoute,
    private hostService: HostService,
    //private wsService: WebsocketService,
    private wsService: WebsocketProviderService,
    private router: Router,
    private cookieService: CookieService,
    private csvService :CsvExportService,
    public dialog: MatDialog,
    public breakpointObserver: BreakpointObserver
  ) {
    this.file=null;
    this.gameTeams = [];
    this.user = JSON.parse(localStorage.getItem('TrivTrak_USER'));
    this.teamLimitValue = localStorage.getItem('team_limit');
    this.sortRank = localStorage.getItem("rank");
    this.sortTTL = localStorage.getItem("total_score");
    this.sortTeamName = localStorage.getItem("name");
    //Poor Cookie logic
    if(localStorage.getItem('currentScores')) {
      this.currentScores = JSON.parse(localStorage.getItem('currentScores'));
      this.currentScoresTmp = this.currentScores;
    } else {
      this.currentScores = {};
      this.currentScoresTmp = this.currentScores;
    }
    let token = localStorage.getItem('TrivTrak_TOKEN');
    this.headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    };
    this.loader = true;

    // this.currentScores = {};
    jQuery('body').removeClass('modal-open');
    jQuery('body').css('padding-right', 0);
    jQuery('.modal-backdrop').remove();
    this.route.params.subscribe(result => this.gameId = result.id);
    
    

    this.loader = false;
    this.teamForm = new FormGroup({
      team_name: new FormControl('', [
        Validators.required
      ])
    });

    this.getVenues();
  }

  ngOnInit() {
    this.breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait,
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.TabletLandscape,
      Breakpoints.TabletPortrait,
      '(max-width: 1024px) and (orientation: portrait)'
    ]).subscribe(result => {
      if (result.matches) {
        this.displayImport = false;
      }
      else {
        this.displayImport = true;
      }
    });


    this.wsService.connect(this.gameId)
      .subscribe((result) => {
        console.log(result);
        if (!(typeof result.error === 'undefined') && result.error == "Game is finished"){
          this.router.navigate(['/host/game-result', this.gameId]);
        }
        switch (result.msg_type) {
          case "status":
            this.getGameData();
            break;
          case "error":
            break;
          case "leave":
            //may need to enter a leave command
            break;
          case "join":
            console.log(result);
            //might not be needed
          case "update":
            if ((typeof result.message.available === 'undefined') && (typeof result.message.submit === 'undefined')){
              this.websocketUpdate(JSON.parse(result.message));
            }
            break;
          default:
            console.log("default case");
            break;
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.wsService.close();
  }

  ngAfterViewInit() {
  }

  updateScoreView(team, round, score) {

  }

  websocketUpdate(data:any){
    console.log(data);
    //update scores object. IF you assign the values by a simple = it will cause a re-render because the object has been reassigned.
    //ALL of the below code for maintaining the scoreboard should be moved to an intercept and the scoreboard should be provided by a service.
    if(this.gameTeams.length != data.teams.length){ //update teams in game
      console.log("Team joined");
      /*data.forEach(element => {
        if (this.gameTeams.find(t => t.id === element.id) === undefined){
          console.log("Found team, pushing");
          this.gameTeams.push(element);
        }
      });*/
      this.getGameData();
      return;
    }
    //update scoreboard
    console.log("updating scores");
    for(var i = 0; i < this.gameTeams.length; i++){
      let record = data.teams.find(d => d.id === this.gameTeams[i].id);
      if (record === undefined){
        console.log(this.gameTeams[i]);
        console.log("Above team not found in records array");
      }
      // update score (why isn't this calc'd?)
      if (record.total_score != this.gameTeams[i].total_score){
        this.gameTeams[i].total_score = record.total_score;
      }
      // update number of players
      if (record.players != this.gameTeams[i].players){
        this.gameTeams[i].players = record.players;
      }
      //update rank
      if (record.rank != this.gameTeams[i].rank){
        this.gameTeams[i].rank = record.rank;
      }
      //update player registration status
      if (record.has_registered_player != this.gameTeams[i].has_registered_player){
        this.gameTeams[i].has_registered_player = record.has_registered_player;
      }
      //add new scores
      if (record.scores.length != this.gameTeams[i].scores.length){
        //update round
        if (record.scores[record.scores.length-1].round > this.currentRound){
          this.currentRound = record.scores[record.scores.length-1].round + 1;
          this.rounds.push(this.currentRound);
        }
        this.gameTeams[i].scores.push(record.scores[record.scores.length-1]);
      }
      //update scores
      for (var j = 0; j < record.scores.length; j++){
        if (this.gameTeams[i].scores[j].score != record.scores[j].score){
          this.gameTeams[i].scores[j].score = record.scores[j].score;
        }
      }
    }
    this.hostService.getGameData(this.gameId)
        .subscribe((result) => {
          console.log("Offending Call");
          if (result.end_date) {
            this.router.navigate(['/host/game-result', result.id]);
          }
        });
  }

  sortAlphabetically(prop) {
    var sortOrder = 1;

    if(prop[0] === "-") {
        sortOrder = -1;
        prop = prop.substr(1);
    }
    return function(a, b) {
      if(sortOrder == -1){
        return b[prop].localeCompare(a[prop]);
      }else{
        return a[prop].localeCompare(b[prop]);
    }  
    }
  }
  
  sortResponseAsc(prop){
    return function(a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    }
  }

  returnSortedData (data){
    if (this.sortRank){
      if (this.sortRank == "descending"){
        return data.sort(this.hostService.sortResponse('rank'));
      } else {
        return data.sort(this.sortResponseAsc('rank'));
      }
    } 
    else if (this.sortTTL){
      if (this.sortTTL == "descending"){
        return data.sort(this.hostService.sortResponse('total_score'));
      } else {
        return data.sort(this.sortResponseAsc('total_score'));
      }
    }
    else if (this.sortTeamName){
      if (this.sortTeamName == "descending"){
        return data.sort(this.sortAlphabetically('-name'));
      } else {
        return data.sort(this.sortAlphabetically('name'));
      }
    }
    else {
      return data.sort(this.hostService.sortResponse('total_score'));
    }
  }

  saveScore(teamId, round, score) {
    let data = {
      team_id: teamId,
      round_number: round,
      score: score
    };
    
    this.hostService.saveGameScore(this.gameId, data, this.headers)
      .subscribe((result) => {
        // this.getGameData(); // we could do somethign here as well.
        console.log('Saved Score....')
      });
  }

  openRemoveTeamModal(teamId, teamName) {
    this.addOpacity();
    this.removeTeamId = {
      id: teamId,
      name: teamName
    };
  }

  removeTeam(teamId) {
    // localStorage.setItem('currentScores', JSON.stringify(this.currentScores));
    localStorage.setItem('currentScores', JSON.stringify(this.currentScoresTmp));
    this.loader = true;
    let data = {
      team_id: teamId
    };
    this.hostService.removeTeam(this.game.id, data, this.headers)
      .subscribe(
        (result) => {
          this.game = result.game;
          if(this.game!=null) {
            this.hostService.getVenueTeams(this.game.venue.id)
              .subscribe(res => this.venueTeams = res);
            this.hostService.getGameTeams(this.game.id)
              .subscribe(res => {
                this.gameTeams = res;
              });
          }
          this.successMsg = 'Team Removed Successfully.';
          this.loader = false;
          delete this.errorMsg;
          jQuery('body').removeClass('modal-open');
          jQuery('.modal-backdrop').remove();
          jQuery('#create-team-modal').hide();
          jQuery('#search-team-modal').hide();
          setTimeout(function() {
            location.reload();
          }, 500);
        }
      );
  }

  getGameData() {
    this.hostService.getGameData(this.gameId)
      .subscribe((result) => {
        // this.game = result;

        if ((result.venue.host == this.user.id) || (result.venue.company == this.user.profile.company)) {
          if (result.end_date) {
            this.router.navigate(['/host/game-result', result.id]);
          }
          for (let i = 1; i <= result.max_round; i++) {
            this.rounds.push(i);
          }
          this.hostService.getGameTeams(this.gameId)
            .subscribe(result1 => {
              // this.gameTeams = result.sort(this.hostService.sortResponse('total_score'));
              this.gameTeams = this.returnSortedData(result1);
              this.currentRound = result.max_round + 1;
              this.venueDetails = result1.venue;
              this.game = result;
              //add call to get possible answers
              this.hostService.checkQuestions(this.gameId, this.currentRound, this.headers).subscribe((questionsExist) => {
                this.generate = !questionsExist;
              });

              this.hostService.getAnswers(this.gameId ,{forceSubmit: false, round:this.game.max_round+1}, this.headers).subscribe((roundAnswers) => {
                console.log(roundAnswers);
                roundAnswers.forEach(score => {
                  this.currentScores[score.team_id] = score.score;
                  //need to check for pending answers
                });
                if (roundAnswers.roundType == 3){
                  this.displayWagerTable(roundAnswers);
                }
              });
            });
            this.hostService.getVenueTeams(result.venue.id)
            .subscribe(res => this.venueTeams = res);
        } else {
          this.errorMsg2 = 'This game or venue does not belong to you.';
        }
      });
  }

  sortTableEvent(sortColumn){
    let array = ['rank', 'name', 'total_score']
    array = array.filter(item => item !== sortColumn);
    this.sortColumnData = localStorage.getItem(sortColumn);
    if (this.sortColumnData){
      // we got one column. we will check whether it is ascending/descending and change it accordingly
      if (this.sortColumnData == 'ascending'){
        localStorage.setItem(sortColumn, 'descending');
      } else {
        localStorage.setItem(sortColumn, 'ascending');
      }
      // TO check for all other coulumns excelt we got in sortColumn and remove from localstorage.
      // for (var i = 0; i < array.length; i++) {
      //   localStorage.removeItem(array[i]);
      // }
      array.forEach(val => {localStorage.removeItem(val);})
    } else {
      localStorage.setItem(sortColumn, 'descending');
      // TO check for all other coulumns excelt we got in sortColumn and remove from localstorage.
      // for (var i = 0; i < array.length; i++) {
      //   localStorage.removeItem(array[i]);
      // }
      array.forEach(val => {localStorage.removeItem(val);})
    }
    this.sortTableFunction()
  }

  sortTableFunction(){
    this.sortRank = localStorage.getItem("rank");
    this.sortTTL = localStorage.getItem("total_score");
    this.sortTeamName = localStorage.getItem("name");
    if (this.sortRank){
      if (this.sortRank == "descending"){
        this.gameTeams =  this.gameTeams.sort(this.hostService.sortResponse('rank'));
      } else {
        this.gameTeams =  this.gameTeams.sort(this.sortResponseAsc('rank'));
      }
    } 
    else if (this.sortTTL){
      if (this.sortTTL == "descending"){
        this.gameTeams =  this.gameTeams.sort(this.hostService.sortResponse('total_score'));
      } else {
        this.gameTeams =  this.gameTeams.sort(this.sortResponseAsc('total_score'));
      }
    }
    else if (this.sortTeamName){
      if (this.sortTeamName == "descending"){
        this.gameTeams =  this.gameTeams.sort(this.sortAlphabetically('-name'));
      } else {
        this.gameTeams =  this.gameTeams.sort(this.sortAlphabetically('name'));
      }
    }
    else {
      this.gameTeams =  this.gameTeams.sort(this.hostService.sortResponse('total_score'));
    }
  }

  saveGameData() {
    this.loader = true;
    if (this.isObjectEmpty(this.currentScoresTmp)) {
      for (let team of this.gameTeams) {
        this.currentScoresTmp[team.id] = 0;
      }
    }
    let data = {
      'scores': this.currentScoresTmp
    };
    this.hostService.getAnswers(this.gameId ,{forceSubmit: true, round:this.game.max_round+1}, this.headers).subscribe((result) => {
      console.log(result);
    });
    this.hostService.saveGameScores(this.gameId, JSON.stringify(data), this.headers)
      .subscribe((result) => {
        delete this.errorMsg;
        this.getGameData();
        for (let team of this.gameTeams) {
          this.currentScoresTmp[team.id] = '';
        }
        this.currentScores = this.currentScoresTmp
        localStorage.setItem('currentScores', JSON.stringify(this.currentScoresTmp));
        this.loader = false;
        this.generate = true;
        this.roundType = null;
      });
  }

  endGame() {
    if (this.isObjectEmpty(this.currentScoresTmp)) {
      for (let team of this.gameTeams) {
        this.currentScoresTmp[team.id] = 0;
      }
    }
    this.loader = true;
    let data = {
      'end': true
    };
    this.saveGameData();
    this.hostService.saveGameScores(this.gameId, JSON.stringify(data), this.headers)
      .subscribe((result) => {
        this.getGameData();
        this.loader = false;
        this.currentScoresTmp = null;
        this.currentScores = null;
        localStorage.removeItem("currentScores");
        localStorage.removeItem("rank");
        localStorage.removeItem("total_score");
        localStorage.removeItem("name");
        this.router.navigate(['/host/game-result', this.game.id]);
      });
  }

  pushScore(teamId, score) {
    // this.currentScores[teamId] = score;
    this.currentScoresTmp[teamId] = score;
  }

  joinTeam(teamId: null) {
    localStorage.setItem('currentScores', JSON.stringify(this.currentScoresTmp));
    this.loader = true;
    let data: any;
    if (teamId == null) {
      data = JSON.stringify(this.teamForm.value);
    } else {
      data = {
        team_id: teamId
      };
    }
    this.hostService.joinTeam(this.game.id, data, this.headers)
      .subscribe(
        (result) => {
          this.game = result.game;
          if(this.game!=null) {
            this.hostService.getVenueTeams(this.game.venue.id)
              .subscribe(res => this.venueTeams = res);
            this.hostService.getGameTeams(this.game.id)
              .subscribe(res => {
                this.gameTeams = res;
              });
          }
          jQuery('body').removeClass('modal-open');
          jQuery('#createTeam').trigger('reset');
          jQuery('.modal-backdrop').remove();
          jQuery('#create-team-modal').hide();
          jQuery('#search-team-modal').hide();
          jQuery('#add-team-modal').hide();
          this.successMsg = 'Team Added Successfully';
          this.loader = false;
          delete this.errorMsg;
          setTimeout(function() {
            location.reload();
          }, 500);
        },
        (error: ErrorMsg) => {
          this.loader = false;
          delete this.successMsg;
          this.errorMsg = error.team_name;
        }
      );
  }

  addOpacityExist(){
    jQuery(document).ready(function() {
      jQuery('#search-team-modal').show();
      jQuery('.modal.fade').css('opacity', 1);
      jQuery('.modal.fade').addClass('show');
      let cls = document.getElementsByClassName("modal-backdrop").length;
      if (cls>0) {
        jQuery('div.modal-backdrop').addClass('show');
        jQuery('div.modal-backdrop').css('opacity', 0.8);
      }
    });
  }

  addOpacityAdd(){
    jQuery(document).ready(function() {
      jQuery('#add-team-modal').show();
      jQuery('.modal.fade').css('opacity', 1);
      jQuery('.modal.fade').addClass('show');
      let cls = document.getElementsByClassName("modal-backdrop").length;
      if (cls>0) {
        jQuery('div.modal-backdrop').addClass('show');
        jQuery('div.modal-backdrop').css('opacity', 0.8);
      }
    });
  }

  isObjectEmpty(Obj) {
    for (var key in Obj) {
      if (Obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  getVenues() {
    this.hostService.getAllVenues()
      .subscribe(
        (result) => {
          this.venues = result;
        }
      );
  }

  getVenueTeams(venueId) {
    this.hostService.getVenueTeams(venueId)
      .subscribe(res => this.venueTeams = res);
  }

  addOpacity() {
    jQuery(document).ready(function() {
      jQuery('.modal.fade').css('opacity', 1);
      jQuery('.modal.fade').addClass('show');
      let cls = document.getElementsByClassName("modal-backdrop").length;
      if (cls>0) {
        jQuery('div.modal-backdrop').addClass('show');
        jQuery('div.modal-backdrop').css('opacity', 0.8);
      }
    });
  }
    //#region CSV
    uploadListener($event: any): void {
      this.file = $event.srcElement.files[0]; 
    }  
    processImport(): void {
      //let files = $event.srcElement.files;  
      //console.log("Importing Scores");
      if (this.isValidCSVFile(this.file)){//files[0])) {  
    
        //let input = $event.target;  
        let reader = new FileReader();  
        reader.readAsText(this.file);//input.files[0]);  
    
        reader.onload = () => {  
          let csvData = reader.result;  
          let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);  
    
          let headersRow = this.getHeaderArray(csvRecordsArray);  
    
          this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);

          console.log(this.records);
          //matching records to team ids as a separate step
          //I'm doing this so that it can be sent to the back-end eventually rather than just a front-end input
          this.numScored = 0;
          this.numIgnored = 0;
          this.numInvalid = 0;
          this.numDuplicate = 0;
          this.numNotFound = 0;
          this.records.forEach(r => {
            let gmTm = this.gameTeams.find(t => {
              return t.name.toLowerCase() === r.team.toLowerCase()
            });
            if (gmTm === undefined){
              r.team_id = null;
              this.numNotFound ++;
            }
            else {
              r.team_id = gmTm.id;
            }
          });

          this.records.forEach(r => {
            if (r.team_id !== null && !isNaN(r.score)){
              //console.log("setting score for: " + r.team);
              if(this.records.filter(rec => rec.team_id == r.team_id).length > 1){
                this.numDuplicate ++;
              }else{
              this.currentScores[r.team_id] = r.score;
              console.log(r);
              this.numScored ++;
              }
            }else if (isNaN(r.score)){
              this.numInvalid ++;
            }else{
              //console.log("Team:" + r.team + " Is not present in the game");
              //throw error?
              this.numIgnored ++;
            }
          });
          console.log(JSON.stringify(this.records));
          console.log(JSON.stringify(this.currentScores));
          
          jQuery('#import-report-modal').show();

          this.fileReset(); // Clear File contents
        };  
    
        reader.onerror = function () {  
          console.log('error is occured while reading file!');  
        };  
    
      } else {  
        alert("Please import valid .csv file.");  
        this.fileReset();  
      }  
    }  
    
    getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {  
      let csvArr = [];  
    
      for (let i = 1; i < csvRecordsArray.length; i++) {  
        let curruntRecord = (<string>csvRecordsArray[i]).split(',');  
        if (curruntRecord.length == headerLength) {  
          let csvRecord: CSVScores = new CSVScores();  
          csvRecord.team = curruntRecord[0].trim();  
          csvRecord.score = Number.parseInt(curruntRecord[1].trim());
          csvArr.push(csvRecord);  
        }  
      }  
      return csvArr;  
    }  
    
    isValidCSVFile(file: any) {  
      //console.log(file);
      return file.name.endsWith(".csv");  
    }  
    
    getHeaderArray(csvRecordsArr: any) {  
      let headers = (<string>csvRecordsArr[0]).split(',');  
      let headerArray = [];  
      for (let j = 0; j < headers.length; j++) {  
        headerArray.push(headers[j]);  
      }  
      return headerArray;  
    }  
    
    fileReset() {  
      this.csvReader.nativeElement.value = "";  
      this.records = [];  
    }
    
    closeReport() {
      jQuery('#import-report-modal').hide();
    }

    reportLine(value: number | null){
      return value !== null && value > 0;
    }

    //This does not need to be so complex, but I built it to be genericized for any CSV export.
    getCSVTemplate() {
      const items: CSVScoresHeader[] = [];
      let csvRecord: CSVScoresHeader = new CSVScoresHeader();  
          csvRecord.TEAM = "Enter Team Name Here";  
          csvRecord.SCORE = "Enter Round Score Here";
          items.push(csvRecord);
      this.csvService.exportToCsv('Round_Scores.csv', items);
    }
    //#endregion

    getAnswersAlert(): void {
      if (this.roundType == 1 || this.roundType == 3){
        console.log("open wager table");
      }
      this.hostService.getAnswers(this.gameId ,{forceSubmit: false, round:this.game.max_round+1}, this.headers).subscribe((result) => {
        console.log(result);
        const dialogRef = this.dialog.open(GetAnswersAlertDialog, {
          width: '250px',
          data: {answers:result.length, teams:this.gameTeams.length, round:this.game.max_round+1}
        });

        dialogRef.afterClosed().subscribe(res => {
          if(res==true){
            this.collectAnswers();
          }
        });
      });
    }

    collectAnswers(): void {
      this.hostService.getAnswers(this.gameId ,{forceSubmit: true, round:this.game.max_round+1}, this.headers).subscribe((result) => {
        console.log(result);
        if(result.length == 0){
          this.displayScoringSheet({round: this.game.max_round+1, gameID: this.gameId});
        }else {
          switch(result[0].roundType){
            case 0:
              break;
            case 1:
              this.displayWagerTable(result);
              break;
            case 2:
              console.log("opening scoring sheet");
              this.displayScoringSheet({round: this.game.max_round+1, gameID: this.gameId});
              break;
            case 3:
              this.displayScoringSheet({round: this.game.max_round+1, gameID: this.gameId});
              break;
            default:
              break;
          }
        }

        result.forEach(score => {
          this.currentScores[score.team_id] = score.score;
        });
        
        //update scoreboard with new info.
      });
    }

    openScoringSheet(){
      this.router.navigate;
    }

    generateQuestions(): void {
      const questionGen = this.dialog.open(GenerateQuestionsDialog, {
        width: '500px',
        data: {round: this.game.max_round+1}
      });
      
      questionGen.afterClosed().subscribe(res => {
        console.log(res);
        if (res == false){
          return;
        } else{
          this.hostService.saveQuestions(this.gameId, res, this.headers).subscribe(result =>{
            //make sure that the questions saved?
            this.generate = false;
            this.roundType = res.roundType;
          });
        }
      });
    }

    displayWagerTable(s): void {
      const wagerReport = this.dialog.open(WagerReportDialog, {
        width: '500px',
        data: {scores: s}
      });
    }

    displayScoringSheet(r): void {
      const scoringSheet = this.dialog.open(ScoringSheetDialog, {
        width: '500px',
        data: r
      });

      scoringSheet.afterClosed().subscribe(res => {
        if (res == false){
          return;
        } else {
          console.log(res);
          this.hostService.getAnswers(this.gameId ,{forceSubmit: false, round:this.game.max_round+1}, this.headers).subscribe((result) => {
            console.log(result);
            result.forEach(score => {
              this.currentScores[score.team_id] = score.score;
            });
            if (result[0].roundType == 3){
              this.displayWagerTable(result);
            }
          });
        };
      });
    }
}

export interface AlertData{
  answers: number,
  teams: number,
  round: number
}

@Component({
  selector: 'get-answers-alert-dialog',
  templateUrl: 'get-answers-alert-dialog.html',
})
export class GetAnswersAlertDialog {

  constructor(
    public dialogRef: MatDialogRef<GetAnswersAlertDialog>,
    @Inject(MAT_DIALOG_DATA) public input: AlertData
    ) 
    {
      console.log(input);
      dialogRef.backdropClick().subscribe(result => {
        dialogRef.disableClose = true; //disable default close operation
        dialogRef.close(false);
      });
    }
  onNoClick(): void {
    this.dialogRef.close(false);
  }

  close(): void {
    this.dialogRef.close(true);
  }
}

