import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
declare var jQuery:any;
import {HostService} from '../../../services/host.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-end-game-host',
  templateUrl: './end-game-host.component.html',
  styleUrls: ['./end-game-host.component.css']
})
export class EndGameHostComponent implements OnInit {
  public gameID: number;
  public roundNumber: number;
  public user: any;
  public headers: any = [];
  public currentScores: any;
  public loader: boolean = false;
  public gameTeams: any;

  constructor(private route: ActivatedRoute, private hostService: HostService, private router: Router) {
    this.user = JSON.parse(localStorage.getItem('TrivTrak_USER'));
    let token = localStorage.getItem('TrivTrak_TOKEN');
    this.headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    };
    this.currentScores = JSON.parse(localStorage.getItem('currentScores'))
   }

  ngOnInit() {
    this.route.params.subscribe(params =>{
      this.gameID = params['gameID'];
      this.hostService.getGameTeams(this.gameID)
            .subscribe(result => this.gameTeams = result.sort(this.hostService.sortResponse('total_score')));
      // jQuery('#end-round-modal').modal('toggle');
    })
  }

  saveGameData() {
    this.loader = true;
    if(this.isObjectEmpty(this.currentScores)) {
      for(let team of this.gameTeams) {
        this.currentScores[team.id] = 0;
      }
    }
    let data = {
      'scores': this.currentScores
    };
    this.hostService.saveGameScores(this.gameID, JSON.stringify(data), this.headers)
      .subscribe((result) => {
        // this.getGameData();
        // for(let team of this.gameTeams) {
        //   this.currentScores[team.id] = 0;
        // }
        this.loader = false;
        localStorage.setItem('currentScores', JSON.stringify({}));
      });
  }

  endGame() {
    this.loader = true;
    if(this.isObjectEmpty(this.currentScores)) {
      for(let team of this.gameTeams) {
        this.currentScores[team.id] = 0;
      }
    }
    let data = {
      'end': true
    };
    this.saveGameData();
    this.hostService.saveGameScores(this.gameID, JSON.stringify(data), this.headers)
      .subscribe((result) => {
        // this.getGameData();
        this.loader = false;
        // this.currentScores = null;
        localStorage.removeItem('currentScores');
        this.router.navigate(['/host/game-result', this.gameID]);
      });
  }

  isObjectEmpty(Obj) {
    for(var key in Obj) {
      if(Obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }
}
