import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blankscreen',
  templateUrl: './blankscreen.component.html',
  styleUrls: ['./blankscreen.component.css']
})
export class BlankscreenComponent implements OnInit { 
  constructor() {
  }

  ngOnInit() {
  }

}
