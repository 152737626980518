import {ViewChild, Component, OnInit } from '@angular/core';
import {MatSort} from '@angular/material/sort';
import { FormsModule } from '@angular/forms'; 
//import {MatSelectModule} from '@angular/material/select';
import {HostService} from '../../../services/host.service';
import {CsvExportService} from "../../../services/csv-export.service";
import {Router, ActivatedRoute} from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  public headers: any = {
    'Content-Type': 'application/json'
  };
  public user: any;
  public term: any;
  public token: string;
  public reportName: string;
  public reportList: string[] = [
    "Host List Report",
    "Players Per Venue",
    "Players Per Team Per Host",
    "Teams Per Venue",
    "Game Scoreboard",
    "Game Details",
    "Host Venue Report",
    "Tournament Performance",
    "Tournament Check-Ins",
    "Tournament Scoreboard",
    "Tournament Leaders"
  ];
  public selectedReport: string = "Host List Report";
  public reportData: MatTableDataSource<any>;
  public displayedColumns: string[];
  public displayReport: boolean = false;

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  constructor(
    private hostService: HostService,
    private router: Router,
    private route: ActivatedRoute,
    private csv: CsvExportService
  ) {
    this.headers['Authorization'] = 'Bearer ' + localStorage.getItem('TrivTrak_TOKEN');
    this.getHostReport();
  }


  ngOnInit() {
    //this.reportData.sort = this.sort;
  }

  exportReport(): void {
    this.csv.exportToCsv(this.selectedReport.concat(".csv"), this.reportData.filteredData);
  }

  updateReport(): void {
    console.log(this.selectedReport);
    switch (this.selectedReport){
      case "Host List Report":
        this.getHostReport();
        break;
      case "Players Per Venue":
        this.getVenuePlayerReport();
        break;
      case "Players Per Team Per Host":
        this.getPlayersTeamsReport();
        break;
      case "Teams Per Venue":
        this.getTeamsVenueReport();
        break;
      case "Game Scoreboard":
        this.getGameScoreboardReport();
        break;
      case "Game Details":
        this.getGameDetailReport();
        break;
      case "Host Venue Report":
        this.getVenuesReport();
        break;
      case "Tournament Check-Ins":
        this.getTournamentCheckInsReport();
        break;
      case "Tournament Performance":
        this.getTournamentPerformanceReport();
        break;
      case "Tournament Scoreboard":
        this.getTournamentScoreboardReport();
        break;
      case "Tournament Leaders":
        this.getTournamentLeadersReport();
        break;
      
    }
  }
  /*****************************************************
  * function updateReportDisplay                       *
  * Does not take parameters                           *
  * Returns void                                       *
  * This function updates the data table display       *
  *****************************************************/
  updateReportDisplay(data: any): void {
    this.reportData =  new MatTableDataSource(data);
    this.displayedColumns = Object.keys(data[0]);
    this.reportData.sort = this.sort;
    this.displayReport = true;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.reportData.filter = filterValue.trim().toLowerCase();
  }

  //#region Reports
  getHostReport(): void {
    this.hostService.getAllHosts(this.headers).subscribe(result => {
      //options for locale date display
      var options = { year: 'numeric', month: 'long', day: 'numeric' };
      //map data array to result
      //object keys MUST BE EXACLTY Column headers
      
      this.updateReportDisplay(
        result.map(obj => ({
          Username: obj.username, 
          "First Name": obj.first_name, 
          "Last Name": obj.last_name, 
          Email: obj.email,
          Created: new Date(Date.parse(obj.date_joined)).toLocaleDateString("en-US", options)
        }))
      );
    });
  }

  getVenuePlayerReport(): void {
    this.hostService.getVenueStats(this.headers).subscribe(result => {
      this.updateReportDisplay(
        result.map(obj => ({
          "Venue Name": obj.venue,
          "Registered Players": obj.number_of_register_players,
          "Unregistered Players": obj.number_of_gust_players,
          "Total Players": obj.number_of_players
        }))
      );
    });
  }

  getPlayersTeamsReport(): void {
    this.hostService.getPlayerTeamStats(this.headers).subscribe(result => {
      this.updateReportDisplay(
        result.result.map(obj => ({
          "Host": obj.host,
          "Registered Players": obj.number_of_register_players,
          "Unregistered Players": obj.number_of_gust_players,
          "Registered Teams" : obj.number_of_register_team,
          "Unregistered Teams" : obj.number_of_gust_team,
          "Total Players": obj.number_of_players,
          "Total Teams" : obj.number_of_teams
        }))
      );
    });
  }

  getTeamsVenueReport(): void {
    this.hostService.getTeamsVenueStats(this.headers).subscribe(result => {
      this.updateReportDisplay(
        result.result.map(obj => ({
          "Venue Name" : obj.venue,
          "Registered Teams" : obj.number_of_register_team,
          "Unregistered Teams" : obj.number_of_gust_team,
          "Total Teams" : obj.number_of_teams
        }))
      );
    });
  }

  getGameScoreboardReport(): void {
    this.hostService.getGameScoreboardStats(this.headers).subscribe(result => {
      var options = { year: 'numeric', month: 'long', day: 'numeric' };
      this.updateReportDisplay(
        result.map(obj => ({
          "Game ID" : "G-".concat(obj.id),
          "Venue Name" : obj.venue_name,
          "Number of Teams" : obj.num_of_teams,
          "Number of Checkins" : obj.num_of_checkins,
          "Number of Rounds" : obj.max_round,
          "Creation Date" : new Date(Date.parse(obj.creation_date)).toLocaleDateString("en-US", options),
          "End Date" : obj.end_date ? new Date(Date.parse(obj.end_date)).toLocaleDateString("en-US", options) : null
        }))
      );
    });
  }

  getGameDetailReport(): void {
    this.hostService.getGameDetailStats(this.headers).subscribe(result => {
      var options = { year: 'numeric', month: 'long', day: 'numeric' };
      this.updateReportDisplay(
        result.map(obj => ({
          "Game ID" : "G-".concat(obj.id),
          "Creation Date" : new Date(Date.parse(obj.creation_date)).toLocaleDateString("en-US", options),
          "End Date" : obj.end_date ? new Date(Date.parse(obj.end_date)).toLocaleDateString("en-US", options) : null,
          Name : obj.name,
          "Number of Players" : obj.number_of_players,
          Score : obj.score,
          Rank : obj.rank
        }))
      );
    });
  }

  getVenuesReport(): void {
    this.hostService.getHostVenueStats(this.headers).subscribe(result => {
      var options = { year: 'numeric', month: 'long', day: 'numeric' };
      this.updateReportDisplay(
        result.map(obj => ({
          "Venue Name" : obj.name,
          "Host Name" : obj.host_name,
          "Stage Name" : obj.stage_name,
          "Address" : obj.address,
          "Creation Date" : new Date(Date.parse(obj.creation_date)).toLocaleDateString("en-US", options),
        }))
      );
    });
  }

  getTournamentCheckInsReport(): void {
    this.hostService.getTournamentCheckinStats(this.headers).subscribe(result => {
      var options = { year: 'numeric', month: 'long', day: 'numeric' };
      this.updateReportDisplay(
        result.map(obj => ({
          "Tournament ID" : "TR-".concat(obj.id),
          "Start Date" : obj.start_date,
          "End Date" : obj.end_date,
          Username : obj.username,
          "First Name" : obj.first_name,
          "Last Name" : obj.last_name,
          Email : obj.email,
          "Team Name" : obj.team_name,
          "Venue Name" : obj.venue_name,
          "Creation Date" : new Date(Date.parse(obj.creation_date)).toLocaleDateString("en-US", options)
        }))
      );
    });
  }

  getTournamentPerformanceReport(): void {
    this.hostService.getTournamentPerformanceStats(this.headers).subscribe(result => {
      var options = { year: 'numeric', month: 'long', day: 'numeric' };
      this.updateReportDisplay(
        result.map(obj => ({
          "Tournament ID" : "TR-".concat(obj.t_id),
          "Start Date" : obj.start_date,
          "End Date" : obj.end_date,
          Game : "G-".concat(obj.g_id),
          Venue : obj.venue,
          Team : obj.team,
          Score : obj.score,
          Email : obj.email
        }))
      );
    });
  }

  getTournamentScoreboardReport(): void {
    this.hostService.getTournamentScoreboardStats(this.headers).subscribe(result => {
      var options = { year: 'numeric', month: 'long', day: 'numeric' };
      this.updateReportDisplay(
        result.map(obj => ({
          "Tournament ID" : "TR-".concat(obj.t_id),
          "Tournament Start Date" : new Date(Date.parse(obj.t_start)).toLocaleDateString("en-US", options),
          "Tournament End Date" : new Date(Date.parse(obj.t_end)).toLocaleDateString("en-US", options),
          "Game ID" : "G-".concat(obj.g_id),
          "Game Creation Date" : new Date(Date.parse(obj.g_create)).toLocaleDateString("en-US", options),
          "Game End Date" : new Date(Date.parse(obj.g_end)).toLocaleDateString("en-US", options),
          "Venue Name" : obj.venue,
          "Number of Teams" : obj.num_teams,
          "Number of Checkins" : obj.checkins,
        }))
      );
    });
  }

  getTournamentLeadersReport(): void {
    this.hostService.getTournamentLeaderStats(this.headers).subscribe(result => {
      var options = { year: 'numeric', month: 'long', day: 'numeric' };
      this.updateReportDisplay(
        result.map(obj => ({
          "Tournament ID" : "TR-".concat(obj.t_id),
          "Tournament Start Date" : new Date(Date.parse(obj.t_start)).toLocaleDateString("en-US", options),
          "Tournament End Date" : new Date(Date.parse(obj.t_end)).toLocaleDateString("en-US", options),
          "Team Name" : obj.name,
          "Venue Name" : obj.venue,
          Rank : obj.rank,
          Equalizer : obj.equalizer,
          "Number of Players" : obj.players,
        }))
      );
    });
  }
  //#endregion
}
