import {Component, Inject, ViewChild, OnInit} from '@angular/core';
import {MatSort, MatSortable} from '@angular/material/sort';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Answer } from 'src/app/models/AnswersEnum';

export interface ReportData{
  Rank: number;
  Team: string;
  Score: number;
  Wager: number;
  Answer: string;
}

@Component({
  selector: 'wager-report-dialog',
  templateUrl: 'wager-report-dialog.html',
})
export class WagerReportDialog implements OnInit{

  public displayedColumns: string[] = ['Rank','Team', 'Score', 'Wager', 'Answer'];
  //public dataSource: MatTableDataSource<any>;
  public reportData: MatTableDataSource<any>;
  public ready: boolean = false;
  public data: ReportData[] = [];

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  constructor(
    public dialogRef: MatDialogRef<WagerReportDialog> ,
    @Inject(MAT_DIALOG_DATA) public input: any
    ) 
    {
      console.log(input);
      if (typeof input.scores === 'undefined' || input.length == 0){
        this.dialogRef.close();
      }
      // this.reportData = new MatTableDataSource(this.data.scores);
      this.generateReport(input);

      dialogRef.backdropClick().subscribe(result => {
        dialogRef.disableClose = true; //disable default close operation
        dialogRef.close(false);
      });
    }
  ngOnInit(): void {
    this.reportData.sort = this.sort;
    this.sort.sort(({ id: 'Rank', start: 'asc'}) as MatSortable);
  }

  generateReport(input: any){
    this.data = input.scores.map(obj => ({
      "Rank": obj.rank,
      "Team": obj.team_name,
      "Score": obj.total_score,
      "Wager" : obj.score,
      "Answer" : obj.roundType == 1? this.getAns(obj.answers[0]) : obj.textAnswers[0].response
    }));

    this.reportData = new MatTableDataSource(this.data);
  }

  // updateReportDisplay(){
  //   this.dataSource = new MatTableDataSource(this.input.scores);
  //   this.dataSource.sort = this.sort;
  //   this.ready=true;
  //   console.log(this.dataSource);
  //   console.log(this.dataSource.sortData);
  // }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }

  getAns(a: number){
    return Answer[a];
  }

}