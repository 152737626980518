import { Component, OnInit } from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {PlayerSharedService} from '../../../services/player-shared.service';
import {PlayerUser} from '../../../models/player_user';
import {SimpleJWTToken} from '../../../models/token-payload';
import {BannerAd} from '../../../models/banner_ad';
import {CookieService} from 'ngx-cookie-service';
import {Observable} from "rxjs";
import {ResultsService} from '../../../services/results.service';
import {TeamGameScore} from '../../../models/team-game-score';
import {Router} from '@angular/router';
import {LoginError} from '../../../models/login-error-model';
import {
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
declare var jQuery:any;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
  token: string;
  refresh: string;
  player: PlayerUser;
  selectedVenue: any = {};
  gameTeamArray: Array<TeamGameScore> = [];
  team_rank: number = 1;
  localstorage_obj: any;
  player_user_logout_status: boolean;
  loginForm: FormGroup;
  loginErrors: LoginError = {email:[], password:[]};
  tournID: number;
  deviceFlag: string = 'desktop';
  bannerAdData: any = {};
  bannerFlag: boolean = false;

  constructor(private router: Router, private _resultService: ResultsService, private _sharedService: PlayerSharedService, private cookieService: CookieService, breakpointObserver: BreakpointObserver) {
      this.player = JSON.parse(localStorage.getItem('player_user'));
      this.token = localStorage.getItem('player_token');
      this.refresh = localStorage.getItem('refresh_token');
      this.selectedVenue = JSON.parse(localStorage.getItem('venue'));
      this.player_user_logout_status =  JSON.parse(localStorage.getItem('player_user_logout_status'));
      this.localstorage_obj = localStorage;

    breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait,
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.TabletLandscape,
      Breakpoints.TabletPortrait,
      '(max-width: 1024px) and (orientation: portrait)'
    ]).subscribe(result => {
      if (result.matches) {
        this.deviceFlag = 'mobile';
      }
    });
      // if (this.player && !this.selectedVenue){
      //   this._resultService.getVenueDetailService(this.token, this.player.my_team.venue).subscribe(result => {
      //       this.selectedVenue = result;
      //       localStorage.setItem('venue', JSON.stringify(result));
      //       this._resultService.getRankInState(this.selectedVenue.state, this.player.my_team.id)
      //       .subscribe(data => {
      //         this.team_rank = data.rank;
      //       });
      //   })
      // }
      // this.token = sessionStorage.getItem('player_token');
      // this.player = JSON.parse(sessionStorage.getItem('player_user'));
      // this.loginFormFunc();
  }

  ngOnInit() {
    jQuery('body').removeClass('model-open');
    jQuery('.modal-backdrop').remove();
    this.getBannerAd(this.router.url, this.deviceFlag).subscribe(adData => {
        if (Object.keys(adData).length > 0){
          this.bannerAdData = adData;
          this.bannerFlag = true;
        }
   });
    this._sharedService.getDefaultTournament().subscribe(tournData => {
      this.tournID = tournData.id;


      //  popup video code starts here
      jQuery(document).ready(function(){
   $("#myBtn2").click(function () {
     $(this).text($(this).text() == 'I Dont Need This' ? 'Learn More' : 'I Dont Need This');
     // @ts-ignore
     $("#myvideo1").toggle(function () {
       jQuery('#myvideo1').modal('show');
       // @ts-ignore
       jQuery('#cartoonVideo').get(0).play();
       console.log('Index MODAL Played')
     },
       function () {
         // @ts-ignore
         jQuery('#myvideo1').modal('hide');
         jQuery('#cartoonVideo').get(0).pause()
       });
   })
 });

 $(".close, .createTeamModal").click(function(){
      jQuery('#myvideo1').modal('hide');
      jQuery('#embed-responsive embed-responsive-16by9').modal('hide');
      jQuery('#cartoonVideo').get(0).pause();
  })



      jQuery(document).ready(function(){
        jQuery('#myvideo1').modal('hide');
       });

    })

  //   if(this.token){
  //     this.getRefreshToken({'token': this.token}).subscribe(tokenData => {
  //         // sessionStorage.setItem('player_user', JSON.stringify(tokenData.user));
  //         // sessionStorage.setItem('player_token', tokenData.token)
  //         localStorage.setItem('player_user', JSON.stringify(tokenData.user));
  //         localStorage.setItem('player_token', tokenData.token);
  //         this.player = tokenData.user;
  //         this.token = tokenData.token;
  //     })
  // }

    if(this.selectedVenue){
      this._sharedService.getVenueDetailService(this.selectedVenue.id).subscribe(venueData => {
        this.selectedVenue = venueData;
          if (!this.selectedVenue.active_game){
            if (this.player && this.player.my_team && this.token){
              if (this.player.email != null && this.player.email.trim().length > 0) {
                localStorage.setItem('venue', JSON.stringify(venueData));
                this.selectedVenue = venueData;
                if(this.token){
                  //this.getRefreshToken({'token': this.token}).subscribe(tokenData => {
                  this.getRefreshToken({refresh:this.refresh}).subscribe(tokenData => {
                      // sessionStorage.setItem('player_user', JSON.stringify(tokenData.user));
                      // sessionStorage.setItem('player_token', tokenData.token)
                      //localStorage.setItem('player_user', JSON.stringify(tokenData.user));
                      localStorage.setItem('player_token', tokenData.access);
                      //this.player = tokenData.user;
                      this.token = tokenData.access;
                  })
              }
              } else {
                // not bonafide should forget all details
                localStorage.clear();
                this.player = JSON.parse(localStorage.getItem('player_user'));
                this.token = localStorage.getItem('player_token');
                this.selectedVenue = JSON.parse(localStorage.getItem('venue'));
              }
            }
            // localStorage.setItem('venue', JSON.stringify(venueData));
            // this._sharedService.setOption("venue", venue);
            //We need to allow a player who is already a "Bonafide Member" to create or join a different team at a different venue.
            // if (this.player && this.player.my_team && this.token){
            //         if (this.player.email != null && this.player.email.trim().length > 0) {
            //             this.getRefreshToken({'token': this.token}).subscribe(tokenData => {
            //               // sessionStorage.setItem('player_user', JSON.stringify(tokenData.user));
            //               // sessionStorage.setItem('player_token', tokenData.token)
            //               localStorage.setItem('player_user', JSON.stringify(tokenData.user));
            //               localStorage.setItem('player_token', tokenData.token);
            //               this.player = tokenData.user;
            //               this.token = tokenData.token;
            //           })
            //       } else {
            //             //not Bonafide Member.
            //             this._sharedService.JoinTeamService(this.selectedVenue.active_game, {'team_id': this.player.my_team.id}, this.token)
            //               .subscribe(data => {
            //                 this.player = data.user;
            //                 this.token = data.token;
            //                 localStorage.setItem('player_token', data.token);
            //                 localStorage.setItem('player_user', JSON.stringify(data.user));
            //                 localStorage.setItem('game', JSON.stringify(data.game));
            //                 this.router.navigate(['/score-card', this.selectedVenue.active_game]);
            //           });
            //         }
            //     }
            // else{
            //   this.router.navigateByUrl('/locations');
            // }
            // // this._sharedService.changeMessage("Venue Set.........")
        }
        if(this.selectedVenue && this.player){
          // if(this.selectedVenue.state && this.player.my_team.id){
          //   this._resultService.getRankInState(this.selectedVenue.state, this.player.my_team.id)
          //     .subscribe(data => {
          //       this.team_rank = data.rank;
          //     });
          //   }
          this._sharedService.getRank(this.player.my_team.id).subscribe(result => {
            this.team_rank = result.rank;
          })
        }
      });
    }
    if (this.player && !this.selectedVenue){

      if(this.token){
        //this.getRefreshToken({'token': this.token})
        this.getRefreshToken({refresh: this.refresh})
        .subscribe(tokenData => {
            // sessionStorage.setItem('player_user', JSON.stringify(tokenData.user));
            // sessionStorage.setItem('player_token', tokenData.token)
            //localStorage.setItem('player_user', JSON.stringify(tokenData.user));
            localStorage.setItem('player_token', tokenData.access);
            //this.player = tokenData.user;
            this.token = tokenData.access;
        })
    }
      this._resultService.getVenueDetailService(this.player.my_team.venue).subscribe(result => {
          this.selectedVenue = result;
          localStorage.setItem('venue', JSON.stringify(result));
          // this._resultService.getRankInState(this.selectedVenue.state, this.player.my_team.id)
          // .subscribe(data => {
          //   this.team_rank = data.rank;
          // });
          this._sharedService.getRank(this.player.my_team.id).subscribe(result => {
            this.team_rank = result.rank;
          });
      })
    }


  // if(this.selectedVenue && this.player){
  //   if(this.selectedVenue.active_game){
  //     debugger;
  //     this._resultService.getTeamsPerGames(this.selectedVenue.active_game)
  //       .subscribe(teams => {
  //         this.gameTeamArray = teams;
  //         for(let i=0; i<this.gameTeamArray.length; i++){
  //             if (this.player.my_team.name == this.gameTeamArray[i]['name']){
  //                 this.team_rank = this.gameTeamArray[i]['rank'];
  //             }
  //         }
  //       });
  //     }
  // }

  }

  getRefreshToken(data): Observable<SimpleJWTToken>{
    return this._sharedService.refreshUser(data);
  }

  getBannerAd(pageRoute, device): Observable<BannerAd>{
    return this._sharedService.getBannerAdData(pageRoute, device);
  }

  onPlayClickWithoutUser(){
    // jQuery('#infoIndex').modal('toggle');
    this.router.navigateByUrl('/locations');
  }

  // onClickvideoIntro(){
  //   jQuery('#myvideo1').modal('show');
  //     // @ts-ignore
  //   $('#cartoonVideo').get(0).play()
  //   console.log('Index MODAL Played')
  // }
  // onClickvideoIntroclose(){
  //   // @ts-ignore
  //   $('#cartoonVideo').get(0).pause()
  //   jQuery('#myvideo1').modal('hide');
  //   console.log('Index MODAL Closed')
  // }



  onPlayClick() {
    if(this.selectedVenue){
      this._sharedService.getVenueDetailService(this.selectedVenue.id).subscribe(venueData => {
        this.selectedVenue = venueData;
          if (this.selectedVenue.active_game){
            localStorage.setItem('venue', JSON.stringify(venueData));
            if (this.player && this.player.email != null && this.player.email.trim().length > 0 && this.token){
              this._sharedService.checkPlyerPlayGameService(this.player.id, this.token)
              .subscribe(data => {
                    this._sharedService.checkTeanGameLimitService(this.selectedVenue.active_game, this.player.my_team.id).subscribe(data1 => {
                      if (data1.canJoin){
                        if (data.play){
                          this.selectVenue();
                        } else{
                          // jQuery('#infoIndex').modal('toggle');
                          this.router.navigateByUrl('/locations');
                        }
                      } else {
                        // jQuery('#infoIndex').modal('toggle');
                        this.router.navigateByUrl('/locations');
                      }
                    })

              })
            } else {
              this.selectVenue();
            }
            // this.selectVenue();
            // this._sharedService.changeMessage("Venue Set.........")
        } else {
          // for not active locations
          if (this.player && this.player.email != null && this.player.email.trim().length > 0 && this.token){
            this.selectedVenue = venueData;
            localStorage.setItem('venue', JSON.stringify(venueData));
            // jQuery('#infoIndex').modal('toggle');
            this.router.navigateByUrl('/locations');
          } else {
            localStorage.clear();
            // jQuery('#infoIndex').modal('toggle');
            this.router.navigateByUrl('/locations');
          }
        }
      });
    } else {
      // jQuery('#infoIndex').modal('toggle');
      this.router.navigateByUrl('/locations');
    }

  }

  selectVenue(){
      // this._sharedService.setOption("venue", venue);
            //We need to allow a player who is already a "Bonafide Member" to create or join a different team at a different venue.
            if (this.player && this.player.my_team && this.token){
              if (this.player.email != null && this.player.email.trim().length > 0) {
                //Register Player
                if (this.selectedVenue.is_register_player_check_in && this.selectedVenue.open_tournament){
                  //Register player & already check ins the game.
                  this.router.navigateByUrl('/join-game-confirm');
                } else {
                  //Register player & new Check-ins to game.
                  this.joinTheTournament();
                }
            } else {
                  //not Bonafide Member.
                  this._sharedService.checkTeanGameLimitService(this.selectedVenue.active_game, this.player.my_team.id).subscribe(data1 => {
                    if (data1.canJoin){
                      this._sharedService.JoinTeamService(this.selectedVenue.active_game, {'team_id': this.player.my_team.id}, this.token)
                          .subscribe(data => {
                            this.player = data.user;
                            this.token = data.token.access;
                            localStorage.setItem('player_token', data.token.access);
                            localStorage.setItem('refresh_token', data.token.refresh);
                            localStorage.setItem('player_user', JSON.stringify(data.user));
                            localStorage.setItem('game', JSON.stringify(data.game));
                            this.router.navigate(['/score-card', this.selectedVenue.active_game]);
                      });
                    } else {
                      jQuery('#teamLimitModalIndex').modal('toggle');
                      this.addOpacity();
                    }
                  })
              }
          }
      else{
        // jQuery('#infoIndex').modal('toggle');
        this.router.navigateByUrl('/locations');
      }
  }

  joinTheTournament(){
    localStorage.setItem('player_user_logout_status', JSON.stringify(false));
    let game_id = this.selectedVenue.active_game;
    if (game_id >0 ){
        this._sharedService.CheckInUser(game_id, {'team_id': this.player.my_team.id}, this.token)
          .subscribe(data => {
            this.player = data.user;
            this.token = data.token.access;
            localStorage.setItem('player_token', data.token.access);
            localStorage.setItem('refresh_token', data.token.refresh);
            localStorage.setItem('player_user', JSON.stringify(data.user));
            localStorage.setItem('game', JSON.stringify(data.game));
            this.router.navigate(['/score-card', this.selectedVenue.active_game]);
          });
    }
  }

  // loginFormFunc(){
  //   this.loginForm = new FormGroup({
  //     email: new FormControl('', [Validators.required, Validators.email]),
  //     password: new FormControl('', [Validators.required])
  //   })
  // }

  // login(){
  //   this._sharedService.playerLoginService(this.loginForm.value)
  //     .subscribe(data => {
  //       this.player = data.user;
  //       this.token = data.token;
  //       localStorage.setItem('player_user', JSON.stringify(data.user));
  //       localStorage.setItem('player_token', data.token)
  //       jQuery('#team-login-modal').modal('toggle');

  //       // if (this.selectedVenue.open_tournament) {
  //       //   // this.getVenue();
  //       //   console.log('1')
  //       // } else {
  //       //   this.joinTheTournament();
  //       // }
  //      },
  //      error => {
  //        this.loginErrors = error;
  //      })
  // }

  // resetLogin(){
  //   this.loginFormFunc();
  //   this.loginErrors = {email:[], password:[]};
  // }

  goToLeaderBoard(){
    let partialTeamName = this.player.my_team.name.substring(0,4);
    this.router.navigate(['/tournament-leaderboard-home', this.tournID], {queryParams:{teamName:partialTeamName}})
  }

  addOpacity() {
    jQuery(document).ready(function() {
      jQuery('.modal.fade').css('opacity', 1);
      jQuery('.modal.fade').addClass('show');
      let cls = document.getElementsByClassName("modal-backdrop").length;
      if (cls>0) {
        jQuery('div.modal-backdrop').addClass('show');
        jQuery('div.modal-backdrop').css('opacity', 0.8);
      }
    });
  }


}
