<!-- Join team modal -->
<div class="join-team-modal">
    <div class="modal fade" id="teamModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" class="fa fa-times"></span>
                    </button>
                </div>
                <div class="modal-container">
                    <div class="modal-body">
                        <span>BE A BONIFIDE MEMBER OF THIS TEAM</span>
                    </div>
                        <div class="modal-btn">
                            <a role="button" class="btn team-form mr-1" data-toggle="modal" data-target="#team-form-modal" (click)="addOpacity();">YES</a>
                        </div>
                        <div class="link mt-2">
                            <a (click)="navigate()">NO THANK YOU</a>
                        </div>
                        <div class="modal-info">
                            <div class="info-header mb-2">
                                <span>BENEFITS OF OWNERSHIP</span>
                            </div>
                            <ul>
                                <li>1.  Ownership of Team Name</li>
                                <li>2.  Change Team Name</li>
                                <li>3.  Access to scoreboards</li>
                                <li>4.  Access to special events</li>
                                <li>5.  Rewards and Stats</li>
                                <li>6.  Join our Trivia Community</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<div class="team-form-modal">
    <div class="modal fade" id="team-form-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header"></div>
                <div class="modal-container">
                    <div class="modal-body">
                        <span>FILL IN THE FOLLOWING DETAILS TO BE PART OF THE TEAM</span>
                    </div>
                    <div class="team-form mb-4">
                        <form (ngSubmit)="ownTeam()" [formGroup]="ownForm">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Fullname" formControlName="first_name">
                                <span *ngFor="let error of ownTeamErrors.first_name" class="error-message">{{ error }}</span>
                            </div>
                            <div class="form-group">
                                <input type="email" class="form-control" placeholder="Email Address" formControlName="email">
                                <span *ngFor="let error of ownTeamErrors.email" class="error-message">{{ error }}</span>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="City" formControlName="city">
                                <span *ngFor="let error of ownTeamErrors.city" class="error-message">{{ error }}</span>
                            </div>
                            <div class="form-group">
                                <input type="password" class="form-control" placeholder="Password can be anything" formControlName="password">
                                <span *ngFor="let error of ownTeamErrors.password" class="error-message">{{ error }}</span>
                            </div>
                            <div class="form-btn">
                                <!-- <a style="cursor: pointer;" class="btn success mr-1" data-toggle="modal" data-target="#success-modal">CLICK TO OWN</a> -->
                                <button type="submit" style="cursor: pointer;" id="own-submit1" class="btn success mr-1" data-toggle="modal">CLICK TO JOIN</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="loader" *ngIf="loader">
    <div class="loaderbx">
      <i class="fas fa-3x fa-spinner fa-spin" aria-hidden="true"></i>
    </div>
  </div>