import { Component, OnInit, Input } from '@angular/core';
import {Flair} from "../../../models/team-game-score";
import {environment} from 'src/environments/environment';
declare var jQuery:any;

@Component({
  selector: 'flair',
  templateUrl: './flair.component.html',
  styleUrls: ['./flair.component.css']
})
export class FlairComponent implements OnInit {
  @Input() flair: Flair;
  public tooltip: string;
  constructor() {
  }

  ngOnInit() {
    if(this.flair){
      if (this.flair.name == "Founder's Badge"){
        this.tooltip = this.flair.name;
      }else {
        this.tooltip = this.flair.name +": Level " + this.flair.level;
      }
      this.flair.image = environment.BASE_API_URL.substring(0,environment.BASE_API_URL.length-1) + this.flair.image;
    }
  }

}
