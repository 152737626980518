import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
export class UtilsClass {
    getHeaders(token){
        let headers = {'Content-Type': 'application/json'}
        if (token){
          //headers['Authorization'] = 'JWT ' + token
          headers['Authorization'] = 'Bearer ' + token
        }
        return headers
      }
}