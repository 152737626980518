import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {HostLoginService} from '../services/host-login.service';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private router: Router,
              private loginService: HostLoginService,
              private cookieService: CookieService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.initUser();
    if (this.isAuthenitcated()) {
      return true;
    }
    this.router.navigate(['host']);
    return false;
  }

  initUser() {
    //console.log(this.cookieService.getAll());
  }

  //!! TODO: Needs proper auth guard logic Token needs to be checked against API
  isAuthenitcated() {
    if (this.cookieService.get('TrivTrak_TOKEN') && this.cookieService.get('TrivTrak_USERNAME')) {
      return true;
    }
    return false;
  }
}
