import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HostService} from '../../../services/host.service';

@Component({
  selector: 'app-change-passowrd',
  templateUrl: './change-passowrd.component.html',
  styleUrls: ['./change-passowrd.component.css']
})
export class ChangePassowrdComponent implements OnInit {

  public changePasswordForm: FormGroup;
  public successMsg: any;
  public errorMsg: any;
  constructor(private hostService: HostService) { }

  ngOnInit() {
    this.createChangePasswordForm()
  }

  createChangePasswordForm() {
    this.changePasswordForm = new FormGroup({
      password: new FormControl('',[
        Validators.required
      ]),
      re_password: new FormControl('',[
        Validators.required
      ])
    })
  }

}
