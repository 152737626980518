import {Injectable} from '@angular/core';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {tap, retryWhen, delay } from 'rxjs/operators';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

export const WS_ENDPOINT = environment.WS_URL;
export const RECONNECT_INTERVAL = environment.RECONNECT_INTERVAL;


@Injectable({
  providedIn: 'root'
})
export class WebsocketProviderAnswersService {
  private socket$: WebSocketSubject<any>;
  public ws: Observable<any>;
  private gameID: number;
  public url: string;

  constructor() {
  }
  
  public connect(gameID: number): Observable<any> {
    this.gameID = gameID;
    this.url = WS_ENDPOINT+'answers';
    console.log(this.url);
      this.socket$ = this.getNewAnswersWebSocket();
      
      this.ws = this.socket$.pipe(
        retryWhen(errors => errors.pipe(
            tap(err => console.log('[WebsocketProviderService] Trying to reconnect to game: ', this.gameID,' Error: ', err)
          ), 
          delay(RECONNECT_INTERVAL)
          )
        )
      );
    return this.ws;
  }

  private getNewAnswersWebSocket(): WebSocketSubject<any> {
    try {
      return webSocket({
            url: this.url,
            openObserver: {
              next: () => {
                console.log("[WebsocketProvider Service]: connection ok ANSWERS");
                this.sendMessage({
                  command: "join",
                  game: this.gameID
                });
              }
            },
            closeObserver: {
              next(closeEvent) {
                console.log('[WebsocketProvider Service]: connection closed ANSWERS');
                console.log(closeEvent);
              }
            },
          });
    } catch {
      setTimeout(function(){
        return this.getNewWebSocket();
      }, RECONNECT_INTERVAL*100);
    }
  }

  sendMessage(msg: any): void {
    console.log("sending connect message");
    this.socket$.next(msg);
  }

  close(): void {
    console.log("Closing socket to" +  this.url);
    this.socket$.complete(); 
  }

}