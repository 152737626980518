<div class="title-container mt-3">
  <div class="page-t">

        <div class="page-title" style="margin: auto;">
            <b>HOST LEADERBOARD ALL TIME - TOP 50</b>
        </div>
 </div>
</div>
<!-- searchbox (_sections.scss) -->
<div class="searchbox mt-3 ml-3 mr-3">
  <div class="row">
         <div class="col-xl-6  col-lg-6 col-md-12 col-sm-12 col-12 top-align">
                 <form action="#">
                 <input type="text" #searchTeamInput placeholder="SEARCH HOST" name="search">
           <button type="submit"><i class="fa fa-search"></i></button>
           
       </form>  
         </div>
         
           <div class="col-xl-6 col-lg-5 col-md-12 col-sm-12 col-12 sidedropdown">
               <form  class="form-inline">
                 <div class="row">
                         <div class="col-md-5 col-4 col-sm-5 col-lg-5 col-xl-4">
                              <div class="form-group">
                             <label>Time: </label>
                           <select class="form-control" (change)="filterTime($event.target.value)">
                             <option value="all">--All--</option>
                             <option *ngFor="let timeFilter of timeFilterValues" value={{timeFilter[1]}}>
                                 {{timeFilter[0]}}
                             </option>
                           </select>
                           </div>
                         </div>
                     
                        
                         <div class="col-md-5 col-5 col-sm-5 col-lg-5 col-xl-5">
                                 <div class="form-group">
                             <label  >State: </label>
                             <select class="form-control" (change)="filterState($event.target.value)">
                               <option value="">--All--</option>
                               <option *ngFor="let state of states" value={{state[0]}}>
                                   {{state[1]}}
                               </option>
                             </select>
                           </div>
                         </div>
                         <div class="col-md-2 col-1 col-sm-2 col-lg-2 col-xl-3">
                           <div class="form-group">
                               <label _ngcontent-ron-c4="">TIME: </label>
                             <label class="switch"><input type="checkbox" id="togBtn" (click)="onToggleChange(null)"><div class="slider round"><span class="on">C</span><span class="off">A</span></div></label>
                           </div>
                         </div>
                     </div>
                 </form>
           </div>
  </div>
      
 </div>
<!-- end of searchbox -->
<!-- <div class="scoreboard-tbl m-3" *ngIf="isSearching">
  <div class="table-responsive table-fontsize">
      <h4>Searching ... </h4>
  </div>
</div> -->

<!-- scoreboard table (_tables.scss) -->
<div class="scoreboard-tbl m-3">
  <div class="table-responsive table-fontsize">
      <table class="table-hover" mdbTable>
          <thead>
          <tr>
              <th [mdbTableSort]="leaderboard" sortBy="rank" style="background-color: rgb(33, 32, 30); color:  #ffbf00;"><div class="text-center">RK</div></th>
              <th [mdbTableSort]="leaderboard" sortBy="name" style="background-color: rgb(33, 32, 30); color:  #ffbf00;"><div class="text-center">HOST</div></th>
              <!-- <th class="lapColumn" style="background-color: rgb(33, 32, 30); color:  #ffbf00;">Host</th> -->
              <th style="background-color: rgb(33, 32, 30); color:  #ffbf00;"><div class="text-center">VENUE</div></th>
              <th class="lapColumn" style="background-color: rgb(33, 32, 30); color:  #ffbf00;"><div class="text-center">P</div></th>
              <!-- <th [mdbTableSort]="leaderboard" sortBy="equalizer" style="background-color: rgb(33, 32, 30); color:  #ffbf00;"><div class="text-center">TWS</div></th> -->
              <th style="background-color: rgb(33, 32, 30); width: 3% !important"><div class="text-center"></div></th>
          </tr>
          </thead>
          <tbody *ngIf="leaderboard != null">
          <tr *ngFor="let leadeBoard of leaderboard" style="background-color: rgb(33, 32, 30); color: white;">
              <td scope="row"><div class="text-center">{{leadeBoard.rank}}</div></td>
              <td><div class="text-center">{{leadeBoard.name}}</div></td>
              <!-- <td class="lapColumn"><div class="text-center">{{leadeBoard.venue.stagename}}</div></td> -->
              <td>
                  <div class="text-center">
              <!-- <td class="lapColumn"><div class="text-center">{{leadeBoard.venue.hostname}}</div></td> -->
                      <!-- <img class="lapColumn" [src]="leadeBoard.venue.image != null ? leadeBoard.venue.image : defaultImage" alt=""> -->
                      <span>{{leadeBoard.venue_name}}</span>
                      </div>
                  </td>
              <td class="lapColumn"><div class="text-center">{{leadeBoard.points_2019}}</div></td>
              <!-- <td><div class="text-center">{{leadeBoard.equalizer}}</div></td> -->
              <!-- <td><div class="arrow fa fa-angle-right" [routerLink]="['/tournament-team-prof', leadeBoard.id, leadeBoard.venue.id,leadeBoard.rank]"></div></td> -->
              <td><div class="arrow fa fa-angle-right" data-target="#select-report-modal" data-toggle="modal" role="button"  (click)="showHostReport(leadeBoard.id, leadeBoard.name)"></div></td>
          </tr>
          </tbody>
      </table>
      </div>
  </div>

  <div class="select-venue-modal">

    <div class="modal fade" id="select-report-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
              <span class="modal-title">Host Report - {{hostName}}</span>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" class="fa fa-times"></span>
              </button>
            </div>
            <div class="modal-body">
  
  
              <div class="venue-tbl m-3">
                <div style="height: 400px; overflow: auto;">
                <table class="table-hover">
                  <thead>
                    <tr>
                      <th>Report Name</th>
                      <th class="hostColumn">Count</th>
                      <!--<th class="hostColumn">Host</th>-->
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Registered Players</td>
                      <td><a>{{registeredPlayers}}</a></td>
                      <!--<td class="hostColumn"><a>{{item.host_name}}</a></td>-->
                    </tr>
                    <tr>
                      <td>Unregistered Players</td>
                      <td><a>{{unRegisteredPlayers}}</a></td>
                      <!--<td class="hostColumn"><a>{{item.host_name}}</a></td>-->
                    </tr>
                    <tr>
                      <td>Registered Teams</td>
                      <td><a>{{registeredTeams}}</a></td>
                      <!--<td class="hostColumn"><a>{{item.host_name}}</a></td>-->
                    </tr>
                    <tr>
                      <td>Unregistered Teams</td>
                      <td><a>{{unRegisteredTeams}}</a></td>
                      <!--<td class="hostColumn"><a>{{item.host_name}}</a></td>-->
                    </tr>
                  </tbody>
                </table>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>

<div class="loader" *ngIf="loader">
<div class="loaderbx">
  <i class="fas fa-3x fa-spinner fa-spin" aria-hidden="true"></i>
</div>
</div>
