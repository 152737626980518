import { Component, OnInit } from '@angular/core';
import {HostLoginService} from '../../services/host-login.service';
import {Router, RouterEvent, NavigationEnd} from '@angular/router';
import {UserInfoService} from '../../services/user-info.service';
import {CookieService} from 'ngx-cookie-service';
import {ActiveGameVenue} from '../../models/active_game_venue';
import {TeamGameScore} from '../../models/team-game-score';
import {PlayerUser} from '../../models/player_user';
import {LoginError} from '../../models/login-error-model';
import {PlayerSharedService} from '../../services/player-shared.service'
import {
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import { filter } from 'rxjs/operators';
declare var jQuery:any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  private isAuthenticated: boolean;
  private user: any;
  player_token: string;
  localstorage_obj: any;
  loginForm: FormGroup;
  forgotPasswordForm: FormGroup;
  token: string;
  refresh: string;
  player: PlayerUser;
  host: PlayerUser;
  loginErrors: LoginError = {email:[], password:[]};
  forgotPassErrors: any = {email:[]};
  forgotStatus: boolean = false;
  defaultLogo: string = 'assets/img/logo.png';
  isUpdated: boolean = false;
  constructor(private loginService: HostLoginService,
              public router: Router,
              private userInfoService: UserInfoService,
              public cookieService: CookieService,
              private _sharedService: PlayerSharedService
              ) {
                this.localstorage_obj = localStorage;
                this.loginFormFunc();
                this.forgotPasswordFunc();
  }

  ngOnInit() {
    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.player =  JSON.parse(localStorage.getItem('player_user'));
      this.host = JSON.parse(localStorage.getItem('TrivTrak_USER'));
    });
    this.user = this.userInfoService.getUser();
    this.player =  JSON.parse(localStorage.getItem('player_user'));
    this.host = JSON.parse(localStorage.getItem('TrivTrak_USER'));
    if (sessionStorage.getItem('TrivTrak_TOKEN') && sessionStorage.getItem('TrivTrak_USERNAME')) {
      this.isAuthenticated = true;
    }
    this._sharedService.isFQAUpdated().subscribe(data => {
      this.isUpdated = data.isUpdated;
    }, error => {
      console.log(error);
    })
  }

  logout(currentUrl) {
    var re = /host/gi;
    this.loginService.logout();
    this.isAuthenticated = false;
    localStorage.clear();
    if (currentUrl.search(re) == -1){
      this.router.navigate(['/'], {replaceUrl:true});
    } else {
      this.router.navigate(['/host'], {replaceUrl:true});
    }

    //this.router.navigate(['host']);
  }

  forgotPasswordFunc(){
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    })
  }

  loginFormFunc(){
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    })
  }

  forgotPassword(){
    this.forgotPasswordForm.value['type'] = 'player';
    this._sharedService.forgotPasswordService(this.forgotPasswordForm.value).subscribe(data => {
        this.forgotStatus = true;
    }, error => {
      this.forgotStatus = false;
      this.forgotPassErrors = error;
    })
  }

  login(){
    this._sharedService.playerLoginService(this.loginForm.value)
      .subscribe(data => {
        this.player = data.user;
        this.token = data.token;
        this.refresh = data.refresh;
        localStorage.setItem('player_user', JSON.stringify(data.user));
        localStorage.setItem('player_token', data.token);
        localStorage.setItem('refresh_token', data.refresh);
        jQuery('#team-login-modal').modal('toggle');
        this.resetLogin();
        if (this.router.url == '/'){
          location.reload();
        } else {
          this.router.navigateByUrl('/');
        }

       },
       error => {
         this.loginErrors = error;
       })
  }


  resetLogin(){
    this.loginFormFunc();
    this.loginErrors = {email:[], password:[]};
  }

  resetForgotPassword(){
    this.forgotPasswordFunc();
    this.forgotPassErrors = {email:[]};
    this.forgotStatus = false;
    // jQuery('#forgot-password-modal').toggle();
    // jQuery('.modal-backdrop').remove();
    // jQuery('body').removeClass('modal-open');
    // jQuery('body').css({'padding-right': '0px'});
  }

  // openForgotPasswordModal(){
  //   jQuery('#team-login-modal').hide();
  //   jQuery('#forgot-password-modal').show();
  //   this.addOpacity();
  // }

  addOpacity() {
    jQuery(document).ready(function() {
      jQuery('.modal.fade').css('opacity', 1);
      jQuery('.modal.fade').addClass('show');
      let cls = document.getElementsByClassName("modal-backdrop").length;
      if (cls>0) {
        jQuery('div.modal-backdrop').addClass('show');
        jQuery('div.modal-backdrop').css('opacity', 0.8);
      }
    });
  }
}


// Popup video

jQuery(document).ready(function(){
  // Show the Modal on load
  jQuery('#myModal1').modal('hide');

  // Hide the Modal
  jQuery('#myBtn').click(function(){
    jQuery('#myModal1').modal('hide');
  });

    // Show the Modal
  jQuery('#showModel').click(function(){
    console.log('Header Model Clicked');
    jQuery('#myModal1').modal('show');
  });

  jQuery('#myModal1').on('hidden.bs.modal', function(){
    console.log('Heelo Video Paused');
    jQuery('#cartoonVideo1').get(0).pause();
  });

  jQuery('#myModal1').on('shown.bs.modal', function(){
    console.log('Heelo Video Paused');
    jQuery('#cartoonVideo1').get(0).play();
  });
});

// Popvideo codes ends here
