import {Component, OnInit, Inject, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {HostService} from '../../../services/host.service';
import { Answers } from 'src/app/models/QuestionInterface';
import { MatSelectionList } from '@angular/material/list';


export interface team{
  id: number,
  name: string
}

//Component to score text answers
@Component({
  selector: 'scoring-sheet-dialog',
  templateUrl: './scoring-sheet.component.html',
  styleUrls: ['./scoring-sheet.component.css']
})
export class ScoringSheetDialog implements OnInit {
  public headers: any = {
    'Content-Type': 'application/json'
  };
  public user: any;
  public gameID: number;
  public teamSheets: Answers[];
  public unscored: Answers[];
  public rounds: number[];
  public teams: team[];
  public selectedRound: number;
  public selectedTeam: number;
  public selectedCard: Answers;
  public gradedAnswers: number[] | null;
  public currentRound: number;
  public ready: boolean = false;

  @ViewChild('list') list: MatSelectionList;
  constructor(
    @Inject(MAT_DIALOG_DATA) public input: {round: number, gameID: number},
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<ScoringSheetDialog>,
    private hostService: HostService
  ) {
  }

  ngOnInit() {
    console.log(this.input);
    this.currentRound = this.input.round;
    this.gameID = this.input.gameID;
    this.headers['Authorization'] = 'Bearer ' + localStorage.getItem('TrivTrak_TOKEN');
    this.hostService.getAllAnswerSheets(this.gameID, this.headers).subscribe(sheets => {
      this.teamSheets = sheets;
      console.log(this.teamSheets);
      this.unscored = this.getUnscored(sheets);
      if(this.unscored.length > 0){
        this.selectedRound = this.unscored[0].round;
        this.selectedTeam = this.unscored[0].team_id;
        this.selectedCard = this.unscored[0];
      } else {
        this.selectedRound = this.teamSheets[0].round;
        this.selectedTeam = this.teamSheets[0].team_id;
        this.selectedCard = this.teamSheets[0];
      }
      this.setGraded();
      //this.rounds = [...Array(this.currentRound+1).keys()];
      //this.rounds.shift();
      this.rounds = Array.from(new Set(this.teamSheets.map(t => t.round)));
      this.updateTeamList();
      this.ready = true;
    });
  }

  // uses null default value for correct field to determine if scorecard has been graded.
  getUnscored(sheets: Answers[]): Answers[]{
    return sheets.filter(s => s.textAnswers.find(a => a.correct == null) != undefined);
  }

  getRoundTeams(r: number): team[]{
    return this.teamSheets.filter(ts => ts.round == r).map(t => ({"id": t.team_id, "name": t.team_name}));
  }

  setGraded(){
    this.gradedAnswers = [];
    console.log("in graded setter");
    console.log(this.selectedCard);
    this.selectedCard.textAnswers.forEach((ans, index) => {
      if (ans.correct === true){
        console.log(ans.correct);
        this.gradedAnswers.push(index);
      }
    });
  }

  getNextCard(){
    if (this.notUnscored()){
      this.selectedCard = this.unscored[0];
      this.list.deselectAll();
      this.setGraded();
    } else {
      let next = this.unscored.findIndex(c => c.round === this.selectedCard.round && c.team_id === this.selectedCard.team_id)+1;
      if (next < this.unscored.length){
        this.selectedCard = this.unscored[next];
        this.list.deselectAll();
        this.setGraded();
        this.selectedTeam = this.selectedCard.team_id;
        this.selectedRound = this.selectedCard.round;
      }
      else{
        this.close();
      }
    }
  }
  
  updateTeamList(){
    this.teams = this.getRoundTeams(this.selectedRound);
    this.selectedTeam = this.teams[0].id;
    this.updateAnswerDisplay();
  }

  updateAnswerDisplay(){
    let newId = this.teamSheets.findIndex(c => c.round === this.selectedRound && c.team_id === this.selectedTeam);
    if (!(typeof newId === 'undefined')){
      this.selectedCard = this.teamSheets[newId];
      this.setGraded();
    } else {
      console.log("card not found");
    }
  }

  redirect(){
    //this.router.navigate(['host/start-round/', this.gameID]);
    //!!TODO: Implement websockets call to 
  }
  
  notUnscored(){
    return (typeof this.unscored.findIndex(c => c.round === this.selectedCard.round && c.team_id === this.selectedCard.team_id) === 'undefined');
  }

  save(){
    this.selectedCard.textAnswers.forEach((q, index) => {
      q.correct = this.gradedAnswers.includes(index);
    });
    if (this.selectedCard.round != this.currentRound){
      let data = {
        team_id: this.selectedCard.team_id,
        round_number: this.selectedCard.round,
        score: this.selectedCard.textAnswers.reduce(function(prev, cur) {
          return cur.correct? prev + cur.points : prev;
        }, 0)
      };
      this.hostService.saveGameScore(this.gameID, data, this.headers).subscribe(result => {
        this.hostService.saveGrades(this.gameID, this.selectedCard, this.headers).subscribe((result1) => {
          console.log(result1);
          this.getNextCard();
        });
        console.log(result);
      });
    } else{
      this.hostService.saveGrades(this.gameID, this.selectedCard, this.headers).subscribe((result) => {
        console.log(result);
        this.teamSheets[this.teamSheets.findIndex(c => c.round === this.selectedRound && c.team_id === this.selectedTeam)] = Object.assign(this.selectedCard);
        this.getNextCard();
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  close(): void {
    this.dialogRef.close(this.teamSheets.filter(t => t.round == this.currentRound));
  }
}
