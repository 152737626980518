import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {ActiveGameVenues} from '../models/active_game_venues';
import {ActiveGameVenue} from '../models/active_game_venue';
import {GameResult} from '../models/game_result'
import {TeamGameScore} from '../models/team-game-score'
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators'
import {UtilsClass} from '../components/utils'

@Injectable({
  providedIn: 'root'
})
export class ResultsService {

  constructor(private _http: HttpClient, private _utils: UtilsClass) { }

  getActiveGameVenues(token): Observable<Array<ActiveGameVenue>>{
    return this._http.get<Array<ActiveGameVenue>>(environment.BASE_API_URL + 'venues/?active_game=1', { headers: this._utils.getHeaders(token) })
    .pipe(
      map(result => 
        result)
      );
  }

  getAllVenuesService(token): Observable<Array<ActiveGameVenue>>{
    return this._http.get<Array<ActiveGameVenue>>(environment.BASE_API_URL + 'venues/', { headers: this._utils.getHeaders(token) })
    .pipe(
      map(result => 
        result)
      );
  }

  getVenueDetailService(venueId): Observable<Array<ActiveGameVenue>>{
    return this._http.get<Array<ActiveGameVenue>>(environment.BASE_API_URL + 'venues/' + venueId + '/')
    .pipe(
      map(result => 
        result)
      );
  }

  getTeamsPerGames(game_id: any): Observable<Array<TeamGameScore>>{
    return this._http.get<Array<TeamGameScore>>(environment.BASE_API_URL + 'games/' + game_id + '/teams/')
    .pipe(
      map(result => 
        result)
      );
  }

  getRankInState(state: any, team: any): Observable<any>{
    return this._http.get<Array<TeamGameScore>>(environment.BASE_API_URL + 'games/rank-in-state/' + state + '/' + team + '/')
    .pipe(
      map(result => 
        result)
      );
  }

  getGameData(game_id: any): Observable<any>{
    return this._http.get<any>(environment.BASE_API_URL + 'games/' + game_id +'/')
    .pipe(
      map(result => 
        result)
      );
  }

  getGameResult(game_id: any): Observable<GameResult>{
    return this._http.get<GameResult>(environment.BASE_API_URL + 'games/' + game_id + '/result/')
    .pipe(
      map(result => 
        result)
      );
  }

  getEndGameTwoDaysResult(): Observable<Array<GameResult>>{
    return this._http.get<Array<GameResult>>(environment.BASE_API_URL + 'games/games-ended-two-days/')
    .pipe(
      map(result => 
        result)
      );
  }
}
