import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {WorldLeaderboardService} from '../../../services/world-leaderboard.service'
import {PlayerSharedService} from '../../../services/player-shared.service';
import {WorldLeaders} from '../../../models/world-leaders';
import {WorldLeader} from '../../../models/world-leader';
import {Observable, fromEvent, of} from "rxjs";
import {PlayerUser} from '../../../models/player_user';
import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter
} from "rxjs/operators";
declare var jQuery: any;

@Component({
  selector: 'app-default-tournament-leaderboard',
  templateUrl: './default-tournament-leaderboard.component.html',
  styleUrls: ['./default-tournament-leaderboard.component.css']
})
export class DefaultTournamentLeaderboardComponent implements OnInit {
  @ViewChild('searchTeamInput', {static: true}) searchTeamInput: ElementRef;
  isSearching:boolean;
  public user: PlayerUser;
  public leaderboard: Array<WorldLeader>;
  public leaderboard_tmp: Array<WorldLeader>;
  public tournamentName: string = 'TWS';
  public filter: any;
  defaultImage: string = "assets/img/location.png";
  public loader: boolean;
  public tournamentID: number;
  public timeFilterValues: Array<any> = [['Week','1w'], ['Month', '1m'], ['3 Months', '3m'], ['Year', '1y']]
  public timeFilterValue: string = 'all';
  public stateFilterValue: string = '';
  public states: Array<any>;
  public toggleSwitch: boolean = false;
  public defaultLogo: string = 'assets/img/logo1.png';
  public main_sponsor_image_path: string = 'assets/img/logo1.png';
  public second_sponsor_image_path: string = 'assets/img/logo1.png';
  public second_sponsor_url: string = null;
  public main_sponsor_url: string = null;

  constructor(private router: Router, private route: ActivatedRoute, private _leaderboardServie: WorldLeaderboardService, private _sharedService: PlayerSharedService) {
    this.user = JSON.parse(localStorage.getItem('player_user'));
    this.isSearching = false;
   }

  ngOnInit() {
    jQuery('body').removeClass('modal-open');
    jQuery('.modal-backdrop').remove();
    this.loader = true;

    this.route.params.subscribe(params => { 
      this.tournamentID = params['id']
      this.getTournamentDetails(params['id']).subscribe(tournDetail => {
        this.main_sponsor_image_path = tournDetail.main_sponsor_image_path;
        this.second_sponsor_image_path = tournDetail.second_sponsor_image_path;
        this.second_sponsor_url = tournDetail.second_sponsor_url;
        this.main_sponsor_url = tournDetail.main_sponsor_url;
    })
    this.getLeaderBoard(this.timeFilterValue, this.stateFilterValue, this.toggleSwitch).subscribe(leaderboard => {
      this.loader = false;
      this.leaderboard = leaderboard.results.sort(this.sortResponse('equalizer'));
      this.leaderboard_tmp = this.leaderboard;
      this._leaderboardServie.getStates().subscribe(states => {
          this.states = states;
      })
    });

    fromEvent(this.searchTeamInput.nativeElement, 'keyup').pipe(
      // get value
      map((event:any) => {
        if (event.target.value === ''){
            this.leaderboard = this.leaderboard_tmp;
        }
        return event.target.value;
      }),
      // if character length greater then 3
      filter(res => res.length > 3),
      // Time in milliseconds between key events
      debounceTime(100),
      // If previous query is diffent from current
      distinctUntilChanged()).subscribe((text: string) => {
        this.loader = true;
        this.getSearchedTeamWorld(text, this.timeFilterValue, this.stateFilterValue, this.toggleSwitch).subscribe(leaderboard => {
          this.loader = false;
          this.leaderboard = leaderboard.results.sort(this.sortResponse('equalizer'));
        }, error => {
          console.log(error)
        })
      })
  });
  }

  getTournamentDetails(tournament_id: any): Observable<any>{
    return this._leaderboardServie.getTournamentDetails(tournament_id);
  }

  sortResponse(prop) {
    return function(a, b) {
      if (a[prop] < b[prop]) {
        return 1;
      } else if (a[prop] > b[prop]) {
        return -1;
      }
      return 0;
    }
  }

  getLeaderBoard(timeFilter, stateFilter, isHomeBar): Observable<WorldLeaders>{
    return this._leaderboardServie.getLeaderBoard(timeFilter, stateFilter, isHomeBar);
}

getSearchedTeamWorld(term: string, timeFilter, stateFilter, isHomeBar){
  if (term === ''){
    return of([]);
  }
  return this._leaderboardServie.getSearchedTeamLeaderBoard(term, timeFilter, stateFilter, isHomeBar);
}

filterTime(filterVal: any) {
  this.loader = true;
  this.timeFilterValue = filterVal;
  this.getLeaderBoard(this.timeFilterValue, this.stateFilterValue, this.toggleSwitch).subscribe(leaderboard => {
    this.loader = false;
    this.leaderboard = leaderboard.results.sort(this.sortResponse('equalizer'));
    for (let i=0; i<this.leaderboard.length; i++){
      this.leaderboard[i]['rank'] = i+1;
    }
    this.leaderboard_tmp = this.leaderboard;
  });
}

filterState(filterState: any){
  this.loader = true;
  this.stateFilterValue = filterState;
  this.getLeaderBoard(this.timeFilterValue, this.stateFilterValue, this.toggleSwitch).subscribe(leaderboard => {
    this.loader = false;
    this.leaderboard = leaderboard.results.sort(this.sortResponse('equalizer'));
    for (let i=0; i<this.leaderboard.length; i++){
      this.leaderboard[i]['rank'] = i+1;
    }
    this.leaderboard_tmp = this.leaderboard;
  });
}

onToggleChange(event: any){
  this.router.navigate(['/tournament-leaderboard-home', this.tournamentID], {replaceUrl:true});
}

popItUpSecondary(){
  window.open(this.second_sponsor_url, 'popUpWindow','height='+window.innerHeight+',width='+window.innerWidth+',left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes');
}

popItUpMain(){
  window.open(this.main_sponsor_url, 'popUpWindow','height='+window.innerHeight+',width='+window.innerWidth+',left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes');
}

}
