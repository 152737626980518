<div class="title-container mt-3">
  <div class="page-t">
    <div class="page-title" style="margin: auto;">
      <b>TEAM PROFILE</b>
    </div>
  </div>
</div>

<div class="hostprofile">
  <div class="card hovercard" *ngIf="result">
    <div class="cardheader"></div>
    <div class="avatar" >
      <img alt="" [src]="result.image != null ? result.image :'assets/img/pic1.jpg'">
    </div>
    <div class="content">
      <div class="d-flex flex-column">
        <span>{{result.name}} <img src="../../../../assets/img/star.jpg" style="width: 50px;height: auto;display: inline-block;"/></span>
        <small></small>
      </div>
      <HR>
      <div class="team-info">
        <div class="info-content col-6">
          <span>RANK IN VENUE</span>
          <span class="ranknumber">{{rank}}</span>
        </div>
        <div class="info-content col-6">
          <span>RANK IN NETWORK</span>
          <span class="ranknumber">21</span>
        </div>
      </div>
    </div>
  </div>

  <div>
    <!--<div class="button-grp">
      <a class="button mt-2" routerLink="/host/tournaments" role="button">SCOREBOARDS</a>
    </div>-->
    <div class="button-grp">
      <a class="button mt-2" style="cursor:pointer;" data-toggle="modal" data-target="#team-members" role="button" (click)="addOpacity();" >TEAM MEMBERS</a>
    </div>
    <!--<div class="button-grp mt-2">
      <a class="button mr-1 mb-1" routerLink="/blankscreen" role="button">REWARDS</a>
      <a class="button ml-1 mb-1" routerLink="/blankscreen" role="button">INVITE</a>
    </div>-->
  </div>
</div>

<div class="team-members" *ngIf="result">
  <div class="modal fade" id="team-members" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Team Members</span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="venue-tbl m-3" *ngIf="result['team_members'].length > 0" >
            <div style="height: 400px; overflow: auto;">
              <table class="table-hover">
                <tbody>
                <tr *ngFor="let item of result['team_members']; let i = index;">
                  <td>{{i+1}}</td>
                  <td>{{item}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div *ngIf="result['team_members'].length == 0" style="text-align: center;font-family: 'BourtonBase';">
            <span>No Registered Members</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loader" *ngIf="loader">
  <div class="loaderbx">
    <i class="fas fa-3x fa-spinner fa-spin" aria-hidden="true"></i>
  </div>
</div>
