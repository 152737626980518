<div class="login-container">
  <div id="login">
    <span>HOST Change Password</span>
    <form id="change-password" [formGroup]="changePasswordForm">
      <fieldset>
        <div class="alert alert-danger" *ngIf="errorMsg">
          {{errorMsg}}
        </div>
        <div class="alert alert-danger" *ngIf="successMsg">
          {{successMsg}}
        </div>

        <div class="form-group m-3">
          <div class="input-group col-12 show_hide_password">
            <show-hide-password size="lg" btnStyle="primary">
              <input name="password" class="form-control" type="text" placeholder="ENTER PASSWORD" formControlName="password" >
            </show-hide-password>
          </div>
        </div>
        <div class="form-group m-3">
          <div class="input-group col-12 show_hide_password">
            <show-hide-password size="lg" btnStyle="primary">
              <input name="re_password" class="form-control" type="text" placeholder="RE-ENTER PASSWORD" formControlName="re_password" >
            </show-hide-password>
          </div>
        </div>
        <div class="form-group m-2 ml-3 mr-3">
          <div class="col-md-12">
            <button type="submit" class="btn btn-lg btn-block d-flex-inline" role="button" [disabled]="!changePasswordForm.valid">Change Password</button>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</div>
