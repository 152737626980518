<h1 mat-dialog-title>Questions for Round {{currentRound}}</h1>
<div mat-dialog-content>
  <mat-label>Round Type</mat-label>
  <mat-select [(ngModel)]="roundType" (selectionChange)="createForm()">
    <mat-option *ngFor="let rt of roundTypes" [value]="rt">{{getRoundType(rt)}}</mat-option>
  </mat-select>
  <div *ngIf="roundType == 1">
    <form [formGroup]="wagerForm" (ngSubmit)="save(wagerForm.value)" novalidate>
      <div class="full-width">
        <label for="#ans_slider">Number of Answers</label>
        <mat-slider id="ans_slider"
            [max]="numAnswerSlider.max"
            [min]="numAnswerSlider.min"
            [step]="numAnswerSlider.step"
            [thumbLabel]="numAnswerSlider.thumbLabel"
            (change)="updateMCAnswers(wagerForm)"
            formControlName="numAnswers">
        </mat-slider>
      </div>
      <div formArrayName="questions">
        <table>
          <tr *ngFor="let wg of wagerForm['controls'].questions['controls']; let w = index;" class="form-group" [formGroupName]="w">
            <td class = "qlabel">
              <mat-label>Final Q:</mat-label>
            </td>
            <td class="answer">
              <mat-form-field>
                <mat-label>Answer</mat-label>
                <mat-select formControlName="answer">
                  <mat-option *ngFor="let answ of answerOptions" [value]="answ">
                    {{getAns(answ)}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            </tr>
        </table>
      </div>
      <button class ="btn btn-large mt-2" type="submit" [disabled]="!wagerForm.valid">Submit</button>
    </form>
  </div>
  <div *ngIf="roundType == 0">
    <form [formGroup]="questionsForm" (ngSubmit)="save(questionsForm.value)" novalidate>
      <div class="full-width">
        <label for="#qs_slider"> Number of Questions</label>
        <mat-slider id="qs_slider"
            [max]="numQuestionSlider.max"
            [min]="numQuestionSlider.min"
            [step]="numQuestionSlider.step"
            [thumbLabel]="numQuestionSlider.thumbLabel"
            (change)="updateMCQuestions()"
            formControlName="numQuestions">
        </mat-slider>
      </div>
      <div class="full-width">
        <label for="#ans_slider">Number of Answers</label>
        <mat-slider id="ans_slider"
            [max]="numAnswerSlider.max"
            [min]="numAnswerSlider.min"
            [step]="numAnswerSlider.step"
            [thumbLabel]="numAnswerSlider.thumbLabel"
            (change)="updateMCAnswers(questionsForm)"
            formControlName="numAnswers">
        </mat-slider>
      </div>
      <div fxLayout="column" formArrayName="questions">
        <div fxLayout="row" fxLayoutGap="10px" *ngFor="let q of questionsForm['controls'].questions['controls']; let i = index;" class="form-group" [formGroupName]="i">
          <span style="font-size: 14px; padding-top: 15px;" fxFlex="20%">
            Q&nbsp;{{i+1}}:
          </span>
          <mat-form-field fxFlex="40%">
            <mat-label>Answer</mat-label>
            <mat-select formControlName="answer">
              <mat-option *ngFor="let ans of answerOptions" [value]="ans">
                {{getAns(ans)}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="40%">
            <mat-label>Points</mat-label>
            <input matInput type='number' formControlName="points">
          </mat-form-field>
        </div>
      </div>
      <button class ="btn btn-large mt-2" type="submit" [disabled]="!questionsForm.valid">Submit</button>
    </form>
  </div>
  <div *ngIf="roundType == 2">
    <form [formGroup]="textQuestionsForm" (ngSubmit)="save(textQuestionsForm.value)" novalidate>
      <div class="full-width">
        <label for="#qs_slider"> Number of Questions</label>
        <mat-slider id="qs_slider"
            [max]="numQuestionSlider.max"
            [min]="numQuestionSlider.min"
            [step]="numQuestionSlider.step"
            [thumbLabel]="numQuestionSlider.thumbLabel"
            (change)="updateTextQuestions()"
            formControlName="numQuestions">
        </mat-slider>
      </div>
      <div fxLayout="column" formArrayName="questions">
        <div fxLayout="row" fxLayoutGap="10px" *ngFor="let q of textQuestionsForm['controls'].questions['controls']; let i = index;" class="form-group" [formGroupName]="i">
          <span style="font-size: 14px; padding-top: 15px;" fxFlex="20%">
            Q&nbsp;{{i+1}}:
          </span>
          <mat-form-field fxFlex="40%">
            <mat-label>Points</mat-label>
            <input matInput type='number' formControlName="points">
          </mat-form-field>
        </div>
      </div>
      <button class ="btn btn-large mt-2" type="submit" [disabled]="!textQuestionsForm.valid">Submit</button>
    </form>
  </div>
  <div *ngIf="roundType == 3">
    <form [formGroup]="textQuestionsForm" (ngSubmit)="save(textQuestionsForm.value)" novalidate>
      <button class ="btn btn-large mt-2" type="submit">Submit</button>
    </form>
  </div>
</div>
