import {Component, EventEmitter, OnInit} from '@angular/core';
import {
  FormGroup,
  ReactiveFormsModule,
  FormsModule,
  FormControl,
  Validators,
  FormBuilder,
  FormGroupName
} from '@angular/forms';
import {HostLoginService} from '../../../services/host-login.service';
import {UserInfo} from '../../../models/user-info';
import {Router} from '@angular/router';
import {UserInfoService} from '../../../services/user-info.service';
import {CookieService} from 'ngx-cookie-service';
import {AuthGuardService} from '../../../guards/auth-guard.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {ErrorMsg} from '../../../models/error';
import {AuthService, FacebookLoginProvider, SocialUser} from 'angularx-social-login';
import {StateListService} from '../../../services/state-list.service';
import {HostService} from '../../../services/host.service';
declare var jQuery:any;

@Component({
  selector: 'app-host-login',
  templateUrl: './host-login.component.html',
  styleUrls: ['./host-login.component.css']
})
export class HostLoginComponent implements OnInit {

  public loginForm: FormGroup;
  public registerForm: FormGroup;
  public resetPasswordForm: FormGroup;
  public results: any;
  public errorMsg: any;
  public errorMsg2: any;
  public error: any;
  public successMsg: any;
  public registrationSuccess: any;
  public stateList: any;
  public companyList: any;
  public user: SocialUser;
  public loggedIn: boolean;
  public loader: boolean = true;

  constructor(
    private loginService: HostLoginService,
    private stateService: StateListService,
    private authService: AuthGuardService,
    private router: Router,
    private userInfoService: UserInfoService,
    private cookieService: CookieService,
    public http: HttpClient,
    private socialAuthService: AuthService,
    private hostService: HostService
  ) {
    if (this.authService.isAuthenitcated()) {
      this.router.navigate(['host/profile']);
    }
  }

  ngOnInit() {
    jQuery('body').removeClass('model-open');
    jQuery('.modal-backdrop').remove();
    this.createLoginForm();
    this.createRegisterForm();
    this.createResetPasswordForm();
    this.socialAuthService.authState.subscribe((user) => {
      this.user = user;
      this.loader = false;
      this.loggedIn = (user != null);
    });

    this.stateService.getStates()
      .subscribe(
        (res: any) => this.stateList = res.results
      );
    this.stateService.getCompanies().subscribe(
      res => this.companyList = res.results
    );
  }

  createLoginForm() {
    this.loginForm = new FormGroup({
      username: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6)
      ]),
    });
  }

  createRegisterForm() {
    this.registerForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      first_name: new FormControl('', [
        Validators.required
      ]),
      last_name: new FormControl('', [
        Validators.required
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(1)
      ]),
      re_password: new FormControl('', [
        Validators.required,
        Validators.minLength(1)
      ]),
      profile: new FormGroup({
        city: new FormControl('', [
          Validators.required
        ]),
        state: new FormControl(null, [
          Validators.required
        ]),
        company: new FormControl(null, [
          Validators.required
        ])
      })
    });
  }

  createResetPasswordForm() {
    this.resetPasswordForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ])
    });
  }

  loginHost() {
    this.loader = true;
    let data = JSON.stringify(this.loginForm.value);
    this.results = this.loginService.login(data);
    this.results.subscribe(
      (result: UserInfo) => {
        this.userInfoService.setUser(result);
        let date = new Date();
        let time = date.getTime();
        let hours = 1;
        date.setTime(time + (hours * 60 * 60 * 1000));
        let oneDay = 24 * 60 * 60 * 1000;
        let firstDate = new Date();
        let secondDate = new Date(date);
        let cookieDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));

        //this.hostService.clearData();
        sessionStorage.clear();
        localStorage.clear();
        this.cookieService.deleteAll();

        this.cookieService.set('TrivTrak_USERNAME', result.user.email, cookieDays, '/');
        this.cookieService.set('TrivTrak_TOKEN', result.token, cookieDays, '/');
        this.cookieService.set('TrivTrak_refresh', result.refresh, cookieDays, '/');
        this.cookieService.set('TrivTrak_USER', JSON.stringify(result.user), cookieDays, '/');
        localStorage.setItem('TrivTrak_USERNAME', result.user.email);
        localStorage.setItem('TrivTrak_TOKEN', result.token);
        localStorage.setItem('TrivTrak_refresh', result.refresh);
        localStorage.setItem('TrivTrak_USER', JSON.stringify(result.user));
        sessionStorage.setItem('TrivTrak_USERNAME', result.user.email);
        sessionStorage.setItem('TrivTrak_TOKEN', result.token);
        sessionStorage.setItem('TrivTrak_refresh', result.refresh);
        sessionStorage.setItem('TrivTrak_USER', JSON.stringify(result.user));
        delete this.errorMsg;
        this.loader = false;
        return this.router.navigate(['host/profile']);
      },
      (error: ErrorMsg) => {
        this.loader = false;
        delete this.successMsg;
        if (error.password) {
          return this.errorMsg = error.password;
        }
        if (error.username) {
          return this.errorMsg = error.username;
        }
        if(error.inactive) {
          return this.errorMsg = error.inactive;
        }
      }
    );
  }

  registerHost() {
    this.loader = true;
    let data = JSON.stringify(this.registerForm.value);
    this.results = this.loginService.register(data);
    this.results.subscribe(
      (result) => {
        this.successMsg = 'Congratulations, An Email was sent to your Email Address. Follow the link in the Email to activate your acccount';
        this.loader = false;
        jQuery('#form-register').trigger('reset');
        delete this.errorMsg;
        return result;
      },
      (error: ErrorMsg) => {
        this.loader = false;
        this.errorMsg2 = {
          'email': error.email ? error.email : null,
          'password': error.password ? error.password : null,
          're_password': error.re_password ? error.re_password : null,
        };
        delete this.successMsg;
        return this.errorMsg;
      }
    );
  }

  sendResetEmail() {
    this.loader = true;
    let data = this.resetPasswordForm.value;
    data['domain_name'] = environment.domain;

    this.results = this.loginService.resetPassword(data);

    this.results.subscribe(
      (result) => {
        this.loader = false;
        delete this.errorMsg;
        jQuery('#form-reset').trigger('reset');
        jQuery('#forgotPassModal').modal('hide');
        jQuery('#sendSuccessModal').modal('show');
        return this.successMsg = "Your password reset mail sent successfully.";
      },
      (error: ErrorMsg) => {
        this.loader = false;
        delete this.successMsg;
        return this.errorMsg = error.email;
      }
    );
  }

  clearHistory() {
    delete this.errorMsg;
    delete this.successMsg;
  }

  signInWithFB() {
    let response = this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
    response.then(value => {
      if (value) {
        let data = {
          'facebook_id': value.id,
          'name': value.name,
          'email': value.email,
          'image_url': value.photoUrl,
          'first_name': value.firstName,
          'last_name': value.lastName
        };
        this.results = this.loginService.FBLogin(data);
        this.results.subscribe(
          (result: UserInfo) => {
            this.userInfoService.setUser(result);
            let date = new Date();
            let time = date.getTime();
            let hours = 1;
            date.setTime(time + (hours * 60 * 60 * 1000));
            let oneDay = 24 * 60 * 60 * 1000;
            let firstDate = new Date();
            let secondDate = new Date(date);
            let cookieDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));

            //this.hostService.clearData();
            sessionStorage.clear();
            localStorage.clear();
            this.cookieService.deleteAll();

            this.cookieService.set('TrivTrak_USERNAME', result.user.email, cookieDays, '/');
            this.cookieService.set('TrivTrak_TOKEN', result.token, cookieDays, '/');
            this.cookieService.set('TrivTrak_USER', JSON.stringify(result.user), cookieDays, '/');
            localStorage.setItem('TrivTrak_USERNAME', result.user.email);
            localStorage.setItem('TrivTrak_TOKEN', result.token);
            localStorage.setItem('TrivTrak_USER', JSON.stringify(result.user));
            sessionStorage.setItem('TrivTrak_USERNAME', result.user.email);
            sessionStorage.setItem('TrivTrak_TOKEN', result.token);
            sessionStorage.setItem('TrivTrak_USER', JSON.stringify(result.user));
            delete this.errorMsg;
            return this.router.navigate(['host/profile']);

          },
          (error: ErrorMsg) => {
            this.errorMsg = {
              'email': error.email ? error.email : '',
              'password': error.password ? error.password : '',
              're_password': error.re_password ? error.password : ''
            };
            delete this.successMsg;
            return this.errorMsg;
          }
        );
      }
    });
  }

  getStates() {
    this.http.get<any>(environment.BASE_API_URL + 'accounts/states/')
      .subscribe((res: any) => this.stateList = res.results);
  }

  addOpacity() {
    jQuery(document).ready(function() {
      jQuery('.modal.fade').css('opacity', 1);
      jQuery('.modal.fade').addClass('show');
      let cls = document.getElementsByClassName("modal-backdrop").length;
      if (cls>0) {
        jQuery('div.modal-backdrop').addClass('show');
        jQuery('div.modal-backdrop').css('opacity', 0.8);
      }
    });
  }
}
