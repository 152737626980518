import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  public faqs: any;
  public totalFAQ: any;
  constructor(private http: HttpClient) {
    this.http.get<any>(environment.BASE_API_URL + 'faq/')
      .subscribe((result) => {
        this.totalFAQ = result.results;
        // this.faqs = result.results;
        this.filterFAQ("player");
      });
  }

  ngOnInit() {
  }

  filterFAQ(profileTYPE){
    this.faqs = this.totalFAQ.filter(function(item){return item.profile_type==profileTYPE});
  }

}
