import { Injectable } from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  constructor(private cookieService: CookieService) { }

  public user = {
    id: '0',
    first_name: '',
    last_name: '',
    email: '',
    my_team: '',
    is_guest: '',
    profile: ''
  };

  setUser(data) {
    if (data && data.token) {
      this.user = Object.assign({}, this.user, data.user);
      return true;
    }
    return false;
  }

  getUser() {
    return this.user;
  }

  clearUser() {
    this.user = {
      id: '0',
      first_name: '',
      last_name: '',
      email: '',
      my_team: '',
      is_guest: '',
      profile: ''
    };
    sessionStorage.removeItem('TrivTrak_TOKEN');
    sessionStorage.removeItem('TrivTrak_USERNAME');
    localStorage.removeItem('TrivTrak_TOKEN');
    localStorage.removeItem('TrivTrak_USERNAME');
    this.cookieService.deleteAll();
  }
}
