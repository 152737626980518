<div class="title-container mt-3">
  <div class="page-t">
    <div>
      <div class="page-title">
        <b><span>Round</span> : <span>{{ currentRound  }}</span></b>
      </div>
    </div>
  </div>
</div>

<div class="teamcard m-3  hovercard" *ngIf="errorMsg2">
  <div class="alert alert-danger alert-dismissible">
    <button type="button" class="close" data-dismiss="alert">&times;</button>
    {{errorMsg2}}
  </div>
</div>

<div class="teamcard m-3 hovercard" *ngIf="!errorMsg2" >

  <div class="alert alert-success alert-dismissible" *ngIf="successMsg">
    <button type="button" class="close" data-dismiss="alert">&times;</button>
    {{successMsg}}
  </div>

  <div class="alert alert-danger alert-dismissible" *ngIf="errorMsg">
    <button type="button" class="close" data-dismiss="alert">&times;</button>
    {{errorMsg}}
  </div>

  <div class="card-header d-flex justify-content-between">
    <div style="margin: auto 0;" class="d-flex">
      <div>
        <span>Company: {{user.profile.company_name}}</span>
      </div>
      <div class="text-left mr-2 ml-2" *ngIf="venueDetails">
        <span>{{user.profile.company_name}}</span>
      </div>

    </div>
    <div style="margin: auto 0;" class="d-flex bar-title">
      <div>
        <span>Venue: </span>
      </div>
      <div class="text-left mr-2 ml-2" *ngIf="venueDetails">
        <span>{{venueDetails.name}}</span>
      </div>
    </div>

    <div style="margin: auto 0;" class="d-flex bar-title">
      <div>
        <span>STAGE: </span>
      </div>
      <div class="text-left mr-2 ml-2" *ngIf="venueDetails">
        <span>{{venueDetails.stage_name}}</span>
      </div>
    </div>

    <div style="margin: auto 0;">
      <a style="background-color:white; white-space: normal;font-size: 12px;" class="btn btn-sm mr-1 mb-1" data-id="{{gameId}}" onclick="window.open('/#/host/presentation/'+ this.id ,'popUpWindow','height=500,width=1130,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=yes');" role="button">PRESENT</a>
    </div>
  </div>
</div>

<div class="rank-table" *ngIf="gameTeams && game">
  <div class="tbl1">
    <table class="table" mdbTable [attr.value]="game.max_round">
      <thead>
      <tr>
        <th (click)="sortTableEvent('rank')" width="25%" class="rkname" scope="col"><div >RK</div></th>
        <th (click)="sortTableEvent('name')" width="25%" class="teamname" scope="col" ><div >TEAM</div></th>
        <th (click)="sortTableEvent('total_score')" width="25%" scope="col" class="ttlname"><div >TTL</div></th>
        <th [mdbTableSort]="gameTeams" width="25%" class="pname" sortBy="number_of_player"  scope="col" ><div >P</div></th>
      </tr>
      </thead>
      <tbody *ngIf="gameTeams">
      <tr *ngFor="let team of gameTeams" [ngClass]="{'unregistered': !team.has_registered_player}">
        <td scope="row"><div >{{team.rank}}</div></td>
        <td class="teamname">
          <a style="cursor: pointer;" data-target="#remove-team-modal"data-toggle="modal" (click)="openRemoveTeamModal(team.id, team.name)">{{team.name}}</a>
        </td>
        <td><div >{{team.total_score}}</div></td>
        <td *ngIf="team.total_registered_player"><div >{{team.total_registered_player}}</div></td>
        <td *ngIf="!team.total_registered_player">0</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="tbl2">
    <div class=" table-responsive">
      <table class="table" mdbTable  [attr.value]="game.max_round">
        <thead>
        <tr>
          <th *ngFor="let item of [].constructor(game.max_round + 1); let i = index" class="inputbox">
            {{i+1}}
          </th>
        </tr>
        </thead>
        <tbody *ngIf="gameTeams">
        <tr *ngFor="let team of gameTeams" [ngClass]="{'unregistered': !team.has_registered_player}">
          <td *ngFor="let score of team.scores" class="inputbox">
            <input type="number" min="0" step="any" class="score-input" (change)="saveScore(team.id, score.round_number, $event.target.value)" [value]="score.score" *ngIf="score.score"/><!--Variable is not null display score-->
            <input type="number" min="0" step="any" class="score-input" (change)="saveScore(team.id, score.round_number, $event.target.value)" [value]="0" *ngIf="!score.score"/><!--Variable is null display 0-->
          </td>
          <td style="width: 70px;" max="10" maxlength="2" *ngIf="!game.end_date" class="lastColumn">
            <input type="number" min="0" maxlength="2" step="any" class="score-input" (change)="pushScore(team.id, $event.target.value)" tabindex="1" [value]="currentScores[team.id]" *ngIf="currentScores[team.id]!=0" />
            <input type="number" min="0" maxlength="2" step="any" class="score-input" (change)="pushScore(team.id, $event.target.value)" tabindex="1" *ngIf="currentScores[team.id]==0" />
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<br>

<div class="footer" id="footer2" *ngIf="!errorMsg2 && game">

  <button class="button" data-toggle="modal" data-target="#add-team-modal" [disabled]="game.end_date" (click)="addOpacity">Add Team</button>

  <button class="button" data-toggle="modal" data-target="#import-modal" [disabled]="game.end_date" (click)="addOpacity();" *ngIf="displayImport">Import Scores</button>

  <button class="button" [disabled]="game.end_date" [disabled]="!generate" (click)="generateQuestions();">Create Answer Sheet</button>

  <button class="button" data-toggle="modal" [disabled]="game.end_date" (click)="getAnswersAlert()">Grade Answers</button>

  <button class="button" data-toggle="modal" data-target="#end-round-modal" [disabled]="game.end_date" (click)="addOpacity();">Next</button>

  <button class="button" data-toggle="modal" data-target="#end-game-modal" [disabled]="game.end_date" (click)="addOpacity();">End Game</button>

</div>

<div class="add-team-modal">
  <div class="modal fade" id="add-team-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Add Team</span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="modal-btn mr-3 ml-3 mb-3">
            <button type="button" data-target="#create-team-modal" pushScore data-toggle="modal" class="btn btn-large mt-2" (click)="addOpacity();">New Team</button>
            <button type="button" data-target="#search-team-modal" data-dismiss="modal" data-toggle="modal" class="btn btn-large mt-2" (click)="addOpacityExist();">Existing Team</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="add-team-modal">
  <div class="modal fade" id="limit-team-modal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
          <div class="modal-header">
            <span class="modal-title">Team Limit Reached</span>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" class="fa fa-times"></span>
            </button>
          </div>
          <div class="modal-container">
            <div class="modal-body" style="text-align: center; font-family: 'BourtonBase';">
                <span style="font-size: 25px;">You can not add a team</span>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>


<div class="create-team-modal">
  <div class="modal fade" id="create-team-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Add Team</span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger alert-dismissible" *ngIf="errorMsg">
            {{errorMsg}}
          </div>
          <form [formGroup]="teamForm" (ngSubmit)="joinTeam(null)" id="createTeam">
            <input type="text" class="form-control" placeholder="Team Name *" formControlName="team_name"/>
            <div class="modal-btn ">
              <button type="submit" class="button btn btn-large mt-2" [disabled]="!teamForm.valid">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="search-team-modal" *ngIf="venueTeams">
  <div class="modal fade" id="search-team-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Add Team</span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="venue-tbl m-3"  >
            <select class="form-control" (change)="getVenueTeams(team.value);" #team>
              <option value="">Select Venue</option>
              <option value="{{venue.id}}" *ngFor="let venue of venues" >{{venue.name}}</option>
            </select>
            <div class="searchbox">
              <input type="text" [(ngModel)]="term" placeholder="Search Team *">
              <button type="submit"><i class="fa fa-search"></i></button>
            </div>
            <div style="height: 400px; overflow: auto;">
              <table class="table-hover">
                <thead>
                <tr>
                  <th>Team Name</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of venueTeams | filter:term ">
                  <td><a style="cursor: pointer;" (click)="joinTeam(item.id)">{{item.name}}</a></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="remove-team-modal">
  <div class="modal fade" id="remove-team-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title" *ngIf="removeTeamId">Remove Team  "{{removeTeamId.name}}"?</span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="modal-btn mr-3 ml-3 mb-3">
            <button type="button" data-dismiss="modal" class="btn btn-large mt-2" (click)="removeTeam(removeTeamId.id)" >Yes</button>
            <button type="button" data-dismiss="modal" class="btn btn-large mt-2">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="end-round-modal">
  <div class="modal fade" id="end-round-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Is Round Complete?</span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="modal-btn mr-3 ml-3 mb-3">
            <button type="button" data-dismiss="modal" (click)="saveGameData()" class="btn btn-large mt-2">Yes</button>
            <button type="button" data-dismiss="modal" class="btn btn-large mt-2">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="import-modal">
  <div class="modal fade" id="import-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Select File to Import Scores</span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="modal-btn mr-3 ml-3 mb-3">
            <button type="button" (click)="getCSVTemplate()" class="btn btn-large mt-2">Download Template</button>
            <input type="file" #csvReader name="Upload Scores" id="txtFileUpload" (change)="uploadListener($event)" accept=".csv" style="padding-top: 5;"/>
            <button type="button" (click)="processImport()" data-dismiss="modal" class="btn btn-large mt-2">Submit</button>
            <button type="button" data-dismiss="modal" class="btn btn-large mt-2">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="import-report-modal">
  <div class="modal fade" id="import-report-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Scores Import Report</span>
          <button type="button" class="close" data-dismiss="modal" (click)="closeReport()" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times"></span>
          </button>
        </div>
        <div class="modal-body">
          <div *ngIf="reportLine(numScored)" style="color:limegreen">{{numScored}} of {{gameTeams.length}} team scores imported for teams in game! </div>
          <div *ngIf="numScored !== gameTeams.length" style="color:lightcoral">{{gameTeams.length - numScored}} of {{gameTeams.length}} team scores not imported from the import file.</div>
          <hr>
          <div *ngIf="reportLine(numNotFound)" style="color:lightcoral">{{numNotFound}} team scores are in game but are not in import file. </div>
          <div *ngIf="reportLine(numIgnored)" style="color:lightcoral">{{numIgnored}} team scores are not on scoreboard but are in import file.</div>
          <div *ngIf="reportLine(numInvalid)" style="color:lightcoral">{{numInvalid}} team scores are not numbers in import file.</div>
          <div *ngIf="reportLine(numDuplicate)" style="color:lightcoral">{{numDuplicate}} team scores are duplicates in import file. </div>
          <div class="modal-btn mr-3 ml-3 mb-3">
            <button type="button" data-dismiss="modal" (click)="closeReport()" class="btn btn-large mt-2">Continue</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="end-game-modal">
  <div class="modal fade" id="end-game-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Do you want to end game?</span>
          <button type="button"   class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="fa fa-times"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="modal-btn mr-3 ml-3 mb-3">
            <button type="button" data-dismiss="modal" (click)="endGame()" class="btn btn-large mt-2">Yes</button>
            <button type="button" data-dismiss="modal" class="btn btn-large mt-2">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loader" *ngIf="loader">
  <div class="loaderbx">
    <i class="fas fa-3x fa-spinner fa-spin" aria-hidden="true"></i>
  </div>
</div>
