    <div class="title-container mt-3">
        <div class="page-t">
            <div class="page-title" style="margin: auto;">
                <b>RESULTS</b>
            </div>
        </div>
    </div>
    <div *ngIf="gameData">
        <div *ngIf="gameData.length > 0">

        <div class="resultcard m-3" *ngFor="let game of gameData let i = index">
            <div *ngIf="game.teams.length">
                    <div class="card-header navbar">
                      <img src="assets/img/logo1.png" alt="TRIVTRAK LOGO" width="4%" class="text-left" style="float: left;">
                      <span style="text-align:center !important; margin:auto">{{game.venue.name}}</span>
                      <span style="text-align:right !important">{{game.end_date| date: 'EEEE, LLLL dd ' }}</span>
                    </div>
                  <div class="resultlistcard">
                    <ul class="list-unstyled">
                      <!--<li [ngClass]="{'rank-1 mr-3':(j==0)?true:false, 'rank-2 second': (j==1)?true:false}" *ngFor="let team of game.teams|slice:0:2 let j = index">-->
                      <li  *ngFor="let team of game.teams|slice:0:5 let j = index">
                        <img [src]="team.image != null ? team.image : 'assets/img/pic1.jpg'">
                        <div class="rank-left"><p>{{j + 1}}</p></div>
                        <span >{{team.name}}</span>

                      </li>

                      <div class="bottom">
                        <a class="btn btn-md" [routerLink]="['/score-card', game.id]" role="button">VIEW SCOREBOARD</a>
                      </div>
                    </ul>
                    <div class="clearfix"></div>
                  </div>
        <!--            <div class="top">-->
        <!--                <div [ngClass]="{'rank-1 mr-3':(j==0)?true:false, 'rank-2 second': (j==1)?true:false}" *ngFor="let team of game.teams|slice:0:2 let j = index">-->
        <!--                    <img [src]="team.image != null ? team.image : 'assets/img/pic1.jpg'">-->
        <!--                    <div class="corner-left"></div>-->
        <!--                    <div class="rank-left">{{j + 1}}</div>-->
        <!--                    <span class="mt-2">{{team.name}}</span>-->
        <!--                </div>-->
        <!--                <div class="mid">-->
        <!--                    <div class="mid-text">-->
        <!--                        <div class="d-flex" *ngFor="let team of game.teams|slice:2:5 let k = index">-->
        <!--                            <span class="mr-2">{{k+3}}</span> -->
        <!--                            <span>{{team.name}}</span>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>
                    <div class="bottom pb-3 pt-3">
                            <a class="btn btn-md" [routerLink]="['/score-card', game.id]" role="button">VIEW SCOREBOARD</a>
                    </div> -->
                    </div>
        </div>
        </div>
    </div>

    <div *ngIf="!gameData && !loader">
        <div class="card-header navbar">
            <span style="text-align:center !important; margin:auto">NO GAMES ENDED IN PAST 48 HOURS</span>
      </div>
    </div>

<div class="loader" *ngIf="loader">
  <div class="loaderbx">
    <i class="fas fa-3x fa-spinner fa-spin" aria-hidden="true"></i>
  </div>
</div>
